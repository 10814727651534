import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatalogRoutingModule } from './catalog-routing.module';
import { CatalogComponent } from './catalog.component';
import { SharedModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { CityComponent } from './city/city.component';

@NgModule({
  declarations: [CatalogComponent, CityComponent],
  imports: [CommonModule, TranslateModule, SharedModule, CatalogRoutingModule]
})
export class CatalogModule {}
