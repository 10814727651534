import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { PlaceRoutingModule } from './place-routing.module';
import { PlaceComponent } from '@app/place/place.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BarRatingModule } from 'ngx-bar-rating';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { ProductComponent } from './product/product.component';
import { MatRadioModule } from '@angular/material/radio';
//import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MatSelectModule } from '@angular/material/select';
import { ClipboardModule } from 'ngx-clipboard';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { LocationModalComponent } from '@app/home/location/modal/location.modal';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    PlaceRoutingModule,
    FormsModule,
    SlickCarouselModule,
    MatButtonModule,
    MatCardModule,
    BrowserAnimationsModule,
    BarRatingModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatMenuModule,
    MatRadioModule,
    //LeafletModule,
    MatSelectModule,
    ClipboardModule,
    RoundProgressModule,
    LazyLoadImageModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatBadgeModule
  ],
  declarations: [PlaceComponent, ProductComponent, InfoDialogComponent]
})
export class PlaceModule {}
