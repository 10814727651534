import { Pipe, PipeTransform } from '@angular/core';
import { normalizeSync } from 'normalize-diacritics';

@Pipe({
  name: 'normalizeString'
})
export class NormalizeStringPipe implements PipeTransform {
  transform(value: any): string {
    if (value) {
      return normalizeSync(value).replace(/\s+/g, '-');
    } else {
      return '';
    }
  }
}
