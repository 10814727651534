import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize, map, catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, I18nService, AuthenticationService, untilDestroyed, CredentialsService } from '@app/core';
import { AuthService, SocialUser, GoogleLoginProvider } from 'angularx-social-login';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../model/user.model';

const log = new Logger('Login');
@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss']
})
export class HeaderHomeComponent implements OnInit {
  target: string;
  menuHidden = true;
  public user: SocialUser;
  userCoins: Number = 0;
  //public user = new SocialUser();
  private loggedIn: boolean;
  error: string | undefined;
  isLoading = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private i18nService: I18nService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.target = environment.target;
    this.authService.authState.subscribe(user => {
      log.debug(user);

      if (user) {
        this.getUserCoins(user.id);
        log.debug(`${user.name} google said: successfully logged in`);
        this.user = user;
        log.debug(`${this.user.name} google said: successfully logged in`);
        const login$ = this.authenticationService.login(this.user);
        login$
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
            untilDestroyed(this)
          )
          .subscribe(
            credentials => {
              log.debug(`${credentials.name} successfully logged in`);
              this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
            },
            error => {
              log.debug(`Login error: ${error}`);
              this.error = error;
            }
          );
      } else {
        this.user = null;
      }
    });
  }

  ngOnDestroy() {}

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  login() {
    log.debug('Logowanie');
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  getUserCoins(userId: string) {
    this.http
      .skipLoader()
      .post('https://system.abspos.pl/api/getUser', {
        uid: userId
      })
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not send request'))
      )

      .subscribe(
        (res: UserModel) => {
          this.userCoins = res.abscoin;
        },
        (err: any) => {}
      );
  }

  logout() {
    this.user = null;
    this.authenticationService.logout();
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.name : null;
  }
}
