import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderedProductModel, SelectedComponentModel } from '@app/shared/model/order.model';
import { TranslateService } from '@ngx-translate/core';
import { IngService } from '../../ing.service';
import { Subscription, timer, BehaviorSubject } from 'rxjs';
import { switchMap, first, take, skip } from 'rxjs/operators';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, LoadChildren, Router } from '@angular/router';
@Component({
  selector: 'app-blik',
  templateUrl: './blik.component.html',
  styleUrls: ['./blik.component.scss']
})
export class BlikComponent implements OnInit, OnDestroy {
  //id_sale: string;
  id_sale: string;

  subscription: Subscription;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BlikComponent>,
    private router: Router,
    private ingService: IngService,
    public translate: TranslateService
  ) {
    this.id_sale = data.id_sale;
    //console.log(this.orderedProduct, 'ordered');
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    console.log(this.id_sale);
    this.subscription = timer(0, 10000)
      .pipe(switchMap(() => this.ingService.Check(this.id_sale)))
      .subscribe(result => {
        if (result?.transaction?.status === 'settled') {
          if (isPlatformBrowser(this.platformId)) {
            this.subscription.unsubscribe();
            this.router.navigate(['/confirm', result.transaction.orderId]);
            this.dialogRef.close();
          }
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
