import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, I18nService, AuthenticationService, untilDestroyed } from '@app/core';
import { AuthService, SocialUser, GoogleLoginProvider } from 'angularx-social-login';

const log = new Logger('Login');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public user: SocialUser;
  //public user = new SocialUser();
  private loggedIn: boolean;
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: UntypedFormGroup;
  isLoading = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private i18nService: I18nService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authService.authState.subscribe(user => {
      log.debug(user);
      if (user) {
        log.debug(`${user.name} google said: successfully logged in`);
        this.user = user;
        log.debug(`${this.user.name} google said: successfully logged in`);
        const login$ = this.authenticationService.login(this.user);
        login$
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
            untilDestroyed(this)
          )
          .subscribe(
            credentials => {
              log.debug(`${credentials.name} successfully logged in`);
              this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
            },
            error => {
              log.debug(`Login error: ${error}`);
              this.error = error;
            }
          );
      }
    });
  }

  ngOnDestroy() {}

  signInWithGoogle(): void {
    log.debug('Logowanie');
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }

  login() {
    this.isLoading = true;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }
}
