import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@app/core/http/loader.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  target: string;
  loading: boolean;
  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.target = environment.target;
    this.loaderService.isLoading.subscribe(v => {
      //console.log(v);
      this.loading = v;
    });
  }
}
