<div class="emptylocation-modal">
  <h1 mat-dialog-title></h1>
  <div mat-dialog-content>
    <h1 class="title title-thin" [innerHTML]="'SEARCH_SLOGAN' | translate"></h1>
    <div class="new_search">
      <p></p>
      <p>Nie znaleziono położenia miejsca dostawy według wprowadzonego adresu.</p>
      <p>Spróbuj ponownie wprowadzić adres i wybierz z listy odpowiednią podpowiedz.</p>
    </div>
  </div>
</div>
