// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  hostUrl: 'https://wciagnij.to',
  local_host: '',
  target: 'web',
  production: false,
  hmr: false,
  version: env.npm_package_version,
  serverUrl: 'https://system.abspos.pl/api',
  defaultLanguage: 'pl',
  supportedLanguages: ['pl', 'en'],
  defaultDistance: 10,
  paylanelogin: '37e4ce98e1314db8f4cacd614408d045',
  paylanepassword: 'thE6%DRA8*STO1*b',
  googleMapsKey: 'AIzaSyALeXl_qhMVEeAacsK_QKLNDDXYeyBZIzo',
  secret: 'temRzBuxFBJUpxeKAOzV2sMJT9QyjB5n'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
