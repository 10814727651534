<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <div class="oferta_container" style="min-height:  320px; display: flex; flex-direction: column;">
      <h1 class="text-center page-title">PRACA</h1>
      <div style="display: flex; flex: 1; justify-content: center; align-items: center;">
        <p class="no-offers">
          Obecnie nie prowadzimy rekrutacji.<br />
          Możesz nam zostawić swoje CV, wysyłając je na adres: <a href="mailto:biuro@abspos.pl">biuro@abspos.pl</a>
        </p>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
