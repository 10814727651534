interface ICartStateProps extends Partial<CartState> {}
export class CartState {
  isOpen: boolean = true;
  isLoading: boolean = false;

  constructor(fieldsObj?: ICartStateProps) {
    if (fieldsObj) {
      // Object.keys(fieldsObj).forEach((key) => {
      //   console.log('key', key);
      //   this[key] = fieldsObj[key];
      // });
      Object.assign(this, fieldsObj);
    }
  }
}

interface IStateProps extends Partial<State> {}
export class State {
  cart: CartState = new CartState();
  consume_type: number;

  constructor(fieldsObj?: IStateProps) {
    if (fieldsObj) {
      Object.assign(this, fieldsObj);
    }
  }
}
