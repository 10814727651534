import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, LoadChildren, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger, CredentialsService } from '../../core/index';
import { environment } from '../../../environments/environment';
import { RevLocationIQContext } from '../../shared/reversegeocode/reversegeocode.service';
import {
  DeliveryModel,
  OrderedProductModel,
  ProductDiscount,
  ProductDiscountDetailed
} from '../../shared/model/order.model';
import { BillModel } from '../../shared/model/bill.model';
import { payMethods, payMethod } from '../../shared/model/paymethods.model';
import { SessionService } from '../../core/session.service';
import { PaylaneService } from '@app/checkout/paylane.service';
import { IngService } from '@app/checkout/ing.service';
import { finalize } from 'rxjs/operators';
import { APIRestaurantsContext } from '@app/search/nearest/nearest.service';
import { ToastrService } from 'ngx-toastr';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

import {
  CardsSaleModel,
  Cards3DModel,
  CardsSaleResponse,
  Cards3DResponse,
  CustomerModel,
  TransferSaleModel,
  TransferSaleResponse,
  BlikModel,
  BlikResponse,
  CustomerBlikModel
} from '@app/shared/model/payline.model';

import {
  IngCardsSaleModel,
  IngCards3DModel,
  IngCardsSaleResponse,
  IngCards3DResponse,
  IngCustomerModel,
  IngTransferSaleModel,
  IngTransferSaleResponse,
  IngBlikModel,
  IngBlikResponse,
  IngCustomerBlikModel,
  IngActionModel
} from '@app/shared/model/ing.model';

import {
  DiscountRequestModel,
  DiscountRequestProductsModel,
  DiscountResponseModel,
  DiscountResponseProductDiscountsModel
} from '@app/shared/model/discount.model';
import { CartModel } from '@app/shared/model/cart.model';
import { IpService } from '@app/core/ip.service';
import { OrderService } from '@app/checkout/checkout/order.service';
import { DiscountService } from '@app/checkout/checkout/discount.service';
import { PlaceModel } from '@app/shared/model/place.model';
import { MenuAttributeModel, MenuComponentModel, MenuProductModel } from '@app/shared/model/menuProduct.model';
import { SelectedComponentModel } from '@app/shared/model/order.model';
import { ProductModel } from '@app/shared/model/product.model';
import { LoaderService } from '@app/core/http/loader.service';
import { UserLocation } from '@app/shared/model/userLocation';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from './modal/modal.component';
import { BlikComponent } from './blik/blik.component';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { FormControl, NgForm } from '@angular/forms';
import { ɵINTERNAL_SERVER_PLATFORM_PROVIDERS } from '@angular/platform-server';
import { HttpClient } from '@angular/common/http';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
const log = new Logger('checkout');

@Injectable()
export class NgbTimeMiliAdapter extends NgbTimeAdapter<number> {
  fromModel(value: number | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    const date = new Date(value);
    return {
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: 0
    };
  }

  toModel(time: NgbTimeStruct | null): number | null {
    if (time == null) {
      return null;
    }
    const date = new Date();
    date.setHours(time.hour);
    date.setMinutes(time.minute);
    console.log(date, date.getTime());
    return date.getTime();
  }
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeMiliAdapter }]
})
export class CheckoutComponent implements OnInit {
  debug = false;
  id_sale: any;
  target: string;
  mobNumberPattern = '^((\\+91-?)|0)?[0-9]{9}$';
  discountCode: string = '';
  DiscountResponse: DiscountResponseModel;
  loading: boolean;
  buttonLoading = false;
  blikCode: string;
  delivery: DeliveryModel = {
    address: null,
    post_code: null,
    city: null,
    apart_number: null,
    floor_number: null,
    cage: null,
    phone_number: null,
    client_name: null,
    email: null,
    access_code: null,
    price: 0,
    price_net: 0,
    price_vat: 0,
    show_as_product: false,
    exist_in_other: false,
    delivery_type: 0,
    geo: {
      lat: 0,
      lon: 0
    },
    distance: 0,
    delivery_pay_mode: 0
  };

  deliveryAddress = {
    street: '',
    number: ''
  };

  bill: BillModel = {
    id: null,
    type: 'bill',
    flag: 0,
    timestamp: null,
    uid: this.userId(),
    local_id: null,
    status: 2,
    consume_type: null,
    name: null,
    create: null,
    verified: 0,
    payment_method: [],
    payment_doc: 0,
    tax_number: null,
    invoice_url: null,
    payment_status: 2,
    orders_id: [],
    user_id: null,
    source: 3,
    hall_id: null,
    discount: null,
    sum: 0,
    sum_net: 0,
    sum_vat: 0,
    user_close_id: null,
    split_bills: [],
    coins: 0,
    webhook: false,
    exist_discount: false,
    tip: null,
    service_fee: null
  };

  cart: CartModel = {
    id: null,
    slug: null,
    name: null,
    order: null,
    bill: null,
    place: null
  };

  userLocation: UserLocation;

  customer: CustomerModel = {
    name: null,
    email: this.delivery.email,
    ip: null,
    address: {
      street_house: null,
      city: null,
      state: null,
      zip: null,
      country_code: 'PL'
    }
  };

  customerBlik: CustomerBlikModel = {
    name: null,
    email: null,
    ip: null
  };

  customerIng: IngCustomerModel = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    ip: null
  };

  IngcustomerBlik: IngCustomerBlikModel = {
    firstName: null,
    lastName: null,
    email: null,
    ip: null
  };

  cardsale: Cards3DModel = {
    sale: {
      amount: 0,
      currency: 'PLN',
      description: null,
      fraud_check_on: undefined,
      avs_check_level: undefined,
      id_sub_merchant: 1,
      service_fee_amount: 0
    },
    customer: this.customer,
    card: {
      card_number: null,
      expiration_month: '',
      expiration_year: '',
      name_on_card: '',
      card_code: ''
    },
    back_url: 'https://system.abspos.pl/api/3dsecure'
  };

  transfersale: IngTransferSaleModel = {
    sale: {
      amount: 0,
      currency: 'PLN',
      description: '1111',
      id_sub_merchant: 1,
      service_fee_amount: 0
    },
    customer: this.customerIng,
    payment_type: undefined
  };

  bliksale: BlikModel = {
    sale: {
      amount: 0,
      currency: 'PLN',
      description: '1111',
      id_sub_merchant: 1,
      service_fee_amount: 0
    },
    customer: this.customerBlik,
    code: null
  };

  Ingbliksale: IngBlikModel = {
    sale: {
      amount: 0,
      currency: 'PLN',
      description: '1111',
      id_sub_merchant: 1,
      service_fee_amount: 0
    },
    customer: this.IngcustomerBlik,
    code: null
  };

  transfers: any = [
    {
      type: 'alior',
      icon: 'AB.jpg'
    },

    {
      type: 'mtransfer',
      icon: 'MT.jpg'
    },
    {
      type: 'inteligo',
      icon: 'IN.jpg'
    },
    {
      type: 'millennium',
      icon: 'MI.jpg'
    },
    {
      type: 'creditagricole',
      icon: 'CA.png'
    },
    {
      type: 'pocztowy',
      icon: 'PCZ.jpg'
    },
    {
      type: 'ideabank',
      icon: 'IB.jpg'
    },
    {
      type: 'pekao24',
      icon: 'PO.jpg'
    },
    {
      type: 'getin',
      icon: 'GB.jpg'
    },
    {
      type: 'ing',
      icon: 'IG.jpg'
    },
    {
      type: 'bzwbk',
      icon: 'WB.png'
    },
    {
      type: 'bnpparibas',
      icon: 'PB.png'
    },
    {
      type: 'bnpparibas',
      icon: 'CT.jpg'
    },
    {
      type: 'plusbank',
      icon: 'PL.jpg'
    },
    {
      type: 'noble',
      icon: 'NP.png'
    },
    /* {
            type: 'BS',
            icon: 'BS.jpg'
        },*/
    {
      type: 'nest',
      icon: 'NB.png'
    },

    {
      type: 'pbs',
      icon: 'PBS.jpg'
    },
    {
      type: 'bs',
      icon: 'SGB.jpg'
    }
    // {
    //   type: 'BLIK',
    //   icon: 'BLIK.png'
    // }
  ];

  selected_delivery: number;

  theme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: 'white',
      buttonColor: '#009606'
    },
    dial: {
      dialBackgroundColor: '#009606'
    },
    clockFace: {
      clockFaceBackgroundColor: '#fafafa',
      clockHandColor: '#009606',
      clockFaceTimeInactiveColor: '#009606'
    }
  };

  payMethods: payMethods = {
    gpay_gateway_key: null,
    gpay_gateway_name: null,
    methods: [],
    id: null,
    type: null
  };

  tip = [0];
  times: number[] = [];
  isDisableTimePicker = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private session: SessionService,
    private paylane: PaylaneService,
    private ing: IngService,
    private ip: IpService,
    private orderAPI: OrderService,
    private discountService: DiscountService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private credentialsService: CredentialsService,
    private http: HttpClient
  ) {
    this.loaderService.isLoading.subscribe(v => {
      //console.log(v);
      this.loading = v;
    });

    this.paylane
      .PayMethods()
      .pipe(finalize(() => {}))
      .subscribe((quote: payMethods) => {
        if (quote) {
          this.payMethods = quote;
          //log.error(this.payMethods);
        }
      });
  }

  openDialog(item: OrderedProductModel): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '720px',
      data: {
        item
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.CalculateOrder();
      this.session.setSessionKey('cart', this.cart);
    });
  }

  openBlikDialog(id_sale: string): void {
    const dialogRef = this.dialog.open(BlikComponent, {
      width: '300px',
      data: {
        id_sale
      }
    });
  }

  CheckPayMethods(find_id: number): boolean {
    //log.error(typeof(this.payMethods.methods));
    if (this.payMethods.methods.length > 0) return this.payMethods.methods.find(x => x.id === find_id).value;
    else return false;
  }

  async ngOnInit() {
    this.target = environment.target;
    // this.ip.getIpAddress().subscribe(data => {
    //   log.debug(data);
    //   this.cardsale.customer.ip = data.ip;
    // });
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('userLocation')) {
        this.userLocation = JSON.parse(localStorage.getItem('userLocation'));
        this.delivery.geo.lat = this.userLocation.lat;
        this.delivery.geo.lon = this.userLocation.lng;
        this.delivery.distance = 2;
        this.delivery.address =
          this.userLocation.street === '' ? '' : this.userLocation.street + ' ' + this.userLocation.house_number || '';
        this.delivery.post_code = this.userLocation.post_code;
        this.delivery.city = this.userLocation.city;

        this.deliveryAddress.street = this.userLocation.street;
        this.deliveryAddress.number = this.userLocation.house_number;

        log.debug(this.userLocation);
      }
    }
    if (this.cookieService.get('customerData')) {
      var customerTMP = JSON.parse(this.cookieService.get('customerData'));
      if (customerTMP) {
        this.customer.name = customerTMP.name;
        this.customer.email = customerTMP.email;
        this.customer.ip = customerTMP.ip;

        if (customerTMP.address) {
          if (customerTMP.address.street_house) {
            this.customer.address.street_house = customerTMP.address.street_house;

            this.cardsale.customer.address.street_house = this.customer.address.street_house;
          }
          if (customerTMP.address.city) {
            this.customer.address.city = customerTMP.address.city;

            this.cardsale.customer.address.city = this.customer.address.city;
          }
          if (customerTMP.address.state) {
            this.customer.address.state = customerTMP.address.state;
          }

          if (customerTMP.address.zip) {
            this.customer.address.zip = customerTMP.address.zip;
            this.cardsale.customer.address.zip = this.customer.address.zip;
          }

          if (customerTMP.address.country_code) {
            this.customer.address.country_code = customerTMP.address.country_code;
            this.cardsale.customer.address.country_code = this.customer.address.country_code;
          }
        }
        this.bliksale.customer.name = this.customer.name;
        this.bliksale.customer.email = this.customer.email;
        this.delivery.email = this.customer.email;

        this.transfersale.customer.email = this.customer.email;

        this.cardsale.customer.name = this.customer.name;
        this.cardsale.customer.email = this.customer.email;
      }
    }

    if (this.cookieService.get('deliveryData')) {
      var deliveryTMP = JSON.parse(this.cookieService.get('deliveryData'));
      if (deliveryTMP) {
        this.delivery.phone_number = deliveryTMP.phone_number;
        this.delivery.client_name = deliveryTMP.client_name;
        this.delivery.email = deliveryTMP.email;
      }
    }

    /*if (localStorage.getItem('cart')) {
      this.cart = JSON.parse(localStorage.getItem('cart'));
      for (const item of this.cart) {
        item.order.delivery = this.delivery;
        item.bill = this.bill;
      }
    }*/

    log.debug(this.session.checkSessionKey('cart'));

    this.cart = this.session.getSessionKey('cart');
    log.debug(this.cart.order.consume_type, 'Order');
    // log.debug(this.cart.bill.consume_type, 'BIll');
    if (!this.cart.bill) {
      this.cart.bill = this.bill;
      this.bill.consume_type = this.cart.order.consume_type;
      this.cart.bill.consume_type = this.cart.order.consume_type;
    } else {
      this.bill.consume_type = this.cart.order.consume_type;
    }

    this.cart.order.delivery = this.delivery;
    this.cart.order.delivery.distance = await this.getDistance(this.delivery);
    this.CalculateOrder();
    this.getTimes();

    console.log(this.cart);

    // if (!this.cart.place.id_sub_merchant) this.bill.payment_method = [1];
    // else this.bill.payment_method = [];
  }

  // Polecane - slider
  slickAdConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: "<div class='slick-prev'><i class='fas fa-chevron-left'></i></div>",
    nextArrow: "<div class='slick-next'><i class='fas fa-chevron-right'></i></div>",
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  recommended: any = [];

  // ctrl = new FormControl<number | null>(null, (control: FormControl<number | null>) => {
  //   const value = new Date(control.value);

  //   if (!value || !this.open || !this.close || this.close.getTime() === -1) {
  //     return null;
  //   }

  //   const minTime = new Date();
  //   minTime.setHours(minTime.getHours() + 1);
  //   minTime.setSeconds(0);

  //   if (value.getHours() >= this.close.getHours() - 1 && value.getMinutes() >= this.close.getMinutes()) {
  //     return { tooLate: true };
  //   }
  //   if (value < minTime) {
  //     return { minTime: true, value: minTime };
  //   }
  //   // if (value.getHours() < this.open.getHours() && value.getMinutes() < this.open.getMinutes()) {
  //   // 	return { tooEarly: true };
  //   // }

  //   return null;
  // });

  // getOpenCloseTime() {
  //   const dayIndex = new Date().getDay() - 1;
  //   const currentWeekDayIndex = dayIndex >= 0 ? dayIndex : 6;

  //   this.open = new Date(this.cart.place.work_time[currentWeekDayIndex].open);
  //   this.close = new Date(this.cart.place.work_time[currentWeekDayIndex].close);
  // }

  getTimes() {
    const dayIndex = new Date().getDay() - 1;
    const currentWeekDayIndex = dayIndex >= 0 ? dayIndex : 6;

    const close = this.cart.place.work_time[currentWeekDayIndex].close;
    const current = new Date();
    current.setHours(current.getHours() + 1);
    const currentTime = new Date('01/01/1970 ' + current.toLocaleTimeString());
    const currentTimeMili = currentTime.getTime();
    const start = currentTime;
    start.setMinutes(0);
    start.setSeconds(0);
    const startMili = start.getTime();

    let end = close;
    if (close === -1) {
      const endDay = currentTime;
      endDay.setHours(23, 59, 59, 999);
      end = endDay.getTime();
    }
    let i = startMili;
    while (i < end) {
      if (i >= currentTimeMili) {
        this.times.push(i);
      }
      i += 900000;
    }

    this.isDisableTimePicker = this.times.length === 0;
  }

  async CalculateOrder() {
    log.debug(this.cart.bill);
    console.log('calcualte-order');
    this.cart.bill.sum = 0;
    this.cart.bill.sum_vat = 0;
    this.cart.bill.sum_net = 0;
    this.cart.order.sum = 0;
    this.cart.order.sum_vat = 0;
    this.cart.order.sum_net = 0;
    for (const item of this.cart.order.products) {
      item.discount = null;
      var disc: DiscountResponseProductDiscountsModel[] = this.CalculateDiscount(item);
      console.log(disc);

      var tmp_price = {
        price: JSON.parse(JSON.stringify(item.price)),
        price_vat: JSON.parse(JSON.stringify(item.price_vat)),
        price_net: JSON.parse(JSON.stringify(item.price_net))
      };

      if (disc)
        if (disc.length) {
          item.discount = {
            enabled: true,
            sum_vat: item.price_vat * item.count,
            sum: item.price * item.count,
            sum_net: item.price_net * item.count,
            items: disc
          };

          for (var i = 0; i < item.discount.items.length; i++) {
            if (item.discount.items[i].discount_unit == 1) {
              tmp_price.price = tmp_price.price - tmp_price.price * (item.discount.items[i].discount_value / 100);
              tmp_price.price_vat = Math.round(tmp_price.price * (item.product.vat / 100 / 100) * 100) / 100;
              tmp_price.price_net = tmp_price.price - tmp_price.price_vat;
            }
            if (item.discount.items[i].discount_unit == 2) {
              tmp_price.price = tmp_price.price - item.discount.items[i].discount_value;
              tmp_price.price_vat = Math.round(tmp_price.price * (item.product.vat / 100 / 100) * 100) / 100;
              tmp_price.price_net = tmp_price.price - tmp_price.price_vat;
            }
          }
        }

      this.cart.order.sum = this.cart.order.sum + tmp_price.price * item.count;
      this.cart.order.sum_vat = this.cart.order.sum_vat + tmp_price.price_vat * item.count;
      this.cart.order.sum_net = this.cart.order.sum_net + tmp_price.price_net * item.count;

      item.sum = tmp_price.price * item.count;
      item.sum_vat = tmp_price.price_vat * item.count;
      item.sum_net = tmp_price.price_net * item.count;

      for (const p of item.linked.values()) {
        p.count = item.count;
        p.sum = p.price * item.count;
        p.sum_vat = p.price_vat * item.count;
        p.sum_net = p.price_net * item.count;

        this.cart.order.sum = this.cart.order.sum + p.price * item.count;
        this.cart.order.sum_vat = this.cart.order.sum_vat + p.price_vat * item.count;
        this.cart.order.sum_net = this.cart.order.sum_net + p.price_net * item.count;
      }
    }
    var dd = this.CalculateDeliveryPrice();
    this.cart.order.delivery.price = dd.sum;
    this.cart.order.delivery.price_vat = dd.sum_vat;
    this.cart.order.delivery.price_net = dd.sum_net;

    // this.cart.bill.tip = this.tip[0] === 0 ? null : Math.round(((this.cart.order.sum * this.tip[0]) / 100) * 100) / 100;
    // const serviceFee = Math.round(this.cart.order.sum * 0.1 * 100) / 100;
    // this.cart.bill.service_fee = serviceFee > 8 ? 8 : serviceFee;

    this.cart.bill.sum = this.cart.order.sum + this.cart.order.delivery.price; //+ this.cart.bill.service_fee + this.cart.bill.tip;
    this.cart.bill.sum_vat = this.cart.order.sum_vat + this.cart.order.delivery.price_vat;
    this.cart.bill.sum_net = this.cart.order.sum_net + this.cart.order.delivery.price_net;

    // if (this.DiscountResponse.bill.length){
    //   var billDiscount: ProductDiscount;

    //   for (const item of this.DiscountResponse.bill){
    //     var item_pom:ProductDiscountDetailed;

    //       item_pom.id = item.id;
    //       item_pom.name = item.name;
    //       item_pom.auto = item.auto;
    //       item_pom.discount_type = item.discount_type;
    //       item_pom.discount_unit = item.discount_unit;
    //       item_pom.discount_value = item.discount_value;
    //       item_pom.connection_type = item.connection_type;
    //       this.cart.order.sum = this.cart.order.sum
    //       this.cart.bill.sum = this.cart.order.sum + this.cart.order.delivery.price;
    //       this.cart.bill.sum_vat = this.cart.order.sum_vat + this.cart.order.delivery.price_vat;
    //       this.cart.bill.sum_net = this.cart.order.sum_net + this.cart.order.delivery.price_net;

    //       billDiscount.items.push(item);
    //   }
    //   this.cart.bill.discount = billDiscount;
    // }

    this.cardsale.sale.amount = this.cart.bill.sum;
    this.transfersale.sale.amount = this.cart.bill.sum;
    this.bliksale.sale.amount = this.cart.bill.sum;
  }

  CalculateDiscount(product: any): DiscountResponseProductDiscountsModel[] {
    if (this.DiscountResponse) {
      var total_ret: DiscountResponseProductDiscountsModel[] = [];
      if (this.DiscountResponse.products.length) {
        var tmp = this.DiscountResponse.products.find(x => x.id === product.id);
        if (tmp) {
          this.cart.bill.exist_discount = true;
          total_ret.concat(tmp.discounts);
        }
      }
      if (this.DiscountResponse.bill) {
        var tmp2 = this.DiscountResponse.bill;
        if (tmp2) {
          this.cart.bill.exist_discount = true;

          total_ret.push(tmp2);
        }
      }
      return total_ret;
    } else {
      return [];
    }
  }

  CalculateDeliveryPrice = () => {
    var price = {
      sum: 0,
      sum_vat: 0,
      sum_net: 0
    };

    if (this.bill.consume_type == 1) {
      if (this.cart.order.sum < this.cart.place.delivery_sum_mode || this.cart.place.delivery_sum_mode === 0) {
        if (this.cart.place.delivery_price_range?.length) {
          this.cart.place.delivery_price_range.sort((a, b) => a.maxDistance - b.maxDistance);

          const priceRange = this.cart.place.delivery_price_range.find(
            range => this.cart.order.delivery.distance <= range.maxDistance
          );

          console.log(priceRange, 'range');
          price.sum = priceRange ? priceRange.price : 0;
          price.sum_vat = Math.round(price.sum * (this.cart.place.delivery_vat / 100 / 100) * 100) / 100;
          price.sum_net = price.sum - price.sum_vat;
        } else {
          console.log(this.cart.place.delivery_price, 'delivery_price');
          price.sum = this.cart.place.delivery_price;
          price.sum_vat = Math.round(price.sum * (this.cart.place.delivery_vat / 100 / 100) * 100) / 100;
          price.sum_net = price.sum - price.sum_vat;
        }
      }
    }

    return price;
  };

  CalculateDeliveryPriceOLD() {
    var price = {
      sum: 0,
      sum_vat: 0,
      sum_net: 0
    };

    /**
       *
       *
       * Math.round(price.product_price*
       ((this.orderedProduct.product.vat/100)/100) * 100) / 100;
       * @type {number}
       */

    if (this.bill.consume_type == 1) {
      console.log(this.cart.order.sum);
      if (this.cart.order.sum < this.cart.place.delivery_sum_mode || this.cart.place.delivery_sum_mode === 0) {
        price.sum = this.cart.place.delivery_price;
        price.sum_vat = Math.round(price.sum * (this.cart.place.delivery_vat / 100 / 100) * 100) / 100;
        price.sum_net = price.sum - price.sum_vat;
      }
    }

    return price;
  }

  changeDlivery(change: MatRadioChange) {
    let can_change = true;

    for (var i = 0; i < this.cart.order.products.length; i++) {
      if (this.cart.order.products[i].product.consume_type.indexOf(change.value) > -1) {
      } else {
        can_change = false;
      }
    }

    if (can_change) {
      this.bill.consume_type = change.value;
      this.cart.bill.consume_type = change.value;
      this.cart.order.consume_type = change.value;
      this.tip = [0];
      this.CalculateOrder();
    } else {
      this.toastr.warning(
        'W koszyku masz produkty niedostępne w wybranym sposobie dostawy. Nie mozesz zmienić sposobu dostawy'
      );
    }
  }

  changePayment(change: MatRadioChange) {
    this.bill.payment_method[0] = change.value;
    this.CalculateOrder();
  }

  changeDocument(change: MatRadioChange) {
    this.bill.payment_doc = change.value;
    this.CalculateOrder();
  }

  async ChangeData() {
    log.debug(this.delivery, 'delivery');
    this.delivery.address = this.deliveryAddress.street + ' ' + this.deliveryAddress.number;

    this.cart.order.delivery = this.delivery;

    this.cart.bill = this.bill;
    this.cart.order.delivery.distance = await this.getDistance(this.delivery);

    this.userLocation.street = this.deliveryAddress.street;
    this.userLocation.house_number = this.deliveryAddress.number;

    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('userLocation', JSON.stringify(this.userLocation));
    }

    this.CalculateOrder();
    this.session.setSessionKey('cart', this.cart);
  }

  Minus(p: number, i: number) {
    this.cart.order.products[i].count = this.cart.order.products[i].count - 1;

    if (this.cart.order.products[i].count === 0) {
      this.Remove(p, i);
    }
    this.CalculateOrder();

    if (this.cart.order.products.length === 0) {
      this.session.setSessionKey('cart', undefined);
      this.router.navigate([
        '/restauracja',
        this.translate.currentLang,
        this.userLocation.city.toLowerCase(),
        this.cart.slug
      ]);
      return;
    }
    this.session.setSessionKey('cart', this.cart);
  }

  Plus(p: number, i: number) {
    this.cart.order.products[i].count = this.cart.order.products[i].count + 1;

    if (this.cart.order.products[i].count === 0) {
      this.Remove(p, i);
    }
    this.CalculateOrder();
    this.session.setSessionKey('cart', this.cart);
  }

  Remove(p: number, i: any) {
    this.cart.order.products.splice(i, 1);
    this.session.setSessionKey('cart', this.cart);
    this.CalculateOrder();
  }

  CheckOrder() {
    var isok: boolean = false;
  }

  PayByCard3D() {
    this.cardsale.customer.email = this.delivery.email;
    this.cardsale.sale.amount = this.cart.bill.sum;
    this.cardsale.sale.description = this.cart.bill.id;
    this.cardsale.sale.id_sub_merchant = this.cart.place.id_sub_merchant;
    this.cardsale.sale.service_fee_amount =
      Math.round(this.cart.bill.sum * (this.cart.place.service_fee_percent / 100 / 100) * 100) / 100;

    this.paylane
      .Card3DAuth(this.cardsale)
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: Cards3DResponse) => {
        if (quote) {
          //log.debug(quote);
          if (quote.success) {
            this.session.setSessionKey('cart', undefined);
            this.CalculateOrder();
            if (isPlatformBrowser(this.platformId)) {
              window.location.href = quote.redirect_url;
            }

            // if (quote.id_3dsecure_auth && quote.is_card_enrolled) {
            //   this.session.setSessionKey('cart', undefined);
            //   this.CalculateOrder();
            //   window.location.href = quote.redirect_url;
            // } else if (quote.id_3dsecure_auth && !quote.is_card_enrolled) {
            //   this.paylane
            //     .Card3DSale({ id_3dsecure_auth: quote.id_3dsecure_auth })
            //     .pipe(
            //       finalize(() => {
            //         // this.isLoading = false;
            //       })
            //     )
            //     .subscribe((quote: CardsSaleResponse) => {
            //       if (quote) {
            //         log.debug(quote);
            //         if (quote.success) {
            //           this.cart.order.products = [];
            //           this.session.setSessionKey('cart', undefined);
            //           this.CalculateOrder();
            //           //this.router.navigate(['/confirm/', this.cart.order.id]);
            //           this.router.navigate(['/confirm', { id: this.cardsale.sale.description, status: 2 }]);
            //         }
            //       }
            //     });
            // }
          } else {
            this.toastr.warning('Błąd przetwazania płatności:' + quote.error_desc);
          }
        }
      });
  }

  PayByCard() {
    //this.cardsale.customer.email = this.delivery.email;
    this.cardsale.sale.amount = this.cart.bill.sum;
    this.cardsale.sale.description = this.cart.bill.id;
    this.cardsale.sale.id_sub_merchant = this.cart.place.id_sub_merchant;
    this.cardsale.sale.service_fee_amount =
      Math.round(this.cart.bill.sum * (this.cart.place.service_fee_percent / 100 / 100) * 100) / 100;
    this.paylane
      .CardSale(this.cardsale)
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: CardsSaleResponse) => {
        if (quote) {
          log.debug(quote);
          if (quote.success) {
            this.cart.order.products = [];
            this.session.setSessionKey('cart', this.cart);
            this.CalculateOrder();
            this.router.navigate(['/confirm/', { id: this.cardsale.sale.description, status: 2 }]);
          }
        }
      });
  }

  PayByTransfer() {
    //this.transfersale.customer = this.customer;
    //this.transfersale.customer.email = this.delivery.email;
    this.transfersale.sale.amount = this.cart.bill.sum;
    this.transfersale.sale.description = this.cart.bill.id;

    this.ing
      .TransferSale(this.transfersale)
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: IngTransferSaleResponse) => {
        if (quote) {
          log.debug(quote);
          if (quote.success) {
            this.cart.order.products = [];
            this.session.setSessionKey('cart', this.cart);
            if (isPlatformBrowser(this.platformId)) {
              let action: IngActionModel = quote.action;
              if (quote.action.type === 'redirect') {
                if (quote.action.method === 'GET') {
                  window.location.href = quote.action.url;
                }
                if (quote.action.method === 'POST') {
                  const mapForm = document.createElement('form');
                  mapForm.method = quote.action.method; // or "post" if appropriate
                  mapForm.action = quote.action.url;
                  var query = quote.action.contentBodyRaw;
                  console.log(query);
                  var obj = this.parseQuery(query);
                  console.log(obj);

                  Object.keys(obj).forEach(param => {
                    console.log(param);
                    console.log(obj[param]);
                    const mapInput = document.createElement('input');
                    mapInput.type = 'hidden';
                    mapInput.name = param;
                    mapInput.setAttribute('value', this.encodeHTML(obj[param]));
                    mapForm.appendChild(mapInput);
                  });
                  document.body.appendChild(mapForm);
                  console.log(mapForm);
                  mapForm.submit();
                }
              }
            }
          } else {
            this.toastr.warning('Błąd przetwazania płatności');
          }
        }
      });
  }

  parseQuery(queryString: string) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  encodeHTML(str: string) {
    return str.replace(/([\u00A0-\u9999<>&])(.|$)/g, function(full, char, next) {
      if (char !== '&' || next !== '#') {
        if (/[\u00A0-\u9999<>&]/.test(next)) next = '&#' + next.charCodeAt(0) + ';';

        return '&#' + char.charCodeAt(0) + ';' + next;
      }

      return full;
    });
  }

  PayByBlik() {
    this.bliksale.sale.amount = this.cart.bill.sum;
    this.bliksale.sale.description = this.cart.bill.id;
    this.bliksale.sale.id_sub_merchant = this.cart.place.id_sub_merchant;
    this.bliksale.sale.service_fee_amount =
      Math.round(this.cart.bill.sum * (this.cart.place.service_fee_percent / 100 / 100) * 100) / 100;
    this.bliksale.code = this.blikCode;
    this.paylane
      .BlikSale(this.bliksale)
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: BlikResponse) => {
        if (quote) {
          log.debug(quote);
          if (quote.success) {
            this.cart.order.products = [];
            this.session.setSessionKey('cart', this.cart);
            if (isPlatformBrowser(this.platformId)) {
              this.router.navigate(['/confirm', this.cart.bill.id]);
            }
          } else {
            this.toastr.warning('Błąd przetwarzania płatności');
          }
        }
      });
  }

  PayByBlikIng() {
    this.Ingbliksale.sale.amount = this.cart.bill.sum;
    this.Ingbliksale.sale.description = this.cart.bill.id;
    this.Ingbliksale.sale.id_sub_merchant = this.cart.place.id_sub_merchant;
    this.Ingbliksale.sale.service_fee_amount =
      Math.round(this.cart.bill.sum * (this.cart.place.service_fee_percent / 100 / 100) * 100) / 100;
    this.Ingbliksale.code = this.blikCode;
    this.ing
      .BlikSale(this.Ingbliksale)
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: IngBlikResponse) => {
        this.blikCode = '';
        if (quote) {
          log.debug(quote);
          if (quote.success) {
            this.id_sale = quote.id_sale;
            this.openBlikDialog(quote.id_sale);
            this.cart.order.products = [];
            this.session.setSessionKey('cart', this.cart);
            if (isPlatformBrowser(this.platformId)) {
              this.router.navigate(['/confirm', this.cart.bill.id]);
            }
          } else {
            this.blikCode = '';
            this.toastr.warning('Błąd przetwarzania płatności');
          }
        }
      });
  }

  PickTransfer(type: string) {
    this.transfersale.payment_type = type;
  }

  validate() {
    let is_ok: boolean = true;
    if (this.cart.order.sum < this.cart.place.min_order_price && this.bill.consume_type) {
      is_ok = false;
      this.toastr.warning('Zamówienie poniżej minimalnej wartości zamówienia.');
    }

    if (this.bill.consume_type == 1) {
      if (!this.delivery.address || !this.delivery.address.trim()) is_ok = false;
      if (!this.delivery.post_code) is_ok = false;
      if (!this.delivery.city) is_ok = false;
      if (!is_ok) {
        this.toastr.warning('Brak adresu dostawy');
      }
    }

    if (!this.delivery.client_name) is_ok = false;
    if (!this.delivery.phone_number) is_ok = false;
    if (!this.delivery.email) is_ok = false;

    if (
      this.bill.payment_method[0] !== 1 &&
      this.bill.payment_method[0] !== 16 &&
      this.bill.payment_method[0] !== 12 &&
      this.bill.payment_method[0] !== 912 &&
      this.bill.payment_method[0] !== 8
    ) {
      if (!this.customer.name) is_ok = false;
      if (!this.customer.address.street_house) is_ok = false;
      if (!this.customer.address.city) is_ok = false;
      if (!this.customer.address.zip) is_ok = false;
      if (!is_ok) {
        this.toastr.warning('Brak danych klienta');
      }
    }

    if (this.bill.payment_method[0] == 12) {
      if (!this.bliksale.customer.name) is_ok = false;
      if (!this.bliksale.customer.email) is_ok = false;
      if (!this.blikCode) is_ok = false;
      if (!is_ok) {
        this.toastr.warning('Brak danych dla płatności typu Blik');
      }
    }

    if (this.bill.payment_method[0] == 912) {
      if (!this.Ingbliksale.customer.firstName) is_ok = false;
      if (!this.Ingbliksale.customer.lastName) is_ok = false;
      if (!this.Ingbliksale.customer.email) is_ok = false;
      if (!this.blikCode) is_ok = false;
      if (!is_ok) {
        this.toastr.warning('Brak danych dla płatności typu Blik');
      }
    }

    if (this.bill.payment_method[0] == 8) {
      if (!this.transfersale.customer.firstName) is_ok = false;
      if (!this.transfersale.customer.lastName) is_ok = false;

      if (!this.transfersale) is_ok = false;
      if (!is_ok) {
        this.toastr.warning('Przelew bankowy - brak danych klienta');
      }
    }

    if (this.bill.payment_method[0] === 2) {
      if (!this.cardsale.card.card_number) is_ok = false;
      if (!this.cardsale.card.expiration_month) is_ok = false;
      if (!this.cardsale.card.expiration_year) is_ok = false;
      if (!this.cardsale.card.name_on_card) is_ok = false;
      if (!this.cardsale.card.card_code) is_ok = false;
    }

    if (this.bill.payment_method[0] === 8) {
      if (!this.transfersale.payment_type) {
        is_ok = false;
        this.toastr.warning('Nie wybrałeś typu płatności online - zaznacz swój bank');
      }
    }

    if (this.bill.payment_doc === 2) {
      if (!this.bill.tax_number || this.bill.tax_number.length === 0) is_ok = false;
    }

    return is_ok;
  }

  async PlaceOrder() {
    const isDeliverable = await this.isAddressDeliverable(this.delivery);
    if (!isDeliverable) {
      this.toastr.error('Niestety nie dostarczamy pod wskazane miejsce. Prosimy wpisać dokładny adres dostawy.');
      return;
    }

    if (this.validate()) {
      this.cookieService.set('deliveryData', JSON.stringify(this.delivery));

      this.cart.order.uid = this.userId();
      if (this.bill.consume_type === 1) {
        //this.AddDeliveryProduct();
      }

      if (this.bill.payment_method[0] === 1) {
        this.CashOrder();
      }
      if (this.bill.payment_method[0] === 16) {
        this.DeliveryCardOrder();
      }
      if (this.bill.payment_method[0] === 2) {
        this.cardsale.customer.email = this.delivery.email;
        this.cookieService.set('customerData', JSON.stringify(this.cardsale.customer));
        this.CardOrder();
      }
      if (this.bill.payment_method[0] === 8) {
        this.transfersale.customer.email = this.delivery.email;
        this.cookieService.set('customerData', JSON.stringify(this.transfersale.customer));
        this.TransferOrder();
      }
      if (this.bill.payment_method[0] === 12) {
        this.cookieService.set('customerData', JSON.stringify(this.bliksale.customer));
        this.BlikOrder();
      }
      if (this.bill.payment_method[0] === 912) {
        this.cookieService.set('customerData', JSON.stringify(this.Ingbliksale.customer));
        this.BlikOrderIng();
      }
    } else {
      this.toastr.warning('Uzupełnij wszystkie wymagana dane');
    }
  }

  // AddDeliveryProduct() {
  //   if (this.bill.consume_type === 1 && this.cart.order.delivery.price > 0) {
  //     const new_product: OrderedProductModel = {
  //       product: this.cart.place.delivery_product,
  //       id: this.cart.place.delivery_product.id,
  //       attribute: this.cart.place.delivery_product.attributes[0],
  //       components: [],
  //       count: 1,
  //       order: 1,
  //       comment: '',
  //       status: 2,
  //       price: this.cart.order.delivery.price,
  //       price_net: this.cart.order.delivery.price_net,
  //       price_vat: this.cart.order.delivery.price_vat,
  //       sum: this.cart.order.delivery.price,
  //       sum_net: this.cart.order.delivery.price_net,
  //       sum_vat: this.cart.order.delivery.price_vat,
  //       discount: this.cart.
  //     };
  //     this.cart.order.products.push(new_product);
  //     this.cart.order.sum = this.cart.order.sum + this.cart.order.delivery.price;
  //     this.cart.order.sum_vat = this.cart.order.sum_vat + this.cart.order.delivery.price_vat;
  //     this.cart.order.sum_net = this.cart.order.sum_net + this.cart.order.delivery.price_net;
  //   }
  // }

  DeliveryCardOrder() {
    this.bill.local_id = this.cart.place.id;
    this.bill.payment_status = 2;

    const data = {
      bill: this.bill,
      order: this.cart.order
    };

    this.orderAPI
      .setOrder(data)
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: any) => {
        log.debug(quote);
        if (quote.bill_id && quote.order_id) {
          this.cart.bill.id = quote.bill_id;
          this.cart.order.id = quote.order_id;

          this.session.setSessionKey('cart', undefined);
          this.session.setSessionKey('ordered', quote);
          this.router.navigate(['/confirm', quote.bill_id]);
        }
      });
  }

  CashOrder() {
    this.bill.local_id = this.cart.place.id;
    this.bill.payment_status = 2;

    const data = {
      bill: this.bill,
      order: this.cart.order
    };

    this.orderAPI
      .setOrder(data)
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: any) => {
        log.debug(quote);
        if (quote.bill_id && quote.order_id) {
          this.cart.bill.id = quote.bill_id;
          this.cart.order.id = quote.order_id;

          this.session.setSessionKey('cart', undefined);
          this.session.setSessionKey('ordered', quote);
          this.router.navigate(['/confirm', quote.bill_id]);
        }
      });
  }

  CardOrder() {
    this.bill.local_id = this.cart.place.id;
    this.bill.payment_status = 2;

    const data = {
      bill: this.bill,
      order: this.cart.order
    };

    this.orderAPI
      .setOrder(data)
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: any) => {
        log.debug(quote);
        if (quote.bill_id && quote.order_id) {
          this.cart.bill.id = quote.bill_id;
          this.cart.order.id = quote.order_id;

          this.session.setSessionKey('ordered', quote);
          this.PayByCard3D();
          //this.PayByCard();
        }
      });
  }

  TransferOrder() {
    this.bill.local_id = this.cart.place.id;
    this.bill.payment_status = 2;

    const data = {
      bill: this.bill,
      order: this.cart.order
    };

    this.orderAPI
      .setOrder(data)
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: any) => {
        log.debug(quote);
        if (quote.bill_id && quote.order_id) {
          this.cart.bill.id = quote.bill_id;
          this.cart.order.id = quote.order_id;

          this.session.setSessionKey('ordered', quote);
          this.PayByTransfer();
          //this.router.navigate(['/confirm/', quote.bill_id]);
        }
      });
  }

  BlikOrder() {
    this.bill.local_id = this.cart.place.id;
    this.bill.payment_status = 2;

    const data = {
      bill: this.bill,
      order: this.cart.order
    };

    this.orderAPI
      .setOrder(data)
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: any) => {
        log.debug(quote);
        if (quote.bill_id && quote.order_id) {
          this.cart.bill.id = quote.bill_id;
          this.cart.order.id = quote.order_id;

          this.session.setSessionKey('ordered', quote);
          this.PayByBlik();
          //this.router.navigate(['/confirm/', quote.bill_id]);
        }
      });
  }

  BlikOrderIng() {
    if (this.cart.bill.id && this.cart.order.id && this.id_sale) {
      this.PayByBlikIng();
    } else {
      this.bill.local_id = this.cart.place.id;
      this.bill.payment_status = 2;
      //this.bill.payment_method[0] === 12;

      const data = {
        bill: this.bill,
        order: this.cart.order
      };

      this.orderAPI
        .setOrder(data)
        .pipe(
          finalize(() => {
            // this.isLoading = false;
          })
        )
        .subscribe((quote: any) => {
          log.debug(quote);
          if (quote.bill_id && quote.order_id) {
            this.cart.bill.id = quote.bill_id;
            this.cart.order.id = quote.order_id;

            this.session.setSessionKey('ordered', quote);
            this.PayByBlikIng();
            //this.router.navigate(['/confirm/', quote.bill_id]);
          }
        });
    }
  }

  userId(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.id : null;
  }

  CheckCode() {
    this.buttonLoading = true;
    var productsToCheck: DiscountRequestProductsModel[] = [];
    for (const item of this.cart.order.products) {
      productsToCheck.push({ category_id: item.product.category, count: item.count, id: item.id, sum: item.sum });
    }

    var checkData: DiscountRequestModel = {
      code: this.discountCode,
      local_id: this.cart.place.id,
      consume_type: this.bill.consume_type,
      source: 3,
      products: productsToCheck,
      user: this.delivery.email
    };
    this.discountService
      .getDiscountCode(checkData)
      .pipe(
        finalize(() => {
          this.buttonLoading = false;
          // this.isLoading = false;
        })
      )
      .subscribe((quote: DiscountResponseModel) => {
        log.debug(quote);
        this.DiscountResponse = quote;
        console.log(this.DiscountResponse);
        if (quote.bill) {
          this.CalculateOrder();
        } else {
          this.toastr.error('Podany kod rabatowy nie istnieje.');
        }
      });
  }

  changeFirstName(value: string) {
    this.Ingbliksale.customer.firstName = value;
    this.transfersale.customer.firstName = value;
    this.delivery.client_name = value + ' ' + this.Ingbliksale.customer.lastName;
  }

  changeLastName(value: string) {
    this.Ingbliksale.customer.lastName = value;
    this.transfersale.customer.lastName = value;
    this.delivery.client_name = this.Ingbliksale.customer.firstName + ' ' + value;
  }

  changeEmail() {
    const email = JSON.parse(JSON.stringify(this.delivery.email));
    this.bliksale.customer.email = email;
    this.Ingbliksale.customer.email = email;
    this.transfersale.customer.email = email;
  }

  changePhoneNumber() {
    this.transfersale.customer.phone = JSON.parse(JSON.stringify(this.delivery.phone_number));
  }

  // changeImieNazwisko() {
  //   this.bliksale.customer.name = this.delivery.client_name;
  //   this.cardsale.customer.name = this.delivery.client_name;
  // }

  isAddressDeliverable = async (address: any) => {
    if (this.bill.consume_type != 1) {
      return Promise.resolve(true);
    }

    console.log(this.cart.place, 'place');
    const reqData = {
      delivery: {
        address: `${address.address}`,
        city: address.city,
        state: address.state,
        country: address.country,
        post_code: address.post_code
      },
      sub_local_geo: this.cart.place.geo
    };

    return new Promise(async (resolve, reject) => {
      const result: any = await this.http
        .skipLoader()
        .post('https://system.abspos.pl/api/driver/getDistance', reqData)
        .toPromise();
      if (result) {
        if (this.cart.place.delivery_price_range?.length) {
          const maxDistance = Math.max.apply(
            Math,
            this.cart.place.delivery_price_range.map(range => range.maxDistance)
          );

          return resolve(maxDistance >= result.distance);
        } else {
          resolve(this.cart.place.maxDistance >= result.distance);
        }
      } else {
        resolve(false);
      }
    });
  };

  getDistance = async (address: any): Promise<number> => {
    if (this.bill.consume_type != 1) {
      return Promise.resolve(0);
    }

    console.log(this.cart.place, 'place');
    const reqData = {
      delivery: {
        address: `${address.address}`,
        city: address.city,
        state: address.state,
        country: address.country,
        post_code: address.post_code
      },
      sub_local_geo: this.cart.place.geo
    };

    return new Promise(async (resolve, reject) => {
      const result: any = await this.http
        .skipLoader()
        .post('https://system.abspos.pl/api/driver/getDistance', reqData)
        .toPromise();
      if (result) {
        if (this.cart.place.delivery_price_range?.length) {
          const maxDistance = Math.max.apply(
            Math,
            this.cart.place.delivery_price_range.map(range => range.maxDistance)
          );

          return resolve(result.distance);
        } else {
          resolve(result.distance);
        }
      } else {
        resolve(0);
      }
    });
  };

  tipChange(event: any) {
    let toggle = event.source;
    if (toggle) {
      if (event.value.some((item: any) => item == toggle.value)) {
        this.tip = [toggle.value];
      }
    } else {
      this.tip = [0];
    }
    this.CalculateOrder();
  }

  timeSet(time: String) {
    const date = new Date();
    date.setHours(Number(time.substring(0, 2)));
    date.setMinutes(Number(time.substring(3, 5)));
    this.cart.order.take_away_time = date.getTime();
  }

  // validateTime(): boolean {
  //   const mili = this.cart.order.take_away_time;
  //   const min = new Date(mili + (60 * 60 * 60));
  //   const dayIndex = new Date().getDay() - 1;
  //   let currentWeekDayIndex = dayIndex >= 0 ? dayIndex : 6;
  //   const open = new Date(this.cart.place.work_time[currentWeekDayIndex].open);
  //   const close = new Date(this.cart.place.work_time[currentWeekDayIndex].close);
  //   if (mili < min.getTime()) {
  //     this.toastr.warning(`Minimalny czas odbioru to: ${min.getHours()}:${min.getMinutes()}`);
  //     return false
  //   } else if (mili)
  // }
}
