<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <h1 class="text-center">Polityka cookies</h1>
    Polityka Cookies:

    <ul>
      <li>
        Administrator wykorzystuje pliki cookies (ciasteczka), czyli niewielkie informacje tekstowe, przechowywane na
        urządzeniu końcowym Użytkownika (np. komputerze, tablecie, smartfonie). Cookies mogą być odczytywane przez
        system teleinformatyczny Administratora.
      </li>
      <li>
        Administrator przechowuje pliki cookies na urządzeniu końcowym Użytkownika, a następnie uzyskuje dostęp do
        informacji w nich zawartych w celach:
      </li>
      <ul>
        <li>
          tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy korzystają ze stron internetowych, co
          umożliwia ulepszanie ich struktury i zawartości;
        </li>
        <li>marketingowych (remarketing)</li>
        <li>
          określania profilu Użytkownika w celu wyświetlania mu dopasowanych materiałów w sieciach reklamowych, w
          szczególności sieci Google,
        </li>
        <li>zapewnienia prawidłowego działania portalu.</li>
      </ul>
      <li>
        Istnieje możliwość takiej konfiguracji przeglądarki internetowej przez Użytkownika, która uniemożliwia
        przechowywanie plików cookies na urządzeniu końcowym. Jednak, w takiej sytuacji, korzystanie ze strony
        internetowej przez Użytkownika może być utrudnione.
      </li>
      <li>
        Pliki cookies mogą być przez Użytkownika usunięte po ich zapisaniu poprzez odpowiednie funkcje przeglądarki
        internetowej, programy służące w tym celu lub skorzystanie z odpowiednich narzędzi dostępnych w ramach systemu
        operacyjnego, z którego korzysta Użytkownik.
      </li>
      <li>
        Pod tymi linkami zamieszczone są informacje o sposobach usunięcia cookies w najpopularniejszych przeglądarkach
        internetowych:<br />

        • Firefox: http://support.mozilla.org/pl/kb/usuwanie-ciasteczek,<br />
        • Opera: http://help.opera.com/Linux/9.60/pl/cookies.html,<br />
        • Internet Explorer: http://support.microsoft.com/kb/278835/pl,<br />
        • Chrome: http://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647<br />
        • Safari: http://support.apple.com/kb/HT1677?viewlocale=pl_PL<br />
      </li>
    </ul>
  </div>

  <app-footer></app-footer>
</div>
