import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  CardsSaleModel,
  Cards3DSaleModel,
  Cards3DModel,
  CardsSaleResponse,
  TransferSaleModel,
  TransferSaleResponse,
  Cards3DResponse,
  BlikModel,
  BlikResponse
} from '@app/shared/model/payline.model';
import { Logger } from '../core/logger.service';
import { APIRestaurantsContext } from '@app/search/nearest/nearest.service';
const log = new Logger('paylane');
import { environment } from '@env/environment';
import { payMethods } from '@app/shared/model/paymethods.model';

const routes = {
  cardsCheck: () => `/checkCard3DSecure`,
  cards3DSell: () => `/saleBy3DSecureAuthorization`,
  cardsSell: () => `/cardSale`,
  bank: () => `/bankTransferSale`,
  blik: () => `/bliksale`,
  methods: () => '/getPayMethods'
};

@Injectable({
  providedIn: 'root'
})
export class PaylaneService {
  constructor(private httpClient: HttpClient) {}

  Card3DAuth(context: Cards3DModel): Observable<Cards3DResponse> {
    const data: Cards3DModel = context;
    log.debug(data);
    log.debug(routes.cardsCheck());
    return (
      this.httpClient
        //.disableApiPrefix()
        .skipSource()
        .post<Cards3DResponse>(routes.cardsCheck(), data)
        .pipe(
          map((body: any) => body),
          catchError(() => of('Error, could not load your location'))
        )
    );
  }

  Card3DSale(context: Cards3DSaleModel): Observable<CardsSaleResponse> {
    const data: Cards3DSaleModel = context;
    log.debug(data);
    log.debug(routes.cards3DSell());
    return (
      this.httpClient
        //.disableApiPrefix()
        .skipSource()
        .post<Cards3DSaleModel>(routes.cards3DSell(), data)
        .pipe(
          map((body: any) => body),
          catchError(() => of('Error, could not load your location'))
        )
    );
  }

  CardSale(context: CardsSaleModel): Observable<CardsSaleResponse> {
    const data: CardsSaleModel = context;
    log.debug(data);
    return (
      this.httpClient
        //.disableApiPrefix()
        .skipSource()
        .post<CardsSaleResponse>(routes.cardsSell(), data)
        .pipe(
          map((body: any) => body),
          catchError(() => of('Error, could not load your location'))
        )
    );
  }

  TransferSale(context: TransferSaleModel): Observable<TransferSaleResponse> {
    const data: TransferSaleModel = context;
    log.debug(data);
    log.debug(routes.bank());
    return (
      this.httpClient
        //.disableApiPrefix()
        .skipSource()
        .post<CardsSaleResponse>(routes.bank(), data)
        .pipe(
          map((body: any) => body),
          catchError(() => of('Error, could not load your location'))
        )
    );
  }

  BlikSale(context: BlikModel): Observable<BlikResponse> {
    const data: BlikModel = context;
    log.debug(data);
    log.debug(routes.blik());
    return (
      this.httpClient
        //.disableApiPrefix()
        .skipSource()
        .post<BlikResponse>(routes.blik(), data)
        .pipe(
          map((body: any) => body),
          catchError(() => of('Error, could not load your location'))
        )
    );
  }

  PayMethods(): Observable<payMethods> {
    const data: any = [];
    return this.httpClient
      .skipSource()
      .post<payMethods>(routes.methods(), data)
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not load your location'))
      );
  }
}
