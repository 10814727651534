import { Component, OnInit } from '@angular/core';
import { CitiesService } from '../cities.service';
import { debounce, debounceTime, finalize } from 'rxjs/operators';
import { Logger } from '../../core/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateStringPipe } from '@app/shared/translate-string.pipe';
import { SlugifierPipe } from '@app/shared/slugifier.pipe';
import { ActivatedRoute } from '@angular/router';
import { CatelogCityService } from './catelog-city.service';
import { Meta, Title } from '@angular/platform-browser';
import { SeoPlacesResp, SeoMeta, SeoPlace } from '../../shared/model/seoPlaces.model';
const log = new Logger('catalog-city');

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss'],
  providers: [SlugifierPipe, TranslateStringPipe]
})
export class CityComponent implements OnInit {
  places: SeoPlacesResp;
  loading: boolean;
  citySlug: string;
  seo: SeoMeta = {
    title: 'Wciagnij.to',
    desc: 'Zamów online na wciagnij.to',
    text_top: '',
    text_bottom: '',
    city: '',
    state: '',
    type: ''
  };
  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private serviceCatelogCity: CatelogCityService,
    private metaTagService: Meta,
    private titleService: Title
  ) {
    this.route.paramMap.subscribe(params => {
      log.debug(params.get('city'));
      this.citySlug = params.get('city');
      if (this.citySlug) {
        this.serviceCatelogCity
          .getCityQuote(this.citySlug)
          .pipe(
            finalize(() => {
              // this.isLoading = false;
            })
          )
          .subscribe((quote: SeoPlacesResp) => {
            if (quote) {
              log.debug(quote);
              this.places = quote;
              if (quote.seo) {
                this.seo = quote.seo;
                //this.metaTagService.removeTag('name="robots"');
                //this.metaTagService.removeTag('name="description"');
                this.metaTagService.updateTag({ name: 'description', content: this.seo.desc });
                // this.metaTagService.addTags([
                //   { name: 'robots', content: 'index, follow' },
                //   {
                //     name: 'description',
                //     content: this.seo.desc
                //   }
                // ]);

                this.titleService.setTitle(this.seo.title);
              }
            }
          });
      }
    });
  }

  ngOnInit() {
    // this.route.paramMap.subscribe(params => {
    //   log.debug(params.get('city'));
    //   this.citySlug = params.get('city');
    //   if (this.citySlug) {
    //     this.serviceCatelogCity
    //       .getCityQuote(this.citySlug)
    //       .pipe(
    //         finalize(() => {
    //           // this.isLoading = false;
    //         })
    //       )
    //       .subscribe((quote: SeoPlacesResp) => {
    //         if (quote) {
    //           log.debug(quote);
    //           this.places = quote;
    //           if (quote.seo) {
    //             this.seo = quote.seo;
    //             //this.metaTagService.removeTag('name="robots"');
    //             //this.metaTagService.removeTag('name="description"');
    //             this.metaTagService.updateTag({ name: 'description', content: this.seo.desc });
    //             // this.metaTagService.addTags([
    //             //   { name: 'robots', content: 'index, follow' },
    //             //   {
    //             //     name: 'description',
    //             //     content: this.seo.desc
    //             //   }
    //             // ]);
    //             this.titleService.setTitle(this.seo.title);
    //           }
    //         }
    //       });
    //   }
    // });
  }
}
