<header [ngClass]="{ navOpened: !menuHidden }">
  <div class="container">
    <nav class="navbar navbar-expand-lg bg-transparent">
      <button
        class="hamburger hamburger--arrow-r"
        [class.is-active]="!menuHidden"
        type="button"
        aria-controls="navbar-menu"
        aria-label="Toggle navigation"
        (click)="toggleMenu()"
        [attr.aria-expanded]="!menuHidden"
        [ngClass]="{ 'padding-mobile': target === 'mobile' }"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
      <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
        <a routerLink="" routerLinkActive="active" title="{{ 'Home' | translate }}" class="logoLink">
          <div class="logo" [inlineSVG]="'assets/logo_2020.svg'" fill="#fff"></div>
        </a>
        <div class="navbar-nav ml-auto">
          <!-- <a class="nav-item nav-link nav-separator" routerLink="/konkurs" routerLinkActive="active">
            <span>Konkurs</span>
          </a> -->

          <a class="nav-item nav-link nav-separator" routerLink="/jak-dzialamy" routerLinkActive="active">
            <span>Ekologiczna zamawiarka</span>
          </a>
          <a class="nav-item nav-link nav-separator" routerLink="/dla-restauracji" routerLinkActive="active">
            <span>Dla restauracji</span>
          </a>
          <a class="nav-item nav-link" href="http://festiwal-smaku.pl/" target="_blank">
            <span>Festiwal Smaku</span>
          </a>

          <!-- <div class="nav-item nav-separator" ngbDropdown display="dynamic" placement="bottom-right">
            <a id="language-dropdown" class="nav-link " ngbDropdownToggle>{{ currentLanguage }}</a>
            <div ngbDropdownMenu aria-labelledby="language-dropdown">
              <button class="dropdown-item" *ngFor="let language of languages" (click)="setLanguage(language)">
                {{ language }}
              </button>
            </div>
          </div> -->

          <!--        <button mat-flat-button color="primary" class="login" (click)="login()" *ngIf="!user">-->
          <!--          zaloguj-->
          <!--        </button>-->
          <div class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
            <a id="user-dropdown" class="nav-link d-none d-lg-block" ngbDropdownToggle>
              <i class="fas fa-user-circle"></i>
            </a>
            <div ngbDropdownMenu aria-labelledby="user-dropdown" class="user-info show-mobile">
              <div class="user-info-container dropdown-content" *ngIf="user">
                <img [src]="user?.photoUrl" class="user-info-image" />
                <div class="user-info-desc">
                  <p>{{ user?.name }}</p>
                  <p class="coin">
                    <img src="assets/icon/abscoin.svg" /> <span>{{ userCoins }}</span>
                  </p>
                  <div class="dropdown-divider"></div>
                </div>
              </div>

              <button class="dropdown-item" (click)="login()" *ngIf="!user">zaloguj</button>
              <button class="dropdown-item" (click)="logout()" *ngIf="user">wyloguj się</button>
            </div>
          </div>

          <a class="nav-item nav-link" href="https://www.youtube.com/channel/UC5MmBtPfjuRioHw3SZXh5wQ/featured">
            <i class="fab fa-youtube"></i>
          </a>
          <a class="nav-item nav-link" href="https://www.facebook.com/Wciagnij.to/">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a class="nav-item nav-link" href="https://www.instagram.com/wciagnijto/">
            <i class="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </nav>
  </div>
</header>
