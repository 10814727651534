import { Component, HostListener, OnInit, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { PlaceModel } from '../model/place.model';
import { AuthenticationService, CredentialsService, I18nService, untilDestroyed } from '../../core/index';
import { finalize, map, catchError } from 'rxjs/operators';
import { AuthService, SocialUser, GoogleLoginProvider } from 'angularx-social-login';
import { UserModel } from '../model/user.model';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { isOpen, isMain } from '@app/shared/utility-functions';
import { environment } from '../../../environments/environment';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input()
  place: PlaceModel;
  user: SocialUser;
  isLoading: boolean = false;
  error: string | undefined;
  userCoins: number = 0;
  menuHidden = true;
  currentWeekDayIndex: number = 0;
  main: boolean = true;
  target: string;
  @Input()
  page: string = '';
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private authenticationService: AuthenticationService,
    private authService: AuthService,
    private credentialsService: CredentialsService,
    private i18nService: I18nService,
    private http: HttpClient
  ) {
    this.main = isMain(this.platformId);
  }

  ngOnInit() {
    this.target = environment.target;
    const dayIndex = new Date().getDay() - 1;
    this.currentWeekDayIndex = dayIndex >= 0 ? dayIndex : 6;

    this.authService.authState.subscribe(user => {
      if (user) {
        this.user = user;
        this.getUserCoins(user.id);

        const login$ = this.authenticationService.login(this.user);
        login$
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
            untilDestroyed(this)
          )
          .subscribe(
            credentials => {
              //this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
            },
            error => {
              this.user = null;
              this.error = error;
            }
          );
      } else {
        this.user = null;
      }
    });
    //console.log(this.credentialsService.credentials, 'credentials');
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  login() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  logout() {
    this.user = null;
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/'], { replaceUrl: true }));
  }

  getUserCoins(userId: string) {
    this.http
      .skipLoader()
      .post('https://system.abspos.pl/api/getUser', {
        uid: userId
      })
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not send request'))
      )

      .subscribe(
        (res: UserModel) => {
          this.userCoins = res.abscoin;
        },
        (err: any) => {}
      );
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.name : null;
  }

  get userInfo(): any {
    const credentials = this.credentialsService.credentials;
    return credentials || null;
  }

  isScrolled: boolean = false;

  @HostListener('window:scroll', ['$event']) onWindowScroll(event: any) {
    if (event.target['scrollingElement'].scrollTop > 60) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
