import { Component, OnInit } from '@angular/core';
import { LoaderService } from '@app/core/http/loader.service';
import { environment } from '@env/environment';
@Component({
  selector: 'app-install-ios',
  templateUrl: './install-ios.component.html',
  styleUrls: ['./install-ios.component.scss']
})
export class InstallIosComponent implements OnInit {
  target: string;
  loading: boolean;
  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.target = environment.target;
    this.loaderService.isLoading.subscribe(v => {
      //console.log(v);
      this.loading = v;
    });
  }
}
