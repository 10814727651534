<div class="install">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 content">
        <h2>Aplikacja <span>Wciągnij.to</span></h2>
        <p>
          Aktualne menu ulubionej restauracji w Twoim telefonie! Zamawiaj bezpośrednio w swojej restauracji z dowozem
          lub na miejscu, dzięki kodom QR - dostępne również na Easy QR Menu! Korzystaj z funkcji kalendarza i zaplanuj
          własne menu na miesiąc! Bądź na bieżąco z aktualnościami Festiwal Smaku!
        </p>

        <div class="download">
          <a href="https://play.google.com/store/apps/details?id=pl.abs.wcignijto&hl=pl&gl=PL" target="_blank"
            ><img src="../../assets/Inteligentny_obiekt_wektorowy (1).png"
          /></a>
          <a href="https://apps.apple.com/pl/app/wciągnij-to/id1592732104" target="_blank">
            <img src="../../assets/Inteligentny_obiekt_wektorowy_kopia.png"
          /></a>
        </div>
      </div>
      <div class="col-md-6 d-none d-md-flex align-items-center justify-content-center position-relative">
        <img src="../../../assets/img/app4.jpg" class="phone_image" />
      </div>

      <div class="steps col-md-12 col-sm-12">
        <div class="container">
          <div class="header text-center">
            <h3>U nas kod QR w 3 krokach</h3>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-12 text-center">
              <div class="icon">
                <img src="../../assets/krok_1.png" />
              </div>
              <p class="desc">
                <strong>Zainstaluj</strong> aplikacje <strong>Wciagnij.to</strong> <br />
                lub <strong>Easy QR Menu</strong> na swoim telefonie
              </p>
            </div>
            <div class="col-md-4 col-sm-12 text-center">
              <div class="icon">
                <img src="../../assets/krok_2.png" />
              </div>
              <p class="desc">
                <strong>Zeskanuj</strong> kod QR ze stolika<br />
                lub zapytaj o niego w twoim ulubionym lokalu
              </p>
            </div>
            <div class="col-md-4 col-sm-12 text-center">
              <div class="icon">
                <img src="../../assets/krok_3.png" />
              </div>
              <p class="desc">
                <strong>Zamów</strong> swoje danie, <br />
                nie czekając na kelnera<br />
                i ciesz się smakiem!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
