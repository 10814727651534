import { OnInit, Component, ViewChild, ElementRef, Inject, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { finalize } from 'rxjs/operators';
import { Logger } from '../core/logger.service';
import { PlaceService } from '@app/place/place.service';
import { PlaceModel } from '../shared/model/place.model';
import { APIPromoContext, PromoService } from '../search/nearest/promo.service';
import { APIPopularContext, PopularService } from '../search/nearest/popular.service';
import { OrderedProductModel, OrderModel } from '../shared/model/order.model';
import { CartModel } from '@app/shared/model/cart.model';
import { SessionService } from '@app/core/session.service';
import { ViewportScroller } from '@angular/common';
import { LoaderService } from '@app/core/http/loader.service';
import { Observable, Observer, pipe, Subscription } from 'rxjs';
import { SlugifierPipe } from '../shared/slugifier.pipe';
import { TranslateStringPipe } from '../shared/translate-string.pipe';
import { Meta, Title } from '@angular/platform-browser';
import { isOpen, isMain } from '@app/shared/utility-functions';
import { LocationModalComponent } from '../home/location/modal/location.modal';
import { ToastrService } from 'ngx-toastr';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { InfoDialogComponent } from '@app/place/info-dialog/info-dialog.component';
import { StateService } from '@app/core/state.service';
import { CartState } from '@app/shared/model/state.model';
import * as _ from 'lodash';

const log = new Logger('restaurant');

@Component({
  selector: 'app-restaurant',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.scss'],
  providers: [SlugifierPipe, TranslateStringPipe]
})
export class PlaceComponent implements OnInit, OnDestroy {
  @ViewChild('imgBuffer') imageElement: ElementRef;
  // @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  consume_type = 1;
  currentWeekDayIndex = 0;
  isOpen = false;
  closeAllDay = false;
  promoData: APIPromoContext = {
    data: null,
    count: null
  };

  popularData: APIPopularContext = {
    data: null,
    count: null
  };

  public place_page = true;
  loading: boolean;
  layers: any = [];
  toCat: any;
  toCatId: string;
  isActiveCatScrollEvent = true;
  toProduct: any;
  toProductId: any;

  center: any;
  zoom = 15;
  cart: CartModel = {
    id: null,
    slug: null,
    name: null,
    order: null,
    bill: null,
    place: null
  };

  order: OrderModel = {
    id: null,
    type: 'order',
    timestamp: null,
    uid: null,
    local_id: null,
    status: 2,
    consume_type: 0,
    delivery: {
      price: 0,
      price_vat: 0,
      price_net: 0,
      email: null,
      phone_number: null,
      client_name: null,
      floor_number: null,
      apart_number: null,
      access_code: null,
      address: null,
      cage: null,
      city: null,
      post_code: null,
      show_as_product: false,
      exist_in_other: false,
      delivery_type: 0,
      geo: {
        lat: 0,
        lon: 0
      },
      distance: 0,
      delivery_pay_mode: 0
    },
    reservation_id: null,
    phone_number: null,
    estimated_ready_time: 0,
    products: [],
    bill_id: null,
    create: null,
    comment: null,
    take_away_time: null,
    user_id: null,
    send_time: null,
    sum: 0,
    sum_net: 0,
    sum_vat: 0,
    source: 3,
    table_number: null
  };

  place: PlaceModel = {
    address: null,
    name: null,
    local_host: null,
    local_type: null,
    id: null,
    delivery: false,
    maxDistance: 0,
    delivery_price: 0,
    delivery_price_range: [],
    delivery_mode: 1,
    delivery_vat: 0,
    delivery_sum_mode: 0,
    festival_smak: false,
    description: [],
    remote_order: false,
    work_time: [
      { open: 0, close: 0 },
      { open: 0, close: 0 },
      { open: 0, close: 0 },
      { open: 0, close: 0 },
      { open: 0, close: 0 },
      { open: 0, close: 0 },
      { open: 0, close: 0 }
    ],
    image: null,
    logo: null,
    geo: null,
    slug: null,
    category: [],
    short_description: [],
    phone_number: null,
    prefix: null,
    company_info: null,
    image_object: null,
    takeaway: null,
    delivery_product: null,
    average_time: 0,
    id_sub_merchant: null,
    service_fee_percent: null,
    instagram: null,
    youtube: null,
    facebook: null,
    min_order_price: 0,
    on_local: null,
    webhook: false,
    imoje_account: null,
    imoje_active: false
  };

  placeCart: CartModel = {
    id: null,
    slug: null,
    name: null,
    order: this.order,
    bill: null,
    place: null
  };

  userLocation: any;

  all_products: any;

  rate: 5;
  logo_img_element: string = undefined;

  image: any;
  base: any;
  target: any;

  subscriptions: Array<Subscription> = [];
  count: number;

  cartState = new CartState();
  placeSlug: string;

  isHiddenPhoneNum = true;
  phoneNumber: string = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private placeService: PlaceService,
    private promoService: PromoService,
    private popularService: PopularService,
    private sessionS: SessionService,
    private viewportScroller: ViewportScroller,
    private slugifier: SlugifierPipe,
    private toastr: ToastrService,
    private translator: TranslateStringPipe,
    private metaTagService: Meta,
    private titleService: Title,
    private http: HttpClient,
    public dialog: MatDialog,
    private state: StateService
  ) {
    this.loaderService.isLoading.subscribe(v => {
      this.loading = v;
    });
    this.all_products = new Map();
    this.changeCatOnScroll();
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.image = this.imageElement.nativeElement;
    //   this.imageElement.nativeElement.src = this.logo_img_element;
    //   this.image = this.imageElement.nativeElement;
    // }, 500);
  }

  async ngOnInit() {
    this.target = environment.target;

    if (isMain(this.platformId)) {
      this.route.paramMap.subscribe(params => {
        log.debug(params.get('slug'));
        log.debug(params.get('slug'));
        this.toCat = params.get('cat');
        this.toProduct = params.get('product');
        this.placeSlug = params.get('slug');
        this.placeService
          .getPlaceQuote(params.get('slug'))
          .pipe(
            finalize(() => {
              // this.isLoading = false;
            })
          )
          .subscribe(async (quote: PlaceModel) => {
            log.debug(quote);
            if (quote) {
              this.place = quote;

              for (const ccat of this.place.category) {
                for (const pprod of ccat.products) {
                  if (pprod !== null) {
                    this.all_products.set(pprod.id, pprod);
                  }
                }
              }

              this.isOpen = isOpen(this.place.work_time);
              const dayIndex = new Date().getDay() - 1;
              this.currentWeekDayIndex = dayIndex >= 0 ? dayIndex : 6;
              if (this.place.work_time[this.currentWeekDayIndex].open == 0) {
                this.closeAllDay = true;
              }

              this.metaTagService.updateTag({
                name: 'description',
                content:
                  this.place.name +
                  ' - ' +
                  this.place.address.city +
                  ' ' +
                  this.place.address.street +
                  ' ' +
                  this.place.address.street_number +
                  '. Zamów online na Wciagnij.to'
              });

              this.titleService.setTitle('Zamów jedzenie online w ' + this.place.name + ' - Wciagnij.to');

              this.popularService
                .getPopularQuote([this.place.id])
                .pipe(
                  finalize(() => {
                    // this.isLoading = false;
                  })
                )
                .subscribe((quote: APIPopularContext) => {
                  if (quote) {
                    this.popularData = quote;
                  }
                });

              this.toProductByURL();
              this.initConsumeType();

              if (isPlatformBrowser(this.platformId)) {
                if (localStorage.getItem('userLocation')) {
                  this.userLocation = JSON.parse(localStorage.getItem('userLocation'));
                  this.order.delivery.geo.lat = this.userLocation.lat;
                  this.order.delivery.geo.lon = this.userLocation.lng;
                  this.order.delivery.distance = 0;
                  this.order.delivery.address = this.userLocation.street + ' ' + this.userLocation.house_number || '';
                  this.order.delivery.post_code = this.userLocation.post_code;
                  this.order.delivery.city = this.userLocation.city;
                  this.order.delivery.distance = await this.getDistance(this.order.delivery);
                }
              }
              this.order.local_id = this.place.id;
              this.order.delivery = this.order.delivery;
              this.order.consume_type = this.consume_type;
              this.placeCart.id = this.place.id;
              this.placeCart.slug = this.place.slug;
              this.placeCart.name = this.place.name;
              const tmp_place: any = {
                id: this.place.id,
                delivery: this.place.delivery,
                delivery_mode: this.place.delivery_mode,
                delivery_price: this.place.delivery_price,
                maxDistance: this.place.maxDistance,
                delivery_price_range: this.place.delivery_price_range,
                delivery_product: this.place.delivery_product,
                delivery_vat: this.place.delivery_vat,
                id_sub_merchant: this.place.id_sub_merchant,
                min_order_price: this.place.min_order_price,
                delivery_sum_mode: this.place.delivery_sum_mode,
                takeaway: this.place.takeaway,
                on_local: this.place.on_local,
                remote_order: this.place.remote_order,
                imoje_active: this.place.imoje_active,
                imoje_account: this.place.imoje_account,
                geo: this.place.geo,
                work_time: this.place.work_time,
                festival_smak: this.place.festival_smak
              };
              this.placeCart.place = JSON.parse(JSON.stringify(tmp_place));
              this.setCurrentCart();
            }
          });
      });
    } else {
      let local_host = '';
      if (isPlatformBrowser(this.platformId)) {
        local_host = environment.local_host ? environment.local_host : window.location.hostname;
      }
      if (isPlatformServer(this.platformId)) {
        local_host = environment.local_host ? environment.local_host : 'bistroabs.wciagnij.to';
      }

      this.placeService
        .getPlaceQuote(local_host)
        .pipe(
          finalize(() => {
            // this.isLoading = false;
          })
        )
        .subscribe((quote: PlaceModel) => {
          log.debug(quote);
          if (quote) {
            this.place = quote;

            for (const ccat of this.place.category) {
              for (const pprod of ccat.products) {
                if (pprod !== null) {
                  this.all_products.set(pprod.id, pprod);
                }
              }
            }

            this.isOpen = isOpen(this.place.work_time);
            this.metaTagService.updateTag({
              name: 'description',
              content:
                this.place.name +
                ' - ' +
                this.place.address.city +
                ' ' +
                this.place.address.street +
                ' ' +
                this.place.address.street_number +
                '. Zamów online na Wciagnij.to'
            });

            this.titleService.setTitle('Zamów jedzenie online w ' + this.place.name + ' - Wciagnij.to');

            this.popularService
              .getPopularQuote([this.place.id])
              .pipe(
                finalize(() => {
                  // this.isLoading = false;
                })
              )
              .subscribe((quote: APIPopularContext) => {
                if (quote) {
                  this.popularData = quote;
                }
              });

            this.toProductByURL();
            this.initConsumeType();

            this.order.local_id = this.place.id;
            this.order.delivery = this.order.delivery;
            this.order.consume_type = this.consume_type;
            this.placeCart.id = this.place.id;
            this.placeCart.slug = this.place.slug;
            this.placeCart.name = this.place.name;
            const tmp_place: any = {
              id: this.place.id,
              delivery: this.place.delivery,
              delivery_mode: this.place.delivery_mode,
              delivery_price: this.place.delivery_price,
              delivery_product: this.place.delivery_product,
              delivery_vat: this.place.delivery_vat,
              id_sub_merchant: this.place.id_sub_merchant,
              min_order_price: this.place.min_order_price,
              delivery_sum_mode: this.place.delivery_sum_mode,
              takeaway: this.place.takeaway,
              on_local: this.place.on_local,
              remote_order: this.place.remote_order,
              imoje_active: this.place.imoje_active,
              imoje_account: this.place.imoje_account,
              geo: this.place.geo,
              delivery_price_range: this.place.delivery_price_range,
              maxDistance: this.place.maxDistance,
              work_time: this.place.work_time,
              festival_smak: this.place.festival_smak
            };
            this.placeCart.place = JSON.parse(JSON.stringify(tmp_place));

            this.setCurrentCart();
          }
        });
    }

    // this.subscriptions.push(this.sessionS.currentData.subscribe(() => {
    //   const cart = this.sessionS.getSessionKey('cart');
    //   if (this.cart) {
    //     this.cart = cart;
    //     if (this.cart.order) {
    //       if (this.cart.order.products) {
    //         this.count = this.cart.order.products.reduce((a: any, b: any) => a + b.count, 0);
    //       }
    //     }
    //   }
    // }));

    this.subscriptions.push(
      this.state.cart$.subscribe(state => {
        this.cartState = state;
      })
    );

    this.subscriptions.push(
      this.sessionS.currentData.subscribe(() => {
        const cart = this.sessionS.getSessionKey('cart');

        if (cart && cart.hasOwnProperty('slug') && cart.slug === this.placeSlug) {
          this.cart = cart;

          if (cart.order && cart.order.products)
            this.count = cart.order.products.reduce((a: any, b: any) => a + b.count, 0);
        }
      })
    );

    this.updateCartState(window.outerWidth);
    addEventListener(
      'resize',
      _.debounce(event => this.updateCartState(event.target.outerWidth), 200)
    );
  }

  ngOnDestroy() {
    document.body.classList.remove('place');

    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  updateCartState(width: number) {
    this.state.cartIsOpen(width > 767);
  }

  ChangeDelivery(type: number) {
    if (!this.cart.order.products.length) {
      // this.consume_type = type;
      this.state.consume_type$.next(type);
    } else {
      this.toastr.warning('Masz juź produkty w koszyku. Aby zmienić sposób dostawy musisz skasować zawartość koszyka');
      this.consume_type = this.cart.order.consume_type;
    }
  }

  initConsumeType() {
    this.consume_type = this.state.consume_type$.value;
    let types = [];
    if (this.place.delivery) {
      types.push(1);
    }
    if (this.place.takeaway) {
      types.push(2);
    }
    if (this.place.on_local) {
      types.push(3);
    }

    if (!types.includes(this.consume_type)) {
      this.consume_type = types[0];
    }
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

  async receiveMessage($event: OrderedProductModel) {
    if (!this.placeCart.order.delivery.distance) {
      if (isPlatformBrowser(this.platformId)) {
        if (localStorage.getItem('userLocation')) {
          this.userLocation = JSON.parse(localStorage.getItem('userLocation'));
          this.order.delivery.geo.lat = this.userLocation.lat;
          this.order.delivery.geo.lon = this.userLocation.lng;
          this.order.delivery.distance = 0;
          this.order.delivery.address = this.userLocation.street + ' ' + this.userLocation.house_number || '';
          this.order.delivery.post_code = this.userLocation.post_code;
          this.order.delivery.city = this.userLocation.city;
          this.order.delivery.distance = await this.getDistance(this.order.delivery);
        }
      }
    }
    this.setCurrentCart();

    this.placeCart.order.products.push($event);
    // this.placeCart.order = JSON.parse(JSON.stringify(this.order));
    this.placeCart.order.sum = this.cart.order.products.reduce((a: any, b: any) => a + b.sum, 0);

    const tmp_place: any = {
      id: this.place.id,
      delivery: this.place.delivery,
      delivery_mode: this.place.delivery_mode,
      delivery_price: this.place.delivery_price,
      delivery_product: this.place.delivery_product,
      delivery_vat: this.place.delivery_vat,
      id_sub_merchant: this.place.id_sub_merchant,
      min_order_price: this.place.min_order_price,
      delivery_sum_mode: this.place.delivery_sum_mode,
      takeaway: this.place.takeaway,
      on_local: this.place.on_local,
      remote_order: this.place.remote_order,
      imoje_active: this.place.imoje_active,
      imoje_account: this.place.imoje_account,
      geo: this.place.geo,
      delivery_price_range: this.place.delivery_price_range,
      maxDistance: this.place.maxDistance,
      work_time: this.place.work_time,
      festival_smak: this.place.festival_smak
    };

    this.placeCart.place = JSON.parse(JSON.stringify(tmp_place));
    this.placeCart.order.consume_type = this.consume_type;
    this.cart = JSON.parse(JSON.stringify(this.placeCart));

    // localStorage.setItem('cart', JSON.stringify(this.cart));
    this.sessionS.setSessionKey('cart', this.cart);
    log.debug(this.order);

    //if(!this.placeCart.order.products.length && !this.placeCart.isOpen)
    // this.state.cartIsOpen(true);
  }

  plus(item: any) {
    if (!item.quantity) {
      item.quantity = 0;
    }
    item.quantity++;
  }

  minus(item: any) {
    if (!item.quantity) {
      item.quantity = 0;
    }
    if (item.quantity > 1) {
      item.quantity--;
    }
  }

  // Kategorie - slider
  // slickCatConfig = {
  //   slidesToShow: 8,
  //   slidesToScroll: 8,
  //   prevArrow: "<div class='slick-prev'><i class='fas fa-chevron-left'></i></div>",
  //   nextArrow: "<div class='slick-next'><i class='fas fa-chevron-right'></i></div>",
  //   dots: false,
  //   infinite: false,
  //   center: true,
  //   cssEase: 'linear',
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 5,
  //         slidesToScroll: 5
  //       }
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 4
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 3
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2
  //       }
  //     },
  //     {
  //       breakpoint: 320,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };

  actionToProduct(toCat: any, toProduct: any) {
    this.toCat = this.slugifier.transform(this.translator.transform(toCat, this.translate.currentLang));
    this.toProduct = this.slugifier.transform(this.translator.transform(toProduct, this.translate.currentLang));

    log.debug(this.toCat);
    log.debug(this.toProduct);
    this.toProductByURL();
  }

  toProductByURL(): void {
    for (const i in this.place.category) {
      for (const j in this.place.category[i].name) {
        for (const k in this.place.category[i].name[j]) {
          if (
            this.place.category[i].name[j].locale === this.translate.currentLang &&
            this.slugifier.transform(this.place.category[i].name[j].text) === this.slugifier.transform(this.toCat)
          ) {
            this.toCatId = this.place.category[i].id;
          }
        }
      }

      for (const s in this.place.category[i].products) {
        for (const m in this.place.category[i].products[s].name) {
          if (
            this.place.category[i].products[s].name[m].locale === this.translate.currentLang &&
            this.slugifier.transform(this.place.category[i].products[s].name[m].text) ===
              this.slugifier.transform(this.toProduct)
          ) {
            this.toProductId = this.place.category[i].products[s].id;
          }
        }
      }
    }

    setTimeout(() => {
      this.viewportScroller.setOffset([0, 124]);
      this.viewportScroller.scrollToAnchor(this.toProductId);
      this.viewportScroller.scrollToPosition([0, this.viewportScroller.getScrollPosition()[1]]);
    }, 500);
  }

  toCategory(id: string): void {
    this.toCatId = null;
    this.isActiveCatScrollEvent = false;

    setTimeout(() => {
      this.viewportScroller.scrollToAnchor(id);
      this.viewportScroller.setOffset([0, 49]);
      this.viewportScroller.scrollToPosition([0, this.viewportScroller.getScrollPosition()[1]]);
      this.toCatId = id;
      setTimeout(() => {
        this.isActiveCatScrollEvent = true;
      }, 50);
    }, 300);
  }

  // onSlickInit(event: any) {
  //   //console.log('slick initialized');
  //   if (isPlatformBrowser(this.platformId)) {
  //     setTimeout(() => {
  //       window.dispatchEvent(new Event('resize'));
  //     }, 1000);
  //   }
  // }

  setCurrentCart() {
    if (this.sessionS.checkSessionKey('cart')) {
      this.cart = this.sessionS.getSessionKey('cart');
      if (this.cart && this.cart.hasOwnProperty('id')) {
        if (this.cart.id !== this.placeCart.id) {
          this.cart = JSON.parse(JSON.stringify(this.placeCart));
        } else {
          this.placeCart = JSON.parse(JSON.stringify(this.cart));
          // this.consume_type = this.placeCart.order.consume_type;
        }
      } else {
        this.cart = this.placeCart;
        // this.consume_type = this.placeCart.order.consume_type;
      }
    } else {
      this.cart = this.placeCart;
      // this.consume_type = this.placeCart.order.consume_type;
    }
  }

  openSearchDialog(category: string) {
    // event.preventDefault();

    // if (this.userLocation && this.userLocation.lat)
    // return this.router.navigateByUrl(`szukaj/kategoria/${this.translate.currentLang}/${category}`);

    this.dialog.open(LocationModalComponent, {
      data: {
        category
      }
    });
  }

  getDistance = async (address: any): Promise<number> => {
    if (this.consume_type != 1) {
      return Promise.resolve(0);
    }

    const reqData = {
      delivery: {
        address: `${address.address}`,
        city: address.city,
        state: address.state,
        country: address.country,
        post_code: address.post_code
      },
      sub_local_geo: this.place.geo
    };

    return new Promise(async (resolve, reject) => {
      const result: any = await this.http
        .skipLoader()
        .post('https://system.abspos.pl/api/driver/getDistance', reqData)
        .toPromise();
      if (result) {
        resolve(result.distance);
      } else {
        resolve(0);
      }
    });
  };

  openInfoDialog(): void {
    this.dialog.open(InfoDialogComponent, {
      data: {
        place: this.place
      }
    });
  }

  changeCatOnScroll() {
    window.onscroll = () => {
      if (this.isActiveCatScrollEvent) {
        this.place.category.forEach(category => {
          const catEl = document.getElementById(category.id);
          if (catEl) {
            if (pageYOffset >= catEl.offsetTop) {
              this.toCatId = category.id;
            }
          }
        });
      }
    };
  }

  toggleCart() {
    this.state.cartIsOpen('toggle');
  }

  showPhoneNumber() {
    this.placeService.getPhoneNumber(this.place.id).subscribe(
      (phone: string) => {
        if (phone) {
          this.phoneNumber = phone;
        }
      },
      () => (this.phoneNumber = null),
      () => (this.isHiddenPhoneNum = false)
    );
  }
}
