import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobileapp-install',
  templateUrl: './mobileapp-install.component.html',
  styleUrls: ['./mobileapp-install.component.scss']
})
export class MobileappInstallComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
