import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SeoFoodCity, SeoFoodMeta, SeoFoodPlaces, SeoFoodResp } from '../../shared/model/seoFood.model';

const routes = {
  quote: () => `/catalog-food`
};

@Injectable({
  providedIn: 'root'
})
export class FoodService {
  constructor(private httpClient: HttpClient) {}

  getFoodQuote(slug: string): Observable<any> {
    return this.httpClient
      .skipLoader()
      .cache()
      .post<SeoFoodResp>(routes.quote(), { rodzaj: slug })
      .pipe(
        map((body: SeoFoodResp) => body),
        catchError(() => of('Error, could not load nearest restaurants'))
      );
  }
}
