import { MenuProductModel } from '@app/shared/model/menuProduct.model';
import { MenuAttributeModel, MenuComponentModel } from './menuProduct.model';
import { NameLocalizationModel } from './nameLocalization.model';

export class OrderModel {
  id: string;
  type: string;
  timestamp: number;
  uid: string;
  local_id: string;
  status: number;
  consume_type: number;
  delivery: DeliveryModel;
  reservation_id: string;
  phone_number: string;
  estimated_ready_time: number;
  products: OrderedProductModel[];
  bill_id: string;
  create: number;
  comment: string;
  take_away_time: number;
  user_id: string;
  send_time: number;
  sum: number;
  source: number;
  sum_net: number;
  sum_vat: number;
  table_number: string;
}

export class ProductDiscountDetailed {
  id: string;
  name: NameLocalizationModel[];
  auto: boolean;
  discount_type: number;
  discount_unit: number;
  discount_value: number;
  connection_type: number;
}

export class ProductDiscount {
  enabled: boolean;
  sum: number;
  sum_net: number;
  sum_vat: number;
  items: ProductDiscountDetailed[] | any[];
}

export class OrderedProductModel {
  product: MenuProductModel;
  id: string;
  attribute: MenuAttributeModel;
  components: SelectedComponentModel[];
  count: number;
  order: number;
  comment: string;
  status: number;
  price: number;
  price_net: number;
  price_vat: number;
  sum: number;
  sum_net: number;
  sum_vat: number;
  discount: ProductDiscount;
  is_additional: boolean;
  linked: OrderedProductModel[];
  sum_linked: number;
  freeLimit?: number;
}

export class SelectedComponentModel {
  component: MenuComponentModel;
  count: number;
  checked: boolean;
  weight: number;
}

export class DeliveryModel {
  address: string;
  apart_number: string;
  floor_number: string;
  phone_number: string;
  client_name: string;
  email: string;
  cage: string;
  post_code: string;
  city: string;
  price: number;
  price_vat: number;
  price_net: number;
  access_code: string;
  show_as_product: boolean;
  exist_in_other: boolean;
  delivery_type: number;
  geo: {
    lat: number;
    lon: number;
  };
  distance: number;
  delivery_pay_mode: number;
}

export enum CalculationType {
  default,
  noSubtract,
  pricePriority
}
