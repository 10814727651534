import { NgModule } from '@angular/core';
import { RouterModule, Routes, RoutesRecognized } from '@angular/router';

import { extract } from '@app/core';
import { PlaceComponent } from './place.component';
import { Shell } from '@app/shell/shell.service';
import { DomainGuard } from '@app/core/domain/domain.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'restauracja/:lang/:city/:slug',
      component: PlaceComponent
    },
    {
      path: 'restauracja/:lang/:city/:slug/:cat/:product',
      component: PlaceComponent
    }
  ])
];

// const routesRest: Routes = [
//   Shell.childRoutes([
//     {
//       path: '',
//       component: PlaceComponent
//     },
//     {
//       path: 'danie/:product',
//       component: PlaceComponent
//     }
//   ])
// ];
// const isCurrentDomainMain: boolean =
//   window.location.hostname === 'wciagnij.to' ||
//   window.location.hostname === 'dev.wciagnij.to' ||
//   window.location.hostname === 'localhost'; // local // prod

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class PlaceRoutingModule {}
