import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../core/session.service';
import { Logger } from '../../core/index';
import { TranslateService } from '@ngx-translate/core';
import { BillModel } from '@app/shared/model/bill.model';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

const log = new Logger('basketwidget');
//declare var $: any;

@Component({
  selector: 'app-widget-basket',
  templateUrl: './widget-basket.component.html',
  styleUrls: ['./widget-basket.component.scss']
})
export class WidgetBasketComponent implements OnInit {
  data: any;
  cart: any;
  count = 0;
  bill: BillModel = {
    id: null,
    type: 'bill',
    timestamp: null,
    uid: null,
    flag: 0,
    local_id: null,
    status: 2,
    consume_type: 1,
    name: null,
    create: null,
    verified: 0,
    payment_method: [2],
    payment_doc: 0,
    tax_number: null,
    invoice_url: null,
    payment_status: 2,
    orders_id: [],
    user_id: null,
    source: 3,
    hall_id: null,
    discount: null,
    sum: 0,
    sum_net: 0,
    sum_vat: 0,
    user_close_id: null,
    split_bills: [],
    coins: 0,
    webhook: false,
    exist_discount: false,
    tip: null,
    service_fee: null
    //p24: null
  };
  subscription: Subscription;

  constructor(
    private sessionService: SessionService,
    public translate: TranslateService,
    private session: SessionService
  ) {}

  ngOnInit() {
    this.subscription = this.sessionService.currentData.subscribe(data => {
      //console.log('basket-subscriber');
      this.cart = this.sessionService.getSessionKey('cart');
      if (this.cart) {
        if (this.cart.order) {
          if (this.cart.order.products) {
            this.CalculateOrder();
            this.count = this.cart.order.products.reduce((a: any, b: any) => a + b.count, 0);
          }
        }
      } else {
        this.count;
      }
    });
  }

  openCart() {
    //$('#cart-dropdown').toggle();
  }

  Minus(p: number, i: number) {
    this.cart.order.products[i].count = this.cart.order.products[i].count - 1;

    if (this.cart.order.products[i].count === 0) {
      this.Remove(p, i);
    }
    this.CalculateOrder();
    this.count = this.cart.order.products.reduce((a: any, b: any) => a + b.count, 0);
    this.updateSession();
  }

  Plus(p: number, i: number) {
    this.cart.order.products[i].count = this.cart.order.products[i].count + 1;

    if (this.cart.order.products[i].count === 0) {
      this.Remove(p, i);
    }
    this.CalculateOrder();
    this.count = this.cart.order.products.reduce((a: any, b: any) => a + b.count, 0);
    this.updateSession();
  }

  Remove(p: number, i: any) {
    this.cart.order.products.splice(i, 1);
    this.CalculateOrder();
    this.count = 0;
    this.updateSession();
  }

  Delete() {
    this.cart.order.products = [];
    this.CalculateOrder();
    this.count = this.cart.order.products.reduce((a: any, b: any) => a + b.count, 0);
    this.updateSession();
  }

  updateSession = _.debounce(() => {
    //console.log('update');
    this.session.setSessionKey('cart', this.cart);
  }, 1000);

  CalculateOrder() {
    if (!this.cart.bill) {
      this.cart.bill = this.bill;
    }

    log.debug(this.cart.bill);
    this.cart.bill.sum = 0;
    this.cart.bill.sum_vat = 0;
    this.cart.bill.sum_net = 0;
    this.cart.order.sum = 0;
    this.cart.order.sum_vat = 0;
    this.cart.order.sum_net = 0;
    for (const item of this.cart.order.products) {
      this.cart.order.sum = this.cart.order.sum + item.price * item.count;
      this.cart.order.sum_vat = this.cart.order.sum_vat + item.price_vat * item.count;
      this.cart.order.sum_net = this.cart.order.sum_net + item.price_net * item.count;

      for (const p of item.linked.values()) {
        p.count = item.count;
        p.sum = p.price * item.count;
        p.sum_vat = p.price_vat * item.count;
        p.sum_net = p.price_net * item.count;

        this.cart.order.sum = this.cart.order.sum + p.price * item.count;
        this.cart.order.sum_vat = this.cart.order.sum_vat + p.price_vat * item.count;
        this.cart.order.sum_net = this.cart.order.sum_net + p.price_net * item.count;
      }
    }

    var dd = this.CalculateDeliveryPrice();
    this.cart.order.delivery.price = dd.sum;
    this.cart.order.delivery.price_vat = dd.sum_vat;
    this.cart.order.delivery.price_net = dd.sum_net;

    this.cart.bill.sum = this.cart.order.sum + this.cart.order.delivery.price;
    this.cart.bill.sum_vat = this.cart.order.sum_vat + this.cart.order.delivery.price_vat;
    this.cart.bill.sum_net = this.cart.order.sum_net + this.cart.order.delivery.price_net;
    this.cart.bill.webhook = this.cart.place.webhook;
  }

  CalculateDeliveryPrice() {
    var price = {
      sum: 0,
      sum_vat: 0,
      sum_net: 0
    };

    /**
     *
     *
     * Math.round(price.product_price*
     ((this.orderedProduct.product.vat/100)/100) * 100) / 100;
     * @type {number}
     */

    if (this.bill.consume_type == 1) {
      if (this.cart.order.sum < this.cart.place.delivery_sum_mode || this.cart.place.delivery_sum_mode === 0) {
        price.sum = this.cart.place.delivery_price;
        price.sum_vat = Math.round(price.sum * (this.cart.place.delivery_vat / 100 / 100) * 100) / 100;
        price.sum_net = price.sum - price.sum_vat;
      }
    }

    return price;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
