<div class="position-relative">
  <button mat-icon-button aria-label="Close" class="close" (click)="closeModal()"><i class="fas fa-times"></i></button>

  <h3>Dodatki</h3>

  <div class="scroll">
    <div class="configuration" *ngIf="orderedProduct.components.length">
      <div>
        <div *ngFor="let item of orderedProduct.attribute.componentCategory">
          <h4>{{ item.name | translateString: translate.currentLang }}</h4>
          <div>
            <div class="row" *ngIf="item.category_type === 0">
              <ng-container *ngFor="let comp of orderedProduct.components; index as i">
                <div
                  class="col-lg-4 col-md-6 col-12 component"
                  *ngIf="item.id == comp.component.category_id && comp.component.editable"
                >
                  <div class="row">
                    <div class="col-4 p-0 text-center"><img src="{{ comp.component.icon }}" /></div>
                    <div class="col-8">
                      <div class="title">{{ comp.component.name | translateString: translate.currentLang }}</div>

                      <div class="change_count">
                        <button mat-stroked-button color="primary" (click)="Minus(comp)">
                          <i class="fas fa-minus"></i>
                        </button>
                        <input name="count" value="1" [(ngModel)]="comp.count" />
                        <button mat-stroked-button color="primary" (click)="Plus(comp)">
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="price" *ngIf="!orderedProduct.freeLimit || !comp.component.free_components">
                            {{ comp.component.price | currency: ' ' }} zł
                          </div>
                          <div class="price" *ngIf="orderedProduct.freeLimit && comp.component.free_components">
                            darmowy
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row" *ngIf="item.category_type === 1">
              <ng-container *ngFor="let comp of orderedProduct.components; index as i">
                <div
                  class="col-lg-4 col-md-6 col-12 component"
                  *ngIf="item.id == comp.component.category_id && comp.component.editable"
                >
                  <div class="row">
                    <div class="col-4 p-0 text-center"><img src="{{ comp.component.icon }}" /></div>
                    <div class="col-8">
                      <div class="title">{{ comp.component.name | translateString: translate.currentLang }}</div>
                      <div class="row">
                        <div class="col-12">
                          <mat-radio-button
                            name="{{ item.id }}"
                            class="example-radio-button"
                            color="primary"
                            (change)="SelectComponentRa(comp)"
                            [checked]="comp.checked"
                          >
                            <span class="price" *ngIf="!orderedProduct.freeLimit || !comp.component.free_components"
                              >{{ comp.component.price | currency: ' ' }}zł</span
                            >
                            <span class="price" *ngIf="orderedProduct.freeLimit && comp.component.free_components"
                              >darmowy</span
                            >
                          </mat-radio-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row ct2" *ngIf="item.category_type === 2">
              <ng-container *ngFor="let comp of orderedProduct.components; index as i">
                <div
                  class="col-lg-4 col-md-6 component"
                  *ngIf="item.id == comp.component.category_id && comp.component.editable"
                >
                  <div class="row">
                    <div class="col-4 p-0 text-center"><img src="{{ comp.component.icon }}" /></div>
                    <div class="col-8">
                      <div class="title">{{ comp.component.name | translateString: translate.currentLang }}</div>

                      <div class="row">
                        <div class="col-12 d-flex align-items-center">
                          <mat-checkbox color="primary" (change)="SelectComponentCk(comp)" [checked]="comp.checked">
                            <span class="price" *ngIf="!orderedProduct.freeLimit || !comp.component.free_components"
                              >{{ comp.component.price | currency: ' ' }}zł</span
                            >
                            <span class="price" *ngIf="orderedProduct.freeLimit && comp.component.free_components"
                              >darmowy</span
                            >
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
