<div class="product">
  <!-- <button
    mat-icon-button
    aria-label="Example icon-button with a heart icon"
    ngxClipboard
    [cbContent]="
      env.hostUrl +
      '/' +
      route.snapshot.url[0] +
      '/' +
      route.snapshot.url[1] +
      '/' +
      route.snapshot.url[2] +
      '/' +
      route.snapshot.url[3] +
      '/' +
      (cat.name | translateString: translate.currentLang) +
      '/' +
      (product.name | translateString: translate.currentLang)
    "
    (cbOnSuccess)="copyInfo()"
    class="copy_button"
  >
    <i class="fas fa-link"></i>
  </button> -->
  <div class="title">{{ product.name | translateString: translate.currentLang }}</div>
  <div class="desc">{{ product.description | translateString: translate.currentLang }}</div>

  <!--<button
    mat-flat-button
    color="primary"
    (click)="AddToCart()"
    class="add_to_cart position-relative float-right"
    *ngIf="!orderedProduct.components.length"
  >
    Dodaj
  </button>-->

  <div class="priceRow">
    <div class="price">{{ orderedProduct.price + orderedProduct.sum_linked | currency: ' ' }}zł</div>
    <button mat-icon-button color="primary" (click)="toggleMore(product.attributes[0].has_component)">
      <i class="fas fa-{{ moreOpen ? 'minus' : 'plus' }}"></i>
    </button>
  </div>

  <div class="more" *ngIf="moreOpen">
    <ng-container *ngIf="!isLoadingComponents">
      <mat-expansion-panel class="additions" *ngFor="let item of orderedProduct.attribute.componentCategory">
        <mat-expansion-panel-header
          >{{ item.name | translateString: translate.currentLang }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="row" *ngIf="item.category_type === 0">
            <ng-container *ngFor="let comp of orderedProduct.components; index as i">
              <div
                class="col-lg-4 col-md-6 col-12 component"
                *ngIf="item.id == comp.component.category_id && comp.component.editable"
              >
                <div class="row">
                  <!-- <div class="col-4 p-0 text-center">
                    <img [lazyLoad]="comp.component.icon | getImageUrl" [defaultImage]="'assets/placeholder.svg'" />
                  </div> -->
                  <div class="col-12">
                    <div class="title">{{ comp.component.name | translateString: translate.currentLang }}</div>

                    <div class="change_count" *ngIf="isOpen && place.place.remote_order">
                      <button mat-stroked-button color="primary" (click)="Minus(comp)">
                        <i class="fas fa-minus"></i>
                      </button>
                      <input
                        name="count"
                        value="1"
                        [(ngModel)]="comp.count"
                        class="{{ comp.count > 0 ? 'count-green' : '' }}"
                      />
                      <button mat-stroked-button color="primary" (click)="Plus(comp)">
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <div class="price" *ngIf="!orderedProduct.freeLimit || !comp.component.free_components">
                          {{ comp.component.price | currency: ' ' }} zł
                        </div>
                        <div class="price" *ngIf="orderedProduct.freeLimit && comp.component.free_components">
                          darmowy
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="row" *ngIf="item.category_type === 1">
            <ng-container *ngFor="let comp of orderedProduct.components; index as i">
              <div
                class="col-lg-4 col-md-6 col-12 component"
                *ngIf="item.id == comp.component.category_id && comp.component.editable"
              >
                <div class="row">
                  <!-- <div class="col-4 p-0 text-center">
                    <img [lazyLoad]="comp.component.icon" [defaultImage]="'assets/placeholder.svg'" />
                  </div> -->
                  <div class="col-12">
                    <div class="title">{{ comp.component.name | translateString: translate.currentLang }}</div>
                    <div class="row">
                      <div class="col-12">
                        <mat-radio-button
                          name="{{ item.id }}"
                          class="example-radio-button"
                          color="primary"
                          (change)="SelectComponentRa(comp)"
                          [checked]="comp.checked"
                          *ngIf="isOpen && place.place.remote_order"
                        >
                          <span class="price" *ngIf="!orderedProduct.freeLimit || !comp.component.free_components"
                            >{{ comp.component.price | currency: ' ' }}zł</span
                          >
                          <span class="price" *ngIf="orderedProduct.freeLimit && comp.component.free_components"
                            >darmowy</span
                          >
                        </mat-radio-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="row ct2" *ngIf="item.category_type === 2">
            <ng-container *ngFor="let comp of orderedProduct.components; index as i">
              <div
                class="col-lg-4 col-md-6 component"
                *ngIf="item.id == comp.component.category_id && comp.component.editable"
              >
                <div class="row">
                  <!-- <div class="col-4 p-0 text-center">
                    <img [lazyLoad]="comp.component.icon" [defaultImage]="'assets/placeholder.svg'" />
                  </div> -->
                  <div class="col-12">
                    <div class="title">{{ comp.component.name | translateString: translate.currentLang }}</div>

                    <div class="row">
                      <div class="col-12 d-flex align-items-center">
                        <mat-checkbox
                          color="primary"
                          (change)="SelectComponentCk(comp)"
                          [checked]="comp.checked"
                          *ngIf="isOpen && place.place.remote_order"
                        >
                          <span class="price" *ngIf="!orderedProduct.freeLimit || !comp.component.free_components"
                            >{{ comp.component.price | currency: ' ' }}zł</span
                          >
                          <span class="price" *ngIf="orderedProduct.freeLimit && comp.component.free_components"
                            >darmowy</span
                          >
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-container>

    <mat-spinner [diameter]="32" *ngIf="isLoadingComponents" style="margin: 0 auto;"></mat-spinner>

    <div class="options">
      <div *ngIf="product.attributes.length > 1" class="wariants">
        <div *ngIf="product.attributes.length > 3; else buttonsVariants">
          <mat-form-field>
            <mat-label translate>Choose a variant</mat-label>
            <mat-select>
              <mat-option *ngFor="let atr of product.attributes; index as i" [value]="i" (click)="PickAttribute(i)">
                {{ atr.name | translateString: translate.currentLang }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <ng-template #buttonsVariants>
          <button
            mat-button
            [ngClass]="picketAttribute === i ? 'mat-flat-button' : 'mat-stroked-button'"
            [color]="picketAttribute === i ? 'primary' : null"
            *ngFor="let atr of product.attributes; index as i"
            class="mr-1"
            (click)="PickAttribute(i)"
          >
            {{ atr.name | translateString: translate.currentLang }}
          </button>
        </ng-template>
      </div>
      <div class="change_count">
        <button
          mat-stroked-button
          color="primary"
          (click)="MinusProduct()"
          [disabled]="!isOpen || !place.place.remote_order"
        >
          <i class="fas fa-minus"></i>
        </button>
        <input
          class="counter-{{ orderedProduct.id }}"
          type="text"
          name="count"
          value="{{ orderedProduct.count || 0 }}"
          [disabled]="!isOpen || !place.place.remote_order"
          readonly
        />
        <button
          mat-stroked-button
          color="primary"
          (click)="PlusProduct()"
          [disabled]="!isOpen || !place.place.remote_order"
        >
          <i class="fas fa-plus"></i>
        </button>
      </div>

      <button
        mat-flat-button
        color="primary"
        (click)="AddToCart()"
        class="addToCart"
        [disabled]="!isOpen || !place.place.remote_order"
      >
        Dodaj
      </button>

      <div class="remote_order" *ngIf="!place.place.remote_order">
        <mat-icon style="margin-right: 15px">info icon</mat-icon>
        Zamówienia tylko telefoniczne{{ place.place.festival_smak ? ' na hasło "wciągnij to"' : '' }}:
        <div
          *ngIf="isHiddenPhoneNum"
          (click)="showPhoneNumber()"
          style="text-decoration: underline;margin-left: 5px; color: white; cursor: pointer;"
        >
          Pokaz numer
        </div>
        <div *ngIf="!isHiddenPhoneNum">
          <a *ngIf="phoneNumber" href="tel:{{ phoneNumber }}">
            <span style="text-decoration: underline;margin-left: 5px; color: white">{{ phoneNumber }}</span>
          </a>
          <span *ngIf="!phoneNumber" style="margin-left: 5px;">Brak numeru</span>
        </div>
      </div>
    </div>
  </div>

  <!--<div class="row add-product">
    <div class="col-md-12 text-right">
      <div class="row options">
        <div class="col-12">
          <button mat-flat-button color="primary" (click)="AddToCart()">Dodaj</button>
        </div>
      </div>
    </div>
  </div>-->
</div>
