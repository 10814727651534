import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobileapp',
  templateUrl: './mobileapp.component.html',
  styleUrls: ['./mobileapp.component.scss']
})
export class MobileappComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
