<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <div class="container-fluid home-background home-mobile-order" [ngClass]="{ 'padding-mobile': target === 'mobile' }">
    <div class="site-header">
      <app-header-home></app-header-home>
      <div class="container homeTop">
        <div class="text">
          <span class="large-title">Czas zamówić zdrowe jedzenie</span>
        </div>
        <app-location [userLocation]="userLocation" (loaded)="onLoaded($event)" [redirect]="true"></app-location>
        <div class="text">
          <p class="subtitle">
            <strong>Zielone</strong> miejsca w Twoim mieście!<br />Ciesz się smakiem i
            <strong>daj się wciągnąć!</strong>
          </p>
        </div>
      </div>
    </div>
    <div class="instructions position-relative">
      <div class="container">
        <div class="introduction">
          <div class="image">
            <img src="assets/img/wciagnij_min.png" alt="wciagnij.to" />
          </div>
          <div class="text">
            <h5>Polska EKOLOGICZNA zamawiarka jedzenia online!</h5>
            <p>
              - szybkie wyszukiwanie restauracji po adresie,<br />
              - szukanie swojej zielonej restauracji po nazwie,<br />
              - błyskawiczne wyszukiwanie swojego ulubionego dania w danej lokalizacji,<br />
              - wgląd w swoją historię zamówień,<br />
              - wgląd w menu danej restauracji,<br />
              - możliwość skanowania MENU zielonej restauracji poprzez kod QR.<br />
              - niewielka prowizja w porównaniu od konkurencji,<br />
            </p>
          </div>
        </div>
        <div class="header text-center">
          <h3>Jak działa <strong>Wciągnij.to?</strong></h3>
        </div>
        <div class="row content">
          <div class="col-md-4 col-sm-12 text-center">
            <div class="icon">
              <img src="../../assets/icon/instructions_i1.png" />
            </div>
            <p class="desc">
              <strong>Wyszukaj</strong> restaurację,<br />
              bar, cukiernię lub inne gastro,<br />
              podając swój adres dostawy.
            </p>
          </div>
          <div class="col-md-4 col-sm-12 text-center">
            <div class="icon">
              <img src="../../assets/icon/instructions_i2.png" />
            </div>
            <p class="desc">
              Wybierz i zamów to,<br />
              co lubisz z dostawą lub na miejscu.
            </p>
          </div>
          <div class="col-md-4 col-sm-12 text-center">
            <div class="icon">
              <img src="../../assets/icon/instructions_i3.png" />
            </div>
            <p class="desc">
              Wybierz rodzaj płatności<br />
              i ciesz się smakiem!
            </p>
          </div>
        </div>
      </div>
    </div>

    <app-mobileapp-install *ngIf="target === 'web'"></app-mobileapp-install>

    <div class="festivalSmaku" style="order: 3">
      <div class="container">
        <img class="banner" src="assets/img/festival_banner.png" alt="Festival Smaku" />

        <h2>Zamawiaj dania z <strong>Festiwal Smaku!</strong></h2>
        <p class="desc">
          Festiwal Smaku to największe wydarzenie kulinarne w Polsce. Przez pięć dni, od poniedziałku do piątku,
          restauracje oraz kawiarnie proponują mieszkańcom specjalnie przygotowane na tę okazję wyjątkowe dania w
          festiwalowych cenach.
        </p>
      </div>
    </div>

    <div class="container recommend">
      <div class="row no-gutters banner">
        <div class="img col-lg-5 d-none d-lg-block"></div>
        <div class="text col-lg-7 col-md-12">
          <div class="cont">
            <h3 class="title">Znasz fajny <strong>punkt gastro!?</strong></h3>
            <p>
              Nie udało Ci się go znaleźć w naszej bazie? Lub chcesz dołączyć do nas?<br />
              Daj nam znać! Postaramy się, aby w najbliższym czasie do nas dołączyli!
            </p>
            <a [routerLink]="['/recommend']" mat-flat-button color="primary" class="z-index-3 recommand-button">
              Wciągnij lokal!
            </a>
          </div>
        </div>
      </div>

      <p class="desc">
        <strong>Zamów szybko i wygodnie ulubione jedzenie z dowozem na Wciągnij.to</strong><br />
        Szukasz restauracji, która oferuje jedzenie na wynos? Wciagnij.to to największy w Polsce serwis do zamawiania
        jedzenia online. Wszystkie restauracje dostępne na platformie Wciągnij.to oferują jedzenie na dowóz, dzięki
        czemu możesz delektować się smakiem ulubionej pizzy, sushi, kebaba, pierogów i innych potraw gdziekolwiek
        jesteś: w domu, w pracy, podczas imprezy lub spotkania ze znajomymi. Jeśli nie możesz się zdecydować, na co dziś
        masz ochotę, skorzystaj z filtrów wyszukiwania i wybierz lokal np. pod względem oddanych ocen innych
        użytkowników, kuchni świata lub minimalnej kwoty zamówienia. Oszczędzaj czas i pieniądze – wyrzuć ulotki i
        odkryj nowe restauracje z dowozem w Twojej okolicy! Wciagnij.to życzy smacznego
      </p>
    </div>

    <!--    <div class="container-fluid tiles">-->
    <!--      <div class="row no-gutters tile">-->
    <!--        <div class="cont col-lg-6 col-md-12">-->
    <!--          <h3>Daj się wciągnąć i <strong>dołącz do grona</strong> zadowolonych Restauratorów</h3>-->
    <!--          <div class="text">-->
    <!--            <span>-->
    <!--              Prowadzisz gastro? Już dziś możesz korzystać z <strong>DKS (Dodatkowych Kanałów Sprzedaży)</strong> - Easy-->
    <!--              QR Menu i wciągnij.to! <br/>Pomnożymy Twoje zamówienia i zyski przy mniejszej liczbie pracowników.<br/>-->
    <!--              Dołącz do wciągnij.to</span-->
    <!--            >-->
    <!--            <a [routerLink]="['/join']" mat-flat-button color="primary" class="z-index-3 dks-button"-->
    <!--            >Wypełnij formularz</a-->
    <!--            >-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="img col-lg-6 d-none d-lg-block"></div>-->
    <!--      </div>-->
    <!--      <div class="row no-gutters tile">-->
    <!--        <div class="img col-lg-6 d-none d-lg-block"></div>-->
    <!--        <div class="cont col-lg-6 col-md-12">-->
    <!--          <h3>Twój ulepszony system do gastro - <strong>ABS POS!</strong></h3>-->
    <!--          <p class="text">-->
    <!--            <strong>3w1</strong> – ABS POS + EASY QR MENU + wciągnij.to<br/>-->
    <!--            No 1 na Świecie! Zacznij od ABS POS.-->
    <!--          </p>-->
    <!--          <p class="text">Do pobrania ABS POS z Google Play - <strong>Testuj 30 dni za darmo!</strong></p>-->
    <!--          <div class="btns-container">-->
    <!--            <a href="https://abspos.pl" target="_blank" mat-flat-button color="primary" class="z-index-3 pos-site"-->
    <!--            >przejdź do strony ABS POS</a-->
    <!--            >-->
    <!--            <a href="https://abspos.pl" target="_blank" mat-flat-button color="primary" class="z-index-3 pos-test"-->
    <!--            >Testuj 30 dni za darmo</a-->
    <!--            >-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <app-catalog></app-catalog>
  </div>
  <app-footer></app-footer>
</div>
