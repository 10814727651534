<app-loader></app-loader>
<div [hidden]="loading" class="positon-relative checkout">
  <!--<script src="../../search/filters.service.ts"></script>-->
  <app-header></app-header>
  <form #checkout="ngForm">
    <div class="container page_content">
      <a
        [routerLink]="[
          '/restauracja',
          translate.currentLang,
          userLocation.city | normalizeString | lowercase,
          cart.slug
        ]"
        class="back_to_local"
        ><i class="fas fa-undo-alt"></i> {{ cart.name }}</a
      >
      <h1 class="text-center mb-4 title" translate>Checkout</h1>
      <div *ngIf="cart?.id; else cartEmpty">
        <div class="row">
          <div class="col-lg-6 col-sm-12 col-12" *ngFor="let item of cart.order.products; index as i">
            <mat-card class="top_offer checkout">
              <div class="row">
                <!-- <div class="d-none d-sm-flex col-sm-4">
                  <img src="{{ item.product.image | getImageUrl }}" />
                </div> -->
                <div class="col-12 col-sm-12">
                  <h5 class="card-title">
                    {{ item.product.name | translateString: translate.currentLang }}
                    <!-- {{ item.attribute.name | translateString: translate.currentLang }} -->
                  </h5>
                  <div class="attribute" *ngIf="item.product.attributes && item.product.attributes.length > 1">
                    {{ item.attribute.name | translateString: translate.currentLang }}
                  </div>
                  <div class="card-text" *ngFor="let comp of item.components">
                    <div *ngIf="comp.component.default_count != comp.count">
                      {{ comp.component.name | translateString: translate.currentLang }} x {{ comp.count }}
                    </div>
                  </div>
                  <div class="bar">
                    <div class="price">{{ item.price | currency: ' ' }}zł</div>
                    <button
                      mat-stroked-button
                      color="primary"
                      class="px-1 mx-2"
                      (click)="openDialog(item)"
                      *ngIf="item.components.length"
                    >
                      <i class="fas fa-sliders-h"></i> Dodatki
                    </button>
                    <div class="change_count">
                      <button mat-stroked-button color="primary" (click)="Minus(0, i)">
                        <i class="fas fa-minus"></i>
                      </button>
                      <input type="text" name="count" value="{{ item.count }}" />
                      <button mat-stroked-button color="primary" (click)="Plus(0, i)">
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="linked-item" *ngFor="let item_l of item.linked; index as j">
                <div class="row">
                  <!-- <div class="d-none d-sm-flex col-sm-4">
                    <img src="{{ item_l.product.image | getImageUrl }}" />
                  </div> -->
                  <div class="col-12 col-sm-12">
                    <h5 class="card-title">
                      {{ item_l.product.name | translateString: translate.currentLang }}
                    </h5>

                    <div class="bar">
                      <div class="price">{{ item_l.price | currency: ' ' }}zł</div>

                      <div class="change_count">
                        <input type="text" name="count" value="{{ item_l.count }}" readonly />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <span class="badge badge-danger remove" (click)="Remove(0, i)"><i class="far fa-trash-alt"></i></span> -->
              <!--<span class="badge badge-primary edit"><i class="far fa-trash-alt"></i></span>-->
            </mat-card>
          </div>
        </div>
      </div>
      <ng-template #cartEmpty>
        <div class="no_products" translate>Koszyk jest pusty</div>
      </ng-template>

      <!-- <h5 class="sep_title" translate *ngIf="recommended.length">Recommend</h5>
      <ngx-slick-carousel class="recommended_carousel" #slickModal="slick-carousel" [config]="slickAdConfig">
        <div ngxSlickItem *ngFor="let single of recommended" class="slide">
          <mat-card>
            <div class="row">
              <div class="col-6">
                <img src="{{ single.img }}" />
              </div>
              <div class="col-6 right">
                <button mat-raised-button color="primary">Dodaj</button>
                <br />
                <div class="price">19.00zł</div>
                <div class="change_count float-right">
                  <button mat-stroked-button color="primary" (click)="minus(single)">
                    <i class="fas fa-minus"></i>
                  </button>
                  <input type="text" name="count" value="{{ single.quantity || 1 }}" />
                  <button mat-stroked-button color="primary" (click)="plus(single)">
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <h6 class="title">{{ single.title }}</h6>
          </mat-card>
        </div>
      </ngx-slick-carousel> -->
      <form *ngIf="cart?.id">
        <!-- <h5 class="sep_title w-50">Sposób dostawy</h5>
        <mat-radio-group
          aria-label="Sposób dostawy"
          color="primary"
          name="consume_type"
          class="select_delivery"
          (change)="changeDlivery($event)"
          [(ngModel)]="bill.consume_type"
        >
          <mat-radio-button [value]="1" [checked]="bill.consume_type === 1" *ngIf="cart.place.delivery">
            <i class="fas fa-truck icon"></i>Dostawa
          </mat-radio-button>
          <mat-radio-button [value]="2" [checked]="bill.consume_type === 2" *ngIf="cart.place.takeaway">
            <i class="fas fa-shopping-bag"></i>Na wynos
          </mat-radio-button>
          <mat-radio-button class="one" [value]="3" [checked]="bill.consume_type === 3" *ngIf="cart.place.on_local"
            ><i class="fas fa-store"></i>W lokalu
          </mat-radio-button>
        </mat-radio-group> -->

        <div class="forms">
          <div *ngIf="bill.consume_type == 3">
            <h5 class="sep_title">Dodatkowe informacje</h5>
            <!-- <div class="row py-3">
              <div class="col-md-12">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Wprowadz numer stolika"
                    name="table_number"
                    type="text"
                    [(ngModel)]="cart.order.table_number"
                  />
                  <mat-label>Numer stolika</mat-label>
                  <mat-icon matPrefix>
                    <img src="assets/icon/meeting.svg" class="input_icon" />
                  </mat-icon>
                </mat-form-field>
              </div>
            </div> -->

            <!-- <div class="row py-3">
              <div class="col-md-2 py-1">
                <div class="d-flex align-items-center">
                  <img src="assets/icon/meeting.svg" class="element_icon" />
                  <div class="change_count conterinform">
                    <button mat-stroked-button color="primary"><i class="fas fa-minus"></i></button>
                    <input type="text" name="count" value="1" />
                    <button mat-stroked-button color="primary"><i class="fas fa-plus"></i></button>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <mat-form-field appearance="outline">
                  <input matInput [matDatepicker]="dynamicName" placeholder="Data" class="date_input" />
                  <mat-datepicker-toggle matPrefix [for]="dynamicName"></mat-datepicker-toggle>
                  <mat-datepicker #dynamicName></mat-datepicker>
                  <mat-label>Data</mat-label>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field appearance="outline">
                  <input matInput placeholder="Godzina" [ngxTimepicker]="picker" [format]="24" readonly />
                  <ngx-material-timepicker
                    #picker
                    [theme]="theme"
                    [cancelBtnTmpl]="cancelBtn"
                  ></ngx-material-timepicker>
                  <ng-template #cancelBtn>
                    <button mat-button color="primary" style="margin-right: 10px;">Anuluj</button>
                  </ng-template>
                  <mat-label>Godzina</mat-label>
                  <ngx-material-timepicker-toggle [for]="picker" matPrefix>
                    <mat-icon>
                      <i class="far fa-clock"></i>
                    </mat-icon>
                  </ngx-material-timepicker-toggle>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="outline">
                  <input matInput placeholder="Uwagi" value="" />
                  <mat-label>Uwagi</mat-label>
                  <mat-icon matPrefix>
                    <img src="assets/icon/quotes.svg" class="input_icon" />
                  </mat-icon>
                </mat-form-field>
              </div>
            </div> -->
          </div>
          <div *ngIf="bill.consume_type == 2">
            <h5 class="sep_title">Dodatkowe informacje</h5>
            <div class="row py-3">
              <div class="col-md-12">
                <mat-form-field appearance="outline">
                  <mat-label translate>Godzina odbioru</mat-label>
                  <mat-select
                    required
                    [(ngModel)]="cart.order.take_away_time"
                    name="take_away"
                    [disabled]="isDisableTimePicker"
                  >
                    <mat-option [value]="0">Jak najszybciej (50-60 min)</mat-option>
                    <mat-option *ngFor="let mili of times" [value]="mili">
                      {{ mili | date: 'HH:mm' }}
                    </mat-option>
                  </mat-select>
                  <mat-hint *ngIf="isDisableTimePicker">Wybór godziny odbioru niedostępny</mat-hint>
                </mat-form-field>

                <!-- Godzina odbioru:
                <ngb-timepicker
                  [formControl]="ctrl"
                  [(ngModel)]="cart.order.take_away_time"
                  required
                  [spinners]="false"
                ></ngb-timepicker>
                <div *ngIf="ctrl.errors" class="small form-text text-danger">
                  <div *ngIf="ctrl.errors['required']">Wybierz godzinę odbioru</div>
                  <div *ngIf="ctrl.errors['tooLate']">
                    Lokal czynny do {{ close | date: 'HH:mm' }}. Wybierz wcześniejszą godzinę.
                  </div>
                  <div *ngIf="ctrl.errors['tooEarly']">
                    Lokal czynny od {{ open | date: 'HH:mm' }}. Wybierz późniejszą godzinę.
                  </div>
                  <div *ngIf="ctrl.errors['minTime'] && !ctrl.errors['tooLate']">
                    Najwcześniejsza godzina odbioru to {{ ctrl.errors['value'] | date: 'HH:mm' }}.
                  </div>
                </div> -->
              </div>
            </div>
          </div>

          <div *ngIf="bill.consume_type == 1">
            <h5 class="sep_title">Gdzie Twoje zamówienia ma zostać dostarczone?</h5>
            <div class="row py-3">
              <div class="col-md-3 col-12">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Jana Pawła II 64"
                    name="street"
                    type="text"
                    [(ngModel)]="deliveryAddress.street"
                    (change)="ChangeData()"
                    required="required"
                  />
                  <mat-label>Ulica</mat-label>
                  <mat-icon matPrefix><i class="fas fa-road"></i></mat-icon>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-12">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="88"
                    name="house_number"
                    type="text"
                    [(ngModel)]="deliveryAddress.number"
                    (change)="ChangeData()"
                    required="required"
                  />
                  <mat-label>Numer budynku</mat-label>
                  <mat-icon matPrefix><i class="fas fa-home"></i></mat-icon>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-12">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Numer mieszkania"
                    name="apart_number"
                    type="text"
                    [(ngModel)]="delivery.apart_number"
                    (change)="ChangeData()"
                  />
                  <mat-label>Numer mieszkania</mat-label>
                  <mat-icon matPrefix>
                    <i class="fas fa-door-open"></i>
                  </mat-icon>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-12">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Piętro"
                    name="floor_number"
                    type="number"
                    [(ngModel)]="delivery.floor_number"
                    (change)="ChangeData()"
                  />
                  <mat-label>Piętro</mat-label>
                  <!---->
                  <mat-icon matPrefix>
                    <i class="fas fa-angle-double-up"></i>
                  </mat-icon>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-12">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="00-000"
                    name="post_code"
                    type="text"
                    [(ngModel)]="delivery.post_code"
                    (change)="ChangeData()"
                    required="required"
                  />
                  <mat-label>Kod pocztowy</mat-label>
                  <mat-icon matPrefix>
                    <i class="fas fa-mail-bulk"></i>
                  </mat-icon>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-12">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Miasto"
                    name="city1s"
                    type="text"
                    [(ngModel)]="delivery.city"
                    (change)="ChangeData()"
                    required="required"
                  />
                  <mat-label>Miasto</mat-label>
                  <mat-icon matPrefix>
                    <i class="fas fa-city"></i>
                  </mat-icon>
                </mat-form-field>
              </div>

              <div class="col-md-3 col-12">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Klatka"
                    name="cage"
                    type="number"
                    [(ngModel)]="delivery.cage"
                    (change)="ChangeData()"
                  />
                  <mat-label>Klatka</mat-label>
                  <mat-icon matPrefix>
                    <img src="assets/icon/quotes.svg" class="input_icon" />
                  </mat-icon>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-12">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Kod dostępu"
                    name="access_code"
                    type="text"
                    [(ngModel)]="delivery.access_code"
                    (change)="ChangeData()"
                  />
                  <mat-label>Kod dostępu</mat-label>
                  <!---->
                  <mat-icon matPrefix>
                    <i class="fas fa-code-branch"></i>
                  </mat-icon>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row py-3">
            <div class="col-md-12">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Wprowadz dodatkowe informacje."
                  name="comment"
                  type="text"
                  [(ngModel)]="cart.order.comment"
                  (change)="ChangeData()"
                />
                <mat-label>Dodatkowe informacje do zamówienia</mat-label>
                <!---->
                <mat-icon matPrefix>
                  <img src="assets/icon/quotes.svg" class="input_icon" />
                </mat-icon>
              </mat-form-field>
            </div>
          </div>

          <h5 class="sep_title">Jak możemy się z Tobą skontaktować</h5>

          <div class="row py-3">
            <div class="col-md-3 col-12">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Imię"
                  name="name"
                  type="text"
                  (change)="changeFirstName($event.target.value)"
                  required="required"
                />
                <mat-label>Imię</mat-label>
                <mat-icon matPrefix>
                  <img src="assets/icon/name.svg" class="input_icon" />
                </mat-icon>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-12">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Nazwisko"
                  name="name"
                  type="text"
                  (change)="changeLastName($event.target.value)"
                  required="required"
                />
                <mat-label>Nazwisko</mat-label>
                <mat-icon matPrefix>
                  <img src="assets/icon/name.svg" class="input_icon" />
                </mat-icon>
              </mat-form-field>
            </div>
            <!-- <div class="col-md-4 col-12">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Imię i nazwisko"
                  name="client_name"
                  type="text"
                  (change)="changeImieNazwisko()"
                  [(ngModel)]="delivery.client_name"
                  required="required"
                />
                <mat-label>Imię i nazwisko</mat-label>
                
                <mat-icon matPrefix>
                  <img src="assets/icon/name.svg" class="input_icon" />
                </mat-icon>
              </mat-form-field>
            </div> -->
            <div class="col-md-3 col-12">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="E-mail"
                  name="email"
                  type="email"
                  [(ngModel)]="delivery.email"
                  (change)="changeEmail()"
                  required="required"
                />
                <mat-label>E-mail</mat-label>
                <!---->
                <mat-icon matPrefix>
                  <img src="assets/icon/at.svg" class="input_icon" />
                </mat-icon>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-12">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  placeholder="Numer telefonu"
                  name="phone_number"
                  type="tel"
                  [(ngModel)]="delivery.phone_number"
                  required="required"
                  [pattern]="mobNumberPattern"
                  (change)="changePhoneNumber()"
                />
                <mat-label>Numer telefonu</mat-label>
                <!---->
                <mat-icon matPrefix>
                  <img src="assets/icon/phone.svg" class="input_icon" />
                </mat-icon>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-12">
              <h5 class="sep_title">Dokument zakupu</h5>
              <mat-radio-group
                aria-label="Dokument zakupu"
                color="primary"
                name="payment_doc"
                class="select_receipt"
                (change)="changeDocument($event)"
                [(ngModel)]="bill.payment_doc"
                required="required"
              >
                <div class="d-flex justify-content-around">
                  <mat-radio-button [value]="0" [checked]="bill.payment_doc === 0">
                    <i class="fas fa-receipt"></i> Paragon
                  </mat-radio-button>
                  <mat-radio-button [value]="2" [checked]="bill.payment_doc === 2">
                    <i class="fas fa-file-invoice"></i> Faktura
                  </mat-radio-button>
                </div>
              </mat-radio-group>

              <div class="row py-3">
                <div *ngIf="bill.payment_doc === 2" class="col-md-6 col-12"></div>
                <div *ngIf="bill.payment_doc === 2" class="col-md-6 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      #nip
                      placeholder="Numer NIP"
                      name="tax_number"
                      minlength="10"
                      maxlength="10"
                      [(ngModel)]="bill.tax_number"
                    />
                    <mat-label>NIP</mat-label>
                    <mat-hint align="end">{{ nip.value.length }} / 10</mat-hint>
                    <!---->
                    <mat-icon matPrefix>
                      <img src="assets/icon/company.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-12">
              <h5 class="sep_title">Kupon rabatowy</h5>
              <form class="coupon-form py-3">
                <mat-form-field appearance="outline">
                  <input matInput placeholder="Kod" name="discountCode" [(ngModel)]="discountCode" />
                  <mat-label>Kod</mat-label>
                  <mat-icon matPrefix>
                    <img src="assets/icon/discount.svg" class="input_icon" />
                  </mat-icon>
                </mat-form-field>
                <button
                  mat-flat-button
                  [class.spinner]="buttonLoading"
                  [disabled]="buttonLoading"
                  color="primary"
                  (click)="CheckCode()"
                >
                  Zrealizuj
                </button>
              </form>
            </div>
          </div>

          <div class="row" *ngIf="cart?.id">
            <!-- <div class="col-12" *ngIf="bill.consume_type === 1">
              <div class="button_toggle">
                <h5 class="sep_title w-50">Napiwek dla dostawcy</h5>
                <mat-button-toggle-group
                  multiple
                  class=" w-50 m-auto"
                  name="tip"
                  aria-label="tip"
                  [(ngModel)]="tip"
                  (change)="tipChange($event)"
                >
                  <mat-button-toggle [value]="3">3%</mat-button-toggle>
                  <mat-button-toggle [value]="5">5%</mat-button-toggle>
                  <mat-button-toggle [value]="10">10%</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div> -->

            <div class="col-12">
              <h5 class="sep_title w-50">Podsumowanie</h5>
              <table class="summary w-50 m-auto">
                <tbody>
                  <tr>
                    <td>&nbsp;</td>

                    <td>brutto</td>
                  </tr>
                  <tr *ngIf="cart.order.sum < cart.place.min_order_price && this.bill.consume_type == 1">
                    <td colspan="2" class="text-center text-danger">
                      Uwaga!. Minimalna wartość zamówienia to: {{ cart.place.min_order_price | currency: ' ' }} zł
                    </td>
                  </tr>
                  <tr>
                    <td>Zamówione produkty</td>

                    <td>{{ cart.order.sum | currency: ' ' }} zł</td>
                  </tr>
                  <tr *ngIf="cart.order.consume_type === 1">
                    <td>Koszt dostawy</td>

                    <td>{{ cart.order.delivery.price | currency: ' ' }} zł</td>
                  </tr>
                  <!-- <tr *ngIf="cart.bill.tip !== null">
                    <td>Napiwek</td>

                    <td>{{ cart.bill.tip | currency: ' ' }} zł</td>
                  </tr>
                  <tr>
                    <td>
                      Opłata serwisowa
                      <button
                        mat-icon-button
                        class="icon-button-small"
                        matTooltip="Info about the action"
                        placement="top"
                        ngbPopover="Opłata serwisowa pokrywa koszty utrzymania aplikacji i zespołów obsługi. Opłata ta wynosi 10% kwoty zamówienia (maks. 8,00 zł)"
                      >
                        <mat-icon>info</mat-icon>
                      </button>
                    </td>

                    <td>{{ cart.bill.service_fee | currency: ' ' }} zł</td>
                  </tr> -->
                  <tr>
                    <td>Kwota całkowita</td>

                    <td>{{ cart.bill.sum | currency: ' ' }} zł</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-12">
              <h5 class="sep_title">Jak chciałbyś zapłacić?</h5>

              <mat-radio-group
                aria-label="Płatność"
                color="primary"
                name="payment_method"
                class="select_payment"
                (change)="changePayment($event)"
                [(ngModel)]="bill.payment_method[0]"
              >
                <mat-radio-button
                  class="radio-blik"
                  [value]="12"
                  [checked]="bill.payment_method[0] === 12"
                  *ngIf="cart.place.id_sub_merchant > 0 && CheckPayMethods(12)"
                >
                  <img src="assets/img/bank/BLIK.png" class="banklogo" /> &nbsp;
                </mat-radio-button>

                <mat-radio-button
                  class="radio-blik"
                  [value]="912"
                  [checked]="bill.payment_method[0] === 912"
                  *ngIf="(cart.place.imoje_account && cart.place.imoje_active && CheckPayMethods(912)) || debug"
                >
                  <img src="assets/img/bank/BLIK.png" class="banklogo" /> &nbsp;
                </mat-radio-button>

                <mat-radio-button
                  class="radio-payment-other"
                  [value]="2"
                  [checked]="bill.payment_method[0] === 2"
                  *ngIf="(cart.place.imoje_account && cart.place.imoje_active && CheckPayMethods(2)) || debug"
                >
                  <i class="fas fa-credit-card"></i> Karta
                </mat-radio-button>

                <mat-radio-button
                  class="radio-payment-other"
                  [value]="8"
                  [checked]="bill.payment_method[0] === 8"
                  *ngIf="(cart.place.imoje_account && cart.place.imoje_active && CheckPayMethods(8)) || debug"
                >
                  <i class="fas fa-university"></i>Przelew
                </mat-radio-button>
                <mat-radio-button
                  class="radio-payment-other"
                  [value]="1"
                  [checked]="bill.payment_method[0] === 1"
                  *ngIf="CheckPayMethods(1)"
                >
                  <i class="far fa-money-bill-alt"></i> Gotówka
                </mat-radio-button>
                <mat-radio-button
                  class="radio-payment-other"
                  [value]="16"
                  [checked]="bill.payment_method[0] === 16"
                  *ngIf="CheckPayMethods(16)"
                >
                  <i class="fas fa-credit-card"></i> Karta przy odbiorze
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-12 text-center">
              <div class="row py-3" *ngIf="bill.payment_method[0] == 2 || bill.payment_method[0] == 8">
                <div class="col-md-3 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Imię"
                      name="first_name"
                      type="text"
                      [(ngModel)]="transfersale.customer.firstName"
                      required="required"
                    />
                    <mat-label>Imię</mat-label>
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Nazwisko"
                      name="last_name"
                      type="text"
                      [(ngModel)]="transfersale.customer.lastName"
                      required="required"
                    />
                    <mat-label>Nazwisko</mat-label>
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Email"
                      name="email"
                      type="text"
                      [(ngModel)]="transfersale.customer.email"
                      required="required"
                    />
                    <mat-label>Email</mat-label>
                    <mat-icon matPrefix>
                      <img src="assets/icon/at.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Telefon"
                      name="phone"
                      type="text"
                      [(ngModel)]="transfersale.customer.phone"
                      [pattern]="mobNumberPattern"
                      required="required"
                    />
                    <mat-label>Telefon</mat-label>
                    <mat-icon matPrefix>
                      <img src="assets/icon/phone.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="row py-3" *ngIf="bill.payment_method[0] == 2">
                <div class="col-md-3 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Numer karty"
                      name="card_number"
                      type="text"
                      [(ngModel)]="cardsale.card.card_number"
                      required="required"
                    />
                    <mat-label>Numer karty</mat-label>
                    <!---->
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-2 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Miesiąc"
                      name="expiration_month"
                      type="text"
                      [(ngModel)]="cardsale.card.expiration_month"
                      required="required"
                    />
                    <mat-label>Miesiąc</mat-label>
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-2 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Rok"
                      name="expiration_year"
                      type="text"
                      [(ngModel)]="cardsale.card.expiration_year"
                      required="required"
                    />
                    <mat-label>Rok</mat-label>
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Imię i nazwisko posiadacza"
                      name="name_on_card"
                      type="text"
                      [(ngModel)]="cardsale.card.name_on_card"
                      required="required"
                    />
                    <mat-label>Imię i nazwisko posiadacza</mat-label>
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-2 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="CVV2/CVC2/CID"
                      name="card_code"
                      type="text"
                      [(ngModel)]="cardsale.card.card_code"
                      required="required"
                    />
                    <mat-label>CVV2/CVC2/CID</mat-label>
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
              <div class="py-3 banks" *ngIf="bill.payment_method[0] == 8">
                <a
                  [routerLink]=""
                  class="mx-2"
                  (click)="PickTransfer(bank.type)"
                  *ngFor="let bank of transfers; index as i"
                  [ngClass]="{ active: bank.type === transfersale.payment_type }"
                >
                  <img src="assets/img/bank/{{ bank.icon }}" class="banklogo" />
                </a>
              </div>
              <div class="row py-3" *ngIf="bill.payment_method[0] == 12">
                <div class="col-md-3 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Imię"
                      name="name"
                      type="text"
                      [(ngModel)]="bliksale.customer.name"
                      required="required"
                    />
                    <mat-label>Imię i nazwisko</mat-label>
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="adres email"
                      name="email"
                      type="text"
                      [(ngModel)]="bliksale.customer.email"
                      required="required"
                    />
                    <mat-label>E-mail</mat-label>
                    <!---->
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="blikCode"
                      name="blikCode"
                      type="text"
                      [(ngModel)]="blikCode"
                      required="required"
                    />
                    <mat-label>BLIK</mat-label>
                    <!---->
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>

              <div class="row py-3 justify-content-md-center" *ngIf="bill.payment_method[0] == 912">
                <div class="col-md-4 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Imię"
                      name="firstName"
                      type="text"
                      [(ngModel)]="Ingbliksale.customer.firstName"
                      required="required"
                    />
                    <mat-label>Imię</mat-label>
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-4 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Nazwisko"
                      name="lastName"
                      type="text"
                      [(ngModel)]="Ingbliksale.customer.lastName"
                      required="required"
                    />
                    <mat-label>Nazwisko</mat-label>
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-4 col-12">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="adres email"
                      name="email"
                      type="text"
                      [(ngModel)]="Ingbliksale.customer.email"
                      required="required"
                    />
                    <mat-label>E-mail</mat-label>
                    <!---->
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-12 text-center">
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="blikCode"
                      name="blikCode"
                      type="text"
                      [(ngModel)]="blikCode"
                      required="required"
                    />
                    <mat-label>BLIK</mat-label>
                    <!---->
                    <mat-icon matPrefix>
                      <img src="assets/icon/name.svg" class="input_icon" />
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>

              <button mat-raised-button color="primary" (click)="PlaceOrder()" *ngIf="bill.payment_method[0] == 1">
                Zamawiam
              </button>
              <button mat-raised-button color="primary" (click)="PlaceOrder()" *ngIf="bill.payment_method[0] == 16">
                Zamawiam
              </button>
              <button mat-raised-button color="primary" (click)="PlaceOrder()" *ngIf="bill.payment_method[0] == 2">
                Zamawiam i płacę
              </button>
              <button mat-raised-button color="primary" (click)="PlaceOrder()" *ngIf="bill.payment_method[0] == 8">
                Zamawiam i płacę
              </button>
              <button mat-raised-button color="primary" (click)="PlaceOrder()" *ngIf="bill.payment_method[0] == 12">
                Zamawiam i płacę
              </button>
              <button mat-raised-button color="primary" (click)="PlaceOrder()" *ngIf="bill.payment_method[0] == 912">
                Zamawiam i płacę
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </form>
  <app-footer></app-footer>
</div>
