import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@env/environment';
import { CoreModule } from '../app/core';
import { SharedModule } from '../app/shared';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { AboutModule } from './about/about.module';
import { SearchModule } from './search/search.module';
import { LoginModule } from './login/login.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthServiceConfig, GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { FormsModule } from '@angular/forms';
import { StaticModule } from './static/static.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CheckoutModule } from './checkout/checkout.module';
import { PlaceModule } from './place/place.module';
import { WidgetModule } from './widget/widget.module';
import { CatalogModule } from './catalog/catalog.module';
import { FoodtypeModule } from './foodtype/foodtype.module';
import { CookieService } from 'ngx-cookie-service';
import { SessionService } from '../app/core/session.service';
import { IpService } from '../app/core/ip.service';
import { LoaderInterceptor } from './core/http/loader.interceptor';
import { LoaderService } from './core/http/loader.service';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { InlineSVGModule } from 'ng-inline-svg';
import { isMain } from './shared/utility-functions';

import {
  RecaptchaModule,
  RecaptchaFormsModule,
  RECAPTCHA_V3_SITE_KEY,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings
} from 'ng-recaptcha';
import { HammerModule } from '@angular/platform-browser';
import { NotfoundComponent } from './notfound/notfound.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MobileDialogComponent } from './shared/mobile-dialog/mobile-dialog.component';
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('732863802095-560nc2g11vosv1ngugg81e9sm61n6thr.apps.googleusercontent.com')
  }
]);

export function provideConfig() {
  return config;
}

export function SessionServiceFactory(provider: SessionService) {
  return () => provider.load();
}
// const isCurrentDomainMain: boolean =
//   window.location.hostname === 'wciagnij.to' ||
//   window.location.hostname === 'dev.wciagnij.to' ||
//   window.location.hostname === 'localhost'; // local // prod

// var HomePlace: any = [];
// if (isCurrentDomainMain) {
//   HomePlace = [HomeModule, PlaceModule];
// } else {
//   HomePlace = [PlaceModule, HomeModule];
// }

@NgModule({
  imports: [
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey,
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule,
    BrowserModule.withServerTransition({ appId: 'wciagnijto' }),
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule,
    CoreModule,
    SharedModule,
    ShellModule,
    HomeModule,
    PlaceModule,
    WidgetModule,
    AboutModule,
    SearchModule,
    CatalogModule,
    FoodtypeModule,
    LoginModule,
    CheckoutModule,
    SocialLoginModule,
    StaticModule,
    //LeafletModule,
    AppRoutingModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    //MatSliderModule,
    //BrowserAnimationsModule,
    RecaptchaModule,
    RecaptchaFormsModule, // if you need forms support
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-right',
      maxOpened: 4
    }),
    HammerModule,
    InlineSVGModule.forRoot()
    //MatTooltipModule
  ],
  declarations: [AppComponent, NotfoundComponent, MobileDialogComponent],
  providers: [
    CookieService,
    SessionService,
    IpService,
    { provide: APP_INITIALIZER, useFactory: SessionServiceFactory, deps: [SessionService], multi: true },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LeGDO8UAAAAAImXlJfI2PpyD6skdnlEkvfg8IyJ' } as RecaptchaSettings
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

//bitbucket test
