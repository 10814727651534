<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <h1 class="text-center page-title">Dla restauracji</h1>
    <div class="row justify-content-center">
      <div class="col-10">
        <p class="text">
          Nowość do zamawiania jedzenia online!<br />
          Chcesz umożliwić swoim Gościom zamawianie jedzenia online, a nie masz ochoty płacić wysokich prowizji?<br />
          Masz dość czekania na uwzględnienie zmian w menu i chcesz samodzielnie panować nad zasięgami dostaw?<br />
          Wybierz <a href="https://wciagnij.to">wciagnij.to</a>!<br />
          Bez prowizji<sup>*</sup>, bez stresu, bez kłopotu.<br />
          Właśnie ruszamy z ogólnopolską promocją, dlatego nie pobieramy żadnych opłat!<br />
          Chcesz wiedzieć więcej? Zadzwoń!<br />
        </p>

        <div class="text">
          Korzyści dla restauracji: <br />
          💸 niewielka prowizja w porównaniu od konkurencji,<br />
          💵 zamawiarka połączone z oprogramowaniem <a href="https://abspos.pl">ABS POS</a>,<br />
          💰 możliwość korzystania ze swoich kurierów poprzez <span class="green">ABS Drive</span>, <br />
          💳 dodatkowy kanał sprzedaży wśród młodych miłośników zielonej AppKi <span class="green">Wciągnij.to</span>
          <br /><br />
          Niczym nie ryzykujesz... wciągnij innych... <br /><br />
        </div>

        <a [routerLink]="['/join']" mat-flat-button color="primary" class="z-index-3 dks-button text-center"
          >Dołącz do nas i wypełnij formularz</a
        >
      </div>
      <div class="col-10">
        <br /><br /><br /><br />
        <p class="text"><sup>*</sup> W wypadku płatności online prowizja systemu płatności wynosi 5%.</p>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
