import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Logger } from '../../core/logger.service';

const log = new Logger('reverse geocode');
const routes = {
  // 2f1ce640cc026a
  quote: (phraze: string) =>
    `https://eu1.locationiq.com/v1/search.php?key=2f1ce640cc026a&q=${phraze}&format=json&addressdetails=1&normalizecity=1`
  //`https://eu1.locationiq.com/v1/reverse.php?key=2f1ce640cc026a&lat=${c.lat}&lon=${c.lon}&format=json`
};

export interface RevLocationIQContext {
  lon: number;
  lat: number;
  display_name: string;
  class: string;
  icon: string;
  importance: string;
  licence: string;
  osm_id: string;
  osm_type: string;
  place_id: string;
  type: string;
  address: {
    city: string;
    country: string;
    country_code: string;
    county: string;
    state: string;
    house_number: string;
    road: string;
    street: string;
    postcode: string;
  };
}

export interface ReverseGeocodeContext {
  phraze: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReverseGeocodeService {
  constructor(private httpClient: HttpClient) {}

  getGeocodeQuote(context: string): Observable<RevLocationIQContext> {
    return (
      this.httpClient
        // .skipLoader()
        .disableApiPrefix()
        .skipLoader()
        .cache()
        .get(routes.quote(context))
        .pipe(
          tap((response: RevLocationIQContext) => {
            log.debug(response);
            return response;
            // return response.map((item:RevLocationIQContext)=>{
            //   if (item.type !=='convenience'){
            //     return item;
            //     }
            // });
          })
        )
    );
  }
}
