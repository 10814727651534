import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-phoneapp',
  templateUrl: './phoneapp.component.html',
  styleUrls: ['./phoneapp.component.scss']
})
export class PhoneappComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
