import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { GeocodeContext, GeocodeService, LocationIQContext } from '../shared/geocode/geocode.service';
import { RevLocationIQContext } from '../shared/reversegeocode/reversegeocode.service';
import { SessionService } from '@app/core/session.service';
import { Logger } from '../core/logger.service';
import { LoaderService } from '@app/core/http/loader.service';
import { UserLocation } from '@app/shared/model/userLocation';
import { LocationComponent } from './location/location.component';
import { Meta, Title } from '@angular/platform-browser';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

const log = new Logger('home');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  loading: boolean;
  quote: {} | undefined;
  isLoading = false;
  target: string;
  userLocation: UserLocation = {
    city: null,
    country: null,
    post_code: null,
    formatted_address: null,
    lat: null,
    lng: null,
    street: null,
    house_number: null,
    gres: null
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    // private loaderService: LoaderService,
    //private session: SessionService,
    //private metaTagService: Meta,
    private titleService: Title,
    private httpClient: HttpClient
  ) {
    // this.loading = false;
    // this.isLoading = false;
    // this.loaderService.isLoading.subscribe(v => {
    //   //console.log(v);
    //   this.loading = v;
    // });
    // this.loaderService.isLoading.subscribe(v => {
    //   //console.log(v);
    //   this.loading = v;
    // });
  }

  ngOnInit() {
    this.target = environment.target;

    // this.loaderService.isLoading.subscribe(v => {
    //   //console.log(v);
    //   this.loading = v;
    // });
    // document.body.classList.add('home');
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('userLocation')) {
        this.userLocation = JSON.parse(localStorage.getItem('userLocation'));
      }
    }

    this.titleService.setTitle('Wciagnij.to - Zamów jedzenie online');

    this.getCurrentLocation();
  }

  ngOnDestroy() {
    //document.body.classList.remove('home');
  }

  onLoaded(load: boolean) {
    this.isLoading = load;
  }

  getCurrentLocation() {
    if (!this.userLocation.formatted_address) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          console.log('location', pos);

          this.httpClient
            // .get<any>('https://geolocation-db.com/json/')
            .get<any>(
              'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
                pos.coords.latitude +
                ',' +
                pos.coords.longitude +
                '&sensor=true&key=' +
                environment.googleMapsKey
            )
            .subscribe(res => {
              console.log('googleapis res', res);
              this.userLocation = {
                ...this.userLocation,
                city: res.results[0].address_components[3].long_name,
                country: res.results[0].address_components[6].long_name,
                post_code: res.results[0].address_components[7].long_name,
                formatted_address: res.results[0].formatted_address,
                street: res.results[0].address_components[1].long_name,
                house_number: res.results[0].address_components[0].long_name,
                lat: pos.coords.latitude,
                lng: pos.coords.longitude
              };
              console.log('this.userLocation', this.userLocation);
            });
        });
      }
    }
  }
}
