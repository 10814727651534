<div class="location">
  <!--<h1 class="title title-thin">Daj się <strong>wciągnąć!</strong> Wpisz adres!</h1>-->
  <div class="select_delivery">
    <div
      class="single"
      [ngClass]="{ active: searchType === 1 }"
      (click)="selectSearchType(1)"
      inlineSVG="assets/img/adres.svg"
      matTooltip="Lokalizacja"
    ></div>
    <div
      class="single"
      [ngClass]="{ active: searchType === 2 }"
      (click)="selectSearchType(2)"
      inlineSVG="assets/img/restauracja.svg"
      matTooltip="Lokal"
      *ngIf="!hideButtons"
    ></div>
    <div
      class="single"
      [ngClass]="{ active: searchType === 3 }"
      (click)="selectSearchType(3)"
      inlineSVG="assets/img/danie.svg"
      matTooltip="Danie"
      *ngIf="!hideButtons"
    ></div>
  </div>
  <div class="new_search" *ngIf="searchType === 1">
    <mat-form-field appearance="fill" class="w-100" *ngIf="isBrowser()">
      <mat-label>Adres dostawy</mat-label>
      <input
        [name]="inputName"
        matInput
        matGoogleMapsAutocomplete
        [types]="[]"
        [country]="['pl']"
        [value]="userLocation.formatted_address"
        (onAutocompleteSelected)="onAutocompleteSelected($event)"
        (onLocationSelected)="onLocationSelected($event)"
      />
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="Search($event)">Szukaj</button>
  </div>
  <div class="new_search" *ngIf="searchType === 2">
    <mat-form-field appearance="fill" class="w-100" *ngIf="isBrowser()">
      <mat-label>Nazwa restauracji</mat-label>
      <input name="searchPlace" matInput [value]="stringQuery" [(ngModel)]="stringQuery" />
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="Search($event)">Szukaj</button>
  </div>
  <div class="new_search" *ngIf="searchType === 3">
    <mat-form-field appearance="fill" class="w-100" *ngIf="isBrowser()">
      <mat-label>Nazwa dania</mat-label>

      <input name="searchDish" matInput [value]="stringQuery" [(ngModel)]="stringQuery" />
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="Search($event)">Szukaj</button>
  </div>
</div>
