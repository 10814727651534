<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <h1 class="text-center page-title"><span class="green">EKO</span> jest WOW!</h1>
    <div class="row justify-content-center">
      <div class="col-10">
        <p class="text justify">
          Mając przed sobą wybór różnych kuchni oraz różnych smaków w jednym miejscu z możliwością dokonania zamówienia
          z dowolnego miejsca każdy z nas widzi przewagę jakie daje <span class="green">EKOLOGICZNA</span> zamawiarka
          <span class="green">Wciągnij.to!</span>
        </p>
        <p class="text justify">
          Prosta intuicyjna strona www, inspirująca szata graficzna, przejrzyste MENU danego punktu gastro, mobilność,
          responsywność, płatności online to przewaga nad konkurencją. Polska
          <span class="green">EKOLOGICZNA</span> zamawiarka jedzenia online również na urządzenia mobilne. Już jest w
          <a href="https://apps.apple.com/pl/app/wciągnij-to/id1592732104" target="_blank">AppStore</a> oraz w
          <a href="https://play.google.com/store/apps/details?id=pl.abs.wcignijto&hl=pl&gl=PL" target="_blank"
            >Google Play</a
          >
          do pobrania! Cały czas rozwijana... ale ciągle <span class="green">ZIELONA</span>🍀🌿🌱🌳🌲
        </p>

        <div class="text">
          <p><span class="green">ZIELONA</span> i wygodna dla użytkownika:</p>
          🍀 szybkie wyszukiwanie restauracji po adresie,<br />
          🍀 szukanie swojej zielonej restauracji po nazwie,<br />
          🍀 błyskawiczne wyszukiwanie swojego ulubionego dania w danej lokalizacji,<br />
          🍀 wgląd w swoją historię zamówień,<br />
          🍀 wgląd w menu danej restauracji,<br />
          🍀 możliwość skanowania MENU zielonej restauracji poprzez kod QR.<br /><br />

          I co najważniejsze... 🌲🌿🌱ekologiczna jak Diario Ecologia🍀🌱🌳
        </div>

        <p class="text justify">
          Zgłaszać Wasze ulubione ZIELONE miejsca - pytaj się w swoim ulubionym lokalu o zieloną AppKe do zamówień
          Wciagnij.to 🪴🪴🪴
        </p>

        <!-- <p class="text justify">
          Warto też zaznaczyć ze wszystkie zamówienia trafiają od razu z <span class="green">Wciągnij.to</span> do systemu
          sprzedażowego danej restauracji nie trzeba zapisywać nic na kartkach, robi się więcej miejsca na barze – nie
          ma 3-4 tabletów każdy od innego pośrednika – jest wszystko tak jak powinno być … czyli w jednym miejscu w tzw.
          <a href="https://abspos.pl">ABS POS</a>.
        </p> -->
        <p class="text text-right">Zachęcamy do korzystania z rozwiązań naszych – polskich, dokładnie z Częstochowy</p>

        <p class="text text-right">
          Pozdrawiamy<br />
          Wciągnij.to<br />
        </p>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
