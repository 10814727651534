import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RevLocationIQContext } from '@app/shared/reversegeocode/reversegeocode.service';
import { NameLocalizationModel } from '@app/shared/model/nameLocalization.model';
import { ImageModel } from '@app/shared/model/image.model';
import PlaceResult = google.maps.places.PlaceResult;
import { UserLocation } from '@app/shared/model/userLocation';
const routes = {
  quote: () => `/promo`
};

export interface APIPromoContext {
  data: [
    {
      address: {
        city: string;
        country: string;
        county: string;
        latlng: string;
        level: string;
        map_link: string;
        number: string;
        post_code: string;
        radius: string;
        street: string;
      };
      text: NameLocalizationModel;
      image_object: ImageModel;
      local_id: string;
      slug: string;
      name: string;
    }
  ];
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class PromoService {
  constructor(private httpClient: HttpClient) {}

  getPromoQuote(locals_id: any): Observable<any> {
    const data: any = {
      locals_id: locals_id
    };
    return this.httpClient
      .skipLoader()
      .cache()
      .post<APIPromoContext[]>(routes.quote(), data)
      .pipe(
        map((body: APIPromoContext[]) => body),
        catchError(() => of('Error, could not load nearest restaurants'))
      );
  }
}
