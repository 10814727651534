<header [ngClass]="{ 'padding-mobile': target === 'mobile' }">
  <div class="container position-relative">
    <a routerLink="/search" class="back_min" [ngClass]="{ hidden: !isScrolled }">
      <i class="fas fa-undo-alt" title="Powrót do wyników wyszukiwania"></i>
    </a>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 col-4 text-left">
        <a routerLink="/szukaj" routerLinkActive="active" *ngIf="page === 'restaurant'" class="backButton">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </a>
        <a routerLink="" routerLinkActive="active" title="{{ 'Home' | translate }}" class="logoLink">
          <div class="logo" [inlineSVG]="'assets/logo_2020.svg'"></div>
        </a>
      </div>
      <!--<div class="col-lg-3 col-md-6 col-sm-6">
        <form class="search_header mb-4">
          <input type="text" placeholder="Search..." translate />
          <input type="submit" value="Search" translate />
        </form>
      </div>-->
      <div class="col-lg-9  d-lg-block">
        <nav class="navbar navbar-expand-lg bg-transparent page_nav">
          <button
            class="hamburger hamburger--arrow-r"
            [class.is-active]="!menuHidden"
            type="button"
            aria-controls="navbar-menu"
            aria-label="Toggle navigation"
            (click)="toggleMenu()"
            [attr.aria-expanded]="!menuHidden"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
          <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
            <!--<img  class="logo" src="assets/logo.svg"/>-->
            <div class="navbar-nav ml-auto position-relative">
              <!-- <a class="nav-item nav-link" routerLink="/konkurs" routerLinkActive="active">
                <span>Konkurs</span>
              </a> -->
              <a class="nav-item nav-link nav-separator" routerLink="/jak-dzialamy" routerLinkActive="active">
                <span>Ekologiczna zamawiarka</span>
              </a>
              <a class="nav-item nav-link nav-separator" routerLink="/dla-restauracji" routerLinkActive="active">
                <span>Dla restauracji</span>
              </a>
              <a class="nav-item nav-link" href="http://festiwal-smaku.pl/" target="_blank">
                <span>Festiwal Smaku</span>
              </a>
              <!-- <button mat-flat-button color="primary" class="login" (click)="login()" *ngIf="!user">
                zaloguj
              </button>
              <button mat-flat-button color="primary" class="login" (click)="login()" *ngIf="user">
                {{ 'Profil:' | translate }}{{ user.name }}
              </button> -->

              <!-- <div class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
                <a id="language-dropdown" class="nav-link" ngbDropdownToggle>{{ currentLanguage }}</a>
                <div ngbDropdownMenu aria-labelledby="language-dropdown">
                  <button class="dropdown-item" *ngFor="let language of languages" (click)="setLanguage(language)">
                    {{ language }}
                  </button>
                </div>
              </div> -->
              <div class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
                <a id="user-dropdown" class="nav-link d-none d-lg-block" ngbDropdownToggle>
                  <i class="fas fa-user-circle"></i>
                </a>
                <div ngbDropdownMenu aria-labelledby="user-dropdown" class="user-info show-mobile">
                  <div class="user-info-container dropdown-content" *ngIf="user">
                    <img [src]="user.photoUrl" class="user-info-image" />
                    <div class="user-info-desc">
                      <p>{{ userInfo?.name }}</p>
                      <p class="coin">
                        <img src="assets/icon/abscoin.svg" /> <span>{{ userCoins }}</span>
                      </p>
                      <div class="dropdown-divider"></div>
                    </div>
                  </div>

                  <button class="dropdown-item" (click)="login()" *ngIf="!user">zaloguj</button>
                  <button class="dropdown-item" (click)="logout()" *ngIf="user">wyloguj się</button>
                </div>
              </div>
              <a class="nav-item nav-link" href="https://www.youtube.com/channel/UC5MmBtPfjuRioHw3SZXh5wQ/featured">
                <i class="fab fa-youtube"></i>
              </a>
              <a class="nav-item nav-link" href="https://www.facebook.com/Wciagnij.to/">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a class="nav-item nav-link" href="https://www.instagram.com/wciagnijto/">
                <i class="fab fa-instagram"></i>
              </a>
            </div>
          </div>
          <!--          <div class="nav-item basket-container" ngbDropdown display="dynamic" placement="bottom right">-->
          <!--            <app-basket></app-basket>-->
          <!--          </div>-->
        </nav>
      </div>
    </div>
    <!--    <div class="row place_info" [ngClass]="{ hidden: isScrolled }">-->
    <!--      <div class="col-lg-4 col-12 text-center text-lg-left" *ngIf="main">-->
    <!--        <a routerLink="/szukaj" class="back">-->
    <!--          <i class="fas fa-undo-alt"></i>-->
    <!--          Powrót do wyników wyszukiwania-->
    <!--        </a>-->
    <!--      </div>-->

    <!--      <div class="col-lg-4 col-12 text-center text-lg-left p-0" *ngIf="place">-->
    <!--        <i class="fas fa-truck"></i> Średni czas dostawy to {{ place.average_time }} min-->
    <!--      </div>-->

    <!--      <div class="col-lg-4 col-12 text-center text-lg-right" *ngIf="place">-->
    <!--        <i class="fas fa-clock"></i> Dziś otwarte:-->
    <!--        <span *ngIf="place.work_time[currentWeekDayIndex].open != 0 && place.work_time[currentWeekDayIndex].open != -1">-->
    <!--          {{ place.work_time[currentWeekDayIndex].open | date: 'HH:mm' }} - -->
    <!--          {{ place.work_time[currentWeekDayIndex].close | date: 'HH:mm' }}-->
    <!--        </span>-->
    <!--        <span *ngIf="place.work_time[currentWeekDayIndex].open == 0">nieczynne</span>-->
    <!--        <span *ngIf="place.work_time[currentWeekDayIndex].open == -1">całodobowo</span>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</header>
