import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';

import { MobileappComponent } from './mobileapp/mobileapp.component';
import { LocationComponent } from './location/location.component';
import { MobileappInstallComponent } from './mobileapp-install/mobileapp-install.component';
import { CatalogComponent } from './catalog/catalog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { MatIconModule } from '@angular/material/icon';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { MatCardModule } from '@angular/material/card';
import { LocationModalComponent } from './location/modal/location.modal';
import { EmptyLocationModalComponent } from './location/modal/emptyLocation.modal';
import { MatTabsModule } from '@angular/material/tabs';
import { PlatformModule } from '@angular/cdk/platform';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    HomeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatGoogleMapsAutocompleteModule,
    BrowserModule,
    HttpClientModule,
    InlineSVGModule,
    MatCardModule,
    MatDialogModule,
    MatTabsModule,
    PlatformModule
  ],
  declarations: [
    HomeComponent,
    MobileappComponent,
    LocationComponent,
    MobileappInstallComponent,
    CatalogComponent,
    LocationModalComponent,
    EmptyLocationModalComponent
  ]
})
export class HomeModule {}
