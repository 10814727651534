/// <reference types="@types/googlemaps" />

import {
  Component,
  EventEmitter,
  ViewEncapsulation,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  Inject,
  OnDestroy
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap, debounceTime, map, catchError } from 'rxjs/operators';
import {} from 'googlemaps';
import PlaceResult = google.maps.places.PlaceResult;
import { Location, Appearance } from '@angular-material-extensions/google-maps-autocomplete';
import parseAddress from './address.parser';
import { EmptyLocationModalComponent } from '../../home/location/modal/emptyLocation.modal';
import {
  ReverseGeocodeContext,
  ReverseGeocodeService,
  RevLocationIQContext
} from '../../shared/reversegeocode/reversegeocode.service';

import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '../../core/logger.service';
import { UserLocation } from '@app/shared/model/userLocation';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocationModalComponent } from './modal/location.modal';
import { TranslateService } from '@ngx-translate/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { StateService } from '@app/core/state.service';
import { HttpService } from '@app/core';
import { HttpClient } from '@angular/common/http';
import { CardsSaleResponse } from '@app/shared/model/payline.model';

const log = new Logger('location_search');
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LocationComponent implements OnInit, OnDestroy {
  public appearance = Appearance;
  public zoom: number;
  public latitude: number;
  public longitude: number;
  public inputName: string;
  public selectedAddress: PlaceResult;
  // private userLocation: UserLocation = {
  //   city: null,
  //   country: null,
  //   post_code: null,
  //   formatted_address: null,
  //   lat: null,
  //   lng: null,
  //   street: null,
  //   house_number: null,
  //   gres: null
  // };
  isLoading = false;
  consume_type: number = 1;

  subscriptions: Array<Subscription> = [];

  searchType: number = 1;
  stringQuery: string;

  @Input() userLocation: UserLocation;
  @Input() redirect: boolean;
  @Input() dialogRef: MatDialogRef<LocationModalComponent>;
  @Output() loaded = new EventEmitter<boolean>();
  @Input() hideButtons: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private reverseGeocodeService: ReverseGeocodeService,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,
    private stateService: StateService,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.appearance = Appearance;
    this.zoom = 10;
    this.latitude = 52.520008;
    this.longitude = 13.404954;

    //this.setCurrentPosition();

    if (this.dialogRef) {
      this.inputName = 'modal_googlesearch';
      document.querySelector('.pac-container').remove();
    } else {
      this.inputName = 'header_googlesearch';
    }

    //document.body.classList.add('local');

    // Usunięcie domyślnej, bootstrapowej klasy z inputa wyplutego przez mat-google-maps-autocomplete
    //

    this.subscriptions.push(
      this.stateService.consume_type$.subscribe(value => {
        this.consume_type = value;
      })
    );
  }

  onAutocompleteSelected(result: PlaceResult) {
    //console.log('onAutocompleteSelected: ', result);

    this.userLocation = parseAddress(result);
    //console.log(this.userLocation);
  }

  onLocationSelected(location: Location) {
    // console.log('onLocationSelected: ', location);
    this.latitude = location.latitude;
    this.longitude = location.longitude;
  }

  Search(event: Event) {
    //log.error(this.redirect);
    /*if((<HTMLInputElement>document.getElementById("mat-input-0")).getAttribute('ng-reflect-model'))*/

    if (this.userLocation.lat && this.userLocation.lng) {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem('userLocation', JSON.stringify(this.userLocation));
      }
      if (this.redirect) {
        if (this.dialogRef) {
          let { data } = this.dialogRef.componentInstance;
          this.dialogRef.close();
          this.router.navigateByUrl(
            `szukaj/kategoria/${this.translate.currentLang}/${data.category}` +
              (this.stringQuery ? '?stringQuery=' + this.stringQuery : '')
          );
        } else {
          this.router.navigateByUrl('szukaj' + (this.stringQuery ? '?stringQuery=' + this.stringQuery : ''));
        }
      } else {
        this.dialogRef.close();
      }
    } else {
      this.openEmptyLocationDialog();
    }
  }

  openEmptyLocationDialog() {
    //event.preventDefault();

    //if (this.userLocation && this.userLocation.lat)
    //return this.router.navigateByUrl(`szukaj/kategoria/${this.translate.currentLang}/${category}`);

    this.dialog.open(EmptyLocationModalComponent, {
      data: {}
    });
  }

  ngOnDestroy() {
    //document.body.classList.remove('locals');
  }

  isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  selectSearchType(value: number) {
    this.searchType = value;
    //this.stateService.consume_type$.next(value);
  }

  private setCurrentPosition() {
    if (isPlatformBrowser(this.platformId)) {
      // if ('geolocation' in navigator) {
      //   navigator.geolocation.getCurrentPosition(position => {
      //     this.latitude = position.coords.latitude;
      //     this.longitude = position.coords.longitude;
      //     this.zoom = 12;
      //   });
      // }
    }
  }
}
