<div class="catalog">
  <div class="container">
    <mat-tab-group mat-align-tabs="center" animationDuration="0ms" class="tabs">
      <mat-tab label="Najpopularniejsze miasta">
        <div class="tab">
          <div class="cities">
            <a
              *ngFor="let city of cities.data"
              class="single"
              href=""
              [routerLink]="[
                '/katalog',
                translate.currentLang,
                city.name | translateString: translate.currentLang | normalizeString | lowercase
              ]"
              [style.background-image]="'url(/assets/cities/' + city.slug + '.jpg)'"
            >
              <span class="title">
                {{ city.name | translateString: translate.currentLang }}
              </span>
            </a>
            <a [routerLink]="['/katalog', translate.currentLang]" class="single"
              ><span class="title">Wszystkie</span></a
            >
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Kuchnie świata">
        <div class="tab">
          <div class="categories">
            <a
              href=""
              *ngFor="let category of categories.data"
              [routerLink]="['/zamow-jedzenie', translate.currentLang, category.slug]"
              class="single"
              [style.background-image]="'url(/assets/cuisines/' + category.slug + '.jpg)'"
            >
              <span class="title">{{ category.name | translateString: translate.currentLang }}</span>
            </a>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
