<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <div class="oferta_container" style="min-height:  320px; display: flex; flex-direction: column;">
      <h1 class="text-center page-title">Wciagnij.to</h1>
      <div style="display: flex; flex: 1; justify-content: center; align-items: center;">
        <div class="catalog-row" *ngIf="!isEmpty(cities)">
          <div class="catalog-col">
            <div *ngFor="let state of cities.data | slice: 0:10">
              <div *ngIf="isState(state)">
                <h4 class="catalog-state">{{ state.state }}</h4>
                <ul>
                  <li *ngFor="let city of state.cities">
                    <a href="" [routerLink]="['/katalog', translate.currentLang, city | normalizeString | lowercase]">
                      {{ city }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="catalog-col">
            <div *ngFor="let state of cities.data | slice: 10">
              <div *ngIf="isState(state)">
                <h4 class="catalog-state">{{ state.state }}</h4>
                <ul>
                  <li *ngFor="let city of state.cities">
                    <a href="" [routerLink]="['/katalog', translate.currentLang, city | normalizeString | lowercase]">
                      {{ city }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
