import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { RegulaminComponent } from './regulamin/regulamin.component';
import { Shell } from '@app/shell/shell.service';
import { OfertyPracyComponent } from './oferty-pracy/oferty-pracy.component';
import { CookiesComponent } from '@app/static/cookies/cookies.component';
import { ForrestaurantsComponent } from '@app/static/forrestaurants/forrestaurants.component';
import { HowitworksComponent } from '@app/static/howitworks/howitworks.component';
import { LegalComponent } from '@app/static/legal/legal.component';
import { MediaComponent } from '@app/static/media/media.component';
import { PrivacyComponent } from '@app/static/privacy/privacy.component';
import { RecommendComponent } from '@app/static/recommend/recommend.component';
import { InstallIosComponent } from '@app/static/install-ios/install-ios.component';
import { SupportComponent } from '@app/static/support/support.component';
import { JoinComponent } from '@app/static/join/join.component';
import { ContactComponent } from './contact/contact.component';
import { ContestComponent } from '@app/static/contest/contest.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'regulamin', component: RegulaminComponent, data: { title: extract('Regulamin') } },
    { path: 'regulations', redirectTo: '/regulamin', pathMatch: 'full' },
    { path: 'oferty-pracy', component: OfertyPracyComponent, data: { title: extract('Oferty Pracy') } },
    { path: 'job-offers', redirectTo: '/oferty-pracy', pathMatch: 'full' },
    { path: 'cookies', component: CookiesComponent, data: { title: extract('Polityka Cookies') } },
    { path: 'dla-restauracji', component: ForrestaurantsComponent, data: { title: extract('Dla restauracji') } },
    { path: 'for-restaurants', redirectTo: '/dla-restauracji', pathMatch: 'full' },
    { path: 'jak-dzialamy', component: HowitworksComponent, data: { title: extract('Jak działamy') } },
    { path: 'how-it-works', redirectTo: '/jak-dzialamy', pathMatch: 'full' },

    { path: 'konkurs', component: ContestComponent, data: { title: extract('Konkurs') } },
    { path: 'contest', redirectTo: '/konkurs', pathMatch: 'full' },

    { path: 'dolacz', component: JoinComponent, data: { title: extract('Dołącz do systemu') } },
    { path: 'join', redirectTo: '/dolacz', pathMatch: 'full' },
    { path: 'informacje-prawne', component: LegalComponent, data: { title: extract('Informacja prawna') } },
    { path: 'legal', redirectTo: '/informacje-prawne', pathMatch: 'full' },
    { path: 'media', component: MediaComponent, data: { title: extract('Dla mediów') } },
    { path: 'polityka-prywatnosci', component: PrivacyComponent, data: { title: extract('Polityka prywatności') } },
    { path: 'privacy', redirectTo: '/polityka-prywatnosci', pathMatch: 'full' },
    { path: 'polec-wciagnijto', component: RecommendComponent, data: { title: extract('Poleć restaurację') } },
    { path: 'recommend', redirectTo: '/polec-wciagnijto', pathMatch: 'full' },
    { path: 'wsparcie', component: SupportComponent, data: { title: extract('Wsparcie') } },
    { path: 'support', redirectTo: '/wsparcie', pathMatch: 'full' },
    { path: 'kontakt', component: ContactComponent, data: { title: extract('Kontakt') } },
    { path: 'contact', redirectTo: '/kontakt', pathMatch: 'full' },
    { path: 'instalacja-ios', component: InstallIosComponent, data: { title: extract('Instalacja w systemie iOS') } }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class StaticRoutingModule {}
