import { Component, OnInit } from '@angular/core';
import { CitiesService } from './cities.service';
import { debounce, debounceTime, finalize } from 'rxjs/operators';
import { Logger } from '../core/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { SeoCities, SeoCitiesResp } from '../shared/model/seoCities.model';
import { environment } from '../../environments/environment';
const log = new Logger('nearest_search');

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
  target: string;
  cities: SeoCitiesResp;
  loading: boolean;
  constructor(private citiesService: CitiesService, public translate: TranslateService) {
    this.citiesService
      .getCatalogQuote()
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: SeoCitiesResp) => {
        //console.log(typeof quote);
        //console.log(quote instanceof SeoCitiesResp);
        if (quote) {
          log.debug(quote);
          this.cities = quote;
        }
      });
  }

  isState(state: any) {
    return 'state' in state;
  }

  isEmpty(object: any) {
    //console.log(typeof object);
    if (typeof object === 'undefined') return true;

    if (typeof object !== undefined) {
      return Object.keys(object).length === 0;
    } else return true;
  }

  ngOnInit() {
    this.target = environment.target;
    // this.citiesService
    //   .getCatalogQuote()
    //   .pipe(
    //     finalize(() => {
    //       // this.isLoading = false;
    //     })
    //   )
    //   .subscribe((quote: SeoCitiesResp) => {
    //     if (quote) {
    //       log.debug(quote);
    //       this.cities = quote;
    //     }
    //   });
  }
}
