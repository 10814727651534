import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartState, State } from '@app/shared/model/state.model';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  state: State = new State({
    consume_type: 1
  });

  cart$: BehaviorSubject<CartState> = new BehaviorSubject(this.state.cart);
  consume_type$: BehaviorSubject<number> = new BehaviorSubject(this.state.consume_type);

  constructor() {}

  cartIsOpen(value: boolean | 'toggle') {
    this.cart$.next({
      ...this.cart$.getValue(),
      isOpen: value === 'toggle' ? !this.cart$.getValue().isOpen : value
    });
  }

  cartIsLoading(value: boolean) {
    this.cart$.next({
      ...this.cart$.getValue(),
      isLoading: value
    });
  }
}
