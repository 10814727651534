import { Component, OnInit, Inject } from '@angular/core';
import { debounce, debounceTime, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { APICitiesContext, CitiesService } from './cities.service';
import { FiltersService, APIFiltersContext } from '../../search/filters.service';

import { TranslateService } from '@ngx-translate/core';
import { LocationComponent } from '../location/location.component';
import { MatDialog } from '@angular/material/dialog';
import { LocationModalComponent } from '../location/modal/location.modal';
import { UserLocation } from '@app/shared/model/userLocation';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss']
})
export class CatalogComponent implements OnInit {
  userLocation: UserLocation;
  cities: APICitiesContext = {
    data: null,
    count: null
  };

  categories: APIFiltersContext = {
    data: null,
    count: null
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private citiesService: CitiesService,
    private filtersService: FiltersService,
    public translate: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.userLocation = JSON.parse(localStorage.getItem('userLocation'));
    }
    this.citiesService
      .getCitiesQuote({
        slug: null,
        home: true,
        limit: 10
      })
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: APICitiesContext) => {
        if (quote) {
          this.cities = quote;
        }
      });

    this.filtersService
      .getFiltersQuote()
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: APIFiltersContext) => {
        if (quote) {
          this.categories = quote;
        }
      });
  }

  openSearchDialog(category: string, event: MouseEvent) {
    event.preventDefault();

    if (this.userLocation && this.userLocation.lat)
      return this.router.navigateByUrl(`szukaj/kategoria/${this.translate.currentLang}/${category}`);

    this.dialog.open(LocationModalComponent, {
      data: {
        category: category
      }
    });
  }
}
