import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Md5 } from 'md5-typescript';
import { environment } from '../../../environments/environment';

const routes = {
  transaction: () => `/place_order`,
  getBill: () => `/getBill`
};

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private httpClient: HttpClient) {}

  setOrder(data: any) {
    for (var i = 0; i < data['order']['products'].length; i++) {
      data['order']['products'][i]['product']['attributes'] = [];
      data['order']['products'][i]['attribute']['components'] = [];
    }

    //console.log(JSON.stringify(data['order']));
    //console.log(JSON.stringify(data['order'])+environment.secret);
    const ord_checksum = Md5.init(JSON.stringify(data['order']) + environment.secret);
    const bill_checksum = Md5.init(JSON.stringify(data['bill']) + environment.secret);

    data['bill']['chsum'] = ord_checksum;
    data['order']['chsum'] = bill_checksum;

    //console.log(data['bill']);
    //console.log(data['order']);

    return this.httpClient
      .cache()
      .post<any>(routes.transaction(), data)
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not load nearest restaurants'))
      );
  }
  getBillStatus(id: string) {
    return this.httpClient
      .cache()
      .post<any>(routes.getBill(), { id: id })
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not load nearest restaurants'))
      );
  }
}
