import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  IngCardsSaleModel,
  IngCards3DSaleModel,
  IngCards3DModel,
  IngCardsSaleResponse,
  IngTransferSaleModel,
  IngTransferSaleResponse,
  IngCards3DResponse,
  IngBlikModel,
  IngBlikResponse
} from '@app/shared/model/ing.model';
import { Logger } from '../core/logger.service';
import { APIRestaurantsContext } from '@app/search/nearest/nearest.service';
const log = new Logger('ing');
import { environment } from '@env/environment';
import { payMethods } from '@app/shared/model/paymethods.model';

const routes = {
  cardsCheck: () => `/checkCard3DSecure`,
  cards3DSell: () => `/saleBy3DSecureAuthorization`,
  cardsSell: () => `/cardSale`,
  bank: () => `/imoje/payment/transaction`,
  blik: () => `/imoje/payment/transaction`,
  methods: () => '/getPayMethods',
  check: (id_sale: string) => '/imoje/payment/transaction/' + id_sale
};

@Injectable({
  providedIn: 'root'
})
export class IngService {
  constructor(private httpClient: HttpClient) {}

  Card3DAuth(context: IngCards3DModel): Observable<IngCards3DResponse> {
    const data: IngCards3DModel = context;
    log.debug(data);
    log.debug(routes.cardsCheck());
    return (
      this.httpClient
        //.disableApiPrefix()
        .skipSource()
        .post<IngCards3DResponse>(routes.cardsCheck(), data)
        .pipe(
          map((body: any) => body),
          catchError(() => of('Error, could not load your location'))
        )
    );
  }

  Card3DSale(context: IngCards3DSaleModel): Observable<IngCardsSaleResponse> {
    const data: IngCards3DSaleModel = context;
    log.debug(data);
    log.debug(routes.cards3DSell());
    return (
      this.httpClient
        //.disableApiPrefix()
        .skipSource()
        .post<IngCards3DSaleModel>(routes.cards3DSell(), data)
        .pipe(
          map((body: any) => body),
          catchError(() => of('Error, could not load your location'))
        )
    );
  }

  CardSale(context: IngCardsSaleModel): Observable<IngCardsSaleResponse> {
    const data: IngCardsSaleModel = context;
    log.debug(data);
    return (
      this.httpClient
        //.disableApiPrefix()
        .skipSource()
        .post<IngCardsSaleResponse>(routes.cardsSell(), data)
        .pipe(
          map((body: any) => body),
          catchError(() => of('Error, could not load your location'))
        )
    );
  }

  TransferSale(context: IngTransferSaleModel): Observable<IngTransferSaleResponse> {
    const data: IngTransferSaleModel = context;
    log.debug(data);
    log.debug(routes.bank());
    return (
      this.httpClient
        //.disableApiPrefix()
        .skipSource()
        .post<IngCardsSaleResponse>(routes.bank(), data)
        .pipe(
          map((body: any) => body),
          catchError(() => of('Error, could not load your location'))
        )
    );
  }

  BlikSale(context: IngBlikModel): Observable<IngBlikResponse> {
    const data: IngBlikModel = context;
    log.debug(data);
    log.debug(routes.blik());
    return (
      this.httpClient
        //.disableApiPrefix()
        .skipSource()
        .post<IngBlikResponse>(routes.blik(), data)
        .pipe(
          map((body: any) => body),
          catchError(() => of('Error, could not load your location'))
        )
    );
  }

  Check(id_sale: string): Observable<any> {
    return (
      this.httpClient
        //.disableApiPrefix()
        .skipSource()
        .get<any>(routes.check(id_sale))
        .pipe(
          map((body: any) => body),
          catchError(() => of('Error, could not load your location'))
        )
    );
  }

  PayMethods(): Observable<payMethods> {
    const data: any = [];
    return this.httpClient
      .skipSource()
      .post<payMethods>(routes.methods(), data)
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not load your location'))
      );
  }
}
