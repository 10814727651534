<div class="login-container bg-light">
  <div class="login-box">
    <h1 translate>APP_NAME</h1>
    <div class="container">
      <div class="card col-md-6 mt-3 mx-auto">
        <div class="card-body">
          <h4 class="card-title text-center">
            <i class="far fa-3x fa-user-circle text-muted"></i>
          </h4>

          <button class="btn mx-1" (click)="signInWithGoogle()">Zaloguj się z Google</button>
        </div>
      </div>
      <div *ngIf="user" class="card text-center">
        <h6 class="card-header">
          Wciagnij.to
        </h6>
        <div class="card-block"></div>
        <img class="card-img-top img-responsive photo" src="{{ user.photoUrl }}" />
        <div class="card-block">
          <h4 class="card-title">{{ user.name }}</h4>
          <p class="card-text">{{ user.email }}</p>
        </div>
        <div class="card-block">
          <button class="btn btn-danger" (click)="signOut()">Sign out</button>
        </div>
      </div>
    </div>
  </div>
</div>
