import PlaceResult = google.maps.places.PlaceResult;

export class UserLocation {
  lat: number;
  lng: number;
  formatted_address: string;
  city: string;
  country: string;
  post_code: string;
  street: string;
  house_number: string;
  gres: PlaceResult;
}
