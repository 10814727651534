import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BillModel } from '@app/shared/model/bill.model';
import { Subscription } from 'rxjs';
import { SessionService } from '@app/core/session.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { DeliveryPriceRange, PlaceModel } from '@app/shared/model/place.model';
import { Logger } from '@app/core';
import { StateService } from '@app/core/state.service';
import { CartState } from '@app/shared/model/state.model';
import { finalize } from 'rxjs/operators';
import { APIPopularContext } from '@app/search/nearest/popular.service';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { isOpen } from '../utility-functions';

const log = new Logger('basketwidget');

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
  data: any;
  cart: any;
  count = 0;
  bill: BillModel = {
    id: null,
    type: 'bill',
    timestamp: null,
    uid: null,
    flag: 0,
    local_id: null,
    status: 2,
    consume_type: 1,
    name: null,
    create: null,
    verified: 0,
    payment_method: [2],
    payment_doc: 0,
    tax_number: null,
    invoice_url: null,
    payment_status: 2,
    orders_id: [],
    user_id: null,
    source: 3,
    hall_id: null,
    discount: null,
    sum: 0,
    sum_net: 0,
    sum_vat: 0,
    user_close_id: null,
    split_bills: [],
    coins: 0,
    webhook: false,
    exist_discount: false,
    tip: null,
    service_fee: null
    // p24: null
  };
  subscriptions: Array<Subscription> = [];
  cartState = new CartState();

  placeSlug: string;
  isOpen = false;

  updateSession = _.debounce(() => {
    this.session.setSessionKey('cart', this.cart);
  }, 800);

  constructor(
    public translate: TranslateService,
    private session: SessionService,
    private state: StateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.placeSlug = params.get('slug');
    });

    this.subscriptions.push(
      this.session.currentData.subscribe(() => {
        const cart = this.session.getSessionKey('cart');
        if (cart && cart.hasOwnProperty('slug') && cart.slug === this.placeSlug) {
          if (cart.order) {
            if (cart.order.products) {
              this.cart = cart;
              this.count = this.cart.order.products.reduce((a: any, b: any) => a + b.count, 0);
              this.calculateOrder();
              this.isOpen = isOpen(cart.place.work_time);
            }
          }
        }
      })
    );

    this.subscriptions.push(
      this.state.cart$.subscribe(state => {
        this.cartState = state;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  minus(i: number) {
    console.log(this.cart.order.products);
    this.cart.order.products[i].count = this.cart.order.products[i].count - 1;

    if (this.cart.order.products[i].count === 0) {
      this.remove(i);
    }
    this.calculateOrder();
    this.count = this.cart.order.products.reduce((a: any, b: any) => a + b.count, 0);
    this.updateSession();
  }

  plus(i: number) {
    this.cart.order.products[i].count = this.cart.order.products[i].count + 1;

    if (this.cart.order.products[i].count === 0) {
      this.remove(i);
    }
    this.calculateOrder();
    this.count = this.cart.order.products.reduce((a: any, b: any) => a + b.count, 0);
    this.updateSession();
  }

  remove(i: number) {
    console.log('id', i);
    //this.cart.order.products = this.cart.order.products.filter((p: { id: string; }) => (p.id !== id));
    this.cart.order.products.splice(i, 1);
    this.calculateOrder();
    this.count = 0;
    this.state.cartIsLoading(true);
    this.updateSession();
  }

  delete() {
    this.cart.order.products = [];
    this.calculateOrder();
    this.count = this.cart.order.products.reduce((a: any, b: any) => a + b.count, 0);
    this.state.cartIsLoading(true);
    this.updateSession();
  }

  calculateOrder() {
    if (!this.cart.bill) {
      this.cart.bill = this.bill;
    }

    log.debug(this.cart.bill);
    this.cart.bill.sum = 0;
    this.cart.bill.sum_vat = 0;
    this.cart.bill.sum_net = 0;
    this.cart.order.sum = 0;
    this.cart.order.sum_vat = 0;
    this.cart.order.sum_net = 0;
    for (const item of this.cart.order.products) {
      this.cart.order.sum = this.cart.order.sum + item.price * item.count;
      this.cart.order.sum_vat = this.cart.order.sum_vat + item.price_vat * item.count;
      this.cart.order.sum_net = this.cart.order.sum_net + item.price_net * item.count;

      for (const p of item.linked.values()) {
        p.count = item.count;
        p.sum = p.price * item.count;
        p.sum_vat = p.price_vat * item.count;
        p.sum_net = p.price_net * item.count;

        this.cart.order.sum = this.cart.order.sum + p.price * item.count;
        this.cart.order.sum_vat = this.cart.order.sum_vat + p.price_vat * item.count;
        this.cart.order.sum_net = this.cart.order.sum_net + p.price_net * item.count;
      }
    }

    const dd = this.calculateDeliveryPrice();
    this.cart.order.delivery.price = dd.sum;
    this.cart.order.delivery.price_vat = dd.sum_vat;
    this.cart.order.delivery.price_net = dd.sum_net;

    this.cart.bill.sum = this.cart.order.sum + this.cart.order.delivery.price;
    this.cart.bill.sum_vat = this.cart.order.sum_vat + this.cart.order.delivery.price_vat;
    this.cart.bill.sum_net = this.cart.order.sum_net + this.cart.order.delivery.price_net;
    this.cart.bill.webhook = this.cart.place.webhook;
  }

  calculateDeliveryPrice() {
    const price = {
      sum: 0,
      sum_vat: 0,
      sum_net: 0
    };

    /**
     *
     *
     * Math.round(price.product_price*
     ((this.orderedProduct.product.vat/100)/100) * 100) / 100;
     * @type {number}
     */

    if (this.bill.consume_type == 1) {
      if (this.cart.order.sum < this.cart.place.delivery_sum_mode || this.cart.place.delivery_sum_mode === 0) {
        if (this.cart.place.delivery_price_range?.length) {
          this.cart.place.delivery_price_range.sort(
            (a: DeliveryPriceRange, b: DeliveryPriceRange) => a.maxDistance - b.maxDistance
          );
          const priceRange = this.cart.place.delivery_price_range.find(
            (range: DeliveryPriceRange) => this.cart.order.delivery.distance <= range.maxDistance
          );
          price.sum = priceRange ? priceRange.price : 0;
          price.sum_vat = Math.round(price.sum * (this.cart.place.delivery_vat / 100 / 100) * 100) / 100;
          price.sum_net = price.sum - price.sum_vat;
        } else {
          price.sum = this.cart.place.delivery_price;
          price.sum_vat = Math.round(price.sum * (this.cart.place.delivery_vat / 100 / 100) * 100) / 100;
          price.sum_net = price.sum - price.sum_vat;
        }
      }
    }

    return price;
  }

  close() {
    this.state.cartIsOpen(false);
  }
}
