import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounce, debounceTime, finalize } from 'rxjs/operators';
import { APIRestaurantsContext, NearestService } from './nearest.service';
import { APIPopularContext, PopularService } from './popular.service';
import { APIPromoContext, PromoService } from './promo.service';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { Logger } from '../../core/logger.service';
import { UserLocation } from '@app/shared/model/userLocation';
import { isOpen, todayOpenTime } from '@app/shared/utility-functions';
const log = new Logger('nearest_search');

@Component({
  selector: 'app-nearest',
  templateUrl: './nearest.component.html',
  styleUrls: ['./nearest.component.scss']
})
export class NearestComponent implements OnInit {
  nearesData: APIRestaurantsContext = {
    data: null,
    count: null
  };

  festivalData: APIRestaurantsContext = {
    data: null,
    count: null
  };

  popularData: APIPopularContext = {
    data: null,
    count: null
  };

  promoData: APIPromoContext = {
    data: null,
    count: null
  };

  isOpen: Function;
  todayOpenTime: Function;

  public rate: number = 5;

  private eventMessage: any = {
    count: null,
    promoData: null,
    festivalCount: null
  };

  private locals_ids: any = [];

  private _filter: any;

  @Input()
  userLocation: UserLocation;

  @Input()
  view_style: number;

  @Input()
  festival: boolean;

  @Input()
  set filter(filter: any) {
    this._filter = filter || null;
  }

  @Output() messageEvent = new EventEmitter<any>();

  get filter(): any {
    return this._filter;
  }
  isLoading: boolean;

  constructor(
    private nearestService: NearestService,
    private popularService: PopularService,
    private promoService: PromoService,
    private router: Router,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.isOpen = isOpen;
    this.todayOpenTime = todayOpenTime;

    //console.log(todayOpenTime);
    log.debug(this.userLocation);
    if ('lat' in this.userLocation && 'lng' in this.userLocation) {
      // var filterCopy = JSON.parse(JSON.stringify(this.filter));
      // filterCopy.festival = true;
      // this.nearestService
      //   .getNearestQuote(this.userLocation, filterCopy)
      //   .pipe(
      //     finalize(() => {
      //       // this.isLoading = false;
      //     })
      //   )
      //   .subscribe((quote: APIRestaurantsContext) => {
      //     if (quote) {
      //       log.debug(quote);
      //       this.festivalData = quote;
      //     }
      //   });

      this.isLoading = true;
      this.nearestService
        .getNearestQuote(this.userLocation, this.filter)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((quote: APIRestaurantsContext) => {
          if (quote) {
            log.debug(quote);
            this.nearesData = quote;
            this.eventMessage.count = this.nearesData.count;
            //this.messageEvent.emit(this.eventMessage);
            this.locals_ids = [];
            this.nearesData.data.forEach(element => {
              this.locals_ids.push(element.id);
            });

            const festival = quote.data.filter(element => element.festival_smak);
            this.festivalData = {
              data: festival,
              count: festival.length
            };
            this.eventMessage.festivalCount = this.festivalData.count;

            // this.popularService
            //   .getPopularQuote(this.locals_ids)
            //   .pipe(
            //     finalize(() => {
            //       // this.isLoading = false;
            //     })
            //   )
            //   .subscribe((quote: APIPopularContext) => {
            //     if (quote) {
            //       this.popularData = quote;
            //     }
            //   });

            this.promoService
              .getPromoQuote(this.locals_ids)
              .pipe(
                finalize(() => {
                  // this.isLoading = false;
                })
              )
              .subscribe((quote: APIPromoContext) => {
                if (quote) {
                  this.promoData = quote;
                  this.eventMessage.promoData = this.promoData.data;
                  this.messageEvent.emit(this.eventMessage);
                }
              });
          }
        });
    }
  }

  Search() {
    this.nearestService
      .getNearestQuote(this.userLocation, this.filter)
      .pipe(
        debounceTime(50),
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: APIRestaurantsContext) => {
        if (quote) {
          log.debug(quote);
          //console.log(quote, 'qt');
          this.nearesData = quote;
          this.eventMessage.count = this.nearesData.count;
          //this.messageEvent.emit(this.nearesData.count);
          this.locals_ids = [];
          this.nearesData.data.forEach(element => {
            this.locals_ids.push(element.id);
          });

          const festival = quote.data.filter(element => element.festival_smak);
          this.festivalData = {
            data: festival,
            count: festival.length
          };
          this.eventMessage.festivalCount = this.festivalData.count;

          // this.popularService
          //   .getPopularQuote(this.locals_ids)
          //   .pipe(
          //     finalize(() => {
          //       // this.isLoading = false;
          //     })
          //   )
          //   .subscribe((quote: APIPopularContext) => {
          //     if (quote) {
          //       this.popularData = quote;
          //     }
          //   });

          this.promoService
            .getPromoQuote(this.locals_ids)
            .pipe(
              finalize(() => {
                // this.isLoading = false;
              })
            )
            .subscribe((quote: APIPromoContext) => {
              if (quote) {
                this.promoData = quote;
                this.eventMessage.promoData = this.promoData.data;
                this.messageEvent.emit(this.eventMessage);
              }
            });
        }
      });
    /*this.nearestService
      .getNearestQuote(this.userLocation, this.filter)
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: APIRestaurantsContext) => {
        if (quote) {
          log.debug(quote);
          this.nearesData = quote;

          this.messageEvent.emit(this.nearesData.count);
        }
      });*/
  }

  UpdateFilter(filter: any) {
    this._filter = filter;
  }
}
