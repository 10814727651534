import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { catchError, finalize, map } from 'rxjs/operators';
import { PlaceModel } from '@app/shared/model/place.model';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SessionModel } from '@app/shared/model/session.model';
import { Logger } from './logger.service';
const log = new Logger('session');
import { BehaviorSubject } from 'rxjs';
import { StateService } from '@app/core/state.service';

const routes = {
  init: () => `/sessionInit`,
  get: () => `/sessionGet`,
  save: () => `/sessionSave`
};

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  session: SessionModel = {
    sessionToken: null,
    sessionData: [],
    sessionValid: +Date.now() + 3600 * 1000 * 6
  };

  sessionToken: string;
  private dataSource = new BehaviorSubject(this.session);
  currentData = this.dataSource.asObservable();

  constructor(private cookie: CookieService, private httpClient: HttpClient, private state: StateService) {}

  load() {
    return new Promise((resolve, reject) => {
      log.debug('SESSION CONSUTRUCTO');

      if (this.cookie.check('sessionToken')) {
        this.sessionToken = this.cookie.get('sessionToken');

        if (this.sessionToken !== 'undefined') {
          this.session.sessionToken = this.sessionToken;
          this.getSession()
            .pipe(finalize(() => {}))
            .subscribe((quote: SessionModel) => {
              if (quote) {
                log.debug(quote);
                this.session = quote;
                this.dataSource.next(this.session);
                this.sessionToken = quote.sessionToken;
                resolve(true);
              }
            });
        } else {
          this.initSession()
            .pipe(finalize(() => {}))
            .subscribe((quote: SessionModel) => {
              if (quote) {
                log.debug(quote);
                this.session = quote;
                this.dataSource.next(this.session);
                this.sessionToken = quote.sessionToken;
                this.cookie.set('sessionToken', this.sessionToken, this.session.sessionValid, '/');
                resolve(true);
              }
            });
        }
      } else {
        this.initSession()
          .pipe(finalize(() => {}))
          .subscribe((quote: SessionModel) => {
            if (quote) {
              log.debug(quote);
              this.session = quote;
              this.dataSource.next(this.session);
              this.sessionToken = quote.sessionToken;
              this.cookie.set('sessionToken', this.sessionToken, this.session.sessionValid, '/');
              resolve(true);
            }
          });
      }
    });
  }

  initSession() {
    const data: SessionModel = this.session;
    return this.httpClient
      .cache()
      .skipLoader()
      .post<SessionModel>(routes.init(), data)
      .pipe(
        map((body: SessionModel) => {
          this.dataSource.next(body);
          return body;
        }),
        catchError(() => of('Error, could not load session data'))
      );
  }

  getSession() {
    const data: SessionModel = this.session;
    return this.httpClient
      .cache()
      .skipLoader()
      .post<SessionModel>(routes.get(), data)
      .pipe(
        map((body: SessionModel) => {
          this.dataSource.next(body);
          return body;
        }),
        catchError(() => of('Error, could not load session data'))
      );
  }

  updateSession() {
    const data: SessionModel = this.session;
    return this.httpClient
      .cache()
      .skipLoader()
      .post<SessionModel>(routes.save(), data)
      .pipe(
        map((body: SessionModel) => {
          //this.dataSource.next(body);
          return body;
        }),
        catchError(() => of('Error, could not load session data'))
      );
  }

  checkSessionKey(key: string): boolean {
    return this.session.sessionData.find(obj => obj.id === key) ? true : false;
  }

  getSessionKey(key: string): any {
    var sessionKey: any = null;
    sessionKey = this.session.sessionData.find(obj => obj.id === key);

    if (sessionKey) {
      if (sessionKey.data !== 'undefined') {
        return sessionKey.data || null;
      }
    }
    return null;
  }

  setSessionKey(key: string, data: any): any {
    // this.state.cartIsLoading(true);

    const keyIndex = this.session.sessionData.findIndex(obj => obj.id === key);
    log.debug(keyIndex);
    if (keyIndex !== -1) {
      this.session.sessionData[keyIndex].data = data;
    } else {
      const NewsessionKeyValue = {
        id: key,
        data
      };
      this.session.sessionData.push(NewsessionKeyValue);
    }

    this.updateSession()
      .pipe(finalize(() => {}))
      .subscribe((quote: SessionModel) => {
        if (quote) {
          log.debug(quote);
          this.session = quote;
          this.dataSource.next(this.session);
          this.sessionToken = quote.sessionToken;
          this.cookie.set('sessionToken', this.sessionToken, this.session.sessionValid);
          this.state.cartIsLoading(false);
        }
      });
  }
}
