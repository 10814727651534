import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '@app/core/http/loader.service';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from '@env/environment';
@Component({
  selector: 'app-recommend',
  templateUrl: './recommend.component.html',
  styleUrls: ['./recommend.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecommendComponent implements OnInit {
  target: string;
  loading: boolean;
  form: any;
  responseMessage: string;
  constructor(private loaderService: LoaderService, private http: HttpClient, private formBuilder: UntypedFormBuilder) {
    this.responseMessage = null;
    this.form = this.formBuilder.group({
      name: '',
      email: '',
      local_name: '',
      city: '',
      info: '',
      agreement: true,
      recaptchaReactive: new UntypedFormControl(null, Validators.required)
    });
  }

  ngOnInit() {
    this.target = environment.target;
    this.loaderService.isLoading.subscribe(v => {
      //console.log(v);
      this.loading = v;
    });
  }

  submit() {
    const data = this.form.getRawValue();

    this.http
      .skipLoader()
      .post('https://system.abspos.pl/api/recommend', data)
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not send request'))
      )

      .subscribe(
        res => {
          if (res.status === 'ok') {
            this.responseMessage = 'Dziękujemy za zgłoszenie restauracji w systemie!\n';
          }

          if (res.status === 'registered') {
            this.responseMessage = 'Zgłoszenie zostało już wysłane';
          }
        },
        err => {
          //console.log(err);
        }
      );
    //this.form.reset();
  }
}
