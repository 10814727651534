import { OnInit, Component, ViewChild, ElementRef, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { finalize } from 'rxjs/operators';
import { Logger } from '../core/logger.service';
import { RevLocationIQContext } from '@app/shared/reversegeocode/reversegeocode.service';
import { WidgetService } from '@app/widget/widget.service';
import { PlaceModel } from '../shared/model/place.model';
import { APIPromoContext, PromoService } from '../search/nearest/promo.service';
import { APIPopularContext, PopularService } from '../search/nearest/popular.service';
import { DeliveryModel, OrderedProductModel, OrderModel } from '../shared/model/order.model';
import { CartModel } from '@app/shared/model/cart.model';
import { SessionService } from '@app/core/session.service';
import { ViewportScroller } from '@angular/common';
import { LoaderService } from '@app/core/http/loader.service';
import { Observable, Observer } from 'rxjs';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { SlugifierPipe } from '../shared/slugifier.pipe';
import { TranslateStringPipe } from '../shared/translate-string.pipe';
import { Meta, Title } from '@angular/platform-browser';
import { isOpen, isMain } from '@app/shared/utility-functions';
import { LocationModalComponent } from '../home/location/modal/location.modal';
import { ToastrService } from 'ngx-toastr';

import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MenuProductModel } from '@app/shared/model/menuProduct.model';

const log = new Logger('restaurant');

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  providers: [SlugifierPipe, TranslateStringPipe]
})
export class WidgetComponent implements OnInit {
  @ViewChild('imgBuffer') imageElement: ElementRef;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  consume_type: number = 1;
  currentWeekDayIndex: number = 0;
  isOpen: boolean = false;
  promoData: APIPromoContext = {
    data: null,
    count: null
  };

  popularData: APIPopularContext = {
    data: null,
    count: null
  };

  public place_page: boolean = true;
  loading: boolean;
  layers: any = [];
  toCat: any;
  toCatId: any;
  toProduct: any;
  toProductId: any;

  center: any;
  zoom = 15;
  cart: CartModel = {
    id: null,
    slug: null,
    name: null,
    order: null,
    bill: null,
    place: null
  };

  order: OrderModel = {
    id: null,
    type: 'order',
    timestamp: null,
    uid: null,
    local_id: null,
    status: 2,
    consume_type: 0,
    delivery: {
      price: 0,
      price_vat: 0,
      price_net: 0,
      email: null,
      phone_number: null,
      client_name: null,
      floor_number: null,
      apart_number: null,
      access_code: null,
      address: null,
      cage: null,
      city: null,
      post_code: null,
      show_as_product: false,
      exist_in_other: false,
      delivery_type: 0,
      geo: {
        lat: 0,
        lon: 0
      },
      distance: 0,
      delivery_pay_mode: 0
    },
    reservation_id: null,
    phone_number: null,
    estimated_ready_time: 0,
    products: [],
    bill_id: null,
    create: null,
    comment: null,
    take_away_time: null,
    user_id: null,
    send_time: null,
    sum: 0,
    sum_net: 0,
    sum_vat: 0,
    source: 3,
    table_number: null
  };

  place: PlaceModel = {
    address: null,
    name: null,
    local_host: null,
    local_type: null,
    id: null,
    delivery: false,
    delivery_price: 0,
    delivery_mode: 1,
    delivery_vat: 0,
    maxDistance: 0,
    delivery_price_range: [],
    delivery_sum_mode: 0,
    festival_smak: false,
    description: [],
    remote_order: false,
    work_time: [
      { open: 0, close: 0 },
      { open: 0, close: 0 },
      { open: 0, close: 0 },
      { open: 0, close: 0 },
      { open: 0, close: 0 },
      { open: 0, close: 0 },
      { open: 0, close: 0 }
    ],
    image: null,
    logo: null,
    geo: null,
    slug: null,
    category: [],
    short_description: [],
    phone_number: null,
    prefix: null,
    company_info: null,
    image_object: null,
    takeaway: null,
    delivery_product: null,
    average_time: 0,
    id_sub_merchant: null,
    service_fee_percent: null,
    instagram: null,
    youtube: null,
    facebook: null,
    min_order_price: 0,
    on_local: null,
    webhook: false,
    imoje_account: null,
    imoje_active: false
  };

  placeCart: CartModel = {
    id: null,
    slug: null,
    name: null,
    order: this.order,
    bill: null,
    place: null
  };

  userLocation: RevLocationIQContext = {
    lon: null,
    lat: null,
    display_name: null,
    class: null,
    icon: null,
    importance: null,
    licence: null,
    osm_id: null,
    osm_type: null,
    place_id: null,
    type: null,
    address: {
      city: null,
      country: null,
      country_code: null,
      county: null,
      state: null,
      house_number: null,
      road: null,
      street: null,
      postcode: null
    }
  };

  promotions = [
    {
      name: 'Rabat -5%',
      img: '/assets/promocja.png',
      icon: '/assets/wedding.png',
      description: 'Tańszy zestaw'
    },
    {
      name: 'Rabat -10%',
      img: '/assets/promocja.png',
      icon: '/assets/wedding.png',
      description: 'Bilet w jedną stronę'
    }
  ];

  all_products: any;

  rate: 5;
  logo_img_element: string = undefined;

  image: any;
  base: any;
  target: any;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private placeService: WidgetService,
    private promoService: PromoService,
    private popularService: PopularService,
    private session: SessionService,
    private viewportScroller: ViewportScroller,
    private slugifier: SlugifierPipe,
    private toastr: ToastrService,
    private translator: TranslateStringPipe,
    private metaTagService: Meta,
    private titleService: Title,
    public dialog: MatDialog
  ) {
    //console.log('PLACE COMPOENET');
    //console.log(isMain());
    this.loaderService.isLoading.subscribe(v => {
      //console.log(v);
      this.loading = v;
    });
    this.all_products = new Map();
  }
  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.image = this.imageElement.nativeElement;
    //   this.imageElement.nativeElement.src = this.logo_img_element;
    //   this.image = this.imageElement.nativeElement;
    // }, 500);
  }

  ngOnInit() {
    this.target = environment.target;

    //console.log('PLACE COMPOENET');
    // if (isPlatformBrowser(this.platformId)) {
    //   if (localStorage.getItem('userLocation')) {
    //     this.userLocation = JSON.parse(localStorage.getItem('userLocation'));
    //     log.debug(this.userLocation);
    //   } else {
    //     this.openSearchDialog('test');
    //   }
    // } else {
    //   //window.location.hostname = 'localhost';
    // }

    console.log(isPlatformBrowser(this.platformId));
    console.log(isMain(this.platformId));
    if (isMain(this.platformId)) {
      this.route.paramMap.subscribe(params => {
        console.log(params.get('slug'));
        log.debug(params.get('slug'));
        log.debug(params.get('slug'));
        this.toCat = params.get('cat');
        this.toProduct = params.get('product');
        this.placeService
          .getPlaceQuote(params.get('slug'))
          .pipe(
            finalize(() => {
              // this.isLoading = false;
            })
          )
          .subscribe((quote: PlaceModel) => {
            log.debug(quote);
            if (quote) {
              this.place = quote;

              for (const ccat of this.place.category) {
                for (const pprod of ccat.products) {
                  this.all_products.set(pprod.id, pprod);
                }
              }
              //console.log(this.place, 'place');
              this.isOpen = isOpen(this.place.work_time);
              this.metaTagService.updateTag({
                name: 'description',
                content:
                  this.place.name +
                  ' - ' +
                  this.place.address.city +
                  ' ' +
                  this.place.address.street +
                  ' ' +
                  this.place.address.street_number +
                  '. Zamów online na Wciagnij.to'
              });

              this.titleService.setTitle('Zamów jedzenie online w ' + this.place.name + ' - Wciagnij.to');

              this.popularService
                .getPopularQuote([this.place.id])
                .pipe(
                  finalize(() => {
                    // this.isLoading = false;
                  })
                )
                .subscribe((quote: APIPopularContext) => {
                  if (quote) {
                    this.popularData = quote;
                  }
                });

              this.toProductByURL();

              this.order.local_id = this.place.id;
              this.order.delivery = this.order.delivery;
              this.order.consume_type = this.consume_type;
              this.placeCart.id = this.place.id;
              this.placeCart.slug = this.place.slug;
              this.placeCart.name = this.place.name;
              var tmp_place: any = {
                id: this.place.id,
                delivery: this.place.delivery,
                delivery_mode: this.place.delivery_mode,
                delivery_price: this.place.delivery_price,
                delivery_product: this.place.delivery_product,
                delivery_vat: this.place.delivery_vat,
                id_sub_merchant: this.place.id_sub_merchant,
                min_order_price: this.place.min_order_price,
                delivery_sum_mode: this.place.delivery_sum_mode,
                takeaway: this.place.takeaway,
                on_local: this.place.on_local,
                remote_order: this.place.remote_order
              };
              this.placeCart.place = JSON.parse(JSON.stringify(tmp_place));

              this.setCurrentCart();
            }
          });
      });
    } else {
      console.log(isPlatformBrowser(this.platformId));
      var local_host: string = '';
      if (isPlatformBrowser(this.platformId)) {
        local_host = environment.local_host ? environment.local_host : window.location.hostname;
      }
      if (isPlatformServer(this.platformId)) {
        local_host = environment.local_host ? environment.local_host : 'bistroabs.wciagnij.to';
      }

      this.placeService
        .getPlaceQuote(local_host)
        .pipe(
          finalize(() => {
            // this.isLoading = false;
          })
        )
        .subscribe((quote: PlaceModel) => {
          log.debug(quote);
          if (quote) {
            this.place = quote;
            //console.log(this.place, 'place');

            for (const ccat of this.place.category) {
              for (const pprod of ccat.products) {
                this.all_products.set(pprod.id, pprod);
              }
            }

            this.isOpen = isOpen(this.place.work_time);
            this.metaTagService.updateTag({
              name: 'description',
              content:
                this.place.name +
                ' - ' +
                this.place.address.city +
                ' ' +
                this.place.address.street +
                ' ' +
                this.place.address.street_number +
                '. Zamów online na Wciagnij.to'
            });

            this.titleService.setTitle('Zamów jedzenie online w ' + this.place.name + ' - Wciagnij.to');

            this.popularService
              .getPopularQuote([this.place.id])
              .pipe(
                finalize(() => {
                  // this.isLoading = false;
                })
              )
              .subscribe((quote: APIPopularContext) => {
                if (quote) {
                  this.popularData = quote;
                }
              });

            this.toProductByURL();

            this.order.local_id = this.place.id;
            this.order.delivery = this.order.delivery;
            this.order.consume_type = this.consume_type;
            this.placeCart.id = this.place.id;
            this.placeCart.slug = this.place.slug;
            this.placeCart.name = this.place.name;
            var tmp_place: any = {
              id: this.place.id,
              delivery: this.place.delivery,
              delivery_mode: this.place.delivery_mode,
              delivery_price: this.place.delivery_price,
              delivery_product: this.place.delivery_product,
              delivery_vat: this.place.delivery_vat,
              id_sub_merchant: this.place.id_sub_merchant,
              min_order_price: this.place.min_order_price,
              delivery_sum_mode: this.place.delivery_sum_mode,
              takeaway: this.place.takeaway,
              on_local: this.place.on_local,
              remote_order: this.place.remote_order
            };
            this.placeCart.place = JSON.parse(JSON.stringify(tmp_place));

            this.setCurrentCart();
          }
        });
    }
  }

  ChangeDelivery(type: number) {
    if (!this.cart.order.products.length) {
      this.consume_type = type;
    } else {
      this.toastr.warning('Masz juź produkty w koszyku. Aby zmienić sposób dostawy musisz skasować zawartość koszyk');
    }
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    //console.log(dataURL);
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

  receiveMessage($event: OrderedProductModel) {
    this.setCurrentCart();

    this.placeCart.order.products.push($event);
    //this.placeCart.order = JSON.parse(JSON.stringify(this.order));
    this.placeCart.order.sum = this.cart.order.products.reduce((a: any, b: any) => a + b.sum, 0);

    var tmp_place: any = {
      id: this.place.id,
      delivery: this.place.delivery,
      delivery_mode: this.place.delivery_mode,
      delivery_price: this.place.delivery_price,
      delivery_product: this.place.delivery_product,
      delivery_vat: this.place.delivery_vat,
      id_sub_merchant: this.place.id_sub_merchant,
      min_order_price: this.place.min_order_price,
      delivery_sum_mode: this.place.delivery_sum_mode,
      takeaway: this.place.takeaway,
      on_local: this.place.on_local,
      remote_order: this.place.remote_order
    };

    this.placeCart.place = JSON.parse(JSON.stringify(tmp_place));
    this.placeCart.order.consume_type = this.consume_type;
    this.cart = JSON.parse(JSON.stringify(this.placeCart));

    // localStorage.setItem('cart', JSON.stringify(this.cart));
    this.session.setSessionKey('cart', this.cart);
    log.debug(this.order);
  }

  ngOnDestroy() {
    document.body.classList.remove('place');
  }

  plus(item: any) {
    if (!item.quantity) {
      item.quantity = 0;
    }
    item.quantity++;
  }

  minus(item: any) {
    if (!item.quantity) {
      item.quantity = 0;
    }
    if (item.quantity > 1) {
      item.quantity--;
    }
  }

  // Kategorie - slider
  slickCatConfig = {
    slidesToShow: 8,
    slidesToScroll: 8,
    prevArrow: "<div class='slick-prev'><i class='fas fa-chevron-left'></i></div>",
    nextArrow: "<div class='slick-next'><i class='fas fa-chevron-right'></i></div>",
    dots: false,
    infinite: false,
    center: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  actionToProduct(toCat: any, toProduct: any) {
    this.toCat = this.slugifier.transform(this.translator.transform(toCat, this.translate.currentLang));
    this.toProduct = this.slugifier.transform(this.translator.transform(toProduct, this.translate.currentLang));

    log.debug(this.toCat);
    log.debug(this.toProduct);
    this.toProductByURL();
  }

  toProductByURL(): void {
    for (let i in this.place.category) {
      for (let j in this.place.category[i].name) {
        for (let k in this.place.category[i].name[j]) {
          if (
            this.place.category[i].name[j].locale === this.translate.currentLang &&
            this.slugifier.transform(this.place.category[i].name[j].text) === this.slugifier.transform(this.toCat)
          )
            this.toCatId = this.place.category[i].id;
        }
      }

      for (let s in this.place.category[i].products) {
        for (let m in this.place.category[i].products[s].name) {
          if (
            this.place.category[i].products[s].name[m].locale === this.translate.currentLang &&
            this.slugifier.transform(this.place.category[i].products[s].name[m].text) ===
              this.slugifier.transform(this.toProduct)
          )
            this.toProductId = this.place.category[i].products[s].id;
        }
      }
    }

    setTimeout(() => {
      this.viewportScroller.setOffset([0, 124]);
      this.viewportScroller.scrollToAnchor(this.toProductId);
      this.viewportScroller.scrollToPosition([0, this.viewportScroller.getScrollPosition()[1]]);
    }, 500);
  }

  toCategory(id: string): void {
    this.toCatId = 0;

    setTimeout(() => {
      this.viewportScroller.scrollToAnchor(id);
      this.viewportScroller.setOffset([0, 136]);
      this.viewportScroller.scrollToPosition([0, this.viewportScroller.getScrollPosition()[1]]);
      this.toCatId = id;
    }, 300);
  }

  onSlickInit(event: any) {
    //console.log('slick initialized');
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1000);
    }
  }

  setCurrentCart() {
    if (this.session.checkSessionKey('cart')) {
      this.cart = this.session.getSessionKey('cart');
      if (this.cart && this.cart.hasOwnProperty('id')) {
        if (this.cart.id !== this.placeCart.id) {
          this.cart = JSON.parse(JSON.stringify(this.placeCart));
        } else {
          this.placeCart = JSON.parse(JSON.stringify(this.cart));
          this.consume_type = this.placeCart.order.consume_type;
        }
      } else {
        this.cart = this.placeCart;
        //this.consume_type = this.placeCart.order.consume_type;
      }
    } else {
      this.cart = this.placeCart;
      //this.consume_type = this.placeCart.order.consume_type;
    }
  }

  openSearchDialog(category: string) {
    //event.preventDefault();

    //if (this.userLocation && this.userLocation.lat)
    //return this.router.navigateByUrl(`szukaj/kategoria/${this.translate.currentLang}/${category}`);

    this.dialog.open(LocationModalComponent, {
      data: {
        category: category
      }
    });
  }
}
