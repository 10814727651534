import { MenuProductModel } from '@app/shared/model/menuProduct.model';
import { NameLocalizationModel } from '@app/shared/model/nameLocalization.model';
import { ImageModel } from '@app/shared/model/image.model';
import { ProductModel } from '@app/shared/model/product.model';

export class PlaceModel {
  address: {
    city: string;
    country: string;
    county: string;
    latlng: string;
    level: string;
    map_link: string;
    local_number: string;
    post_code: string;
    radius: string;
    street_number: string;
    street: string;
  };
  name: string;
  local_host: string;
  local_type: string;
  id: string;
  delivery: boolean;
  delivery_price: number;
  delivery_price_range: DeliveryPriceRange[];
  delivery_mode: number;
  delivery_vat: number;
  delivery_sum_mode: number;
  festival_smak: boolean;
  description: NameLocalizationModel[];
  short_description: NameLocalizationModel[];
  remote_order: boolean;
  maxDistance: number;
  work_time: any;
  image: string;
  image_object: ImageModel;
  logo: string;
  geo: {
    lat: number;
    lon: number;
  };
  slug: string;
  category: CategoryModel[];
  phone_number: string;
  prefix: string;
  company_info: CompanyInfo;
  takeaway: boolean;
  delivery_product: MenuProductModel;
  average_time: number;
  id_sub_merchant: number;
  service_fee_percent: number;
  facebook: string;
  instagram: string;
  youtube: string;
  min_order_price: number;
  on_local: boolean;
  webhook: boolean;
  imoje_account: string;
  imoje_active: boolean;
}

export class CompanyInfo {
  id: string;
  name: string;
  email: string;
  nip: string;
  nip_prefix: string;
  regon: string;
  type: string;
  local_id: string;
  uid: string;
  timestamp: number;
  client_type: number;
  pesel: string;
  address: string;
}

export class DeliveryPriceRange {
  maxDistance: number;
  price: number;
}

export class CategoryModel {
  id: string;
  name: NameLocalizationModel[];
  order: number;
  parent: string;
  products: MenuProductModel[];
  sort: number;
  type: string;
  uid: string;
}
