import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NameLocalizationModel } from '@app/shared/model/nameLocalization.model';
import PlaceResult = google.maps.places.PlaceResult;

const routes = {
  quote: () => `/cities`
};

export interface APICitiesContext {
  data: [
    {
      name: NameLocalizationModel;
      country: string;
      slug: string;
      lat: number;
      lng: number;
    }
  ];
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class CitiesService {
  constructor(private httpClient: HttpClient) {}

  getCitiesQuote(context: any): Observable<any> {
    const data: any = {
      slug: context.slug,
      home: context.home,
      limit: context.limit
    };
    return this.httpClient
      .cache()
      .post<APICitiesContext[]>(routes.quote(), data)
      .pipe(
        map((body: APICitiesContext[]) => body),
        catchError(() => of('Error, could not load nearest restaurants'))
      );
  }
}
