<div class="location-modal">
  <h1 mat-dialog-title></h1>
  <div mat-dialog-content>
    <!-- <div class="new_search">
			<mat-form-field appearance="fill" class="w-100">
			<mat-label>Adres</mat-label>

			<input
				matInput
				matGoogleMapsAutocomplete
				country="pl"
				[value]="userLocation.formatted_address"
				(onAutocompleteSelected)="onAutocompleteSelected($event)"
				(onLocationSelected)="onLocationSelected($event)"
			/>
			</mat-form-field>
			<button mat-flat-button color="primary" (click)="Search($event)"><span translate>Search</span></button>
		</div>
		 -->

    <app-location
      [userLocation]="userLocation"
      (loaded)="onLoaded($event)"
      [dialogRef]="dialogRef"
      [hideButtons]="data.hideButtons"
    ></app-location>
  </div>
</div>
