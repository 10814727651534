import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from '../../environments/environment';

function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function todayOpenTime(work_time: any): String {
  const dayIndex = new Date().getDay() - 1;

  let currentWeekDayIndex = dayIndex >= 0 ? dayIndex : 6;

  return work_time[currentWeekDayIndex].open;
}

export function isOpen(work_time: any): boolean {
  const dayIndex = new Date().getDay() - 1;
  const current = new Date();

  let currentTime = new Date('01/01/1970 ' + current.toLocaleTimeString());
  if (currentTime < new Date('01/01/1970 07:00:00')) {
    currentTime = new Date('01/02/1970 ' + current.toLocaleTimeString());
  }

  let currentWeekDayIndex = dayIndex >= 0 ? dayIndex : 6;
  //console.log(currentWeekDayIndex);
  //console.log(dayIndex);
  const openTime = new Date(work_time[currentWeekDayIndex].open);
  let closeTime = new Date(work_time[currentWeekDayIndex].close);

  if (closeTime < openTime) {
    closeTime = addDays(closeTime, 1);
  }

  //console.log(currentTime.toLocaleTimeString());
  //console.log(openTime.toLocaleTimeString());
  //console.log(closeTime.toLocaleTimeString());
  //console.log(currentTime);
  //console.log(openTime);
  //console.log(closeTime);

  if (work_time[currentWeekDayIndex].open != 0 && work_time[currentWeekDayIndex].open != -1) {
    if (currentTime >= openTime && currentTime <= closeTime) {
      return true;
    } else {
      return false;
    }
  }
  if (work_time[currentWeekDayIndex].open == 0) return false;
  if (work_time[currentWeekDayIndex].open == -1) return true;
}

export function isMain(platform: any = null): boolean {
  return true;
  if (platform) {
    if (isPlatformServer(platform)) {
      if (environment.local_host) {
        return false;
      } else {
        return true;
      }
    } else {
      if (environment.local_host) {
        return false;
      } else {
        return (
          window.location.hostname === 'wciagnij.to' ||
          window.location.hostname === 'dev.wciagnij.to' ||
          window.location.hostname === 'localhost' // local // prod
        );
      }
    }
  } else {
    return (
      window.location.hostname === 'wciagnij.to' ||
      window.location.hostname === 'dev.wciagnij.to' ||
      window.location.hostname === 'localhost' // local // prod
    );
  }
}
