<div class="placeInfo">
  <h4>{{ place.name }}</h4>

  <div class="addres" *ngIf="place.address">
    <i class="fas fa-map-marker-alt"></i> {{ place.address.street }}, {{ place.address.street_number }}
    {{ place.address.post_code }} {{ place.address.city }}
  </div>
  <!--<a href="tel: {{ place.phone_number }}" class="phone"
    ><i class="fas fa-phone"></i> {{ place.phone_number }}</a
  >-->

  <div class="socialIcons">
    <a href="{{ place.facebook }}" *ngIf="place.facebook" class="fb"><i class="fab fa-facebook-f"></i></a>
    <a href="{{ place.instagram }}" *ngIf="place.instagram"><i class="fab fa-instagram"></i></a>
    <a href="{{ place.youtube }}" *ngIf="place.youtube"><i class="fab fa-youtube"></i></a>
  </div>

  <h6 class="font-weight-bold mb-0">O nas</h6>
  <p class="text-justify">
    {{ place.description | translateString: translate.currentLang }}
  </p>

  <div class="hours">
    <h6 class="font-weight-bold">Godziny otwarcia</h6>
    <div class="box">
      <table *ngIf="place.work_time">
        <tbody>
          <tr>
            <td>Poniedziałek</td>
            <td *ngIf="place.work_time[0].open != 0 && place.work_time[0].open != -1">
              {{ place.work_time[0].open | date: 'HH:mm' }} -
              {{ place.work_time[0].close | date: 'HH:mm' }}
            </td>
            <td *ngIf="place.work_time[0].open == 0">nieczynne</td>
            <td *ngIf="place.work_time[0].open == -1">całodobowo</td>
          </tr>
          <tr>
            <td>Wtorek</td>
            <td *ngIf="place.work_time[1].open != 0 && place.work_time[1].open != -1">
              {{ place.work_time[1].open | date: 'HH:mm' }} -
              {{ place.work_time[1].close | date: 'HH:mm' }}
            </td>
            <td *ngIf="place.work_time[1].open == 0">nieczynne</td>
            <td *ngIf="place.work_time[1].open == -1">całodobowo</td>
          </tr>
          <tr>
            <td>Środa</td>
            <td *ngIf="place.work_time[2].open != 0 && place.work_time[2].open != -1">
              {{ place.work_time[2].open | date: 'HH:mm' }} -
              {{ place.work_time[2].close | date: 'HH:mm' }}
            </td>
            <td *ngIf="place.work_time[2].open == 0">nieczynne</td>
            <td *ngIf="place.work_time[2].open == -1">całodobowo</td>
          </tr>
          <tr>
            <td>Czwartek</td>
            <td *ngIf="place.work_time[3].open != 0 && place.work_time[3].open != -1">
              {{ place.work_time[3].open | date: 'HH:mm' }} -
              {{ place.work_time[3].close | date: 'HH:mm' }}
            </td>
            <td *ngIf="place.work_time[3].open == 0">nieczynne</td>
            <td *ngIf="place.work_time[3].open == -1">całodobowo</td>
          </tr>
          <tr>
            <td>Piątek</td>
            <td *ngIf="place.work_time[4].open != 0 && place.work_time[4].open != -1">
              {{ place.work_time[4].open | date: 'HH:mm' }} -
              {{ place.work_time[4].close | date: 'HH:mm' }}
            </td>
            <td *ngIf="place.work_time[4].open == 0">nieczynne</td>
            <td *ngIf="place.work_time[4].open == -1">całodobowo</td>
          </tr>
          <tr>
            <td>Sobota</td>
            <td *ngIf="place.work_time[5].open != 0 && place.work_time[5].open != -1">
              {{ place.work_time[5].open | date: 'HH:mm' }} -
              {{ place.work_time[5].close | date: 'HH:mm' }}
            </td>
            <td *ngIf="place.work_time[5].open == 0">nieczynne</td>
            <td *ngIf="place.work_time[5].open == -1">całodobowo</td>
          </tr>
          <tr>
            <td>Niedziela</td>
            <td *ngIf="place.work_time[6].open != 0 && place.work_time[6].open != -1">
              {{ place.work_time[6].open | date: 'HH:mm' }} -
              {{ place.work_time[6].close | date: 'HH:mm' }}
            </td>
            <td *ngIf="place.work_time[6].open == 0">nieczynne</td>
            <td *ngIf="place.work_time[6].open == -1">całodobowo</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
