<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <h1 class="text-center page-title">Dołącz do nas - <span class="highlight">daj się wciągnąć!</span></h1>
    <div class="row justify-content-md-center">
      <div class="col-md-8">
        <p class="text mtb-20 text-center" [hidden]="responseMessage">
          Wypełnij poniższy formularz i dołącz do naszego grona!
        </p>
        <p class="text text-center" style="padding: 45px 0" [hidden]="!responseMessage">{{ responseMessage }}</p>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-md-8">
        <form [formGroup]="form" [hidden]="responseMessage">
          <div class="row">
            <div class="col-md-9 col-7">
              <mat-form-field appearance="outline" class="no-init-outline">
                <mat-label>NIP</mat-label>
                <input matInput formControlName="nip" required minlength="10" maxlength="10" />
              </mat-form-field>
            </div>

            <div class="col-md-3 col-5">
              <button mat-raised-button color="primary" class="gus-button" (click)="downloadGus($event)">
                Pobierz z GUS
              </button>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="outline" class="no-init-outline">
                <mat-label>Adres e-mail</mat-label>
                <input matInput formControlName="email" required type="email" />
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="outline" class="no-init-outline">
                <mat-label>Telefon</mat-label>
                <input matInput formControlName="tel" required />
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="outline" class="no-init-outline">
                <mat-label>Nazwa lokalu</mat-label>
                <input matInput formControlName="name" required />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="no-init-outline">
                <mat-label>Typ lokalu</mat-label>
                <mat-select formControlName="local_type" required>
                  <mat-option value="RESTAURANT">
                    Restauracja
                  </mat-option>

                  <mat-option value="PUB">
                    Pub
                  </mat-option>

                  <mat-option value="PIZZA">
                    Pizzeria
                  </mat-option>

                  <mat-option value="FAST_FOOD">
                    Fast-food
                  </mat-option>

                  <mat-option value="CAFE">
                    Kawiarnia
                  </mat-option>

                  <mat-option value="CAFETERIA">
                    Stołówka
                  </mat-option>

                  <mat-option value="BISTRO">
                    Bistro
                  </mat-option>

                  <mat-option value="BUFFET">
                    Bufet
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="no-init-outline">
                <mat-label>Ulica</mat-label>
                <input matInput formControlName="street" required />
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="no-init-outline">
                <mat-label>Miasto</mat-label>
                <input matInput formControlName="city" required />
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-form-field appearance="outline" class="no-init-outline">
                <mat-label>Numer</mat-label>
                <input matInput formControlName="street_number" required />
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-form-field appearance="outline" class="no-init-outline">
                <mat-label>Kod pocztowy</mat-label>
                <input matInput formControlName="post_code" required />
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-form-field appearance="outline" class="no-init-outline">
                <mat-label>Województwo</mat-label>
                <input matInput formControlName="state" required />
              </mat-form-field>
            </div>

            <div class="col-md-3">
              <mat-form-field appearance="outline" class="no-init-outline">
                <mat-label>Regon</mat-label>
                <input matInput formControlName="regon" />
              </mat-form-field>
            </div>

            <div class="col-12 text-center">
              <div class="d-flex justify-content-center">
                <re-captcha formControlName="recaptchaReactive"></re-captcha>
              </div>
              <button
                mat-raised-button
                color="primary"
                type="submit"
                class="mtb-20"
                [disabled]="form.invalid || form.pristine"
                (click)="submit()"
              >
                dołącz
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
