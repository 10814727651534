export class CustomerAddressModel {
  street_house: string;
  city: string;
  state: string;
  zip: string;
  country_code: string;
}

export class CustomerModel {
  name: string;
  email: string;
  ip: string;
  address: CustomerAddressModel;
}

export class CustomerBlikModel {
  name: string;
  email: string;
  ip: string;
}

export class SaleModel {
  amount: number;
  currency: string;
  description: string;
  fraud_check_on: boolean;
  avs_check_level: number;
  id_sub_merchant: number;
  service_fee_amount: number;
}

export class TSaleModel {
  amount: number;
  currency: string;
  description: string;
  id_sub_merchant: number;
  service_fee_amount: number;
}

export class CardModel {
  card_number: number;
  expiration_month: string;
  expiration_year: string;
  name_on_card: string;
  card_code: string;
}

export class CardsSaleModel {
  sale: SaleModel;
  customer: CustomerModel;
  card: CardModel;
}

export class Cards3DSaleModel {
  id_3dsecure_auth: number;
}

export class Cards3DModel {
  sale: SaleModel;
  customer: CustomerModel;
  card: CardModel;
  back_url: string;
}

export class Cards3DResponse {
  success: boolean;
  id_3dsecure_auth: number;
  is_card_enrolled: boolean;
  redirect_url: string;
  error_number: number;
  error_desc: string;
}

export class BlikModel {
  sale: TSaleModel;
  customer: CustomerBlikModel;
  code: string;
}

export class BlikResponse {
  success: boolean;
  id_sale: number;
  error_number: number;
  error_desc: string;
}

export class TransferSaleModel {
  sale: TSaleModel;
  customer: CustomerModel;
  back_url: string;
  payment_type: string;
}

export class CardsSaleResponse {
  success: boolean;
  id_sale: number;
  avs_result: string;
  fraud_score: number;
  id_account: number;
  error_number: number;
  error_desc: string;
}

export class TransferSaleResponse {
  success: boolean;
  id_sale: number;
  redirect_url: string;
  error_number: number;
  error_desc: string;
}
