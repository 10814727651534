<app-header></app-header>

<div class="container page_content">
  <div class="row">
    <div class="col-12 col-sm-6 success" *ngIf="!failed">
      <h3 style="margin-top: 18px; margin-bottom: 18px">Dziękujemy za złożenie zamówienia</h3>
      <p class="text">
        Twoje zamówienie zostało przesłane do <strong>{{ place?.name }}</strong> i jest realizowane.
      </p>
      <p class="text">Na tej stronie możesz śledzić aktualny status zamówienia.</p>
      <p class="text">
        W przypadku pytań związanych z zamówieniem, prosimy o bezpośredni kontakt z
        <strong>{{ place?.name }}</strong> pod numerem telefonu: <strong>{{ place?.phone_number }}</strong>
      </p>
      <p class="text">Życzymy smacznego!</p>
    </div>

    <div class="col-12 col-sm-6 failed" *ngIf="failed">
      <h3 style="margin-top: 18px; margin-bottom: 18px">Coś poszło nie tak.</h3>
      <p class="text">
        Twoja płatność na rzecz restauracji <strong>{{ place?.name }}</strong> nie została przetworzona.
      </p>
      <p class="text">Zamówienie nie zostanie zrealizowane.</p>
      <p class="text">
        Prosimy spróbować ponownie.
      </p>
    </div>
    <div class="col-12 col-sm-6">
      <h3 style="margin-top: 18px; margin-bottom: 18px">Status zamówienia</h3>
      <mat-vertical-stepper [linear]="true" #stepper [ngClass]="{ error: failed }">
        <mat-step
          [label]="step.title"
          [editable]="false"
          [completed]="step.completed"
          *ngFor="let step of steps"
        ></mat-step>
      </mat-vertical-stepper>
    </div>
  </div>
</div>

<app-footer></app-footer>
