import { Component, OnInit, Inject, Input } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserLocation } from '@app/shared/model/userLocation';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'location-modal',
  templateUrl: './location.modal.html',
  styleUrls: ['./location.modal.scss']
})
export class LocationModalComponent implements OnInit {
  isLoading = false;
  userLocation: UserLocation = {
    city: null,
    country: null,
    post_code: null,
    formatted_address: null,
    lat: null,
    lng: null,
    street: null,
    house_number: null,
    gres: null
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public dialogRef: MatDialogRef<LocationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('hideButtons', this.data.hideButtons);
  }

  ngOnInit(): void {
    this.isLoading = false;
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('userLocation')) {
        this.userLocation = JSON.parse(localStorage.getItem('userLocation'));
      }
    }
  }

  onLoaded(load: boolean) {
    this.isLoading = load;
  }
}
