export class payMethod {
  id: number;
  value: boolean;
}

export class payMethods {
  id: string;
  type: string;
  methods: payMethod[];
  gpay_gateway_name: string;
  gpay_gateway_key: string;
}
