import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { SearchRoutingModule } from './search-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NearestComponent } from './nearest/nearest.component';
import { SharedModule } from '@app/shared';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BarRatingModule } from 'ngx-bar-rating';
import { MatSelectModule } from '@angular/material/select';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SearchRoutingModule,
    SharedModule,
    FormsModule,
    MatCardModule,
    MatSliderModule,
    MatRadioModule,
    MatCheckboxModule,
    BarRatingModule,
    MatSelectModule,
    SlickCarouselModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    RoundProgressModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatExpansionModule,
    LazyLoadImageModule,
    MatSlideToggleModule
  ],
  declarations: [SearchComponent, NearestComponent]
})
export class SearchModule {}
