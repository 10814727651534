/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { hmrBootstrap } from './hmr';
//import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

// const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

// if (environment.hmr) {
//   hmrBootstrap(module, bootstrap);
// } else {
//   // document.addEventListener('DOMContentLoaded', () => {
//   //   bootstrap()
//   // });
//   document.addEventListener('DOMContentLoaded', () => {
//     platformBrowserDynamic().bootstrapModule(AppModule)
//     .catch(err => console.error(err));
//   });
// }

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
