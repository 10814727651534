import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SeoCities, SeoCitiesResp } from '../shared/model/seoCities.model';
const routes = {
  quote: () => `/catalog`
};

@Injectable({
  providedIn: 'root'
})
export class CitiesService {
  constructor(private httpClient: HttpClient) {}

  getCatalogQuote(): Observable<any> {
    return this.httpClient
      .skipLoader()
      .cache()
      .post<SeoCitiesResp>(routes.quote(), [])
      .pipe(
        map((body: SeoCitiesResp) => body),
        catchError(() => of('Error, could not load nearest restaurants'))
      );
  }
}
