import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';

import { RegulaminComponent } from './regulamin/regulamin.component';
import { StaticRoutingModule } from './static-routing.module';
import { OfertyPracyComponent } from './oferty-pracy/oferty-pracy.component';
import { InstallIosComponent } from './install-ios/install-ios.component';
import { SupportComponent } from './support/support.component';
import { RecommendComponent } from './recommend/recommend.component';
import { JoinComponent } from './join/join.component';
import { MediaComponent } from './media/media.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { LegalComponent } from './legal/legal.component';
import { CookiesComponent } from './cookies/cookies.component';
import { HowitworksComponent } from './howitworks/howitworks.component';
import { ForrestaurantsComponent } from './forrestaurants/forrestaurants.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ContactComponent } from './contact/contact.component';
import { MatRadioModule } from '@angular/material/radio';
//import { MatSelect } from '@angular/material/select';
import { MatSelectModule } from '@angular/material/select';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
//import { BlogComponent } from './blog/blog.component';
import { ContestComponent } from '@app/static/contest/contest.component';

@NgModule({
  declarations: [
    RegulaminComponent,
    OfertyPracyComponent,
    SupportComponent,
    RecommendComponent,
    JoinComponent,
    MediaComponent,
    PrivacyComponent,
    LegalComponent,
    CookiesComponent,
    HowitworksComponent,
    ForrestaurantsComponent,
    ContactComponent,
    InstallIosComponent,
    ContestComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    StaticRoutingModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaFormsModule,
    RecaptchaModule
  ]
})
export class StaticModule {}
