<app-loader></app-loader>
<div [class.invisible]="loading">
  <app-header></app-header>

  <mat-button-toggle-group aria-label="Change delivery" class="change_delivery" [(value)]="filter.consume_type">
    <mat-button-toggle [value]="1" aria-label="Dostawa" (click)="ChangeDelivery(1)" disableRipple>
      <span translate>Delivery</span>
    </mat-button-toggle>
    <mat-button-toggle [value]="2" aria-label="Na wynos" (click)="ChangeDelivery(2)" disableRipple>
      <span translate>Na wynos</span>
    </mat-button-toggle>
    <mat-button-toggle [value]="3" aria-label="Na miejscu" (click)="ChangeDelivery(3)" disableRipple>
      <span translate>In local</span>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <!--  <button mat-button (click)="prev()">prev</button>-->
  <!--  <button mat-button (click)="next()">next</button>-->

  <!--  <div class="catSection">-->
  <!--    <div id="catContainer" class="container">-->
  <!--      <mat-spinner [diameter]="26" style="margin: 0 auto;" *ngIf="loadingCategories"></mat-spinner>-->
  <!--      <div id="categories" *ngIf="!loadingCategories && filterData.data">-->
  <!--        <button (click)="ChangeFoodType(null)" class="category" [ngClass]="{ active: !filter.food_type }">-->
  <!--          Wszystkie-->
  <!--        </button>-->
  <!--        <ng-container *ngFor="let cat of filterData.data">-->
  <!--          <a-->
  <!--            [routerLink]=""-->
  <!--            (click)="ChangeFoodType((filter.food_type = cat.id))"-->
  <!--            [ngClass]="{-->
  <!--              category: true,-->
  <!--              active: filter.food_type === cat.id-->
  <!--            }"-->
  <!--          >{{ cat.name | translateString: translate.currentLang }}</a>-->
  <!--        </ng-container>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <div class="catSection">
    <div class="container">
      <mat-spinner [diameter]="26" style="margin: 0 auto;" *ngIf="!filterData.data"></mat-spinner>
      <ngx-slick-carousel
        class="cat_slider"
        #slickModal="slick-carousel"
        [config]="slickCatConfig"
        *ngIf="filterData.data"
      >
        <div ngxSlickItem class="slide">
          <button (click)="ChangeFoodType(null)" class="all_button" [ngClass]="{ active: !filter.food_type }">
            Wszystkie
          </button>
        </div>
        <div ngxSlickItem *ngFor="let category of filterData.data" class="slide">
          <a
            [routerLink]="[]"
            (click)="ChangeFoodType((filter.food_type = category.id))"
            [ngClass]="{
              active: filter.food_type === category.id
            }"
            >{{ category.name | translateString: translate.currentLang }}</a
          >
        </div>
      </ngx-slick-carousel>
    </div>
  </div>

  <div class="container page_content">
    <!--    <div class="hr_bottom search_header">-->
    <!--      <div class="container py-4">-->
    <!--        <div class="row">-->
    <!--          <div class="col-md-4 col-sm-12"></div>-->
    <!--          <div class="col-md-4 col-sm-12 text-center pb-2 pb-md-0">-->
    <!--            <h4 class="formatted_address">-->
    <!--              <i class="fas fa-location-arrow"></i> {{ userLocation.formatted_address }}-->
    <!--            </h4>-->
    <!--          </div>-->
    <!--          <div class="d-none d-sm-block col-md-4 col-sm-12">-->
    <!--            <mat-button-toggle-group-->
    <!--              name="changeview"-->
    <!--              aria-label="Change view"-->
    <!--              class="changeview float-none float-md-right"-->
    <!--              value="{{ view_style }}"-->
    <!--            >-->
    <!--              <mat-button-toggle value="0" aria-label="Kafelki" (click)="changeView(0)">-->
    <!--                <i class="fas fa-bars"></i>-->
    <!--              </mat-button-toggle>-->
    <!--              <mat-button-toggle value="1" aria-label="Lista" (click)="changeView(1)">-->
    <!--                <i class="fas fa-th"></i>-->
    <!--              </mat-button-toggle>-->
    <!--            </mat-button-toggle-group>-->
    <!--          </div>-->
    <!--          <ol class="breadcrumb">-->
    <!--            <li class="breadcrumb-item"><a href="/" translate>Main site</a></li>-->
    <!--            <li class="breadcrumb-item">-->
    <!--              <a-->
    <!--                [routerLink]="[-->
    <!--                    '/szukaj/restauracja',-->
    <!--                    translate.currentLang,-->
    <!--                    userLocation.country | normalizeString | lowercase,-->
    <!--                    userLocation.city | normalizeString | lowercase-->
    <!--                  ]"-->
    <!--              >{{ userLocation.city }}</a-->
    <!--              >-->
    <!--            </li>-->
    <!--          </ol>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="row">
      <div class="col-md-3 sidebar">
        <div class="row">
          <div class="col-12">
            <h4 class="formatted_address">
              <i class="fas fa-location-arrow"></i> {{ userLocation.formatted_address }}
            </h4>
          </div>
          <div class="col-12 d-none d-sm-block filters">
            <h6 class="header-title">Filtry</h6>
            <div class="row header">
              <div class="col-6 pb-3">{{ festivalChecked ? festivalCount : count }} restauracji</div>
              <div class="col-6 pb-3">
                <a class="reset_link float-right" [routerLink]="[]" (click)="FilterReset()" translate>Reset filtrów</a>
              </div>
            </div>

            <div class="festiwal_filter">
              <img class="fs-image" src="assets/fs_logo.svg" />
              <mat-slide-toggle color="primary" [(ngModel)]="festivalChecked"></mat-slide-toggle>
            </div>

            <div class="filter" *ngIf="filter.consume_type == 1">
              <div class="title">Wartośc zamowienia - mniej niż: {{ filter.min_order_price }} zł</div>
              <mat-slider
                thumbLabel
                tickInterval="5"
                min="0"
                max="75"
                step="5"
                value="{{ filter.min_order_price }}"
                class="slider"
                [(ngModel)]="filter.min_order_price"
                (change)="ChangeMinOrderPrice()"
                color="primary"
              ></mat-slider>
            </div>

            <div class="filter" *ngIf="filter.consume_type == 1">
              <div class="title">Koszt dostawy - mniej niż: {{ filter.delivery_price }} zł</div>
              <mat-slider
                thumbLabel
                tickInterval="15"
                min="0"
                max="75"
                step="5"
                value="{{ filter.delivery_price }}"
                class="slider"
                [(ngModel)]="filter.delivery_price"
                (change)="ChangeDeliveryPrice()"
                color="primary"
              ></mat-slider>
            </div>

            <div class="filter">
              <div class="title">Czas przygotowania - do: {{ filter.average_time }} min</div>
              <mat-slider
                thumbLabel
                tickInterval="15"
                min="15"
                max="120"
                step="15"
                value="{{ filter.average_time }}"
                class="slider"
                [(ngModel)]="filter.average_time"
                (change)="ChangeAverageTime()"
                color="primary"
              ></mat-slider>
            </div>

            <!-- <div class="filter d-flex flex-justifty-content-flex-start align-items-center">
              <div class="title">Opinie</div>
              <div style="padding: 8px">
                <bar-rating
                  [(rate)]="filter.average_rank"
                  [max]="5"
                  [(ngModel)]="filter.average_rank"
                  (rateChange)="ChangeRate()"
                ></bar-rating>
              </div>
            </div> -->
          </div>

          <div class="col-12 d-block d-sm-none">
            <mat-expansion-panel class="filters" style="box-shadow: none;">
              <mat-expansion-panel-header style="padding: 8px">
                <h6 class="header-title">Filtry</h6>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <div class="row header">
                  <div class="col-6 pb-3">{{ count }} restauracji</div>
                  <div class="col-6 pb-3">
                    <a class="reset_link float-right" [routerLink]="[]" (click)="FilterReset()" translate
                      >Reset filtrów</a
                    >
                  </div>
                </div>

                <div class="festiwal_filter">
                  <img class="fs-image" src="assets/fs_logo.svg" />
                  <mat-slide-toggle color="primary" [(ngModel)]="festivalChecked"></mat-slide-toggle>
                </div>

                <div class="filter" *ngIf="filter.consume_type == 1">
                  <div class="title">Wartośc zamowienia - mniej niż: {{ filter.min_order_price }} zł</div>
                  <mat-slider
                    thumbLabel
                    tickInterval="5"
                    min="0"
                    max="75"
                    step="5"
                    value="{{ filter.min_order_price }}"
                    class="slider"
                    [(ngModel)]="filter.min_order_price"
                    (slideend)="ChangeMinOrderPrice()"
                    color="primary"
                  ></mat-slider>
                </div>

                <div class="filter" *ngIf="filter.consume_type == 1">
                  <div class="title">Koszt dostawy - mniej niż: {{ filter.delivery_price }} zł</div>
                  <mat-slider
                    thumbLabel
                    tickInterval="15"
                    min="0"
                    max="75"
                    step="5"
                    value="{{ filter.delivery_price }}"
                    class="slider"
                    [(ngModel)]="filter.delivery_price"
                    (slideend)="ChangeDeliveryPrice()"
                    color="primary"
                  ></mat-slider>
                </div>

                <div class="filter">
                  <div class="title">Czas przygotowania - do: {{ filter.average_time }} min</div>
                  <mat-slider
                    thumbLabel
                    tickInterval="15"
                    min="15"
                    max="75"
                    step="15"
                    value="{{ filter.average_time }}"
                    class="slider"
                    [(ngModel)]="filter.average_time"
                    (slideend)="ChangeAverageTime()"
                    color="primary"
                  ></mat-slider>
                </div>

                <!-- <div class="filter d-flex flex-justifty-content-flex-start align-items-center">
                  <div class="title">Opinie</div>
                  <div style="padding: 8px">
                    <bar-rating
                      [(rate)]="filter.average_rank"
                      [max]="5"
                      [(ngModel)]="filter.average_rank"
                      (rateChange)="ChangeRate()"
                    ></bar-rating>
                  </div>
                </div> -->
              </ng-template>
            </mat-expansion-panel>
          </div>

          <div class="d-none d-md-block">
            <div class="col-12">
              <h6 class="header-title">Polecane potrawy</h6>
              <div class="card sale" *ngFor="let adv of advs">
                <a
                  [routerLink]="[
                    '/restauracja',
                    translate.currentLang,
                    adv.address.city | normalizeString | lowercase,
                    adv.slug
                  ]"
                >
                  <img
                    [lazyLoad]="adv.image_object.d | getImageUrl"
                    [defaultImage]="'assets/placeholder.svg'"
                    class="card-img-top"
                    alt="Promocja"
                  />
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <p class="card-text promo_text">{{ adv.text | translateString: translate.currentLang:200 }}</p>
                        <p class="card-text promo_text text-right pr-1">Przejdź do lokalu &#8811;</p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="row py-4">
          <div class="col-sm-12 col-12">
            <div class="search">
              <mat-form-field appearance="outline">
                <mat-label translate>Search</mat-label>
                <input matInput name="search" [(ngModel)]="filter.stringQuery" [placeholder]="'Search' | translate" />
                <mat-icon matPrefix>search</mat-icon>
              </mat-form-field>

              <button mat-flat-button color="primary" (click)="ChangeStringQuery()" translate>
                <span translate>Search</span>
              </button>
            </div>
          </div>
          <!--          <div class="col-sm-2 col-2 align-items-center d-none d-sm-flex">-->
          <!--            <mat-form-field class="sort">-->
          <!--              <mat-label>Sortowanie</mat-label>-->
          <!--              <mat-select>-->
          <!--                <mat-option>Malejąco</mat-option>-->
          <!--                <mat-option>Rosnąco</mat-option>-->
          <!--              </mat-select>-->
          <!--            </mat-form-field>-->
          <!--          </div>-->
          <!--          <div class="col-sm-2 col-6 d-none d-sm-block">-->
          <!--            <mat-button-toggle-group-->
          <!--              name="changeview"-->
          <!--              aria-label="Change view"-->
          <!--              class="changeview float-none float-md-right"-->
          <!--              value="{{ view_style }}"-->
          <!--            >-->
          <!--              <mat-button-toggle value="0" aria-label="Kafelki" (click)="changeView(0)">-->
          <!--                <i class="fas fa-bars"></i>-->
          <!--              </mat-button-toggle>-->
          <!--              <mat-button-toggle value="1" aria-label="Lista" (click)="changeView(1)">-->
          <!--                <i class="fas fa-th"></i>-->
          <!--              </mat-button-toggle>-->
          <!--            </mat-button-toggle-group>-->
          <!--          </div>-->
        </div>

        <!-- <a class="" routerLink="/maps" routerLinkActive="active">Mapa</a> -->
        <app-nearest
          #appNearest
          [userLocation]="userLocation"
          [filter]="filter"
          (messageEvent)="receiveMessage($event)"
          [view_style]="view_style"
          [festival]="festivalChecked"
        >
        </app-nearest>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
