import { Component, OnInit } from '@angular/core';
import { FoodtypeService } from '../foodtype.service';
import { debounce, debounceTime, finalize } from 'rxjs/operators';
import { Logger } from '../../core/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateStringPipe } from '@app/shared/translate-string.pipe';
import { SlugifierPipe } from '@app/shared/slugifier.pipe';
import { ActivatedRoute } from '@angular/router';
import { FoodService } from './food.service';
import { Meta, Title } from '@angular/platform-browser';
import { SeoFoodPlaces, SeoFoodResp, SeoFoodCity, SeoFoodMeta } from '../../shared/model/seoFood.model';
const log = new Logger('catalog-food');

@Component({
  selector: 'app-food',
  templateUrl: './food.component.html',
  styleUrls: ['./food.component.scss'],
  providers: [SlugifierPipe, TranslateStringPipe]
})
export class FoodComponent implements OnInit {
  places: SeoFoodResp;
  loading: boolean;
  rodzajSlug: string;
  seo: SeoFoodMeta = {
    title: 'Wciagnij.to',
    desc: 'Zamów online na wciagnij.to',
    text_top: '',
    text_bottom: '',
    rodzaj: '',
    state: '',
    type: ''
  };
  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private serviceFood: FoodService,
    private metaTagService: Meta,
    private titleService: Title
  ) {
    this.route.paramMap.subscribe(params => {
      log.debug(params.get('rodzaj'));
      this.rodzajSlug = params.get('rodzaj');
      if (this.rodzajSlug) {
        this.serviceFood
          .getFoodQuote(this.rodzajSlug)
          .pipe(
            finalize(() => {
              // this.isLoading = false;
            })
          )
          .subscribe((quote: SeoFoodResp) => {
            if (quote) {
              log.debug(quote);
              this.places = quote;
              if (quote.seo) {
                this.seo = quote.seo;
                //this.metaTagService.removeTag('name="robots"');
                //this.metaTagService.removeTag('name="description"');
                this.metaTagService.updateTag({ name: 'description', content: this.seo.desc });
                // this.metaTagService.addTags([
                //   { name: 'robots', content: 'index, follow' },
                //   {
                //     name: 'description',
                //     content: this.seo.desc
                //   }
                // ]);

                this.titleService.setTitle(this.seo.title);
              }
            }
          });
      }
    });
  }

  ngOnInit() {}
}
