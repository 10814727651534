import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { extract } from '@app/core';
import { CatalogComponent } from './catalog.component';
import { CityComponent } from './city/city.component';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'katalog/:lang',
      component: CatalogComponent
    },
    {
      path: 'katalog/:lang/:city',
      component: CityComponent
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class CatalogRoutingModule {}
