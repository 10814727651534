import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalculationType, OrderedProductModel, SelectedComponentModel } from '@app/shared/model/order.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  orderedProduct: OrderedProductModel;
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService
  ) {
    this.orderedProduct = data.item;
    //console.log(this.orderedProduct, 'ordered');
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  CalculateProductPrice() {
    // this.orderedProduct.price = this.orderedProduct.attribute.price;
    //console.log(this.orderedProduct.product.calculation_type);
    this.orderedProduct.freeLimit = this.orderedProduct.attribute.free_components;
    const price = {
      product_price: this.orderedProduct.attribute.price,
      product_vat:
        Math.round(this.orderedProduct.attribute.price * (this.orderedProduct.product.vat / 100 / 100) * 100) / 100,
      product_net:
        this.orderedProduct.attribute.price -
        Math.round(this.orderedProduct.attribute.price * (this.orderedProduct.product.vat / 100 / 100) * 100) / 100
    };

    let componentsMap = new Map();
    for (const obj of this.orderedProduct.components) {
      if (obj) {
        componentsMap.set(obj.component.id, obj);
      }
    }

    const sortStrategy = this.orderedProduct.product.free_ex_select_type;

    if (sortStrategy === 0) {
      //from cheapiest
      componentsMap = new Map([...componentsMap].sort((a, b) => a[1].component.price - b[1].component.price));
    }

    if (sortStrategy === 1) {
      //from most exprensive
      componentsMap = new Map([...componentsMap].sort((a, b) => b[1].component.price - a[1].component.price));
    }

    const old_product_price = JSON.parse(JSON.stringify(price.product_price));

    for (const p of componentsMap.values()) {
      // log.debug(p);
      if (p) {
        if (p.component.editable) {
          price.product_price =
            price.product_price +
            this.ComponentPriceDefault(p, this.orderedProduct, this.orderedProduct.product.calculation_type);
        }
      }
    }
    if (this.orderedProduct.product.calculation_type === CalculationType.pricePriority) {
      if (old_product_price > price.product_price) {
        price.product_price = old_product_price;
      } else {
        price.product_price = price.product_price;
      }
    }
    price.product_vat = Math.round(price.product_price * (this.orderedProduct.product.vat / 100 / 100) * 100) / 100;
    price.product_net = price.product_price - price.product_vat;

    //log.debug(price);
    return price;
  }

  ComponentPriceDefault(
    component: SelectedComponentModel,
    orderedProduct: OrderedProductModel,
    price_calculation_type: number = 0
  ) {
    let price = 0;
    let isFree = component.component.free_components;
    //let pricableCount = component.count;
    let pricableCount = component.count - component.component.default_count;

    if (price_calculation_type === CalculationType.default) {
      if (component.checked && !component.component.default_checked) {
        if (orderedProduct.freeLimit > 0 && isFree) {
          if (orderedProduct.freeLimit >= 1) {
            orderedProduct.freeLimit -= 1;
            price = price + 0;
          } else {
            price = price + component.component.price;
          }
        } else {
          price = price + component.component.price;
        }
      }
      if (!component.checked && component.component.default_checked) {
        price = price - component.component.price;
      }
      if (component.count !== component.component.default_count) {
        if (orderedProduct.freeLimit > 0 && isFree && pricableCount > 0) {
          if (orderedProduct.freeLimit - pricableCount >= 0) {
            orderedProduct.freeLimit -= pricableCount;
            pricableCount = 0;
          } else if (orderedProduct.freeLimit - pricableCount < 0) {
            pricableCount -= orderedProduct.freeLimit;
            orderedProduct.freeLimit = 0;
          }
        }

        price = pricableCount * component.component.price;
      }
    }
    if (price_calculation_type === CalculationType.noSubtract) {
      if (component.checked && !component.component.default_checked) {
        if (orderedProduct.freeLimit > 0 && isFree) {
          if (orderedProduct.freeLimit >= 1) {
            orderedProduct.freeLimit -= 1;
            price = price + 0;
          } else {
            price = price + component.component.price;
          }
        } else {
          price = price + component.component.price;
        }
      }
      if (!component.checked && component.component.default_checked) {
        price = price; // - component.component.price;
      }

      if (component.count !== component.component.default_count) {
        if (pricableCount <= 0) {
          price = price;
        } else {
          if (orderedProduct.freeLimit > 0 && isFree && pricableCount > 0) {
            if (orderedProduct.freeLimit - pricableCount >= 0) {
              console.log(
                component.component.name[0].text,
                orderedProduct.freeLimit,
                pricableCount,
                orderedProduct.freeLimit - pricableCount,
                'typ2: wieksz >= 0'
              );
              orderedProduct.freeLimit -= pricableCount;
              pricableCount = 0;
            } else if (orderedProduct.freeLimit - pricableCount < 0) {
              pricableCount -= orderedProduct.freeLimit;
              orderedProduct.freeLimit = 0;
            }
          }
          price = pricableCount * component.component.price;
        }
      }
    }
    if (price_calculation_type === CalculationType.pricePriority) {
      if (component.checked && !component.component.default_checked) {
        if (orderedProduct.freeLimit > 0 && isFree) {
          if (orderedProduct.freeLimit >= 1) {
            orderedProduct.freeLimit -= 1;
            price = price + 0;
          } else {
            price = price + component.component.price;
          }
        } else {
          price = price + component.component.price;
        }
      }
      if (!component.checked && component.component.default_checked) {
        price = price - component.component.price;
      }
      if (component.count !== component.component.default_count) {
        if (orderedProduct.freeLimit > 0 && isFree && pricableCount > 0) {
          if (orderedProduct.freeLimit - pricableCount >= 0) {
            orderedProduct.freeLimit -= pricableCount;
            pricableCount = 0;
          } else if (orderedProduct.freeLimit - pricableCount < 0) {
            pricableCount -= orderedProduct.freeLimit;
            orderedProduct.freeLimit = 0;
          }
        }
        price = pricableCount * component.component.price;
      }
    }

    return price;
  }

  SelectComponentRa(comp: SelectedComponentModel) {
    for (const p of this.orderedProduct.components) {
      if (p) {
        if (p.component.category_id === comp.component.category_id) {
          p.count = 0;
          p.checked = false;
        }
      }
    }

    if (comp.checked === true) {
      comp.checked = false;
    } else {
      comp.checked = true;
    }

    this.orderedProduct.price = this.CalculateProductPrice().product_price;
    this.orderedProduct.price_vat = this.CalculateProductPrice().product_vat;
    this.orderedProduct.price_net = this.CalculateProductPrice().product_net;

    this.orderedProduct.sum = this.CalculateProductPrice().product_price * this.orderedProduct.count;
    this.orderedProduct.sum_vat = this.CalculateProductPrice().product_vat * this.orderedProduct.count;
    this.orderedProduct.sum_net = this.CalculateProductPrice().product_net * this.orderedProduct.count;
  }

  SelectComponentCk(comp: SelectedComponentModel) {
    if (comp.checked === true) {
      comp.checked = false;
    } else {
      comp.checked = true;
    }

    this.orderedProduct.price = this.CalculateProductPrice().product_price;
    this.orderedProduct.price_vat = this.CalculateProductPrice().product_vat;
    this.orderedProduct.price_net = this.CalculateProductPrice().product_net;

    this.orderedProduct.sum = this.CalculateProductPrice().product_price * this.orderedProduct.count;
    this.orderedProduct.sum_vat = this.CalculateProductPrice().product_vat * this.orderedProduct.count;
    this.orderedProduct.sum_net = this.CalculateProductPrice().product_net * this.orderedProduct.count;
  }

  Minus(comp: SelectedComponentModel) {
    if (comp.count > comp.component.min) {
      comp.count = comp.count - 1;
      this.orderedProduct.price = this.CalculateProductPrice().product_price;
      this.orderedProduct.price_vat = this.CalculateProductPrice().product_vat;
      this.orderedProduct.price_net = this.CalculateProductPrice().product_net;

      this.orderedProduct.sum = this.CalculateProductPrice().product_price * this.orderedProduct.count;
      this.orderedProduct.sum_vat = this.CalculateProductPrice().product_vat * this.orderedProduct.count;
      this.orderedProduct.sum_net = this.CalculateProductPrice().product_net * this.orderedProduct.count;
    }
  }

  Plus(comp: SelectedComponentModel) {
    if (comp.count < comp.component.max) {
      comp.count = comp.count + 1;
      this.orderedProduct.price = this.CalculateProductPrice().product_price;
      this.orderedProduct.price_vat = this.CalculateProductPrice().product_vat;
      this.orderedProduct.price_net = this.CalculateProductPrice().product_net;

      this.orderedProduct.sum = this.CalculateProductPrice().product_price * this.orderedProduct.count;
      this.orderedProduct.sum_vat = this.CalculateProductPrice().product_vat * this.orderedProduct.count;
      this.orderedProduct.sum_net = this.CalculateProductPrice().product_net * this.orderedProduct.count;
    }
  }

  ngOnInit() {}
}
