export class IngCustomerAddressModel {
  street_house: string;
  city: string;
  state: string;
  zip: string;
  country_code: string;
}

export class IngActionModel {
  type: string;
  url: string;
  method: string;
  contentType: string;
  contentBodyRaw: string;
}

export class IngCustomerModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  ip: string;
}

export class IngCustomerBlikModel {
  firstName: string;
  lastName: string;
  email: string;
  ip: string;
}

export class IngSaleModel {
  amount: number;
  currency: string;
  description: string;
  fraud_check_on: boolean;
  avs_check_level: number;
  id_sub_merchant: number;
  service_fee_amount: number;
}

export class IngTSaleModel {
  amount: number;
  currency: string;
  description: string;
  id_sub_merchant: number;
  service_fee_amount: number;
}

export class IngCardModel {
  card_number: number;
  expiration_month: string;
  expiration_year: string;
  name_on_card: string;
  card_code: string;
}

export class IngCardsSaleModel {
  sale: IngSaleModel;
  customer: IngCustomerModel;
  card: IngCardModel;
}

export class IngCards3DSaleModel {
  id_3dsecure_auth: number;
}

export class IngCards3DModel {
  sale: IngSaleModel;
  customer: IngCustomerModel;
  card: IngCardModel;
  back_url: string;
}

export class IngCards3DResponse {
  success: boolean;
  id_3dsecure_auth: number;
  is_card_enrolled: boolean;
  redirect_url: string;
  error_number: number;
  error_desc: string;
}

export class IngBlikModel {
  sale: IngTSaleModel;
  customer: IngCustomerBlikModel;
  code: string;
}

export class IngBlikResponse {
  success: boolean;
  id_sale: string;
  error_number: number;
  error_desc: string;
}

export class IngTransferSaleModel {
  sale: IngTSaleModel;
  customer: IngCustomerModel;
  payment_type: string;
}

export class IngCardsSaleResponse {
  success: boolean;
  id_sale: number;
  avs_result: string;
  fraud_score: number;
  id_account: number;
  error_number: number;
  error_desc: string;
}

export class IngTransferSaleResponse {
  success: boolean;
  id_sale: number;
  action: IngActionModel;
  status: string;
  error_number: number;
  error_desc: string;
}
