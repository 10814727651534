<mat-spinner
  [diameter]="40"
  style="margin: 0 auto 32px auto;"
  *ngIf="!nearesData?.data?.length && isLoading"
></mat-spinner>
<div *ngIf="nearesData?.data?.length">
  <!--  <div class="row">-->
  <!--    <div class="col-md-12">-->
  <!--      <h6 class="section-title">Blisko Ciebie</h6>-->
  <!--    </div>-->
  <!--  </div>-->
  <div *ngIf="!festival">
    <div *ngIf="view_style == 1; else list">
      <div class="row">
        <div class="col-xl-4 col-md-6 col-sm-12 rest-b-box" *ngFor="let item of nearesData?.data; let i = $index">
          <div class="rest-box" [ngClass]="{ closed: !isOpen(item.work_time) }">
            <a
              [routerLink]="[
                '/restauracja',
                translate.currentLang,
                item.address.city | normalizeString | lowercase,
                item.slug
              ]"
            >
              <div class="place_image_container">
                <img
                  class="rest-box-image"
                  [lazyLoad]="item.image_object.a | getImageUrl"
                  [defaultImage]="'assets/placeholder.svg'"
                  *ngIf="item.image_object.a; else noImage"
                />

                <img
                  class="rest-box-image"
                  [lazyLoad]="item.logo | getImageUrl"
                  [defaultImage]="'assets/placeholder.svg'"
                  class="rest-box-logo"
                />
                <div class="rest-box-title">
                  <p class="open-hour" *ngIf="todayOpenTime(item.work_time) == -1">Całodobowo</p>
                  <p class="open-hour" *ngIf="todayOpenTime(item.work_time) == 0">Dzisiaj nieczynne</p>
                  <p
                    class="open-hour"
                    *ngIf="todayOpenTime(item.work_time) != 0 && todayOpenTime(item.work_time) != -1"
                  >
                    Czynne od {{ todayOpenTime(item.work_time) | date: 'HH:mm' }}
                  </p>
                  <p class="local-name">{{ item.name }}</p>
                </div>
                <ng-template #noImage>
                  <div class="noimage">
                    <i class="far fa-eye-slash"></i>
                  </div>
                </ng-template>
              </div>
              <div class="down">
                <div>
                  <p class="rest-box-desc">
                    {{ item.description | translateString: translate.currentLang:60 }}
                  </p>
                </div>
                <div>
                  <hr />
                  <p class="rest-box-desc m-2">
                    <span class="item">
                      <i class="fas fa-road icon"></i> <span>{{ item.distance | number: '1.1-2' }} km</span>
                    </span>
                    <span class="item">
                      <img class="icon" src="assets/icon/delivery.svg" /> <span>Od {{ item.delivery_price }}zł</span>
                    </span>
                    <span class="item">
                      <i class="fas fa-shopping-bag icon"></i> <span>Min. {{ item.min_order_price }}zł</span>
                    </span>
                  </p>
                </div>
              </div>
            </a>
            <!-- <bar-rating [(rate)]="rate" [max]="5" class="place_rate"></bar-rating> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #list>
    <div class="rest-list">
      <a
        [routerLink]="[
          '/restauracja',
          translate.currentLang,
          item.address.city | normalizeString | lowercase,
          item.slug
        ]"
        class="item"
        *ngFor="let item of nearesData?.data"
      >
        <div class="image" [ngClass]="{ closed: !isOpen(item.work_time) }">
          <img
            [lazyLoad]="item.logo | getImageUrl"
            [defaultImage]="'assets/placeholder.svg'"
            *ngIf="item.logo; else noImage"
          />
          <ng-template #noImage>
            <div class="noimage">
              <i class="far fa-eye-slash"></i>
            </div>
          </ng-template>

          <!-- <button mat-icon-button class="icon favourite">
            <i class="fas fa-heart"></i>
          </button>

          <button mat-icon-button class="icon share">
            <i class="fas fa-share-alt"></i>
          </button> -->

          <div class="open-info" *ngIf="!isOpen(item.work_time)">
            <p class="red" *ngIf="todayOpenTime(item.work_time) == 0">Dzisiaj nieczynne</p>
            <p class="orange" *ngIf="todayOpenTime(item.work_time) != 0 && todayOpenTime(item.work_time) != -1">
              Czynne od {{ todayOpenTime(item.work_time) | date: 'HH:mm' }}
            </p>
          </div>

          <!-- <bar-rating [(rate)]="rate" [max]="5" class="rate"></bar-rating> -->
        </div>
        <div class="cont">
          <div>
            <h5 class="title">{{ item.name }}</h5>
            <p class="desc">
              {{ item.description | translateString: translate.currentLang:60 }}
            </p>
          </div>
          <div>
            <p class="info d-flex justify-content-around">
              <span>
                <i class="fas fa-map-marker-alt icon"></i> <span>{{ item.distance | number: '1.1-2' }} km</span>
              </span>
              <span>
                <!--                <img class="icon" src="assets/icon/delivery.svg" width="16" />-->
                <i class="far fa-clock icon"></i> <span>Od {{ item.average_time }} min.</span>
              </span>
              <span>
                <!--                <img class="icon" src="assets/icon/delivery.svg" width="16" />-->
                <i class="fas fa-truck icon"></i>
                <span>Od {{ item.delivery_price }}zł</span>
              </span>
              <span>
                <i class="fas fa-shopping-bag icon"></i>
                <span>Min. {{ item.min_order_price }}zł</span>
              </span>
            </p>
          </div>
        </div>
      </a>
    </div>
  </ng-template>

  <div *ngIf="festival">
    <div class="row" *ngIf="festivalData?.data?.length">
      <div class="col-md-12">
        <h6 class="section-title fs">
          <span>Lista restauracji</span>
          <img class="fs-image" src="assets/fs_logo.svg" />
        </h6>
      </div>
    </div>
    <div *ngIf="view_style == 1; else flist">
      <div class="row">
        <div class="col-xl-4 col-md-6 col-sm-12 rest-b-box" *ngFor="let item of festivalData?.data">
          <div class="rest-box">
            <button mat-icon-button class="rest_icon favourite">
              <i class="fas fa-heart"></i>
            </button>

            <button mat-icon-button class="rest_icon share">
              <i class="fas fa-share-alt"></i>
            </button>
            <a
              [routerLink]="[
                '/restauracja',
                translate.currentLang,
                item.address.city | normalizeString | lowercase,
                item.slug
              ]"
            >
              <img
                class="rest-box-image"
                [lazyLoad]="item.image_object.a | getImageUrl"
                [defaultImage]="'assets/placeholder.svg'"
                *ngIf="item.image_object.a; else noImage"
              />
              <ng-template #noImage>
                <div class="noimage">
                  <i class="far fa-eye-slash"></i>
                </div>
              </ng-template>
              <div class="down">
                <div>
                  <p class="rest-box-title">{{ item.name }}</p>
                  <p class="rest-box-desc">
                    {{ item.description | translateString: translate.currentLang:60 }}
                  </p>
                </div>
                <div>
                  <hr />
                  <p class="rest-box-desc m-2">
                    <span class="item">
                      <i class="fas fa-road icon"></i> <span>{{ item.distance | number: '1.1-2' }} km</span>
                    </span>
                    <span class="item">
                      <img class="icon" src="assets/icon/delivery.svg" /> <span>Od {{ item.delivery_price }}zł</span>
                    </span>
                    <span class="item">
                      <i class="fas fa-shopping-bag icon"></i> <span>Min. {{ item.min_order_price }}zł</span>
                    </span>
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <ng-template #flist>
      <div class="rest-list">
        <a
          [routerLink]="[
            '/restauracja',
            translate.currentLang,
            item.address.city | normalizeString | lowercase,
            item.slug
          ]"
          class="item"
          *ngFor="let item of festivalData?.data"
        >
          <div class="image" [ngClass]="{ closed: !isOpen(item.work_time) }">
            <img
              [lazyLoad]="item.logo | getImageUrl"
              [defaultImage]="'assets/placeholder.svg'"
              *ngIf="item.logo; else noImage"
            />
            <ng-template #noImage>
              <div class="noimage">
                <i class="far fa-eye-slash"></i>
              </div>
            </ng-template>

            <!-- <button mat-icon-button class="icon favourite">
              <i class="fas fa-heart"></i>
            </button>

            <button mat-icon-button class="icon share">
              <i class="fas fa-share-alt"></i>
            </button> -->

            <div class="open-info" *ngIf="!isOpen(item.work_time)">
              <p class="red" *ngIf="todayOpenTime(item.work_time) == 0">Dzisiaj nieczynne</p>
              <p class="orange" *ngIf="todayOpenTime(item.work_time) != 0 && todayOpenTime(item.work_time) != -1">
                Czynne od {{ todayOpenTime(item.work_time) | date: 'HH:mm' }}
              </p>
            </div>

            <!-- <bar-rating [(rate)]="rate" [max]="5" class="rate"></bar-rating> -->
          </div>
          <div class="cont">
            <div>
              <h5 class="title">{{ item.name }}</h5>
              <p class="desc">
                {{ item.description | translateString: translate.currentLang:60 }}
              </p>
            </div>
            <div>
              <p class="info d-flex justify-content-around">
                <span>
                  <i class="fas fa-map-marker-alt icon"></i> <span>{{ item.distance | number: '1.1-2' }} km</span>
                </span>
                <span>
                  <!--                <img class="icon" src="assets/icon/delivery.svg" width="16" />-->
                  <i class="far fa-clock icon"></i> <span>Od {{ item.average_time }} min.</span>
                </span>
                <span>
                  <!--                <img class="icon" src="assets/icon/delivery.svg" width="16" />-->
                  <i class="fas fa-truck icon"></i>
                  <span>Od {{ item.delivery_price }}zł</span>
                </span>
                <span>
                  <i class="fas fa-shopping-bag icon"></i>
                  <span>Min. {{ item.min_order_price }}zł</span>
                </span>
              </p>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
  </div>

  <div class="row" *ngIf="popularData.data">
    <div class="col-md-12">
      <h6 class="section-title">Popularne teraz</h6>
    </div>
  </div>
  <div class="row" *ngIf="popularData.data">
    <div class="col-xl-4 col-md-6 col-sm-12 rest-b-box" *ngFor="let item of popularData.data">
      <div class="rest-box">
        <button mat-icon-button class="rest_icon favourite">
          <i class="fas fa-heart"></i>
        </button>

        <button mat-icon-button class="rest_icon share">
          <i class="fas fa-share-alt"></i>
        </button>
        <a
          [routerLink]="[
            '/restauracja',
            translate.currentLang,
            item.address.city | normalizeString | lowercase,
            item.slug,
            item.cat_name | translateString: translate.currentLang | slugifier,
            item.name | translateString: translate.currentLang | slugifier
          ]"
        >
          <img
            class="rest-box-image"
            [lazyLoad]="item.image_object.c | getImageUrl"
            [defaultImage]="'assets/placeholder.svg'"
          />
          <div class="row">
            <div class="col-10">
              <p class="food_title">{{ item.name | translateString: translate.currentLang }}</p>
            </div>
            <div class="col-2">
              <div class="time_counter">
                <div class="text">{{ item.average_time }} MIN</div>
                <round-progress
                  [current]="item.average_time"
                  [max]="120"
                  [color]="'#009606'"
                  [background]="'#eaeaea'"
                  [radius]="20"
                  [stroke]="2"
                >
                </round-progress>
              </div>
            </div>
          </div>

          <hr />
          <div class="row no-gutters">
            <div class="col-6">
              <p class="price">{{ item.price }}zł</p>
            </div>
            <div class="col-6">
              <!-- <bar-rating [(rate)]="rate" [max]="5"></bar-rating> -->
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="
    (!nearesData?.data?.length && !isLoading && !festival) || (!festivalData?.data?.length && !isLoading && festival)
  "
>
  <p class="mb-50">Brak restauracji spełniających podane kryteria.</p>
  <hr />

  <div class="row">
    <div class="col-md-6 mb-4">
      <p><strong>Znasz fajny punkt gastro?</strong></p>
      <p>
        Postaramy się, aby w najbliższym czasie do nas dołączyli!
      </p>
      <a [routerLink]="['/recommend']" mat-flat-button color="primary" class="z-index-3 recommand-button"
        >Poleć lokal gastro!</a
      >
    </div>
    <div class="col-md-6 mb-4">
      <p><strong>Prowadzisz gastro? Dołącz do nas!</strong></p>
      <p class="text">
        <span>
          Pomnożymy Twoje zamówienia i zyski przy mniejszej liczbie pracowników.
        </span>
      </p>
      <a [routerLink]="['/join']" mat-flat-button color="primary" class="z-index-3 dks-button">Wypełnij formularz</a>
    </div>
  </div>
</div>
