<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <h1 class="text-center">Regulamin</h1>
    <h2>Ogólne warunki świadczenia usług na rzecz Konsumentów</h2>
    <p>
      Niniejsze „Ogólne warunki świadczenia usług na rzecz Konsumentów” mają zastosowanie do stosunków pomiędzy
      WCIAGNIJ.TO PROSTA SPÓŁKA AKCYJNA wpisanej do Centralnej Ewidencji i Informacji o Działalności Gospodarczej
      prowdzonej przez Ministerstwo Rozwoju, Pracy i Technologii, <br />
      z siedzibą Kilińskiego 86, 42-218 Częstochowa, o numerze NIP 5732930988 <b>[dalej: WCIAGNIJ.TO]</b>, której
      przysługują prawa do Platformy a Konsumentami. Restauracje odsyła się do „Ogólnych warunków współpracy dla
      Restauracjami”.
    </p>

    <span class="anchor" id="art1"></span>
    <h2>1. Definicje</h2>
    <p>
      <b>Oferta:</b> asortyment produktów i usług oferowanych przez poszczególne Restauracje, które mogą być zamówione
      przez Konsumenta za pośrednictwem WCIAGNIJ.TO (Platformy).
    </p>
    <p>
      <b>Zamówienie:</b> oświadczenie woli składane Restauracji przez Konsumenta za pośrednictwem WCIAGNIJ.TO, a
      konkretniej Platformy na wybraną przez niego Ofertę, skutkujące zawarciem Umowy.
    </p>
    <p>
      <b>Konsument:</b> Osoba fizyczna dokonującą z przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej
      działalnością gospodarczą lub zawodową, składająca Zamówienie Restauracji za pośrednictwem WCIAGNIJ.TO, a
      konkretniej Platformy.
    </p>
    <p>
      <b>Umowa:</b> stosunek prawny zawarty pomiędzy Konsumentem a Restauracją na podstawie złożonego Zamówienia
      dotyczący jego realizacji oraz jego dostawy lub osobistego odbioru.
    </p>
    <p>
      <b>Platforma:</b> strony internetowe, aplikacje, narzędzia i inne urządzenia Serwisu wciagnij.to oraz jego
      jednostek powiązanych i partnerów handlowych, na których udostępnia się Usługi
    </p>
    <p>
      <b>Restauracja:</b> podmiot, prowadzący działalność gospodarczą w zakresie gastronomii, w szczególności
      przygotowujący dania, napoje i podobne produkty, korzystający z usług świadczonych przez WCIAGNIJ.TO po
      zaakceptowaniu przez nią jego zgłoszenia rejestracyjnego, w celu zawarcia i opłacenia Umów.
    </p>
    <p>
      <b>Dane Restauracji:</b> informacje dotyczące Restauracji, w tym, między innymi, dane podmiotu będącego
      właścicielem Restauracji i dane kontaktowe, informacje ogólne, asortyment produktów (dania, przystawki, dodatki do
      dań i napoje), ceny poszczególnych produktów (z VAT), logotyp, grafika, obszar dostawy (wraz z kodami pocztowymi),
      koszty dostawy oraz minimalne kwoty zamówienia.
    </p>
    <p>
      <b>Usługi:</b> Czynności oferowane Konsumentowi przez WCIAGNIJ.TO za pośrednictwem Platformy, w tym w
      szczególności: publikacja Oferty, ułatwienie/pośredniczenie przy zawieraniu Umów w tym przekazywanie Zamówień do
      właściwej Restauracji, umożliwienie dodawania ocen, komentarzy i opinii na temat poszczególnych Restauracji oraz
      ich wyświetlania, umożliwienie, z zastrzeżeniem postanowień poniższych, uiszczania online - opłat tytułem
      realizacji Umów na rzecz Restauracji [obsługa płatności].
    </p>
    <p>
      <b>Konto -</b> spersonalizowany panel administracyjny Konsumenta dostępny po dokonaniu skutecznej rejestracji i
      zalogowaniu się przez niego na stronie internetowej <a href="https://wciagnij.to">wciagnij.to</a> – po podaniu
      ustalonego wcześniej loginu oraz hasła, za pomocą którego to Konsument może korzystać z usług świadczonych przez
      WCIAGNIJ.TO, w tym w szczególności składać zamówienia na produkty oferowane przez poszczególne Restauracje.
    </p>

    <b>Systemy Płatności Online:</b>
    <p>
      PayLane sp. z o.o. z siedzibą w Gdańsku przy ul. Norwida 4, kod pocztowy: 80-280, KRS: 0000227278, obsługującą
      płatności online.
    </p>
    <p>
      Płatności IMOJE - ING Bank Śląski Spółka Akcyjna z siedzibą przy ul. Sokolskiej 34, 40-086 Katowice; wpisany do
      Rejestru Przedsiębiorców w Sądzie Rejonowym Katowice – Wschód Wydział VIII Gospodarczy Krajowego Rejestru Sądowego
      pod numerem KRS 0000005459; kapitał zakładowy w kwocie 130 100 000 zł opłacony w całości, NIP 634-013-54-75
    </p>

    <span class="anchor" id="art2"></span>
    <h2>2. Dane WCIAGNIJ.TO</h2>
    <p><b>Dane teleadresowe Firmy:</b></p>
    <p>
      WCIAGNIJ.TO PROSTA SPÓŁKA AKCYJNA ,<br />
      ul. Kilińskiego 86, <br />
      42-218 Częstochowa
    </p>

    <span class="anchor" id="art3"></span>
    <h2>3. Zastosowanie i Postanowienia Ogólne</h2>
    <ol>
      <li>
        Niniejsze Ogólne warunki świadczenia usług dla Konsumentów [dalej: OWU] mają zastosowanie wyłącznie do Usług
        świadczonych przez WCIAGNIJ.TO, za które to ponosi ona pełną odpowiedzialność. Firma informuje, iż korzystanie z
        Platformy przez Konsumentów jest usługą świadczoną drogą elektroniczną, w rozumieniu ustawy z dnia 18 lipca 2002
        r. o świadczeniu usług drogą elektroniczną (Dz.U. z 2002 r., Nr 144, poz. 1204, z późn. zm.) [dalej: u.ś.u.e],
        które to odbywa się podstawie niniejszych OWU, pełniących również rolę Regulaminu w rozumieniu 8 u.ś.u.e.
        Niniejsze OWU określają w szczególności zasady świadczenia Usług oferowanych na Platformie, ich zakres oraz
        zasady korzystania z nich. Firma nie odpowiada natomiast za prawidłowe wykonanie Umowy, nie jest bowiem jej
        stroną.
      </li>
      <li>
        Usługi świadczone przez WCIAGNIJ.TO na rzecz Konsumentów, a wchodzące w zakres niniejszych OWU mają, w stosunku
        do nich charakter bezpłatny.
      </li>
      <li>
        Firma nieodpłatnie udostępnia Konsumentom niniejsze OWU za pośrednictwem Platformy w systemie
        teleinformatycznym, a także w sposób umożliwiający jego pobranie, odtworzenie, utrwalenie i wydrukowanie.
      </li>
    </ol>

    <span class="anchor" id="art4"></span>
    <h2>4. Zasady Świadczenia Usług Drogą Elektroniczną:</h2>
    <ol>
      <li>
        Rozpoczęcie świadczenia Usług przez WCIAGNIJ.TO, następuje z chwilą rozpoczęcia korzystania przez Konsumenta z
        Platformy, a co za tym idzie akceptacją warunków niniejszych OWU. Świadczenie Usług przez WCIAGNIJ.TO na rzecz
        Konsumenta nie jest ograniczone w czasie.
      </li>
      <li>
        Konsumenci mają możliwość zarejestrowania się na Platformie – utworzenia Konta na skutek podania w formularzu
        rejestracyjnym dostępnym na stronie internetowej: <a href="https://wciagnij.to">wciagnij.tol</a> w zakładce
        „Rejestracja” swojego imienia, nazwiska, adresu poczty elektronicznej oraz hasła. Do aktywacji Konta niezbędnym
        jest kliknięcie przez Konsumenta w link aktywacyjny przesyłany niezwłocznie na podany przez niego adres e-mail.
        Utworzenie Konta może się również odbyć poprzez wybranie odpowiedniej opcji podczas składania zamówienia lub
        połączenie Platformy z aplikacją Facebook.com. Konsument może zrezygnować ze świadczonych Usług w każdym czasie.
      </li>
      <li>
        Usunięcie Konta danego Konsumenta następuje na skutek wniosku złożonego przez niego w formie pisemnej na adres:
        ul. Kilińskiego 86, 42-218 Częstochowa, za pomocą poczty elektronicznej na adres
        <a href="mailto:kontakt@wciagnij.to">kontakt@wciagnij.to</a> po upływie 14 dni od dnia jego otrzymania przez
        WCIAGNIJ.TO.
      </li>
      <li>
        Firma ma prawo do natychmiastowego usunięcia Konta danego Konsumenta, w sytuacji rażącego naruszania przez niego
        postanowień niniejszych OWU, w tym w szczególności: umyślnego wystawiania niezgodnych z prawdą ocen
        poszczególnych Restauracji, dostarczania treści o charakterze bezprawnym, o których mowa w ust. 9 czy też
        podania nieprawdziwych/fałszywych danych podczas procesu rejestracyjnego - jeżeli nie zaprzestaje on
        przedmiotowego działania pomimo wyznaczonego przez WCIAGNIJ.TO w tym zakresie terminu - nie krótszego niż 7 dni.
      </li>
      <li>
        Firma nie ponosi odpowiedzialności za niemożność świadczenia Usług na rzecz Konsumenta na skutek działania siły
        wyższej.
      </li>
      <li>
        Korzystanie przez Konsumentów z&nbsp;Usług, z zastrzeżeniem ust. 8 jest możliwe wyłącznie przy pomocy
        podłączonego do&nbsp;sieci Internet komputera klasy PC, Mac lub podobnego, wyposażonego w przeglądarkę
        internetową
      </li>
      <li>
        Korzystanie przez Konsumentów z Usług świadczonych przez WCIAGNIJ.TO jest również możliwe na urządzeniach
        przenośnych takich jak: smartfon, tablet, palmtop w wersji mobilnej. Warunkiem niezbędnym jest jednak dostęp
        jednego z urządzeń wymienionych w zd. poprzednim do sieci Internet oraz wyposażenie go w jedną z przeglądarek
      </li>
      <li>
        W ramach korzystania z Usług niedozwolone jest dostarczanie przez Konsumenta treści o charakterze bezprawnym, w
        tym w szczególności:<br />
        - danych i informacji spreparowanych w sposób stwarzający ryzyko naruszenia bezpieczeństwa systemu
        informatycznego lub stabilności Platformy;<br />
        - informacji naruszających jakiekolwiek dobra WCIAGNIJ.TO lub osób trzecich;<br />
        - innych danych i informacji naruszających bezwzględnie obowiązujące przepisy prawa, w tym w szczególności
        treści pornograficznych, odwołujących się do rasizmu, nacjonalizmu, faszyzmu, czy też propagujących przemoc.
      </li>
      <li>
        Korzystając z Platformy Konsumenci mają obowiązek postępować zgodnie z powszechnie obowiązującymi przepisami
        prawnymi oraz zasadami współżycia społecznego.
      </li>
      <li>
        Zakazane jest wykorzystywanie przez Konsumentów Usług w sposób sprzeczny z powszechnie obowiązującymi przepisami
        prawnymi, dobrymi obyczajami lub w sposób naruszający uzasadnione interesy WCIAGNIJ.TO. W szczególności
        Konsument nie będzie podejmował czynności, które mogłyby narazić WCIAGNIJ.TO na jakąkolwiek szkodę majątkową.
      </li>
      <li>
        Zabronione jest pobieranie przez Konsumentów zawartości baz danych udostępnionych na Platformie i wtórne ich
        wykorzystywanie w całości lub w istotnej części co do jakości lub ilości.
      </li>
      <li>
        Firma zastrzega, iż wszystkie wiadomości i materiały dostępne na Platformie są objęte ochroną prawa autorskiego.
        Konsument ma prawo do korzystania z nich jedynie w ramach dozwolonego użytku osobistego. Kopiowanie,
        zwielokrotnianie, rozpowszechnianie w Internecie i inne formy korzystania z materiałów i wiadomości
        umieszczonych na Platformie wykraczające poza granice dozwolone prawem jest zabronione.
      </li>
    </ol>

    <span class="anchor" id="art5"></span>
    <h2>5. Oferta</h2>
    <ol>
      <li>
        Firma publikuje Ofertę w imieniu i na rzecz Restauracji w Platformie, zgodnie z Danymi Restauracji przekazanymi
        przez Restauracje.
      </li>
      <li>
        Firma prezentuje wszystkie Dane Restauracji w sposób wyraźnie wskazujący Konsumentowi jego prawa i obowiązki w
        przypadku akceptacji Oferty.
      </li>
      <li>
        Restauracja może używać składników oraz dodatków do posiłków i napojów, które mogą powodować alergie i
        nietolerancje. Jeśli Konsument ma alergię na jakiekolwiek artykuły spożywcze, zalecamy skontaktować się z
        Restauracją telefonicznie, aby uzyskać aktualne informacje o alergenach przed złożeniem Zamówienia.
      </li>
    </ol>

    <span class="anchor" id="art6"></span>
    <h2>6. Umowa</h2>
    <ol>
      <li>
        Umowa zawierana jest w momencie ukończenia składania Zamówienia przez Konsumenta poprzez kliknięcie przycisku
        „Zamawiam i płacę”. Konsument niezwłocznie otrzyma potwierdzenie jej zawarcia na podany przez siebie podczas
        składania Zamówienia adres mailowy.
      </li>
      <li>
        Umowa może być prawidłowo wykonana przez Restaurację wyłącznie pod warunkiem podania przez Konsumenta
        prawidłowych i pełnych danych kontaktowych podczas składania Zamówienia. Konsument jest zobowiązany do
        natychmiastowego zgłoszenia wszelkich nieścisłości w danych, w szczególności dotyczących płatności, które
        zostały przekazane lub wymienione Spółce lub Restauracji.
      </li>
      <li>
        W celu uzyskania informacji o statusie realizacji Umowy Konsument może kontaktować się bezpośrednio z
        Restauracją za pomocą danych kontaktowych podanych w Platformie oraz potwierdzeniu Zamówienia, o którym mowa w
        ust. 1. Po złożeniu Zamówienia przez Konsumenta, Konsument winien być dostępny za pośrednictwem telefonu lub
        poczty elektronicznej zarówno dla Restauracji, jak i WCIAGNIJ.TO, zgodnie z danymi kontaktowymi wskazanymi przy
        składaniu Zamówienia.
      </li>
      <li>
        Jeżeli Konsument zdecyduje się na realizację Umowy poprzez dostarczenie mu zamówionych produktów, winien on być
        obecny pod wskazanym przy składaniu Zamówienia adresem dostawy w celu jego odbioru.
      </li>
      <li>
        Jeżeli Konsument zdecyduje się na realizację Umowy poprzez samodzielny odbiór zamówionych produktów, winien on
        zgłosić się w tym celu i o wskazanej godzinie do punktu odbioru zamówień Restauracji. Godzina, o której mowa w
        zd. poprzednim zostanie wskazana Konsumentowi drogą elektroniczną (e-mail potwierdzający złożenie Zamówienia)
        lub na Platformie.
      </li>
      <li>
        W momencie dostarczenia Zamówienia Restauracja może poprosić o dokument tożsamości, jeśli Zamówienie obejmuje
        produkty alkoholowe lub inne produkty z ograniczeniem wiekowym. Jeśli Konsument nie jest w stanie wylegitymować
        się odpowiednio lub nie spełnia minimalnych wymogów wiekowych, Restauracja odmówi dostarczenia odpowiednich
        produktów Konsumentowi. W takim przypadku pobrane mogą zostać koszty z tytułu unieważnienia Zamówienia.
      </li>
    </ol>

    <span class="anchor" id="art7"></span>
    <h2>7. Odstąpienie od Umowy</h2>
    <ol>
      <li>
        Z uwagi na łatwo psujący się charakter oferowanych przez poszczególne Restauracje produktów, Konsument nie jest
        uprawniony do odstąpienia od Umowy po rozpoczęciu jej faktycznej realizacji (art. 38 pkt 4 ustawy z dnia 30 maja
        2014 r o prawach konsumenta (Dz.U. 2014 poz. 827). Odstąpienie od zwartej Umowy przed rozpoczęciem jej
        faktycznej realizacji jest możliwe bez podania jakiejkolwiek przyczyny poprzez kontakt telefoniczny z
        Restauracją.
      </li>
      <li>
        Fundajca wskazuje Konsumentowi, że Restauracja jest uprawniona do odstąpienia od Umowy, w przypadku:<br />
        a) zaistnienia siły wyższej uniemożliwiającej realizację Umowy,<br />
        b) dezaktualizacji zamieszczonej w Platformie Oferty w sytuacji, gdy Konsument nie zgodził się na alternatywę
        dotycząca Zamówienia złożoną przez Restaurację,<br />
        c) przekazania przez Konsumenta nieprawidłowego lub nieosiągalnego numer telefonu lub adresu dostawy.
      </li>
      <li>
        W przypadku odstąpienia od Umowy przez którąkolwiek ze stron traktuje się ja jako niezawartą. Firma dokona
        zwrotu płatności uiszczonej przez Konsumenta przy użyciu takiego samego sposobu zapłaty, jakiego użył Konsument.
      </li>
      <li>
        W przypadku złożenia przez Konsumenta fałszywego Zamówienia lub niewykonania przez niego Umowy (w szczególności
        przez jej nieopłacenie lub nieobecność w miejscu dostawy lub w punkcie odbioru w celu odebrania zamówionych
        produktów), Firma jest uprawniona do odmowy przyjęcia jakichkolwiek przyszłych Zamówień od takiego Konsumenta.
      </li>
      <li>
        Firma jest uprawniona do niezwłocznego odrzucania Zamówień składanych przez Konsumentów, jeżeli zachodzi
        uzasadniona wątpliwość co do ich poprawności lub autentyczności. W przypadku odrzucenia przez WCIAGNIJ.TO
        Zamówienia już opłaconego, Firma przeleje wpłaconą kwotę na rachunek, z którego nastąpiła płatność. W przypadku
        uzasadnionego podejrzenia popełnienia przestępstwa, Firma jest zobowiązana do powiadomienia o tym fakcie
        odpowiednich organów ścigania.
      </li>
    </ol>

    <span class="anchor" id="art8"></span>
    <h2>8. Płatność</h2>
    <ol>
      <li>
        W momencie zawarcia Umowy zgodnie z postanowieniami art. 6.1 niniejszych Ogólnych warunków świadczenia usług na
        rzecz Konsumentów, po stronie Konsumenta powstaje obowiązek płatności na rzecz Restauracji ceny wynikającej z
        Zamówienia. Podana w Zamówieniu cena stanowi całkowitą cenę jaką Zamawiający zobowiązany będzie zapłacić wraz z
        należnym podatkiem oraz kosztem dostawy. Obciążenie Zamawiającego dodatkowymi kosztami nastąpi wyłącznie po
        uzyskaniu jego wyraźnej zgody. Konsument może wywiązać się ze wskazanego w zd. pierwszym obowiązku na skutek
        skorzystania z oferowanych za pośrednictwem Platformy Systemów Płatności Online lub przekazując należność
        kurierowi w momencie dostawy lub płacąc w Restauracji przy odbiorze. Dostępność sposobów zapłaty wymienionych w
        zd. poprzednim jest uzależniona od indywidualnych ustaleń poczynionych pomiędzy WCIAGNIJ.TO, a daną Restauracją
        i może zostać ograniczona. Konsument jest informowany o dostępnych sposobach zapłaty na Platformie podczas
        składania Zamówienia w danej Restauracji i musi dokonać wyboru jednego z nich przed złożeniem Zamówienia, tj.
        przed kliknięciem przycisku „Zamawiam i płacę”.
      </li>
      <li>
        Z zastrzeżeniem postanowień art. 7 ust. 3 niniejszych Ogólnych warunków świadczenia usług dla Konsumentów
        (częściowy) zwrot płatności online winien być możliwy wyłącznie w przypadku niemożności dostarczenia części
        Zamówienia i akceptacji realizacji części tego Zamówienia przez Konsumenta. Zwrot jest zawsze dokonywany na
        rachunek, z którego dokonano płatności.
      </li>
      <li>
        Zwrot płatności online jest dokonywany w terminie do 10 dni roboczych od momentu uzyskania przez WCIAGNIJ.TO
        informacji o odstąpieniu od zawartej Umowy. W przypadku odrzucenia Zamówienia, o którym mowa w art. 7 ust. 5
        niniejszych OWU zwrot płatności online jest dokonywany w terminie do 10 dni roboczych od momentu tegoż
        odrzucenia.
      </li>
      <li>
        Restauracja upoważniła WCIAGNIJ.TO do akceptacji w jej imieniu płatności online dokonywanych przez Konsumentów.
      </li>
    </ol>

    <span class="anchor" id="art9"></span>
    <h2>9. Zasady Zamieszczanie Wpisów na Platformie przez Konsumentów:</h2>
    <ol>
      <li>
        Firma umożliwia Konsumentom dokonywanie ocen wykonania Umów przez poszczególne Restauracje w szczególności
        poprzez możliwość przyznawania im gwiazdek i/lub wystawianie komentarzy
      </li>
      <li>W przypadku odstąpienia od Umowy, wystawione przez Konsumentów oceny nie będą publikowane.</li>
      <li>
        Firma jest upoważniona, lecz nie zobowiązana, do ujawnienia ocen, o których mowa w powyższych ustępach wszystkim
        użytkownikom Platformy. Oceny ujawniane są w ciągu 7dni. Firma ma możliwość usuwania wystawionych ocen.
      </li>
      <li>Konsument zamieszcza oceny wyłącznie na własną odpowiedzialność.</li>
    </ol>

    <span class="anchor" id="art10"></span>
    <h2>10. Rozpatrywanie reklamacji</h2>
    <ol>
      <li>
        Reklamacje Konsumentów dotyczące Oferty, Zamówienia lub realizacji Umowy winny być składane bezpośrednio do
        Restauracji z wykorzystaniem jej danych kontaktowych wymienionych w Platformie. W przypadku zgłoszenia
        reklamacji, o której mowa w zd. poprzednim Firma może odgrywać jedynie rolę mediatora.
      </li>
      <li>
        W przypadku reklamacji Konsumenta dotyczącej Usług, reklamacja winna być przekazana Działowi Obsługi Klienta
        WCIAGNIJ.TO za pośrednictwem:<br />
        b) poczty elektronicznej na adres kontakt@wciagnij.to lub<br />
        c) poczty tradycyjnej na adres: ul. Kilińskiego 86, 42-218 Częstochowa
      </li>
      <li>
        Po otrzymaniu przez WCIAGNIJ.TO reklamacji, o której mowa w ust. 2, zostanie ona niezwłocznie rozpatrzona,
        jednak nie później niż w terminie dwóch tygodni od dnia jej otrzymania. Firma potwierdzi otrzymanie reklamacji
        złożonej przez Konsumenta na podany przez niego podczas zgłoszenia adres mailowy.
      </li>
    </ol>

    <span class="anchor" id="art11"></span>
    <h2>11. Prawo właściwe i właściwość sądów</h2>
    <ol>
      <li>
        Do wykonywania Usług świadczonych przez WCIAGNIJ.TO na rzecz Konsumentów, stosuje się powszechnie obowiązujące
        przepisy prawa polskiego, w szczególności przepisy Kodeksu cywilnego oraz ustawy z dnia 30 maja 2014 r o prawach
        konsumenta (Dz.U. 2014 poz. 827),
      </li>
      <li>
        Wszelkie spory pomiędzy WCIAGNIJ.TO, a Konsumentem odnoszące się do realizacji Usług przez WCIAGNIJ.TO będą
        Wszelkie spory pomiędzy WCIAGNIJ.TO, a Konsumentem odnoszące się do realizacji Usług przez WCIAGNIJ.TO będą
        rozpoznawane przez Sąd właściwy miejscowo według przepisów prawa polskiego.
      </li>
    </ol>

    <span class="anchor" id="art12"></span>
    <h2>12. Polubowne rozwiązywanie sporów</h2>
    <p>
      Komisja Europejska prowadzi platformę internetowego rozstrzygania sporów. Platforma ta dostępna jest pod adresem:
      http://ec.europa.eu/odr. Firma zastrzega, iż korzystanie z innych alternatywnych mechanizmów rozwiązywania sporów
      wymaga uzyskania zgody WCIAGNIJ.TO.
    </p>

    <span class="anchor" id="art13"></span>
    <h2>13. Newsletter</h2>
    <p>
      Składając Zamówienie, Konsument może wyrazić zgodę na otrzymywanie w formie newslettera&nbsp;informacji o
      restauracjach, ofertach specjalnych i kuponach rabatowych. Konsument może w każdej chwili wycofać swoją zgodę pod
      adresem: <a href="https://wciagnij.to.pl/#!settings/user">https://wciagnij.to.pl/#!settings/user</a>, po uprzednim
      zalogowaniu na swoje Konto lub kontaktując się z Działem Obsługi Klienta pod adresem kontakt@wciagnij.to
    </p>

    <span class="anchor" id="art14"></span>
    <h2>14. Wgląd i poprawianie przechowywanych danych osobowych</h2>
    <p>
      Firma przetwarza oraz przechowuje dane osobowe Konsumenta. Przechowywanie i przetwarzanie danych osobowych, w tym
      możliwość ich poprawiania/zmiany/usunięcia uregulowane zostało szczegółowo w
      <a href="https://wciagnij.to/polityka-prywatnosci">Polityce Prywatności</a>.
    </p>

    <p>Wersja obowiązująca od dnia 2019.11.01</p>
  </div>

  <app-footer></app-footer>
</div>
