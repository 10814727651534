import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '../../core/index';
import { environment } from '../../../environments/environment';
import { RevLocationIQContext } from '../../shared/reversegeocode/reversegeocode.service';
import { DeliveryModel } from '../../shared/model/order.model';
import { BillModel } from '../../shared/model/bill.model';
import { SessionService } from '../../core/session.service';
import { OrderService } from '../checkout/order.service';
import { Subscription, timer, BehaviorSubject } from 'rxjs';
import { switchMap, first, take, skip } from 'rxjs/operators';
import { PlaceService } from '@app/place/place.service';
import { PlaceModel } from '@app/shared/model/place.model';

const log = new Logger('confirm');

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, OnDestroy {
  target: string;
  bill_id: string = null;
  status: number = null;

  bill: any = null;
  order: any = null;
  place: PlaceModel;
  subscription: Subscription;
  statusText: string;
  localIdSubject: BehaviorSubject<string>;
  steps: any = [];
  failed: boolean = false;

  DeliveryOnline = [
    {
      title: 'Zamówienie złożone',
      status: 2,
      completed: true
    },
    {
      title: 'Płatność zakceptowana',
      status: 3,
      completed: false
    },
    {
      title: 'Zamówienie przyjęte',
      status: 4,
      completed: false
    },
    {
      title: 'W trakcie przygotowania',
      status: 5,
      completed: false
    },
    {
      title: 'Gotowe do wysyłki',
      status: 6,
      completed: false
    },
    {
      title: 'W dostawie',
      status: 7,
      completed: false
    },
    {
      title: 'Dostarczone',
      status: 8,
      completed: false
    }
  ];

  DeliveryCash = [
    {
      title: 'Zamówienie złożone',
      status: 2,
      completed: true
    },
    {
      title: 'Zamówienie przyjęte',
      status: 4,
      completed: false
    },
    {
      title: 'W trakcie przygotowania',
      status: 5,
      completed: false
    },
    {
      title: 'Gotowe do wysyłki',
      status: 6,
      completed: false
    },
    {
      title: 'W dostawie',
      status: 7,
      completed: false
    },
    {
      title: 'Dostarczone',
      status: 8,
      completed: false
    }
  ];

  LocalOnline = [
    {
      title: 'Zamówienie złożone',
      status: 2,
      completed: true
    },
    {
      title: 'Płatność zakceptowana',
      status: 3,
      completed: false
    },
    {
      title: 'Zamówienie przyjęte',
      status: 4,
      completed: false
    }
  ];

  LocalCash = [
    {
      title: 'Zamówienie złożone',
      status: 2,
      completed: true
    },
    {
      title: 'Zamówienie przyjęte',
      status: 4,
      completed: false
    }
  ];

  PaymentFailed = [
    {
      title: 'Płatność nieudana',
      status: 0,
      completed: true
    }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private session: SessionService,
    private orderService: OrderService,
    private placeService: PlaceService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.localIdSubject = new BehaviorSubject(null);
  }

  @ViewChild('stepper')
  stepper: MatStepper;
  ngOnInit() {
    this.target = environment.target;
    this.session.setSessionKey('cart', undefined);
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.bill_id = params['id'];
        this.subscription = timer(0, 10000)
          .pipe(switchMap(() => this.orderService.getBillStatus(this.bill_id)))
          .subscribe(result => {
            this.bill = result.bill;
            this.order = result.order;
            this.setStepper(
              result.bill.payment_method,
              result.order.consume_type,
              result.order.status,
              result.bill.status,
              result.bill.payment_status
            );
            this.localIdSubject.next(this.order.local_id);
            log.debug(result);
          });
      }
    });

    this.localIdSubject.pipe(skip(1), take(1)).subscribe(localId => {
      this.placeService.getPlaceQuoteById(localId).subscribe((data: PlaceModel) => {
        this.place = data;
      });
    });
  }

  setStepper(paymentMethod: number[], consumeType: number, status: number, billStatus: number, paymentStatus: number) {
    //console.log(paymentStatus, 'statusy');
    if (consumeType == 3) {
      if (paymentMethod.includes(1)) this.steps = this.LocalCash;
      else this.steps = this.LocalOnline;
    } else {
      if (paymentMethod.includes(1)) this.steps = this.DeliveryCash;
      else this.steps = this.DeliveryOnline;
    }

    //jesli platnosc nieudana
    if (paymentStatus == 5 || paymentStatus == 8 || paymentStatus == 10 || paymentStatus == 11) {
      this.steps = this.PaymentFailed;
      this.failed = true;
    }

    //uaktalnia stepper pobrny z DOM
    this.changeDetectorRef.detectChanges();

    //jeśli status == 9 (PAYING, to zostawia począkowe ustawienia steppera
    if (status != 9) {
      this.steps = this.steps.map((step: any, index: number) => {
        //console.log(step, index);
        if (step.status <= status) {
          step.completed = true;
          this.stepper.selectedIndex = index;
        }
        return step;
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
