<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <div class="oferta_container" style="min-height:  320px; display: flex; flex-direction: column;">
      <h1 class="text-center page-title">{{ seo.title }}</h1>

      <div class="row">
        <div class="col-12">
          <p [innerHTML]="seo.text_top"></p>
        </div>
      </div>
      <div class="row">
        <ul *ngIf="places">
          <li *ngFor="let place of places.data">
            <a
              [routerLink]="[
                '/restauracja',
                translate.currentLang,
                place.city | normalizeString | lowercase,
                place.slug
              ]"
              >{{ place.name }}</a
            >
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="col-12">
          <p [innerHTML]="seo.text_bottom"></p>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
