import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserLocation } from '@app/shared/model/userLocation';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'emptylocation-modal',
  templateUrl: './emptyLocation.modal.html',
  styleUrls: ['./emptyLocation.modal.scss']
})
export class EmptyLocationModalComponent implements OnInit {
  isLoading = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public dialogRef: MatDialogRef<EmptyLocationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
  }

  onLoaded(load: boolean) {
    this.isLoading = load;
  }
}
