<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <h1 class="text-center page-title">Konkurs WCIĄGNIJ TO</h1>
    <div class="row justify-content-center">
      <div class="col-10">
        <p class="text justify">
          <img src="assets/img/baner-regulamin_2.jpg" />
        </p>
        <h2>Do wygrania Tablet HUAWEI 7" TAB 3 - wygrywają 3 osoby!</h2>
        <h3>Jak zgarnąć tablet!</h3>

        <ul>
          <li>Obserwuj nas!</li>
          <li>Polub i udostępnij post konkursowy publicznie</li>
          <li>W komentarzu oznacz Restaurację, której nie ma na Wciągnij.to, a z której chętnie byś coś zamówił/a!</li>
        </ul>
        <p class="text center" style="color:red;">
          Ogłoszenie wyników 06.09.2021 r. o godzinie 13.00
        </p>
        <br /><br />
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>Regulamin Konkursu WCIĄGNIJ TO&nbsp;</strong>
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>prowadzonego na platformie Facebook</strong>
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>(zwany dalej &bdquo; Regulaminem&rdquo;)</strong>
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>&sect; 1&nbsp;</strong>
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>Postanowienia og&oacute;lne</strong>
        </p>
        <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <ol>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Niniejszy Regulamin określa warunki i zasady uczestnictwa w konkursie pod nazwą &bdquo;WCIĄGNIJ TO&rdquo;
              (zwanym dalej &bdquo;Konkursem&rdquo;).
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Czynności związane z przeprowadzeniem Konkursu (w tym rozstrzygnięcie Konkursu oraz przekazanie
              ustanowionych nagr&oacute;d) oraz czynności związane z administrowaniem danymi osobowymi
              Uczestnik&oacute;w Konkursu, wykonuje Grupa ABS Adrian Sadowski z siedzibą w Kusięta 343 , 42-256 Olsztyn
              (zwany dalej &bdquo;Organizatorem&rdquo;).
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Konkurs w pełni finansuje Organizator ze środk&oacute;w własnych. Konkurs nie jest prowadzony w celach
              zarobkowych.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Konkurs odbywa się na portalu <u><a href="http://www.facebook.com/">www.facebook.com</a></u> (zwany dalej
              &rdquo;Portalem&rdquo;) pod adresem Organizatora
              <u><a href="https://www.facebook.com/Wciagnij.to">https://www.facebook.com/Wciagnij.to</a></u>
            </p>
          </li>
        </ol>
        <p
          style="font-size:16px;text-align: justify;background: transparent;margin-left: 0.64cm;margin-bottom: 0cm;line-height: 100%;"
        >
          <br />
        </p>
        <ol start="5">
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Do uczestnictwa w Konkursie konieczne jest zarejestrowanie się na Portalu, zgodnie z odnośnymi zasadami
              Portalu.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Udział w Konkursie jest r&oacute;wnoznaczny z zapoznaniem się z treścią niniejszego Regulaminu i jego
              akceptacją przez Uczestnika.
            </p>
          </li>
        </ol>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>&sect; 2</strong>
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>Zasady przeprowadzania Konkursu</strong>
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <ol>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Uczestnikiem Konkursu (zwany dalej &bdquo;Uczestnikiem&rdquo;) może być każda pełnoletnia osoba fizyczna
              posiadająca pełną zdolność do czynności prawnych, mająca miejsce zamieszkania na terenie Rzeczpospolitej
              Polskiej i posiadająca konto na portalu Facebook.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Uczestnik nie może wykorzystać do udziału w Konkursie więcej niż jednego konta na Portalu. Do każdego
              konta przypisany jest jeden udział.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Udział w Konkursie polega na publikacji komentarza pod postem konkursowym, oznaczając lokal
              gastronimiczny, w okresie trwania konkursu z profilu Uczestnika w Portalu.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Konkurs trwa od momentu zamieszczenia postu konkursowego do 15.09.2021 r. godz. 23:59. Zgłoszenia
              nadesłane po upływie tego terminu nie zostaną uwzględnione.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Uczestnictwo w Konkursie odbywa się na zasadzie dobrowolności i nieodpłatności.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Organizator zastrzega sobie prawo do wykorzystywania w toku swojej działalności wszelkich treści
              opublikowanych w postach konkursowych Uczestnik&oacute;w. Zgadzając się na uczestnictwo w Konkursie oraz
              wykonując jego zadanie, Uczestnik oświadcza, iż zrzeka się praw co do treści zawartych w postach
              konkursowych, w&nbsp;tym także prawa do jakiegokolwiek wynagrodzenia, jak i wyraża zgodę na nieoznaczanie
              autorstwa Uczestnika przy takim wykorzystaniu.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Organizator jest uprawniony do żądania usunięcia post&oacute;w konkursowych sprzecznych z obowiązującym
              prawem, a także normami obyczajowymi, zasadami wsp&oacute;łżycia społecznego oraz regulaminem Konkursu i
              Portali.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Uczestnik oświadcza, iż posiada wszelkie prawa związane z nadesłanymi przez niego w postach konkursowych
              treściami. W przypadku zgłoszenia roszczeń przez osoby trzecie co do praw związanych z komentarzami,
              Uczestnik ponosi wyłączną odpowiedzialność.
            </p>
          </li>
        </ol>
        <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>&sect; 3</strong>
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>Rozstrzygnięcie Konkursu</strong>
        </p>
        <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <ol>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              W celu rozstrzygnięcia Konkursu Organizator powołuje Komisję Konkursową (zwaną dalej Komisją).
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              W składzie Komisji znajdują się 3 osoby wyznaczone przez Organizatora.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Komisja przyznaje Nagrody wybranym 3 Uczestnikom (zwanym dalej &bdquo;Zwycięzcami&rdquo;), kierując się
              treścią komentarza odpowiadającego na pytanie konkursowe.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Komisja ogłosi wyniki Konkursu w dniu 16.09.2021 r. o godzinie 13.00 na profilu Organizatora w Portalu.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Uczestnicy wyrażają zgodę na publikowanie ich danych osobowych (imienia i nazwiska/nazwy konta) przy
              ogłoszeniu wynik&oacute;w Konkursu. Wyniki zostaną ogłoszone przez publikację zrzutu ekranu zawierającego
              zwycięskie komentarze na Portalu w ramach jego funkcjonalności o nazwie &bdquo;Facebook Story&rdquo;.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Organizator zgłosi się do Zwycięzc&oacute;w za pośrednictwem profilu na Portalu (z wykorzystaniem
              wiadomości prywatnych). Zwycięzca jest zobowiązany do podania Organizatorowi danych niezbędnych do
              przekazania mu nagrody (w tym między innymi adresu do wysiłki oraz adresu e-mail) i jej rozliczenia
              podatkowo.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Organizator zobowiązany jest do wysłania/przekazania Nagr&oacute;d w terminie 30 dni od dnia przekazania
              danych przez Zwycięzcę.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Organizator nie ponosi odpowiedzialności za op&oacute;źnienia lub jakiekolwiek inne negatywne
              konsekwencje, będące wynikiem błędnego wprowadzenia danych przez Uczestnika. Organizator nie ponosi
              odpowiedzialności także, gdy doręczenie Nagrody będzie niemożliwe z uwagi na błędne dane podane przez
              Uczestnika.
            </p>
          </li>
        </ol>
        <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>&sect; 4</strong>
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>Nagrody</strong>
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <ol>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Nagrodą w Konkursie dla każdego Zwycięzcy jest tablet 7&rsquo; TAB3 marki HUAWEI
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Uczestnik jest uprawniony do maksymalnie jednej Nagrody w czasie trwania Konkursu.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Uczestnik nie jest uprawniony do przenoszenia praw związanych z wygraniem Nagrody na inne osoby.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Nagroda rzeczowa nie podlega wymianie na ekwiwalent pieniężny ani na inną nagrodę.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Organizator przekazuje Nagrody Uczestnikom zgodnie z postanowieniami niniejszego Regulaminu oraz
              obowiązującymi przepisami. Nagrody podlegają opodatkowaniu. Podatek w wysokości 10% wartości Nagrody
              odprowadza do właściwego urzędu skarbowego Organizator, stosownie do w art. 30 ust. 1 pkt 2 Ustawy o
              podatku dochodowym od os&oacute;b fizycznych z dnia 26.07.1991 r.
            </p>
          </li>
        </ol>
        <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>&sect; 5</strong>
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>Ochrona danych osobowych</strong>
        </p>
        <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <ol>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Uczestnik biorąc udział w Konkursie, wyraża zgodę na przetwarzanie danych osobowych koniecznych do
              rozstrzygnięcia i przeprowadzenia Konkursu, zawartych na profilu Organizatora na Portalu. Zgoda dotyczy
              także przetwarzania wizerunku Uczestnika lub innej osoby trzeciej jeżeli są one uwidocznione na zdjęciach
              (przy czym przyjmuje się, że Uczestnik posiada zgodę os&oacute;b trzecich na wykorzystanie przez
              Organizatora na potrzeby Konkursu).
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Administratorem danych osobowych Uczestnik&oacute;w jest podmiot określony w &sect; 1 pkt. 2. Dane osobowe
              Uczestnik&oacute;w przetwarzane są zgodnie z ustawą z dnia 10.05.2018 r. o ochronie danych i z
              Rozporządzeniem RODO, w celu organizacji i rozliczenia Konkursu oraz przeprowadzenia postępowania
              reklamacyjnego.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Uczestnikom przysługuje prawo dostępu do treści ich danych osobowych, ich sprostowania, ograniczenia
              przetwarzania, usunięcia i cofnięcia zgody na przetwarzanie. Uczestnik może także wnieść skargę dot.
              przetwarzania jego danych osobowych w zw. z Konkursem do organu nadzoru &ndash; Prezesa Urzędu Ochrony
              Danych Osobowych.
            </p>
          </li>
        </ol>
        <p
          style="font-size:16px;text-align: justify;background: transparent;margin-left: 0.64cm;margin-bottom: 0cm;line-height: 100%;"
        >
          <br />
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>&sect; 6</strong>
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>Reklamacje</strong>
        </p>
        <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <ol>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Reklamacje dotyczące przeprowadzenia Konkursu wymagają przesłania listem poleconym na adres siedziby
              Organizatora lub e-mailem na adres: biuro@wciagnij.to
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Pisemna reklamacja powinna zawierać: imię, nazwisko, adres Uczestnika oraz uzasadnienie twierdzeń
              zawartych w reklamacji.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Reklamacje można składać w trakcie trwania Konkursu lub po jego zakończeniu, jednak najp&oacute;źniej w
              terminie 14 dni od dnia rozstrzygnięcia Konkursu, zgodnie z &sect; 3 pkt 4.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Za reklamację wysłaną z zachowaniem terminu uznaje się reklamację nadaną w plac&oacute;wce pocztowej
              najp&oacute;źniej w 14 dniu od dnia rozstrzygnięcia Konkursu lub wysłanie e-mail. Reklamacja zgłoszona po
              wyznaczonym terminie nie jest skuteczna.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Organizator rozpatrzy reklamacje w ciągu 14 dni od dnia ich otrzymania.
            </p>
          </li>
        </ol>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>&sect; 7</strong>
        </p>
        <p style="font-size:16px;text-align: center;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <strong>Postanowienia końcowe</strong>
        </p>
        <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <ol>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Po zapoznaniu się ze zmianami Regulaminie każdy Uczestnik jest uprawniony do odstąpienia od udziału w
              Konkursie, poprzez usunięcie swojego Zgłoszenia lub poinformowanie Organizatora o tym fakcie. Jeżeli
              Uczestnik zdecydował się odstąpienie od udziału w Konkursie, Organizator nie jest uprawniony do
              korzystania z treści Zgłoszenia.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Konkurs nie jest organizowany, zarządzany, wspierany ani w jakikolwiek spos&oacute;b sponsorowany przez
              Portal.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              W sprawach nieuregulowanych niniejszym Regulaminem stosuje się przepisy prawa polskiego.
            </p>
          </li>
          <li>
            <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
              Niniejszy Regulamin dostępny jest na profilu Organizatora w Portalu oraz w siedzibie Organizatora.
            </p>
          </li>
        </ol>
        <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
        <p style="font-size:16px;text-align: justify;background: transparent;margin-bottom: 0cm;line-height: 100%;">
          <br />
        </p>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
