<div id="cart" [ngClass]="{ hasItems: count > 0 }">
  <div class="cartContent">
    <div class="header">
      <h3>Koszyk</h3>
      <button mat-icon-button (click)="close()" class="close">
        <mat-icon size="44">cancel</mat-icon>
      </button>
    </div>

    <mat-spinner [diameter]="40" *ngIf="cartState.isLoading" class="loader"></mat-spinner>

    <ng-container *ngIf="!cartState.isLoading">
      <div class="products">
        <div *ngIf="count; else noProducts">
          <div class="productContainer" *ngFor="let item of cart.order.products; index as i">
            <div class="product">
              <div class="counterRow">
                <div class="quantity">
                  <button class="plus" (click)="plus(i)">
                    <mat-icon class="icon">add_box</mat-icon>
                  </button>
                  <!--                <div class="value">{{ item.count }}</div>-->
                  <button class="minus" (click)="minus(i)">
                    <mat-icon class="icon">indeterminate_check_box</mat-icon>
                  </button>
                </div>
              </div>
              <div class="textRow">
                <div>
                  <span class="name">{{ item.product.name | translateString: translate.currentLang }}</span>
                  <span class="multiplier">x</span>
                  <span class="count">{{ item.count }}</span>
                </div>
                <div class="attribute" *ngIf="item.product.attributes && item.product.attributes.length > 1">
                  {{ item.attribute.name | translateString: translate.currentLang }}
                </div>
                <div class="components" *ngFor="let comp of item.components">
                  <div *ngIf="comp.component.default_count != comp.count">
                    {{ comp.component.name | translateString: translate.currentLang }} x {{ comp.count }}
                  </div>
                </div>
                <div class="price">{{ item.price * item.count | currency: ' ' }} PLN</div>

                <div class="product linked" *ngFor="let item_l of item.linked; index as j">
                  <div>{{ item_l.product.name | translateString: translate.currentLang }} x {{ item_l.count }}</div>
                  <div>{{ item_l.price * item_l.count | currency: ' ' }} PLN</div>
                </div>
              </div>
              <div class="actionsRow">
                <button mat-flat-button (click)="remove(i)" class="remove">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <ng-template #noProducts>
          <div class="noProducts">
            <img src="assets/img/undraw_empty_cart.svg" alt="Koszyk pusty" class="emptyCart" />
            <div class="text">Brak produktów w koszyku</div>
          </div>
        </ng-template>

        <div class="bootomInfo" *ngIf="count !== 0">
          <img src="assets/icon/delivery.svg" width="24px" />
          <div>
            CENA DOSTAWY: <span class="price">{{ cart.order.delivery.price | currency: ' ' }} PLN</span>
          </div>
        </div>
      </div>

      <div class="footer" *ngIf="count !== 0">
        <div class="row no-gutters" *ngIf="count !== 0 && cart.place.min_order_price > cart.bill.sum">
          <div class="col-12 text-center text-danger minPrice">
            Do minimalnej wartości zamówienia brakuje:
            {{ cart.place.min_order_price - (cart.order.sum - cart.place.delivery_price) | currency: ' ' }}
          </div>
        </div>

        <div class="buttons">
          <button mat-flat-button color="secondary" (click)="delete()">
            <mat-icon>delete_outline</mat-icon>
          </button>
          <button
            mat-flat-button
            color="primary"
            routerLink="/checkout"
            [disabled]="cart.place.min_order_price > cart.order.sum || !isOpen"
          >
            <mat-icon>shop</mat-icon>
            Do kasy ({{ cart.order.sum | currency: ' ' }} PLN)
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
