<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <div class="oferta_container" style="min-height:  320px; display: flex; flex-direction: column;">
      <h1 class="text-center page-title">Instalacja aplikacji Wciągnij dla iOS iMac</h1>
      <h3 class="text-center page-title">Aby dodać skrót do aplikacji należy:</h3>
      <ul>
        <li>wejść w przeglądarkę Safari.</li>
        <li>w dolnym menu wybieramy przycisk Udostępnij.</li>
        <li>następnie klikamy w przycisk Do ekranu początkowego.</li>
        <li>wpisujemy etykietę nazwy aplikacji jaka ma się wyświetlać - Wciągnij.to</li>
        <li>na ekranie głównym pojawi się ikona do aplikacji mobilnej Wciągnij.to.</li>
      </ul>
      <div class="row">
        <div class="col-lg-4">
          <img src="../../assets/img/install_step_1.jpg" />
        </div>
        <div class="col-lg-4">
          <img src="../../assets/img/install_step_2.jpg" />
        </div>
        <div class="col-lg-4">
          <img src="../../assets/img/install_step_3.jpg" />
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
