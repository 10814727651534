import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoodtypeRoutingModule } from './foodtype-routing.module';
import { FoodtypeComponent } from './foodtype.component';
import { SharedModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { FoodComponent } from './food/food.component';

@NgModule({
  declarations: [FoodtypeComponent, FoodComponent],
  imports: [CommonModule, TranslateModule, SharedModule, FoodtypeRoutingModule]
})
export class FoodtypeModule {}
