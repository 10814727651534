import { Platform } from '@angular/cdk/platform';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mobile-dialog',
  templateUrl: './mobile-dialog.component.html',
  styleUrls: ['./mobile-dialog.component.scss']
})
export class MobileDialogComponent implements OnInit {
  @Output() closeEvent = new EventEmitter();

  mobileAppLink = '';

  constructor(private platform: Platform) {}

  ngOnInit(): void {
    this.mobileAppLink = this.platform.IOS
      ? 'https://apps.apple.com/pl/app/wciągnij-to/id1592732104'
      : 'https://play.google.com/store/apps/details?id=pl.abs.wcignijto&hl=pl&gl=PL';
  }

  close() {
    this.closeEvent.emit();
  }
}
