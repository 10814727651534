import PlaceResult = google.maps.places.PlaceResult;
import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import { UserLocation } from '@app/shared/model/userLocation';

export default function parseAddress(placeResult: PlaceResult): UserLocation {
  let address: UserLocation = {
    city: '',
    country: '',
    formatted_address: '',
    gres: null,
    house_number: '',
    lat: -1,
    lng: -1,
    post_code: '',
    street: ''
  };

  address.gres = placeResult;

  if (placeResult.formatted_address) address.formatted_address = placeResult.formatted_address;

  if (placeResult.geometry && placeResult.geometry.location) {
    address.lat = placeResult.geometry.location.lat();
    address.lng = placeResult.geometry.location.lng();
  }

  if (placeResult.address_components && placeResult.address_components.length > 0) {
    placeResult.address_components.forEach(value => {
      if (value.types.indexOf('street_number') > -1) {
        address.house_number = value.short_name;
      }
      if (value.types.indexOf('route') > -1) {
        address.street = value.long_name;
      }
      if (value.types.indexOf('postal_code') > -1) {
        address.post_code = value.short_name;
      }

      if (value.types.indexOf('locality') > -1) {
        address.city = value.long_name;
      }

      if (value.types.indexOf('country') > -1) {
        address.country = value.long_name;
      }
      if (value.types.indexOf('administrative_area_level_3') > -1) {
        //address.city = value.short_name;
        //console.log(value.short_name);
      }
    });
  }

  return address;
}
