import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Inject, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RevLocationIQContext } from '@app/shared/reversegeocode/reversegeocode.service';
import { environment } from '../../environments/environment';
import { NearestComponent } from './nearest/nearest.component';
import { APIFiltersContext, FiltersService } from './filters.service';
import { finalize } from 'rxjs/operators';
import { Logger } from '../core/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@app/core/http/loader.service';
import PlaceResult = google.maps.places.PlaceResult;
import { UserLocation } from '@app/shared/model/userLocation';
import { CookieService } from 'ngx-cookie-service';
import { APICitiesContext, CitiesService } from '../home/catalog/cities.service';
import { Meta, Title } from '@angular/platform-browser';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { StateService } from '@app/core/state.service';
import { Subscription } from 'rxjs';

const log = new Logger('search');

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
  loading: boolean;
  // @ViewChild( NearestComponent ) child: NearestComponent ;
  limit = 3;
  view_style = 0;
  consume_type = 1;
  target: string;
  @ViewChild(NearestComponent) child: NearestComponent;

  slickCatConfig = {
    slidesToShow: 10,
    slidesToScroll: 10,
    prevArrow: "<div class='slick-prev'><i class='fas fa-chevron-left'></i></div>",
    nextArrow: "<div class='slick-next'><i class='fas fa-chevron-right'></i></div>",
    dots: false,
    infinite: false,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1601,
        settings: {
          slidesToShow: 9,
          slidesToScroll: 9
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 481,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 361,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
      // {
      //   breakpoint: 320,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1
      //   }
      // }
    ]
  };

  advs: any = [];

  filterData: APIFiltersContext = {
    data: null,
    count: null
  };
  count: number;
  festivalCount: number;
  deliveryPriceOption: any[] = [
    {
      id: '0.00',
      label: 'DARMOWA'
    },
    {
      id: 5,
      label: 'Mniej niż 5 zł'
    },
    {
      id: 15,
      label: 'Mniej niż 15 zł'
    },
    {
      id: 30,
      label: 'Mniej niż 30 zł'
    },
    {
      id: 1000,
      label: 'Bez znaczenia'
    }
  ];

  userLocation: UserLocation = {
    lat: null,
    lng: null,
    formatted_address: null,
    city: null,
    country: null,
    post_code: null,
    street: null,
    house_number: null,
    gres: null
  };

  filter: any = {
    stringQuery: null,
    local_type: null,
    food_type: null,
    delivery_price: 75,
    min_order: null,
    delivery: undefined,
    average_time: 120,
    average_rank: 0,
    min_order_price: 75,
    city: null,
    filter: false,
    consume_type: 1
  };

  loadingCategories = true;
  festivalChecked = false;

  subscriptions: Array<Subscription> = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private router: Router,
    private filterService: FiltersService,
    public translate: TranslateService,
    private cookieService: CookieService,
    private citiesService: CitiesService,
    private metaTagService: Meta,
    private titleService: Title,
    private stateService: StateService
  ) {
    this.loaderService.isLoading.subscribe(v => {
      // console.log(v);
      this.loading = v;
    });

    this.activatedRoute.params.subscribe(params => {
      if (params.city && params.country) {
        this.citiesService
          .getCitiesQuote({
            slug: params.city,
            home: true,
            limit: 10
          })
          .pipe(
            finalize(() => {
              // this.isLoading = false;
            })
          )
          .subscribe((quote: APICitiesContext) => {
            if (quote) {
              this.filter.city = params.city;

              this.userLocation.city = quote.data[0].name[0].text;
              this.userLocation.formatted_address = this.userLocation.city;

              this.filter.country = quote.data[0].country;
              this.userLocation.country = quote.data[0].country;
              this.userLocation.lat = quote.data[0].lat;
              this.userLocation.lng = quote.data[0].lng;
              if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem('userLocation', JSON.stringify(this.userLocation));
              }
            }
          });
      }

      if (params.category) {
        this.filter.food_type = params.category;
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.filter.stringQuery = params.stringQuery;
    });

    // const curentNav = this.router.getCurrentNavigation();
    // if(curentNav.extras && curentNav.extras.state && curentNav.extras.state.deliveryType) {
    //   this.consume_type = curentNav.extras.state.deliveryType;
    //   this.filter.consume_type = curentNav.extras.state.deliveryType;
    // }

    this.subscriptions.push(
      this.stateService.consume_type$.subscribe(value => {
        this.consume_type = value;
        this.filter.consume_type = value;
      })
    );
  }

  ngOnInit() {
    this.target = environment.target;
    log.debug(this.userLocation);

    this.filterService
      .getFiltersQuote()
      .pipe(
        finalize(() => {
          // this.isLoading = false;
        })
      )
      .subscribe((quote: APIFiltersContext) => {
        if (quote) {
          log.debug(quote);
          this.filterData = quote;
          this.loadingCategories = false;
        }
      });

    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('userLocation')) {
        this.userLocation = JSON.parse(localStorage.getItem('userLocation'));
        log.debug(this.userLocation);
        this.metaTagService.updateTag({
          name: 'description',
          content: 'Wyszukaj restauracje w ' + this.userLocation.city + ' Zamów online na Wciagnij.to'
        });

        this.titleService.setTitle('Wyszukaj restauracje w ' + this.userLocation.city + ' Zamów online na Wciagnij.to');
      }
    }

    this.view_style = Number(this.cookieService.get('searchViewStyle'));
  }

  ngOnDestroy() {
    document.body.classList.remove('search');

    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  // isInViewport(element: HTMLElement) {
  //   const rect = element.getBoundingClientRect();
  //   return (
  //     rect.top >= 0 &&
  //     rect.left >= 0 &&
  //     rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
  //     rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  //   );
  // }

  // ngAfterViewChecked() {
  //   const categories = document.querySelectorAll('#categories .category');
  //   let width = 0;
  //   categories.forEach((catEl: HTMLElement) => {
  //     width += catEl.offsetWidth;
  //   })
  //   console.log('categories', categories)
  //   console.log('width', width)
  //
  //   const categoriesEl = document.querySelector('#categories') as HTMLElement;
  //   if(categoriesEl)
  //     categoriesEl.style.width = String(width + 'px');
  // }

  receiveMessage($event: any) {
    log.debug($event);
    this.count = $event.count;
    this.festivalCount = $event.festivalCount;
    this.advs = $event.promoData;
  }

  receivePromo($event: any) {
    log.debug($event);
    this.advs = $event.data;
  }

  ChangeDelivery(type: number) {
    this.filter.consume_type = type;
    this.stateService.consume_type$.next(type);
    this.child.UpdateFilter(this.filter);
    this.child.Search();
  }

  ChangeFoodType(food_type: string) {
    this.filter.food_type = food_type;
    this.child.UpdateFilter(this.filter);
    this.child.Search();
  }

  ChangeRate() {
    this.child.UpdateFilter(this.filter);
    this.child.Search();
  }

  ChangeAverageTime() {
    this.child.UpdateFilter(this.filter);
    this.child.Search();
  }

  ChangeDeliveryPrice() {
    this.child.UpdateFilter(this.filter);
    this.child.Search();
  }

  ChangeMinOrderPrice() {
    this.child.UpdateFilter(this.filter);
    this.child.Search();
  }

  ChangeStringQuery() {
    this.filter.stringQuery = this.filter.stringQuery;
    this.child.UpdateFilter(this.filter);
    this.child.Search();
  }

  FilterReset() {
    this.filter = {
      stringQuery: null,
      local_type: null,
      food_type: null,
      delivery_price: 75,
      min_order: null,
      delivery: this.filter.delivery,
      average_time: 120,
      average_rank: 0,
      min_order_price: 75,
      consume_type: this.filter.consume_type
    };
    this.festivalChecked = false;
    this.child.UpdateFilter(this.filter);
    this.child.Search();
  }

  ngAfterViewInit() {
    // child is set
    this.child.Search();
  }

  // changeView(i: number) {
  //   this.view_style = i;
  //
  //   this.cookieService.set('searchViewStyle', i.toString());
  // }
}
