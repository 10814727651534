<div class="row">
  <div class="col-md-3 col-12">
    <div class="position-relative" *ngIf="product.image_object">
      <img
        class="food_img"
        [lazyLoad]="product.image_object.c | getImageUrl"
        [defaultImage]="'assets/placeholder.svg'"
        *ngIf="product.image_object.c"
      />
      <img
        class="food_img"
        [lazyLoad]="logo | getImageUrl"
        [defaultImage]="'assets/placeholder.svg'"
        *ngIf="!product.image_object.c"
      />
    </div>
    <div class="position-relative" *ngIf="!product.image_object">
      <img class="food_img" [lazyLoad]="logo | getImageUrl" [defaultImage]="'assets/placeholder.svg'" />
    </div>
  </div>
  <div class="col-md-9 col-12">
    <!-- <bar-rating [(rate)]="rate" [max]="5" class="rate_product"></bar-rating> -->
    <button
      mat-icon-button
      aria-label="Example icon-button with a heart icon"
      ngxClipboard
      [cbContent]="
        base_url +
        '/' +
        route.snapshot.url[0] +
        '/' +
        route.snapshot.url[1] +
        '/' +
        route.snapshot.url[2] +
        '/' +
        route.snapshot.url[3] +
        '/' +
        (cat.name | translateString: translate.currentLang) +
        '/' +
        (product.name | translateString: translate.currentLang)
      "
      (cbOnSuccess)="copyInfo()"
      class="copy_button"
    >
      <i class="fas fa-link"></i>
    </button>
    <div class="title">{{ product.name | translateString: translate.currentLang }}</div>
    <div class="desc">{{ product.description | translateString: translate.currentLang }}</div>
    <div class="row rateandwariants">
      <div class="col-md-12">
        <!-- <div *ngIf="product.product_type == 1 && product.attributes.length > 1">
          <button
            mat-flat-button
            color="primary"
            *ngFor="let atr of product.attributes; index as i"
            [routerLink]=""
            class="mr-1 pizza_size"
            (click)="PickAttribute(i)"
            [ngClass]="{ active: picketAttribute === i }"
          >
            <img src="/assets/icon/pizza_icon-{{ atr.size }}.svg" />
            {{ atr.name | translateString: translate.currentLang }}
          </button>
        </div> -->
        <div *ngIf="product.attributes.length > 1">
          <div *ngIf="product.attributes.length > 3; else buttonsVariants">
            <mat-form-field>
              <mat-label translate>Choose a variant</mat-label>
              <mat-select>
                <mat-option *ngFor="let atr of product.attributes; index as i" [value]="i" (click)="PickAttribute(i)">
                  {{ atr.name | translateString: translate.currentLang }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <ng-template #buttonsVariants>
            <button
              mat-flat-button
              color="primary"
              *ngFor="let atr of product.attributes; index as i"
              [routerLink]=""
              class="mr-1"
              (click)="PickAttribute(i)"
              [ngClass]="{ active: picketAttribute === i }"
            >
              {{ atr.name | translateString: translate.currentLang }}
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row no-gutters">
  <div class="col-12 p-0">
    <!--<button
      mat-flat-button
      color="primary"
      (click)="AddToCart()"
      class="add_to_cart position-relative float-right"
      *ngIf="!orderedProduct.components.length"
    >
      Dodaj
    </button>-->

    <mat-expansion-panel class="configuration" *ngIf="orderedProduct.attribute.has_component">
      <mat-expansion-panel-header (click)="getComponents()">
        Dodatki
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-expansion-panel class="additions" *ngFor="let item of orderedProduct.attribute.componentCategory">
          <mat-expansion-panel-header
            >{{ item.name | translateString: translate.currentLang }}
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <div class="row" *ngIf="item.category_type === 0">
              <ng-container *ngFor="let comp of orderedProduct.components; index as i">
                <div
                  class="col-lg-4 col-md-6 col-12 component"
                  *ngIf="item.id == comp.component.category_id && comp.component.editable"
                >
                  <div class="row">
                    <div class="col-4 p-0 text-center">
                      <img [lazyLoad]="comp.component.icon | getImageUrl" [defaultImage]="'assets/placeholder.svg'" />
                    </div>
                    <div class="col-8">
                      <div class="title">{{ comp.component.name | translateString: translate.currentLang }}</div>

                      <div class="change_count" *ngIf="isOpen && place.place.remote_order">
                        <button mat-stroked-button color="primary" (click)="Minus(comp)">
                          <i class="fas fa-minus"></i>
                        </button>
                        <input name="count" value="1" [(ngModel)]="comp.count" />
                        <button mat-stroked-button color="primary" (click)="Plus(comp)">
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <div class="price">{{ comp.component.price | currency: ' ' }}zł</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row" *ngIf="item.category_type === 1">
              <ng-container *ngFor="let comp of orderedProduct.components; index as i">
                <div
                  class="col-lg-4 col-md-6 col-12 component"
                  *ngIf="item.id == comp.component.category_id && comp.component.editable"
                >
                  <div class="row">
                    <div class="col-4 p-0 text-center">
                      <img [lazyLoad]="comp.component.icon" [defaultImage]="'assets/placeholder.svg'" />
                    </div>
                    <div class="col-8">
                      <div class="title">{{ comp.component.name | translateString: translate.currentLang }}</div>
                      <div class="row">
                        <div class="col-12">
                          <mat-radio-button
                            name="{{ item.id }}"
                            class="example-radio-button"
                            color="primary"
                            (change)="SelectComponentRa(comp)"
                            [checked]="comp.checked"
                            *ngIf="isOpen && place.place.remote_order"
                          >
                            <span class="price">{{ comp.component.price | currency: ' ' }}zł</span>
                          </mat-radio-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="row ct2" *ngIf="item.category_type === 2">
              <ng-container *ngFor="let comp of orderedProduct.components; index as i">
                <div
                  class="col-lg-4 col-md-6 component"
                  *ngIf="item.id == comp.component.category_id && comp.component.editable"
                >
                  <div class="row">
                    <div class="col-4 p-0 text-center">
                      <img [lazyLoad]="comp.component.icon" [defaultImage]="'assets/placeholder.svg'" />
                    </div>
                    <div class="col-8">
                      <div class="title">{{ comp.component.name | translateString: translate.currentLang }}</div>

                      <div class="row">
                        <div class="col-12 d-flex align-items-center">
                          <mat-checkbox
                            color="primary"
                            (change)="SelectComponentCk(comp)"
                            [checked]="comp.checked"
                            *ngIf="isOpen && place.place.remote_order"
                          >
                            <span class="price">{{ comp.component.price | currency: ' ' }}zł</span>
                          </mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </mat-expansion-panel>
      </ng-template>
    </mat-expansion-panel>

    <div class="add_to_cart" [class.add_to_cartwc]="!orderedProduct.components.length">
      <div class="price">{{ orderedProduct.price + orderedProduct.sum_linked | currency: ' ' }}zł</div>
      <div class="change_count" *ngIf="isOpen && place.place.remote_order">
        <button mat-stroked-button color="primary" (click)="MinusProduct()">
          <i class="fas fa-minus"></i>
        </button>
        <input
          class="counter-{{ orderedProduct.id }}"
          type="text"
          name="count"
          value="{{ orderedProduct.count || 0 }}"
        />
        <button mat-stroked-button color="primary" (click)="PlusProduct()">
          <i class="fas fa-plus"></i>
        </button>
      </div>
      <button mat-flat-button color="primary" (click)="AddToCart()" *ngIf="isOpen && place.place.remote_order">
        Dodaj
      </button>
    </div>
  </div>
</div>

<!--<div class="row add-product">
  <div class="col-md-12 text-right">
    <div class="row options">
      <div class="col-12">
        <button mat-flat-button color="primary" (click)="AddToCart()">Dodaj</button>
      </div>
    </div>
  </div>
</div>-->
