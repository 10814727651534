import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostListener,
  Inject,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '@app/core';
import {
  CalculationType,
  OrderedProductModel,
  OrderModel,
  SelectedComponentModel
} from '../../shared/model/order.model';
import { MenuComponentModel, MenuProductModel, ComponentsForProduct } from '../../shared/model/menuProduct.model';
import { main } from '@angular/compiler-cli/src/main';
import { ToastrService } from 'ngx-toastr';
import { PlaceService } from '../place.service';
import { finalize } from 'rxjs/operators';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { RevLocationIQContext } from '@app/shared/reversegeocode/reversegeocode.service';
import { MatDialog } from '@angular/material/dialog';
import { LocationModalComponent } from '../../home/location/modal/location.modal';
import { StateService } from '@app/core/state.service';
import { environment } from '@env/environment';

const log = new Logger('product');

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnChanges {
  @Input()
  product: MenuProductModel;
  rate: 4;
  @Input()
  place: any;

  @Input()
  isOpen: boolean = true;

  @Input()
  logo: string;

  @Input()
  cat: any;

  @Input()
  consume_type: number;

  @Input()
  all_products: any;

  @Input()
  isHiddenPhoneNum: boolean;

  @Input()
  phoneNumber: string;

  @Output() messageEvent = new EventEmitter<OrderedProductModel>();

  @Output() showPhoneNumberEvent = new EventEmitter();

  picketAttribute = 0;

  orderedProduct: OrderedProductModel = {
    product: null,
    id: null,
    attribute: null,
    components: [],
    count: 1,
    order: 1,
    comment: null,
    status: 0,
    price: 0,
    price_net: 0,
    price_vat: 0,
    sum: 0,
    sum_net: 0,
    sum_vat: 0,
    discount: null,
    is_additional: false,
    linked: [],
    sum_linked: 0
  };

  order: OrderModel;
  cart: any = [];
  base_url: any; // = location.origin;
  productUrl: any;
  mediumSize = false;
  userLocation: RevLocationIQContext = {
    lon: null,
    lat: null,
    display_name: null,
    class: null,
    icon: null,
    importance: null,
    licence: null,
    osm_id: null,
    osm_type: null,
    place_id: null,
    type: null,
    address: {
      city: null,
      country: null,
      country_code: null,
      county: null,
      state: null,
      house_number: null,
      road: null,
      street: null,
      postcode: null
    }
  };

  moreOpen = false;
  isLoadingComponents = false;
  env = environment;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router,
    public route: ActivatedRoute,
    public translate: TranslateService,
    private toastr: ToastrService,
    private placeService: PlaceService,
    public dialog: MatDialog,
    private state: StateService
  ) {
    //this.getScreenSize();
  }

  screenWidth: number;

  ngOnInit() {
    this.Reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.consume_type) {
      this.Reset();
    }
  }

  getComponents() {
    this.isLoadingComponents = true;

    this.placeService
      .getComponentsForProduct(this.orderedProduct.product.id, this.orderedProduct.attribute.id, 1, 3)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            this.isLoadingComponents = false;
          }, 100);
        })
      )
      .subscribe((quote: ComponentsForProduct) => {
        log.debug(quote);
        if (quote) {
          this.product.attributes[this.picketAttribute].components = quote.components;
          this.product.attributes[this.picketAttribute].componentCategory = quote.componentCategory;
          this.Reset();
        }
      });
  }

  Reset() {
    this.orderedProduct.product = JSON.parse(JSON.stringify(this.product));
    this.orderedProduct.attribute = JSON.parse(JSON.stringify(this.product.attributes[this.picketAttribute]));
    this.orderedProduct.freeLimit = this.orderedProduct.attribute.free_components;
    //console.log(this.all_products);

    const selectedComponents = JSON.parse(JSON.stringify(this.product.attributes[this.picketAttribute].components));
    //log.debug(selectedComponents);
    this.orderedProduct.components = selectedComponents.map((comp: MenuComponentModel) => {
      const newComp: SelectedComponentModel = {
        component: comp,
        checked: comp.default_checked,
        count: comp.default_count,
        weight: comp.weight
      };
      return newComp as SelectedComponentModel;
    });
    // log.debug('test');
    // log.debug(this.orderedProduct.components);
    this.orderedProduct.count = 1;

    this.orderedProduct.price = this.CalculateProductPrice().product_price;
    this.orderedProduct.price_vat = this.CalculateProductPrice().product_vat;
    this.orderedProduct.price_net = this.CalculateProductPrice().product_net;

    this.orderedProduct.sum = this.CalculateProductPrice().product_price * this.orderedProduct.count;
    this.orderedProduct.sum_vat = this.CalculateProductPrice().product_vat * this.orderedProduct.count;
    this.orderedProduct.sum_net = this.CalculateProductPrice().product_net * this.orderedProduct.count;

    this.orderedProduct.sum_linked = 0;
    this.orderedProduct.linked = [];
    if (this.orderedProduct.attribute.linked) {
      for (const p of this.orderedProduct.attribute.linked.values()) {
        const linked_prod = this.all_products.get(p);
        if (linked_prod) {
          if (linked_prod.consume_type.includes(this.consume_type)) {
            const price = {
              product_price: linked_prod.attributes[0].price,
              product_vat: Math.round(linked_prod.attributes[0].price * (linked_prod.vat / 100 / 100) * 100) / 100,
              product_net:
                linked_prod.attributes[0].price -
                Math.round(linked_prod.attributes[0].price * (linked_prod.vat / 100 / 100) * 100) / 100
            };

            var orderedLinkedProduct: OrderedProductModel = {
              product: linked_prod,
              id: linked_prod.id,
              attribute: linked_prod.attributes[0],
              components: [],
              count: 1,
              order: 1,
              comment: null,
              status: 0,
              price: price.product_price,
              price_net: price.product_net,
              price_vat: price.product_vat,
              sum: price.product_price * 1,
              sum_net: price.product_net * 1,
              sum_vat: price.product_vat * 1,
              discount: null,
              is_additional: true,
              linked: [],
              sum_linked: 0,
              freeLimit: linked_prod.attributes[0].free_components
            };

            this.orderedProduct.linked.push(orderedLinkedProduct);
            this.orderedProduct.sum_linked += orderedLinkedProduct.price;
          }
        }
      }
    }
    //    JSON.parse(JSON.stringify(this.product.attributes[this.picketAttribute].components));
  }

  CalculateProductPrice() {
    // this.orderedProduct.price = this.orderedProduct.attribute.price;
    //console.log(this.orderedProduct.product.calculation_type);
    this.orderedProduct.freeLimit = this.orderedProduct.attribute.free_components;
    const price = {
      product_price: this.orderedProduct.attribute.price,
      product_vat:
        Math.round(this.orderedProduct.attribute.price * (this.orderedProduct.product.vat / 100 / 100) * 100) / 100,
      product_net:
        this.orderedProduct.attribute.price -
        Math.round(this.orderedProduct.attribute.price * (this.orderedProduct.product.vat / 100 / 100) * 100) / 100
    };

    let componentsMap = new Map();
    for (const obj of this.orderedProduct.components) {
      if (obj) {
        componentsMap.set(obj.component.id, obj);
      }
    }

    const sortStrategy = this.orderedProduct.product.free_ex_select_type;

    if (sortStrategy === 0) {
      //from cheapiest
      componentsMap = new Map([...componentsMap].sort((a, b) => a[1].component.price - b[1].component.price));
    }

    if (sortStrategy === 1) {
      //from most exprensive
      componentsMap = new Map([...componentsMap].sort((a, b) => b[1].component.price - a[1].component.price));
    }

    const old_product_price = JSON.parse(JSON.stringify(price.product_price));

    for (const p of componentsMap.values()) {
      // log.debug(p);
      if (p) {
        if (p.component.editable) {
          price.product_price =
            price.product_price +
            this.ComponentPriceDefault(p, this.orderedProduct, this.orderedProduct.product.calculation_type);
        }
      }
    }
    if (this.orderedProduct.product.calculation_type === CalculationType.pricePriority) {
      if (old_product_price > price.product_price) {
        price.product_price = old_product_price;
      } else {
        price.product_price = price.product_price;
      }
    }
    price.product_vat = Math.round(price.product_price * (this.orderedProduct.product.vat / 100 / 100) * 100) / 100;
    price.product_net = price.product_price - price.product_vat;

    //log.debug(price);
    return price;
  }

  ComponentPriceDefault(
    component: SelectedComponentModel,
    orderedProduct: OrderedProductModel,
    price_calculation_type: number = 0
  ) {
    let price = 0;
    let isFree = component.component.free_components;
    //let pricableCount = component.count;
    let pricableCount = component.count - component.component.default_count;

    if (price_calculation_type === CalculationType.default) {
      if (component.checked && !component.component.default_checked) {
        if (orderedProduct.freeLimit > 0 && isFree) {
          if (orderedProduct.freeLimit >= 1) {
            orderedProduct.freeLimit -= 1;
            price = price + 0;
          } else {
            price = price + component.component.price;
          }
        } else {
          price = price + component.component.price;
        }
      }
      if (!component.checked && component.component.default_checked) {
        price = price - component.component.price;
      }
      if (component.count !== component.component.default_count) {
        if (orderedProduct.freeLimit > 0 && isFree && pricableCount > 0) {
          if (orderedProduct.freeLimit - pricableCount >= 0) {
            orderedProduct.freeLimit -= pricableCount;
            pricableCount = 0;
          } else if (orderedProduct.freeLimit - pricableCount < 0) {
            pricableCount -= orderedProduct.freeLimit;
            orderedProduct.freeLimit = 0;
          }
        }

        price = pricableCount * component.component.price;
      }
    }
    if (price_calculation_type === CalculationType.noSubtract) {
      if (component.checked && !component.component.default_checked) {
        if (orderedProduct.freeLimit > 0 && isFree) {
          if (orderedProduct.freeLimit >= 1) {
            orderedProduct.freeLimit -= 1;
            price = price + 0;
          } else {
            price = price + component.component.price;
          }
        } else {
          price = price + component.component.price;
        }
      }
      if (!component.checked && component.component.default_checked) {
        price = price; // - component.component.price;
      }

      if (component.count !== component.component.default_count) {
        if (pricableCount <= 0) {
          price = price;
        } else {
          if (orderedProduct.freeLimit > 0 && isFree && pricableCount > 0) {
            if (orderedProduct.freeLimit - pricableCount >= 0) {
              console.log(
                component.component.name[0].text,
                orderedProduct.freeLimit,
                pricableCount,
                orderedProduct.freeLimit - pricableCount,
                'typ2: wieksz >= 0'
              );
              orderedProduct.freeLimit -= pricableCount;
              pricableCount = 0;
            } else if (orderedProduct.freeLimit - pricableCount < 0) {
              pricableCount -= orderedProduct.freeLimit;
              orderedProduct.freeLimit = 0;
            }
          }
          price = pricableCount * component.component.price;
        }
      }
    }
    if (price_calculation_type === CalculationType.pricePriority) {
      if (component.checked && !component.component.default_checked) {
        if (orderedProduct.freeLimit > 0 && isFree) {
          if (orderedProduct.freeLimit >= 1) {
            orderedProduct.freeLimit -= 1;
            price = price + 0;
          } else {
            price = price + component.component.price;
          }
        } else {
          price = price + component.component.price;
        }
      }
      if (!component.checked && component.component.default_checked) {
        price = price - component.component.price;
      }
      if (component.count !== component.component.default_count) {
        if (orderedProduct.freeLimit > 0 && isFree && pricableCount > 0) {
          if (orderedProduct.freeLimit - pricableCount >= 0) {
            orderedProduct.freeLimit -= pricableCount;
            pricableCount = 0;
          } else if (orderedProduct.freeLimit - pricableCount < 0) {
            pricableCount -= orderedProduct.freeLimit;
            orderedProduct.freeLimit = 0;
          }
        }
        price = pricableCount * component.component.price;
      }
    }

    return price;
  }

  SelectComponentRa(comp: SelectedComponentModel) {
    for (const p of this.orderedProduct.components) {
      if (p) {
        if (p.component.category_id === comp.component.category_id) {
          p.count = 0;
          p.checked = false;
        }
      }
    }

    if (comp.checked === true) {
      comp.checked = false;
    } else {
      comp.checked = true;
    }
    this.orderedProduct.price = this.CalculateProductPrice().product_price;
    this.orderedProduct.price_vat = this.CalculateProductPrice().product_vat;
    this.orderedProduct.price_net = this.CalculateProductPrice().product_net;

    this.orderedProduct.sum = this.CalculateProductPrice().product_price * this.orderedProduct.count;
    this.orderedProduct.sum_vat = this.CalculateProductPrice().product_vat * this.orderedProduct.count;
    this.orderedProduct.sum_net = this.CalculateProductPrice().product_net * this.orderedProduct.count;
  }

  SelectComponentCk(comp: SelectedComponentModel) {
    if (comp.checked === true) {
      comp.checked = false;
    } else {
      comp.checked = true;
    }
    this.orderedProduct.price = this.CalculateProductPrice().product_price;
    this.orderedProduct.price_vat = this.CalculateProductPrice().product_vat;
    this.orderedProduct.price_net = this.CalculateProductPrice().product_net;

    this.orderedProduct.sum = this.CalculateProductPrice().product_price * this.orderedProduct.count;
    this.orderedProduct.sum_vat = this.CalculateProductPrice().product_vat * this.orderedProduct.count;
    this.orderedProduct.sum_net = this.CalculateProductPrice().product_net * this.orderedProduct.count;
  }

  Minus(comp: SelectedComponentModel) {
    if (comp.count > comp.component.min) {
      comp.count = comp.count - 1;
      this.orderedProduct.price = this.CalculateProductPrice().product_price;
      this.orderedProduct.price_vat = this.CalculateProductPrice().product_vat;
      this.orderedProduct.price_net = this.CalculateProductPrice().product_net;

      this.orderedProduct.sum = this.CalculateProductPrice().product_price * this.orderedProduct.count;
      this.orderedProduct.sum_vat = this.CalculateProductPrice().product_vat * this.orderedProduct.count;
      this.orderedProduct.sum_net = this.CalculateProductPrice().product_net * this.orderedProduct.count;
    }
  }

  Plus(comp: SelectedComponentModel) {
    if (comp.count < comp.component.max) {
      comp.count = comp.count + 1;
      this.orderedProduct.price = this.CalculateProductPrice().product_price;
      this.orderedProduct.price_vat = this.CalculateProductPrice().product_vat;
      this.orderedProduct.price_net = this.CalculateProductPrice().product_net;

      this.orderedProduct.sum = this.CalculateProductPrice().product_price * this.orderedProduct.count;
      this.orderedProduct.sum_vat = this.CalculateProductPrice().product_vat * this.orderedProduct.count;
      this.orderedProduct.sum_net = this.CalculateProductPrice().product_net * this.orderedProduct.count;
    }
  }

  MinusProduct() {
    if (this.orderedProduct.count > 1) {
      this.orderedProduct.count = this.orderedProduct.count - 1;
      this.orderedProduct.price = this.CalculateProductPrice().product_price;
      this.orderedProduct.price_vat = this.CalculateProductPrice().product_vat;
      this.orderedProduct.price_net = this.CalculateProductPrice().product_net;

      this.orderedProduct.sum = this.CalculateProductPrice().product_price * this.orderedProduct.count;
      this.orderedProduct.sum_vat = this.CalculateProductPrice().product_vat * this.orderedProduct.count;
      this.orderedProduct.sum_net = this.CalculateProductPrice().product_net * this.orderedProduct.count;

      this.orderedProduct.sum_linked = 0;
      this.orderedProduct.linked = [];

      if (this.orderedProduct.attribute.linked) {
        for (const p of this.orderedProduct.attribute.linked.values()) {
          const linked_prod = this.all_products.get(p);
          if (linked_prod) {
            if (linked_prod.consume_type.includes(this.consume_type)) {
              const price = {
                product_price: linked_prod.attributes[0].price,
                product_vat: Math.round(linked_prod.attributes[0].price * (linked_prod.vat / 100 / 100) * 100) / 100,
                product_net:
                  linked_prod.attributes[0].price -
                  Math.round(linked_prod.attributes[0].price * (linked_prod.vat / 100 / 100) * 100) / 100
              };

              var orderedLinkedProduct: OrderedProductModel = {
                product: linked_prod,
                id: linked_prod.id,
                attribute: linked_prod.attributes[0],
                components: [],
                count: this.orderedProduct.count,
                order: 1,
                comment: null,
                status: 0,
                price: price.product_price,
                price_net: price.product_net,
                price_vat: price.product_vat,
                sum: price.product_price * this.orderedProduct.count,
                sum_net: price.product_net * this.orderedProduct.count,
                sum_vat: price.product_vat * this.orderedProduct.count,
                discount: null,
                linked: [],
                sum_linked: 0,
                is_additional: true
              };

              this.orderedProduct.linked.push(orderedLinkedProduct);
              this.orderedProduct.sum_linked += orderedLinkedProduct.price;
            }
          }
        }
      }
    }
  }

  PlusProduct() {
    this.orderedProduct.count = this.orderedProduct.count + 1;
    this.orderedProduct.price = this.CalculateProductPrice().product_price;
    this.orderedProduct.price_vat = this.CalculateProductPrice().product_vat;
    this.orderedProduct.price_net = this.CalculateProductPrice().product_net;

    this.orderedProduct.sum = this.CalculateProductPrice().product_price * this.orderedProduct.count;
    this.orderedProduct.sum_vat = this.CalculateProductPrice().product_vat * this.orderedProduct.count;
    this.orderedProduct.sum_net = this.CalculateProductPrice().product_net * this.orderedProduct.count;

    this.orderedProduct.sum_linked = 0;
    this.orderedProduct.linked = [];

    if (this.orderedProduct.attribute.linked) {
      for (const p of this.orderedProduct.attribute.linked.values()) {
        const linked_prod = this.all_products.get(p);
        if (linked_prod) {
          if (linked_prod.consume_type.includes(this.consume_type)) {
            const price = {
              product_price: linked_prod.attributes[0].price,
              product_vat: Math.round(linked_prod.attributes[0].price * (linked_prod.vat / 100 / 100) * 100) / 100,
              product_net:
                linked_prod.attributes[0].price -
                Math.round(linked_prod.attributes[0].price * (linked_prod.vat / 100 / 100) * 100) / 100
            };

            var orderedLinkedProduct: OrderedProductModel = {
              product: linked_prod,
              id: linked_prod.id,
              attribute: linked_prod.attributes[0],
              components: [],
              count: this.orderedProduct.count,
              order: 1,
              comment: null,
              status: 0,
              price: price.product_price,
              price_net: price.product_net,
              price_vat: price.product_vat,
              sum: price.product_price * this.orderedProduct.count,
              sum_net: price.product_net * this.orderedProduct.count,
              sum_vat: price.product_vat * this.orderedProduct.count,
              discount: null,
              linked: [],
              sum_linked: 0,
              is_additional: true
            };

            this.orderedProduct.linked.push(orderedLinkedProduct);
            this.orderedProduct.sum_linked += orderedLinkedProduct.price;
          }
        }
      }
    }
  }

  openSearchDialog(category: string) {
    //event.preventDefault();

    //if (this.userLocation && this.userLocation.lat)
    //return this.router.navigateByUrl(`szukaj/kategoria/${this.translate.currentLang}/${category}`);

    this.dialog.open(LocationModalComponent, {
      data: {
        category: category,
        hideButtons: true
      }
    });
  }

  AddToCart() {
    this.state.cartIsLoading(true);
    //log.debug(this.cart);

    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('userLocation')) {
        this.userLocation = JSON.parse(localStorage.getItem('userLocation'));
        this.toastr.success('Produkt dodany do koszyka', null, {
          positionClass: 'toast-bottom-center'
        });
        this.orderedProduct.id = this.orderedProduct.product.id;
        const _copy = JSON.parse(JSON.stringify(this.orderedProduct));
        this.messageEvent.emit(_copy);

        this.Reset();
      } else {
        this.openSearchDialog('test');
      }
    } else {
      //window.location.hostname = 'localhost';
    }
  }

  PickAttribute(i: number) {
    this.picketAttribute = i;
    this.Reset();
    if (this.orderedProduct.product.attributes[0].has_component) this.getComponents();
  }

  getCategoryNameById(id: any) {
    //console.log('Log pace: ');
    //console.log(this.place);
    let cat: any = this.place.place.category.find((e: any) => e.id === id);
    if (cat) {
      let lang = cat.name.find((e2: any) => e2.locale === this.translate.currentLang);
      if (lang) return lang.text;
      else return cat.name.find((e2: any) => e2.default === true).text;
    }
  }

  copyInfo() {
    this.translate.get('Copied').subscribe((res: string) => {
      this.toastr.success(res);
    });
  }

  toggleMore(hasComponent: boolean) {
    if (!this.moreOpen && hasComponent) this.getComponents();

    this.moreOpen = !this.moreOpen;
  }

  showPhoneNumber() {
    this.showPhoneNumberEvent.emit();
  }
}
