export class SeoMeta {
  city: string;
  desc: string;
  state: string;
  text_bottom: string;
  text_top: string;
  title: string;
  type: string;
}

export class SeoPlace {
  city: string;
  id: string;
  name: string;
  slug: string;
}

export class SeoPlacesResp {
  data: SeoPlace[];
  seo: SeoMeta;
  count: number;
}
