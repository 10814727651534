export class SeoFoodMeta {
  rodzaj: string;
  desc: string;
  state: string;
  text_bottom: string;
  text_top: string;
  title: string;
  type: string;
}

export class SeoFoodPlaces {
  id: string;
  name: string;
  slug: string;
  city: string;
}

export class SeoFoodCity {
  city: string;
  locals: SeoFoodPlaces[];
}

export class SeoFoodResp {
  data: SeoFoodCity[];
  seo: SeoFoodMeta;
  count: number;
}
