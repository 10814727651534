import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Logger } from './logger.service';
import { ReverseGeocodeContext } from '@app/shared/reversegeocode/reversegeocode.service';

const log = new Logger('ip');
const routes = {
  quote: () => `/getip`
};
@Injectable()
export class IpService {
  constructor(private httpClient: HttpClient) {}

  getIpAddress(): Observable<any> {
    return this.httpClient
      .disableApiPrefix()
      .skipSource()
      .cache(true)
      .get(routes.quote())
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not load your location'))
      );
  }

  /*   {
        return this.http
            .get('http://freegeoip.net/json/?callback')
            .map(response => response || {})
            .catch(this.handleError);
    }*/

  /* private handleError(error: HttpErrorResponse):
        Observable<any> {
        console.error('observable error: ', error);
        return Observable.throw(error);
    }*/
}
