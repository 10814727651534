import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RevLocationIQContext } from '@app/shared/reversegeocode/reversegeocode.service';

const routes = {
  quote: () => `/Filters`
};

export interface APIFiltersContext {
  data: [
    {
      id: string;
      name: any[];
      type: string;
      uid: string;
      slug: string;
    }
  ];
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  constructor(private httpClient: HttpClient) {}

  getFiltersQuote(): Observable<any> {
    const data: any = [];
    return this.httpClient
      .skipLoader()
      .cache()
      .post<APIFiltersContext>(routes.quote(), data)
      .pipe(
        map((body: APIFiltersContext) => body),
        catchError(() => of('Error, could not load nearest restaurants'))
      );
  }
}
