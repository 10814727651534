import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { SearchComponent } from './search.component';

const routes: Routes = [
  Shell.childRoutes([{ path: 'szukaj', component: SearchComponent }]),
  Shell.childRoutes([{ path: 'szukaj/restauracja/:lang/:country/:city', component: SearchComponent }]),
  Shell.childRoutes([{ path: 'szukaj/kategoria/:lang/:category', component: SearchComponent }])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SearchRoutingModule {}
