<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <div class="oferta_container" style="min-height:  320px; display: flex; flex-direction: column;">
      <h1 class="text-center page-title">Kontakt</h1>
      <div>
        <p class="no-offers text-center ">
          W razie pytań lub wątpliwości, jesteśmy do Państwa dyspozycji!<br /><br /><br />
        </p>
      </div>

      <div class="row justify-content-md-center">
        <div class="col-md-5">
          <p class="text">
            <span class="highlight">Pomoc techniczna:</span><span> 34 300 00 79</span><br />
            <span class="highlight">Biuro Obsługi:</span
            ><span> <a href="mailto:biuro@abspos.pl" class="color-inherit"> biuro@abspos.pl</a></span>
          </p>

          <p class="text">
            <span class="highlight">Dział sprzedaży</span><br />
            <span>Mateusz - <a href="mailto:mateusz@abspos.pl" class="color-inherit">mateusz@abspos.pl</a></span
            ><br />
            <span>Katarzyna - <a href="mailto:katarzyna@abspos.pl" class="color-inherit">katarzyna@abspos.pl</a></span>
          </p>
        </div>
        <div class="col-md-5">
          <p class="text">
            <span class="highlight">Adres firmy</span><br />
            <span>WCIAGNIJ.TO PROSTA SPÓŁKA AKCYJNA</span><br />
            <span>Ul. Kilińskiego 86 | 42-218 Częstochowa</span><br />
            <span>NIP: 5732930988</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
