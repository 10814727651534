<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <h1 class="text-center">Polityka prywatności</h1>
    <div class="entry-content content-page " itemprop="text">
      <div style="font-family: sans-serif;">
        <h2>Dane GRUPA ABS:</h2>
        <p>
          Nazwa: WCIAGNIJ.TO PROSTA SPÓŁKA AKCYJNA<br />
          Adres siedziby: Kilińskiego 86, 42-218 Częstochowa<br />
          NIP: 5732930988<br />
          REGON: 520539073<br />
          Numer telefonu kontaktowego: +48790326700<br />
          Adres email kontaktowy: biuro@grupa-abs.pl<br />
          Adres korespondencyjny: ul. Kilińskiego 86, 42-218 Częstochowa<br />
          Adres strony internetowej: www.abspos.pl
        </p>
        <h2>Oferta produktowa GRUPA ABS:</h2>
        <p>GRUPA ABS, jako producent oprogramowania, posiada w swojej ofercie nw. produkty:</p>
        <p>
          <strong>ABS-POS</strong> aplikacja dedykowana dla podmiotów prowadzących lokale gastronomiczne, służąca do ich
          obsługi, zarządzania zachodzącymi w nich procesami. Zasady korzystania z aplikacji ABS-POS określa Regulamin
          dostępny na stronie internetowej <a href="https://abspos.pl/regulamin">abspos.pl/regulamin</a>
        </p>
        <h2>Cele Polityki Prywatności:</h2>
        <p>
          Dokument niniejszy, określa szczegółowe zasady i cele przetwarzania danych osobowych użytkowników, gromadzone
          podczas korzystania z poszczególnych aplikacji lub serwisów internetowych należących do ABS. Polityka
          Prywatności określa w szczególności rodzaje gromadzonych danych oraz prawa Użytkowników, związane z
          przetwarzaniem danych.
        </p>
        <h2>Zgodność z przepisami prawa:</h2>
        <p>
          Polityka Prywatności jest zgodna z ogólnie obowiązującymi przepisami prawa, w szczególności stanowi wyraz
          realizacji obowiązków wynikających z Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
          kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
          swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE oraz z Ustawy z dnia 10 maja 2018 r. o
          ochronie danych osobowych, Dz. U. 2018.100.
        </p>
        <h2>Słowniczek pojęć:</h2>
        <p>Ilekroć w Polityce Prywatności mowa jest o:</p>
        <ol>
          <li>
            <strong>Operatorze płatności online</strong> – należy przez to rozumieć &nbsp;PayLane Sp. z o.o. z siedzibą
            w Gdańsku (80-280), przy ul. C. K. Norwida 4, zarejestrowana w Rejestrze Przedsiębiorców Krajowego Rejestru
            Sądowego prowadzonym przez Sąd Rejonowy Gdańsk-Północ w Gdańsku, VII Wydział Gospodarczy KRS, pod numerem
            KRS 0000227278, NIP 586-214-10-89, REGON 220010531
          </li>
          <li>
            <strong>Użytkowniku ABS-POS</strong> – należy przez to rozumieć osobę fizyczna prowadzącą działalność
            gospodarczą, osobę prawną lub jednostkę organizacyjną nie posiadającą osobowości prawnej, posiadającą
            zdolność prawną i zdolność do czynności prawnych, która korzysta z aplikacji
          </li>
          <li>
            <strong>Użytkowniku EASY QR MENU</strong> – należy przez to rozumieć każdą osobę fizyczną, posiadającą co
            najmniej ograniczoną zdolność do czynności prawnych i ukończone 18 lat, osobę prawną lub jednostkę
            organizacyjną nie posiadającą osobowości prawnej, posiadającą zdolność prawną i zdolność do czynności
            prawnych, która korzysta z aplikacji
          </li>
          <li>
            <strong>Użytkowniku Wciągnij To</strong> – należy przez to rozumieć każdą osobę fizyczną, posiadającą co
            najmniej ograniczoną zdolność do czynności prawnych i ukończone 18 lat, osobę prawną lub jednostkę
            organizacyjną nie posiadającą osobowości prawnej, posiadającą zdolność prawną i zdolność do czynności
            prawnych, która korzysta z aplikacji
          </li>
          <li>
            <strong>Danych osobowych </strong>– należy przez to rozumieć informacje o&nbsp;zidentyfikowanej lub możliwej
            do zidentyfikowania osobie fizycznej („osobie, której dane dotyczą”); możliwa do zidentyfikowania osoba
            fizyczna to osoba, którą można bezpośrednio lub pośrednio zidentyfikować, w&nbsp;szczególności na podstawie
            identyfikatora takiego jak imię i&nbsp;nazwisko, numer identyfikacyjny, dane o&nbsp;lokalizacji,
            identyfikator internetowy lub jeden bądź kilka szczególnych czynników określających fizyczną, fizjologiczną,
            genetyczną, psychiczną, ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej;
          </li>
        </ol>
        <h2>Podstawy prawne:</h2>
        <p>
          Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r.<br />
          w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
          takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).
        </p>
        <ul>
          <li>Ustawa z dnia 23 kwietnia 1964 r. Kodeks cywilny, oraz przepisy aktów wykonawczych.</li>
          <li>Ustawa z dnia 29 sierpnia 1997 r. Ordynacja podatkowa.</li>
          <li>Ustawa z dnia 11 marca 2004 r. o podatku od towarów i usług.</li>
          <li>Ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną.</li>
          <li>Ustawa z dni 16 lipca 2004 r. Prawo telekomunikacyjne.</li>
        </ul>
        <h2>Przetwarzanie danych osobowych w związku z korzystaniem z aplikacji ABS-POS</h2>
        <p><strong>Rejestracja:</strong></p>
        <p>
          Warunkiem rozpoczęcia nieodpłatnego korzystania z ABS-POS jest rejestracja Użytkownika ABS-POS z
          wykorzystaniem Formularza Rejestracyjnego.
        </p>
        <p>
          Warunkiem rozpoczęcie komercyjnego korzystania z ABS-POS jest dokonanie aktywacji takiej funkcjonalności z
          wykorzystaniem Formularza Aktywacyjnego.
        </p>
        <p><strong>Administrator Danych Osobowych:</strong></p>
        <p>
          Administratorem Danych Osobowych, gromadzonych za pośrednictwem Formularza Rejestracyjnego oraz Formularza
          Aktywacyjnego jest ABS, który odpowiednio do zakresu realizowanych celów, na zasadach wskazanych w niniejszej
          Polityce Prywatności, przetwarza dane osobowe.
        </p>
        <p><strong>Zakres przetwarzanych danych osobowych:</strong></p>
        <p>Dane podane w Formularzu Rejestracyjnym obejmują:</p>
        <ul>
          <li>Nazwa</li>
          <li>NIP</li>
          <li>Email</li>
          <li>Numer telefonu</li>
          <li>Adres</li>
        </ul>
        <p>Dane podane w Formularzu Aktywacyjnym obejmują:</p>
        <ul>
          <li>
            Imię i Nazwisko osoby upoważnionej do reprezentowania Użytkownika ABS-POS uprawnionej do zawarcia umowy
            zgodnie z prawem.
          </li>
          <li>Adres korespondencyjny.</li>
        </ul>
        <p><strong>Cele przetwarzania danych osobowych:</strong></p>
        <p>Dane podane w Formularzu Rejestracyjnym oraz Formularzu Aktywacyjnym przetwarzane są odpowiednio w celu:</p>
        <ul>
          <li>
            <strong>Zawarcia i wykonania umowy</strong> przedmiotem której jest udostępnienie funkcjonalności ABS-POS,
            <strong>nieodpłatnie</strong>, przez okres 30 dni od dnia rejestracji:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong>Zawarcia i wykonania umowy</strong> przedmiotem, której jest udostępnienie funkcjonalności ABS-POS
            <strong>na zasadach komercyjnych</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong>Wystawienia stosownych dokumentów księgowych</strong>, prawem określonych:<br />
            – w oparciu o art. 6 ust. 1 lit. c) RODO
          </li>
          <li>
            <strong>Obsługi zgłoszeń reklamacyjnych</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO<br />
            – w oparciu o art. 6 ust. 1 lit. f) RODO
          </li>
          <li>
            <strong>Prowadzenia korespondencji emial</strong>
            <strong>w związku z zawarciem i wykonaniem umowy</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong>Podyktowanym względami natury technicznej</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO<br />
            – w oparciu o art. 6 ust. 1 lit. f) RODO
          </li>
          <li>
            <strong>Ustalenia i ewentualnego dochodzenia roszczeń</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. f) RODO
          </li>
          <li>
            <strong>Obsługi płatności online</strong>, przy udostępnieniu funkcjonalności ABS-POS na zasadach
            komercyjnych,<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong>Marketingowym</strong> tj. przesyłania informacji handlowych, za pośrednictwem email, SMS/MMS, lub
            za pośrednictwem telefonu, pod warunkiem jednak, iż wyrażenia została wyraźnie zgoda na powyższe<br />
            – w oparciu o art. 6 ust. 1 lit. a) RODO<br />
            – w oparciu o art. 6 ust. 1 lit. f) RODO<br />
            – art. 10 ust. 1 i 2 Ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną,<br />
            – art. 172 Ustawy z dni 16 lipca 2004 r. Prawo telekomunikacyjne.
          </li>
        </ul>
        <p><strong>Obowiązek informacyjny:</strong></p>
        <p>
          ABS, jako Administrator Danych Osobowych, działając zgodnie z art. 13 ust. 1 i 2 Rozporządzenia RODO,
          realizuje obowiązek informacyjny, poprzez udostępnienie treści niniejszej Politykii Prywatności oraz
          adekwatnych klauzul informacyjnych:
        </p>
        <ul>
          <li>w aplikacji ABS-POS,</li>
          <li>na stronie internetowej <a href="https://abspos.pl/rodo">abspos.pl/rodo</a></li>
        </ul>
        <p>
          ABS, jako Administrator Danych Osobowych, działając zgodnie z art. 14 ust. 1 i 2 Rozporządzenia RODO, zleca
          realizację obowiązku informacyjnego w swoim imieniu Użytkownikowi ABS-POS, względem podmiotów danych innych
          niż Użytkownik ABS-POS, których dane osobowe pozyskane zostały nie od nich samych a od Użytkownika ABS-POS.
          Użytkownik ABS-POS zobowiązuje się do realizacji powyższego zobowiązania na zasadach określonych w
          Rozporządzeniu RODO.
        </p>
        <h2>Przetwarzanie danych osobowych w z wykorzystaniem logów na serwerze</h2>
        <p><strong>Przetwarzanie danych:</strong></p>
        <p>
          Warunkiem poprawnego funkcjonowania aplikacji ABS-POS jest przetwarzanie danych osobowych z wykorzystaniem
          logów na serwerach.
        </p>
        <p><strong>Administrator Danych Osobowych:</strong></p>
        <p>
          Administratorem Danych Osobowych, przetwarzanych z wykorzystaniem logów na serwerze jest ABS, który
          odpowiednio do zakresu realizowanych celów, na zasadach wskazanych w niniejszej Polityce Prywatności,
          przetwarza dane osobowe.
        </p>
        <p><strong>Zakres przetwarzanych danych osobowych:</strong></p>
        <p>
          Dane gromadzone w logach na serwerach. ABS gromadzi w logach na serwerach informacje o wszystkich połączeniach
          wykonanych przez urządzenia, na których zainstalowano aplikację ABS-POS.
        </p>
        <ul>
          <li>Adres IP</li>
          <li>Data połączenia</li>
          <li>Adres URL</li>
          <li>Nazwa urządzenia</li>
          <li>Wersja aplikacji</li>
        </ul>
        <p><strong>Cele przetwarzania danych osobowych:</strong></p>
        <p>Dane gromadzone w logach na serwerach podane wyżej przetwarzane są w celu:</p>
        <ul>
          <li>
            <strong>Poprawnego wykonania umowy</strong> przedmiotem której jest udostępnienie funkcjonalności ABS-POS,
            <strong>nieodpłatnie</strong>, przez okres 30 dni od dnia rejestracji:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong>Poprawnego wykonania umowy</strong> przedmiotem, której jest udostępnienie funkcjonalności ABS-POS
            <strong>na zasadach komercyjnych</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong>Wystawiania stosownych dokumentów księgowych związanych z zawarciem umowy<br /> </strong>– w oparciu
            o art. 6 ust. 1 lit. b) RODO<br />
            – w oparciu o art. 6 ust. 1 lit. c) RODO
          </li>
          <li>
            <strong>Prowadzenia korespondencji emial</strong>
            <strong>w związku z zawarciem i wykonaniem umowy</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong>Obsługi zgłoszeń reklamacyjnych</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO<br />
            – w oparciu o art. 6 ust. 1 lit. f) RODO
          </li>
          <li>
            <strong>Podyktowanym względami natury technicznej</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO<br />
            – w oparciu o art. 6 ust. 1 lit. f) RODO
          </li>
          <li>
            <strong>Ustalenia i ewentualnego dochodzenia roszczeń</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. f) RODO
          </li>
          <li>
            <strong>Obsługi płatności online</strong>, przy udostępnieniu funkcjonalności ABS-POS na zasadach
            komercyjnych,<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong>Marketingowym</strong> tj. przesyłania informacji handlowych, za pośrednictwem email, SMS/MMS, lub
            za pośrednictwem telefonu, pod warunkiem jednak, iż wyrażenia została wyraźnie zgoda na powyższe<br />
            – w oparciu o art. 6 ust. 1 lit. a) RODO<br />
            – w oparciu o art. 6 ust. 1 lit. f) RODO<br />
            – art. 10 ust. 1 i 2 Ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną,<br />
            – art. 172 Ustawy z dni 16 lipca 2004 r. Prawo telekomunikacyjne.
          </li>
        </ul>
        <h2>Przetwarzanie danych osobowych w serwisie internetowym.</h2>
        <p><strong>Przetwarzanie danych:</strong></p>
        <p>
          Warunkiem poprawnego funkcjonowania aplikacji ABS-POS jest przetwarzanie danych osobowych z wykorzystaniem
          serwisu internetowego prowadzonego pod adresem abspos.pl, za którego pośrednictwem dokonywany jest proces
          rejestracji oraz przekazywane są treści informacyjne, jak również który wykorzystywany jest do kontaktu z ABS
          za pośrednictwem formularzy kontaktowych.
        </p>
        <p><strong>Administrator Danych Osobowych:</strong></p>
        <p>
          Administratorem Danych Osobowych, przetwarzanych z wykorzystaniem serwisu internetowego, jest ABS, który
          odpowiednio do zakresu realizowanych celów, na zasadach wskazanych w niniejszej Polityce Prywatności,
          przetwarza dane osobowe.
        </p>
        <p><strong>Zakres przetwarzanych danych osobowych:</strong></p>
        <p>Przetwarzane dane to:</p>
        <ul>
          <li>
            Dane podane w Formularzy Rejestracyjnym<br />
            Nazwa<br />
            NIP<br />
            Email<br />
            Numer telefonu<br />
            Adres<br />
            Adres IP
          </li>
          <li>
            Dane podane za pośrednictwem email<br />
            Adres email<br />
            Treść zawarta w korespondencji
          </li>
          <li>
            Dane podane za pośrednictwem Formularza Kontaktowego<br />
            Nazwa / Imię<br />
            Numer telefonu<br />
            Adres email<br />
            Treść zawarta w korespondencji
          </li>
          <li>
            Informacje o wyrażeniu zgody na przetwarzanie danych w celach marketingowych z wykorzystaniem środków
            komunikacji elektronicznej
          </li>
          <li>
            Informacje o wyrażeniu zgody na przetwarzanie danych w celach marketingowych z wykorzystaniem telefonu
            komórkowego
          </li>
          <li>Informacje gromadzone za pośrednictwem plików cookies</li>
        </ul>
        <p><strong>Cele przetwarzania danych osobowych:</strong></p>
        <p>Dane przetwarzane są w celu:</p>
        <ul>
          <li>
            <strong>Zawarcia i wykonania umowy</strong> przedmiotem której jest udostępnienie funkcjonalności ABS-POS,
            <strong>nieodpłatnie</strong>, przez okres 30 dni od dnia rejestracji:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong>Zawarcia i wykonania umowy</strong> przedmiotem, której jest udostępnienie funkcjonalności ABS-POS
            <strong>na zasadach komercyjnych</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong>Wystawienia stosownych dokumentów księgowych</strong>, prawem określonych:<br />
            – w oparciu o art. 6 ust. 1 lit. c) RODO
          </li>
          <li>
            <strong>Obsługi zgłoszeń reklamacyjnych</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO<br />
            – w oparciu o art. 6 ust. 1 lit. f) RODO
          </li>
          <li>
            <strong>Prowadzenia korespondencji emial</strong>
            <strong>w związku z zawarciem i wykonaniem umowy</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong>Podyktowanym względami natury technicznej</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO<br />
            – w oparciu o art. 6 ust. 1 lit. f) RODO
          </li>
          <li>
            <strong>Ustalenia i ewentualnego dochodzenia roszczeń</strong>:<br />
            – w oparciu o art. 6 ust. 1 lit. f) RODO
          </li>
          <li>
            <strong>Obsługi płatności online</strong>, przy udostępnieniu funkcjonalności ABS-POS na zasadach
            komercyjnych,<br />
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong
              >Świadczenia usług drogą elektroniczną w zakresie udostępniana treści gromadzonych w serwisie</strong
            >
            – w oparciu o art. 6 ust. 1 lit. b) RODO
          </li>
          <li>
            <strong
              >Dokonywania analiz oraz tworzenia statystyk w związku z poprawną funkcjonalności serwisu oraz
              aplikacji</strong
            >
            – w oparciu o art. 6 ust. 1 lit. f) RODO
          </li>
          <li>
            <strong>Prowadzenia komunikacji</strong>, tj. udzielania odpowiedzi na zapytania skierowane za pośrednictwem
            email, albo Formularza Kontaktowego – w oparciu o art. 6 ust. 1 lit. b) RODO oraz, , – w oparciu o art. 6
            ust. 1 lit. f) RODO
          </li>
          <li>
            <strong>Marketingowym</strong> tj. przesyłania informacji handlowych, za pośrednictwem email, SMS/MMS, lub
            za pośrednictwem telefonu, pod warunkiem jednak, iż wyrażenia została wyraźnie zgoda na powyższe<br />
            – w oparciu o art. 6 ust. 1 lit. a) RODO<br />
            – w oparciu o art. 6 ust. 1 lit. f) RODO<br />
            – art. 10 ust. 1 i 2 Ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną,<br />
            – art. 172 Ustawy z dni 16 lipca 2004 r. Prawo telekomunikacyjne.
          </li>
        </ul>
        <p><strong>Formularze Kontaktowe:</strong></p>
        <p>
          ABS zapewnia możliwość kontaktu z wykorzystaniem Formularzy Kontaktowych zamieszczonych w serwisach
          internetowych prowadzonych przez ABS albo z wykorzystaniem poczty elektronicznej email. Warunkiem prowadzenia
          komunikacji z wykorzystaniem opisanych kanałów jest podanie danych osobowych:
        </p>
        <ul>
          <li>Nazwa / Imię</li>
          <li>Numer telefonu</li>
          <li>Adres email</li>
        </ul>
        <h2>
          ABS, jako podmiot przetwarzający dane na zlecenie odrębnego Administratora Danych Osobowych, na podstawie
          stosownej umowy powierzenia przetwarzania.
        </h2>
        <p><strong>Przetwarzanie danych:</strong></p>
        <p>
          Wybrane funkcjonalności aplikacji ABS działają z wykorzystaniem tzw. rozwiązania chmurowego, którym zarządza i
          które administruje ABS. W odniesieniu do danych osobowych przetwarzanych z wykorzystaniem tzw. rozwiązania
          chmurowego ABS pełni rolę procesora, podmiotu przetwarzającego, działającego wyłącznie na zlecenie odrębnego
          Administratora Danych Osobowych, jakie są przetwarzane w tzw. rozwiązaniu chmurowym. ABS udostępnia
          funkcjonalność polegającą na możliwości zawarcia z nim Umowy powierzenia przetwarzania, o jakiej mowa w art.
          28 Rozporządzenia RODO, z wykorzystaniem przygotowanego rozwiązania elektronicznego, dostępnego z poziomu
          Konta Użytkownika. Szczegółowe warunki przetwarzania danych osobowych przez ABS, jako procesora, określa
          zawarta Umowa powierzenia przetwarzania z Administratorem Danych Osobowych oraz Regulamin świadczenia usługi
          tzw. rozwiązania chmurowego.
        </p>
        <h2>Odbiorcy danych osobowych / Podmioty przetwarzające:</h2>
        <p>
          Dane osobowe mogą być udostępniane niektórym podmiotom zewnętrznym, tzw. odbiorcom, którzy świadczą na rzecz
          ABS usługi związane z bieżącą działalnością:
        </p>
        <ul>
          <li>dostawcom usług w chmurze, w której przechowywane są dane osobowe,</li>
          <li>dostawcom usługi newslettera,</li>
          <li>dostawcą innych systemów e-mail,</li>
          <li>operatorom systemów informatycznych,</li>
          <li>operatorom systemów sms,</li>
          <li>operatorom systemów płatności,</li>
          <li>firmom księgowym,</li>
          <li>firmom audytorskim,</li>
          <li>firmom prawniczym.</li>
        </ul>
        <p>
          Wskazani odbiorcy mogą przetwarzać dane osobowe wyłącznie na potrzeby usług świadczonych na rzecz ABS, jako
          podmioty przetwarzające, na podstawie Umowy powierzenia przetwarzania, zawartej zgodnie z art. 28
          Rozporządzenia RODO.
        </p>
        <h2>Okres przetwarzania danych osobowych:</h2>
        <p>
          Okres przetwarzania danych osobowych przez Administratora Danych Osobowych zależy od rodzaju świadczonej
          usługi / umowy zawartej przez strony, celu przetwarzania, obowiązujących przepisów prawa.
        </p>
        <p>Jeśli podstawę prawną przetwarzania danych osobowych stanowi:</p>
        <ul>
          <li>
            <strong>umowa stron</strong>, dane osobowe są przetwarzane przez czas trwania takiej umowy, a następnie do
            czasu przedawnienia ewentualnych roszczeń z tej umowy i wyłączenie na potrzeby rozpatrzenia, zabezpieczenia
            takich roszczeń, jak również przez czas określony przepisami prawa,
          </li>
          <li>
            <strong>zgoda</strong> <strong>Użytkownika</strong>, dane osobowe są przetwarzane do czasu cofnięcia takiej
            zgody, a następnie do czasu przedawnienia ewentualnych roszczeń wynikających z przetwarzania danych
            osobowych i wyłączenie na potrzeby rozpatrzenia, zabezpieczenia takich roszczeń,
          </li>
          <li>
            <strong>uzasadniony interes</strong> <strong>Administratora Danych Osobowych</strong>, dane osobowe są
            przetwarzane do czasu zgłoszenia sprzeciwu wobec takiego przetwarzania, a następnie do czasu przedawnienia
            ewentualnych roszczeń wynikających z przetwarzania danych osobowych i wyłączenie na potrzeby rozpatrzenia,
            zabezpieczenia takich roszczeń,
          </li>
          <li>
            <strong
              >zapytanie skierowane do Administratora Danych Osobowych za pośrednictwem formularza / zapytania </strong
            >dane osobowe przetwarzane są przez okres obsługi danego zapytania, a następnie przez okres 6 miesięcy.
          </li>
        </ul>
        <p>
          Po upływie okresu przetwarzania danych osobowych, dane osobowe są nieodwracalnie usuwane lub anonimizowane,
          albo usuwane.
        </p>
        <h2>Uprawnienia podmiotów danych:</h2>
        <p>W związku z przetwarzaniem danych można żądać od ADO</p>
        <ul>
          <li><strong>dostępu</strong> do danych osobowych dotyczących podmiotu danych,</li>
          <li><strong>sprostowania</strong> danych osobowych dotyczących podmiotu danych,</li>
          <li>
            <strong>usunięcia</strong> danych osobowych dotyczących podmiotu danych, w&nbsp;sytuacji, gdy przetwarzanie
            danych nie następuje w&nbsp;celu wywiązania się z&nbsp;obowiązku wynikającego z&nbsp;przepisu prawa,
          </li>
          <li><strong>ograniczenia</strong> przetwarzania danych osobowych dotyczących podmiotu danych,</li>
          <li><strong>przeniesienia </strong>danych osobowych dotyczących podmiotu danych,</li>
          <li>
            wniesienia <strong>sprzeciwu</strong> wobec przetwarzania danych osobowych dotyczących podmiotu danych,
          </li>
          <li>
            <strong>cofnięcia zgody</strong> na przetwarzanie danych, w dowolnym momencie bez konsekwencji dla
            przetwarzania, którego dokonano przed jej cofnięciem, jeśli dane zbierane są na podstawie zgody.
          </li>
        </ul>
        <p>
          Zakres każdego z tych praw oraz sytuacje, z których można z nich skorzystać, wynikają z przepisów RODO.Z praw
          tych może skorzystać podmiot danych składając wniosek u ADO. Przysługuje także prawo wniesienia skargi do
          Prezesa Urzędu Ochrony Danych Osobowych, gdy przetwarzanie danych osobowych narusza przepisy prawa.
        </p>
        <p>
          Wniosek / żądanie w zakresie praw opisanych, może zostać złożony w dowolnej formie ABS. W sytuacji, kiedy po
          złożeniu wniosku / żądania w danym zakresie ABS nie będzie w stanie zidentyfikować osoby składającej wniosek,
          zwróci się do wnioskodawcy o dodatkowe informacje, celem jej identyfikacji i ustalenia tożsamości, co jest
          warunkiem koniecznym. ABS odpowiada na wniosek / żądanie niezwłocznie, nie później niż w ciągu 30 dni od jego
          otrzymania. W przypadku obiektywnie uzasadnionych opóźnień w odpowiedzi, ABS poinformuje o tym fakcie
          wnioskodawcę, informując o przyczynie opóźnienia, a także terminie odpowiedzi. Odpowiedź doręczana jest
          wnioskodawcy w takiej samej formie, w jakiej została skierowana do ABS, chyba, że wnioskodawca wyraźnie
          zażądał innej drogi doręczenia.
        </p>
        <h2>Przekazywanie danych osobowych poza europejski obszar gospodarczy:</h2>
        <p>Dane osobowe nie będą przekazywane do państwa trzecich.</p>
        <h2>Bezpieczeństwo danych osobowych:</h2>
        <p>
          ABS przetwarza dane osobowe zgodnie z powszechnie obowiązującymi przepisami prawa, w zgodnie z wymaganiami
          określonymi w RODO, dokłada wszelkich starań, aby zapewnić wszelkie środki fizycznej, technicznej i
          organizacyjnej ochrony przed ich niedozwolonym lub niezgodnym z prawem przetwarzaniem oraz przypadkową utratą,
          zniszczeniem lub uszkodzeniem. ABS na bieżąco prowadzi analizę ryzyka w celu zapewnienia, że dane osobowe
          przetwarzane są w sposób bezpieczny, zapewniający przede wszystkim, że dostęp do nich mają jedynie osoby
          upoważnione i jedynie w zakresie, w jakim jest to niezbędne ze względu na wykonywane przez nie zadania.
          Ponadto ABS podejmuje wszelkie niezbędne działania, by jego podwykonawcy i inne podmioty współpracujące dawały
          gwarancję stosowania odpowiednich środków bezpieczeństwa w każdym przypadku, gdy przetwarzają dane osobowe na
          zlecenie ABS.
        </p>
        <h2>Zmiany Polityki Prywatności:</h2>
        <p>Polityka Prywatności jest na bieżąco weryfikowana i w razie potrzeby aktualizowana.</p>
        <p>&nbsp;</p>
        <h2><strong>Klauzula informacyjna:</strong></h2>
        <p style="text-align: center;">
          <strong>OBOWIĄZEK INFORMACYJNY RODO<br /> </strong><strong>dla Kontrahentów / Użytkowników ABS-POS</strong>
        </p>
        <p style="text-align: center;">
          Zgodnie z art. 13 ust. 1 i 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
          2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
          przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
          (Dz.U.UE.L.2016.119.1) (dalej „RODO”) informuje się co następuje
        </p>
        <table>
          <tbody>
            <tr>
              <td><strong>Dane Administratora Danych Osobowych [dalej ADO]</strong></td>
              <td>
                GRUPA ABS ADRIAN SADOWSKI<br />
                KUSIĘTA 343, 42-256 OLSZTYN<br />
                NIP: 6112537808<br />
                REGON: 241693864
              </td>
            </tr>
            <tr>
              <td>
                <strong>Cel przetwarzania </strong>
                <p></p>
                <p><strong>danych osobowych</strong></p>
                <p><strong>Podstawa prawna przetwarzania</strong></p>
              </td>
              <td>
                Pana/Pani dane osobowe przetwarzane będą w związku z:
                <p></p>
                <ul>
                  <li>
                    <strong>z koniecznością zawarcia i wykonania umowy na podstawie złożonego zamówienia </strong
                    >przedmiotem której jest udostępnienie funkcjonalności ABS-POS nieodpłatnie / na zasadach
                    komercyjnych<strong> –</strong> w oparciu o art. 6 ust. 1 lit. b) RODO – podstawą przetwarzania jest
                    tutaj zawarcie i wykonanie umowy na żądanie Użytkownika ABS-POS,
                  </li>
                  <li>
                    <strong
                      >z koniecznością wystawiania stosownych dokumentów księgowych związanych z zawarciem umowy
                      –</strong
                    >
                    w oparciu o art. 6 ust. 1 lit. b) RODO – podstawą przetwarzania jest tutaj zawarcie i wykonanie
                    umowy na żądanie Użytkownika ABS-POS, oraz – w oparciu o art. 6 ust. 1 lit. c) RODO – podstawą
                    przetwarzania jest wypełnienie obowiązku prawnego ciążącego na ADO,
                  </li>
                  <li>
                    <strong>obsługą reklamacji </strong>– w oparciu o art. 6 ust. 1 lit. b) RODO – podstawą
                    przetwarzania jest tutaj wykonanie umowy na żądanie Użytkownika ABS-POS oraz – w oparciu o art. 6
                    ust. 1 lit. f) RODO – podstawą przetwarzania jest tutaj prawnie uzasadniony interes ADO,
                  </li>
                  <li>
                    <strong>prowadzeniem korespondencji email dotyczącej umowy </strong>– w oparciu o art. 6 ust. 1 lit.
                    b) RODO – podstawą przetwarzania jest tutaj wykonanie umowy na żądanie Użytkownika ABS-POS oraz – w
                    oparciu o art. 6 ust. 1 lit. f) RODO – podstawą przetwarzania jest tutaj prawnie uzasadniony interes
                    ADO,
                  </li>
                  <li>
                    <strong>potrzebami podyktowanymi względami natury technicznej </strong>– w oparciu o art. 6 ust. 1
                    lit. b) RODO – podstawą przetwarzania jest tutaj wykonanie umowy na żądanie Użytkownika ABS-POS oraz
                    – w oparciu o art. 6 ust. 1 lit. f) RODO – podstawą przetwarzania jest tutaj prawnie uzasadniony
                    interes ADO,
                  </li>
                  <li>
                    <strong>ustalaniem, dochodzeniem roszczeń </strong>– w oparciu o art. 6 ust. 1 lit f) RODO –
                    podstawą przetwarzania jest tutaj prawnie uzasadniony interes ADO.
                  </li>
                  <li>
                    <strong>obsługą płatności online</strong>, przy udostępnieniu funkcjonalności ABS-POS na zasadach
                    komercyjnych – w oparciu o art. 6 ust. 1 lit. b) RODO – podstawą przetwarzania jest tutaj wykonanie
                    umowy na żądanie Użytkownika ABS-POS
                  </li>
                  <li>
                    <strong
                      >wykorzystywaniem danych osobowych w celach marketingowych, (przesyłania informacji handlowych, za
                      pośrednictwem email, SMS/MMS i przez telefon)</strong
                    >
                    – pod warunkiem wyrażenia wyraźnej zgody przez Użytkownika ABS-POS na powyższe – w oparciu o art. 6
                    ust. 1 lit a) RODO – podstawą przetwarzania jest tutaj wyraźnie wyrażona zgoda art. 6 ust. 1 lit. a)
                    RODO, oraz – w oparciu o art. 6 ust. 1 lit f) RODO – podstawą przetwarzania jest tutaj prawnie
                    uzasadniony interes ADO, oraz – na podstawie art. 10 ust. 1 i 2 Ustawy z dnia 18 lipca 2002 r., o
                    świadczeniu usług drogą elektroniczną oraz – na podstawie art. 172 Ustawy z dni 16 lipca 2004 r.,
                    Prawo telekomunikacyjne.
                  </li>
                </ul>
                <p>
                  <strong
                    >Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r.<br />
                    w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
                    przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
                    danych).</strong
                  >
                </p>
                <p>
                  <strong>Ustawa z dnia 23 kwietnia 1964 r. Kodeks cywilny, oraz przepisy aktów wykonawczych.</strong>
                </p>
                <p><strong>Ustawa z dnia 29 sierpnia 1997 r. Ordynacja podatkowa.</strong></p>
                <p><strong>Ustawa z dnia 11 marca 2004 r. o podatku od towarów i usług.</strong></p>
                <p><strong>Ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną.</strong></p>
                <p><strong>Ustawa z dni 16 lipca 2004 r. Prawo telekomunikacyjne. </strong></p>
              </td>
            </tr>
            <tr>
              <td><strong>Informacja o dobrowolności podania danych osobowych.</strong></td>
              <td>
                Podanie danych jest dobrowolne, aczkolwiek ich niepodanie skutkuje brakiem możliwości zawarcia
                skutecznie umowy. W przypadku konieczności wystawiania stosownych dokumentów księgowych związanych z
                zawarciem umowy podanie danych jest obowiązkowe i wynika z przepisów prawa,<br />
                w szczególności z Ustawy z dnia 11 marca 2004 r. o podatku od towarów i usług oraz rozporządzeń
                wykonawczych.
              </td>
            </tr>
            <tr>
              <td>
                <strong
                  ><strong
                    >Odbiorcy danych.<br />
                    Udostępnianie danych.<br />
                    Powierzanie przetwarzania danych.</strong
                  ></strong
                >
              </td>
              <td>
                <strong>Odbiorcy danych:</strong>
                <p></p>
                <p>
                  Odbiorcą jest oznaczona osoba fizyczna lub prawną, organ publiczny, jednostka lub inny podmiot,
                  któremu ujawnia się dane osobowe, niezależnie od tego, czy jest stroną trzecią. Organy publiczne,
                  które mogą otrzymywać dane osobowe w ramach konkretnego postępowania zgodnie z prawem Unii lub prawem
                  państwa członkowskiego nie są jednak uznawane za odbiorców; przetwarzanie tych danych przez te organy
                  publiczne musi być zgodne z przepisami o ochronie danych mającymi zastosowanie odpowiednio do celów
                  przetwarzania.
                </p>
                <p><strong>Kategorie odbiorców:</strong></p>
                <ul>
                  <li>poczta,</li>
                  <li>kurier,</li>
                  <li>zewnętrzna kancelaria prawna,</li>
                  <li>operator płatności online,</li>
                  <li>operator systemów SMS,</li>
                </ul>
                <p><strong>Odbiorcy zdefiniowani:</strong></p>
                <p>
                  <strong>Operator płatności online: </strong>PayLane Sp. z o.o. z siedzibą w Gdańsku (80-280), przy ul.
                  C. K. Norwida 4, zarejestrowana w Rejestrze Przedsiębiorców Krajowego Rejestru Sądowego prowadzonym
                  przez Sąd Rejonowy Gdańsk-Północ w Gdańsku, VII Wydział Gospodarczy KRS, pod numerem KRS 0000227278,
                  NIP 586-214-10-89, REGON 220010531
                </p>
                <p>
                  Dane transakcyjne, w tym dane osobowe, mogą zostać przekazane na rzecz PayLane Sp. z o.o w zakresie
                  niezbędnym do obsługi płatności za zamówienie
                </p>
                <p><strong>Zlecenie przetwarzania danych osobowych:</strong></p>
                <p><strong>Kategorie podmiotów przetwarzających:</strong></p>
                <p>
                  Pani/Pana dane osobowe mogą być przekazywane podmiotom przetwarzającym dane osobowe na zlecenie ADO.
                </p>
                <ul>
                  <li>Dostawcom usług hostingowych,</li>
                  <li>dostawcom usług w tzw. rozwiązaniu chmurowym,</li>
                  <li>
                    firmom wykonującym czynności związane z utrzymaniem systemu teleinformatycznego i zapewniającym jego
                    bezpieczeństwo,
                  </li>
                  <li>dostawcom usługi newslettera,</li>
                  <li>dostawcom innych systemów e-mail,</li>
                  <li>operatorom systemów informatycznych,</li>
                  <li>firmie księgowej,</li>
                  <li>firmy audytorskie,</li>
                  <li>dostawcom usług IT technicznych, czy organizacyjnych które wspierających funkcjonowanie ADO.</li>
                </ul>
                <p>
                  Wskazane podmioty, mogą przetwarzać dane osobowe wyłącznie na polecenie i w imieniu ABS, jako podmioty
                  przetwarzające, na podstawie Umowy powierzenia przetwarzania, zawartej zgodnie z art. 28
                  Rozporządzenia RODO.
                </p>
                <p><strong>Podmioty przetwarzające zdefiniowane:</strong></p>
                <p>
                  Dostawca usług hostingowych: OVH Sp. z o.o.ul. Swobodna 1 50-088 WrocławNr KRS: 0000220286 REGON:
                  933029040 NIP: 899-25-20-556
                </p>
              </td>
            </tr>
            <tr>
              <td><strong>Okres przechowywania danych.</strong></td>
              <td>
                Okres przetwarzania Pani/Pana danych osobowych jest uzależniony od celu, w jakim dane są przetwarzane.
                Okres, przez który Pani/Pana dane osobowe będą przechowywane jest obliczany w oparciu o następujące
                kryteria: czas obowiązywania umowy, przepis prawa, cel przetwarzania.
                <p></p>
                <p>
                  Pani/Pana dane przechowywane będą w szczególności przez okresy prawem określone,<br />
                  w szczególności:
                </p>
                <ul>
                  <li>
                    zgodnie z <u>art. 112</u> Ustawy z dnia 11 marca 2004 r. o podatku od towarów i usług tj. przez
                    okres <strong>5 lat</strong>
                  </li>
                  <li>zgodnie z <u>art. 70 § 1</u> Ustawy z dnia 29 sierpnia 1997 r. Ordynacja podatkowa.</li>
                  <li>
                    <strong>6 lat</strong> – zgodnie z <u>art. 118</u> Ustawy z dnia 23 kwietnia 1964 r. Kodeks Cywilny.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td><strong>Prawa osoby której dane dotyczą.</strong></td>
              <td>
                Ma Pani/Pan prawo do:
                <p></p>
                <ul>
                  <li>żądania od ADO <strong>dostępu</strong> do danych osobowych Pani/Pana dotyczących,</li>
                  <li>żądania od ADO <strong>sprostowania</strong> danych osobowych Pani/Pana dotyczących,</li>
                  <li>
                    żądania od ADO <strong>usunięcia</strong> danych osobowych Pani/Pana dotyczących, w&nbsp;sytuacji,
                    gdy przetwarzanie danych nie następuje w&nbsp;celu wywiązania się z&nbsp;obowiązku wynikającego
                    z&nbsp;przepisu prawa,
                  </li>
                  <li>
                    żądania od ADO <strong>ograniczenia</strong> przetwarzania danych osobowych Pani/Pana dotyczących,
                  </li>
                  <li>żądania<strong> przeniesienia </strong>danych osobowych Pani/Pana dotyczących,</li>
                  <li>
                    wniesienia <strong>sprzeciwu</strong> wobec przetwarzania danych osobowych Pan/Pana dotyczących,
                  </li>
                  <li>
                    <strong>cofnięcia zgody</strong> w dowolnym momencie bez konsekwencji dla przetwarzania, którego
                    dokonano przed jej cofnięciem, jeśli dane zbierane są na podstawie zgody,
                  </li>
                </ul>
                <p>
                  Zakres każdego z tych praw oraz sytuacje, z których można z nich skorzystać, wynikają z przepisów
                  RODO.Z praw tych może Pani/Pan skorzystać składając wniosek u ADO. Ma Pani/Pan prawo wniesienia skargi
                  do Prezesa Urzędu Ochrony Danych Osobowych, gdy uzna Pani/Pan, iż przetwarzanie danych osobowych
                  Pani/Pana dotyczących narusza przepisy RODO.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
