<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <h1 class="text-center page-title">Zgłoś punkt gastro - <span class="highlight">daj się wciągnąć!</span></h1>
    <div class="row justify-content-md-center">
      <div class="col-md-8">
        <p class="text mtb-30" [hidden]="responseMessage">
          Znasz fajny punkt gastro a nie udało Ci się go zaleźć w naszej bazie? Daj nam znać!<br />
          Postaramy się, aby w najbliższym czasie do nas dołączyli
        </p>
        <p class="text text-center" style="padding: 45px 0" [hidden]="!responseMessage">{{ responseMessage }}</p>
      </div>
    </div>
    <form [formGroup]="form" [hidden]="responseMessage">
      <div class="row justify-content-md-center recommand-form-container">
        <div class="col-md-5">
          <mat-form-field appearance="outline" class="no-init-outline">
            <mat-label>Twoje imię</mat-label>
            <input matInput form formControlName="name" required />
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-init-outline">
            <mat-label>Adres e-mail</mat-label>
            <input matInput formControlName="email" required type="email" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-init-outline">
            <mat-label>Nazwa lokalu</mat-label>
            <input matInput formControlName="local_name" required />
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-init-outline">
            <mat-label>Miasto</mat-label>
            <input matInput formControlName="city" required />
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-init-outline">
            <mat-label>Opisz to miejsce lub dodaj coś od siebie</mat-label>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5" formControlName="info"></textarea>
          </mat-form-field>
        </div>

        <div class="col-md-5">
          <p class="text">
            Jeśli uda się podpisać z tym punktem gastro umowę otrzymasz od nas 100 ABS Coin na zamówienia w Easy QR Menu
            lub wciagnij.to.<br /><br />Czy wyrażasz zgodę?
          </p>
          <mat-radio-group formControlName="agreement">
            <div>
              <mat-radio-button [value]="true" color="primary">
                Tak
              </mat-radio-button>
            </div>
            <div>
              <mat-radio-button [value]="false" color="primary">
                Nie
              </mat-radio-button>
            </div>
          </mat-radio-group>

          <re-captcha formControlName="recaptchaReactive"></re-captcha>

          <button
            mat-raised-button
            color="primary"
            class="mtb-20"
            [disabled]="form.invalid || form.pristine"
            (click)="submit()"
          >
            zgłoś lokal
          </button>
        </div>
      </div>
    </form>
  </div>

  <app-footer></app-footer>
</div>
