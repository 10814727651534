import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DiscountRequestModel } from '@app/shared/model/discount.model';

const routes = {
  discountCode: () => `/calculateCode`
};

@Injectable({
  providedIn: 'root'
})
export class DiscountService {
  constructor(private httpClient: HttpClient) {}

  getDiscountCode(data: DiscountRequestModel) {
    return this.httpClient
      .skipLoader()
      .post<any>(routes.discountCode(), data)
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not load nearest restaurants'))
      );
  }
}
