<app-loader></app-loader>
<div [hidden]="loading" class="place position-relative">
  <app-header [place]="place" page="restaurant"></app-header>

  <!--  <div class="row selectconsumption">-->
  <!--    <div class="col-md-4 col-sm-12"></div>-->
  <!--    <div class="col-md-4 col-sm-12 text-center">-->
  <!--      <div class="btn-group switcher">-->
  <!--        <a-->
  <!--          [routerLink]=""-->
  <!--          class="btn btn-primary"-->
  <!--          (click)="ChangeDelivery(1)"-->
  <!--          [ngClass]="{-->
  <!--                    active: consume_type === 1-->
  <!--                  }"-->
  <!--          *ngIf="place.delivery"-->
  <!--        >Dostawa</a-->
  <!--        >-->
  <!--        <a-->
  <!--          [routerLink]=""-->
  <!--          class="btn btn-primary"-->
  <!--          (click)="ChangeDelivery(2)"-->
  <!--          [ngClass]="{-->
  <!--                    active: consume_type === 2-->
  <!--                  }"-->
  <!--          *ngIf="place.takeaway"-->
  <!--        >Na wynos</a-->
  <!--        >-->
  <!--        <a-->
  <!--          [routerLink]=""-->
  <!--          class="btn btn-primary"-->
  <!--          (click)="ChangeDelivery(3)"-->
  <!--          [ngClass]="{-->
  <!--                    active: consume_type === 3-->
  <!--                  }"-->
  <!--          *ngIf="place.on_local"-->
  <!--        >Na miejscu</a-->
  <!--        >-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="col-md-4 col-sm-12">-->
  <!--      <div class="text-right" *ngIf="!isOpen">Lokal zamknięty</div>-->
  <!--    </div>-->
  <!--  </div>-->

  <div id="twoColumns" [class.openedCart]="cartState && cartState.isOpen">
    <div class="placeContainer">
      <mat-button-toggle-group aria-label="Change delivery" class="change_delivery" [(value)]="consume_type">
        <mat-button-toggle
          [value]="1"
          [disabled]="!place.delivery"
          aria-label="Dostawa"
          (click)="ChangeDelivery(1)"
          disableRipple
        >
          <span translate>Delivery</span>
        </mat-button-toggle>
        <mat-button-toggle
          [value]="2"
          [disabled]="!place.takeaway"
          aria-label="Na wynos"
          (click)="ChangeDelivery(2)"
          disableRipple
        >
          <span translate>Na wynos</span>
        </mat-button-toggle>
        <mat-button-toggle
          [value]="3"
          [disabled]="!place.on_local"
          aria-label="Na miejscu"
          (click)="ChangeDelivery(3)"
          disableRipple
        >
          <span translate>In local</span>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div class="top_image"></div>

      <div class="container">
        <div class="place_info">
          <div class="left_row">
            <div class="image">
              <img [src]="place.logo | getImageUrl" />
            </div>
          </div>
          <div class="mid_row">
            <h2 class="name">{{ place.name }}</h2>

            <div class="buttons">
              <button mat-icon-button (click)="openInfoDialog()">
                <mat-icon>info</mat-icon>
              </button>

              <!-- <button mat-icon-button (click)="toggleCart()">
                <mat-icon [matBadge]="count" [matBadgeHidden]="!count" matBadgeColor="warn">shopping_cart</mat-icon>
              </button> -->
            </div>

            <p class="desc">
              {{ place.short_description | translateString: translate.currentLang }}
            </p>
          </div>
          <!--      <div class="right_row">-->
          <!--        <button mat-mini-fab color="none" (click)="openInfoDialog()">-->
          <!--          <mat-icon>info</mat-icon>-->
          <!--        </button>-->
          <!--      </div>-->
        </div>
      </div>

      <div class="container page_content_place">
        <div [class]="closeAllDay ? 'is_close' : 'remote_order'" *ngIf="!isOpen">
          <mat-icon style="margin-right: 15px">info icon</mat-icon>
          <span *ngIf="closeAllDay; else info">Lokal nieczynny</span>
          <ng-template #info
            >Lokal czynny od: {{ place.work_time[currentWeekDayIndex].open | date: 'HH:mm' }}</ng-template
          >
        </div>

        <!-- <div class="remote_order" *ngIf="!place.remote_order && place.phone_number">
          <mat-icon style="margin-right: 15px">info icon</mat-icon>
          Zamówienia tylko telefoniczne:
          <div
            *ngIf="isHiddenPhoneNum"
            (click)="showPhoneNumber()"
            style="text-decoration: underline;margin-left: 5px; color: white; cursor: pointer;"
          >
            Pokaz numer
          </div>
          <div *ngIf="!isHiddenPhoneNum">
            <a *ngIf="phoneNumber" href="tel:{{ phoneNumber }}">
              <span style="text-decoration: underline;margin-left: 5px; color: white">{{ phoneNumber }}</span>
            </a>
            <span *ngIf="!phoneNumber" style="margin-left: 5px;">Brak numeru</span>
          </div>
        </div> -->

        <div id="categoriesMenu" class="categories">
          <ng-container *ngFor="let item of place.category">
            <button
              mat-button
              (click)="toCategory(item.id)"
              class="category"
              *ngIf="item.destinations && (item.destinations.includes(3) === true || item.destinations.length === 0)"
              [class.mat-stroked-button]="item.id !== toCatId"
              [class.mat-flat-button]="item.id === toCatId"
              [color]="item.id == toCatId ? 'primary' : null"
              size="small"
            >
              {{ item.name | translateString: translate.currentLang }}
            </button>
          </ng-container>
        </div>

        <div *ngFor="let cat of place.category">
          <ng-container
            *ngIf="cat.destinations && (cat.destinations.includes(3) === true || cat.destinations.length === 0)"
          >
            <h4 id="{{ cat.id }}">{{ cat.name | translateString: translate.currentLang }}</h4>
            <div *ngFor="let prod of cat.products">
              <app-product
                #appProduct
                [product]="prod"
                [place]="placeCart"
                [logo]="place.logo"
                (messageEvent)="receiveMessage($event)"
                [cat]="cat"
                [id]="prod.id"
                [isOpen]="isOpen"
                [consume_type]="consume_type"
                [all_products]="all_products"
                [isHiddenPhoneNum]="isHiddenPhoneNum"
                [phoneNumber]="phoneNumber"
                (showPhoneNumberEvent)="showPhoneNumber()"
                *ngIf="prod.consume_type.indexOf(consume_type) > -1 && prod.destinations.includes(3)"
              ></app-product>
              <!--<div class="aditional_text">Dostępny od poniedziałku do piątku w godz.: 12:00-16:00</div>-->
            </div>
          </ng-container>
        </div>
      </div>
      <button mat-fab *ngIf="count > 0" (click)="toggleCart()" class="fab-cart" color="primary">
        <mat-icon [matBadge]="count" [matBadgeHidden]="!count" matBadgeColor="warn">shopping_cart</mat-icon>
      </button>
    </div>

    <div class="cartContainer">
      <app-cart></app-cart>
    </div>
  </div>

  <app-footer></app-footer>
</div>
