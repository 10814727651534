<footer>
  <div class="container-fluid text-center">
    <div class="row mobile-width">
      <ul class="footer-menu">
        <li><a routerLink="/polec-wciagnijto" routerLinkActive="active">Polecam restaurację</a></li>
        <li><a routerLink="/dolacz" routerLinkActive="active">Dołącz restaurację</a></li>
        <li><a routerLink="/oferty-pracy" routerLinkActive="active">Oferty pracy</a></li>
        <li><a routerLink="/regulamin" routerLinkActive="active">Regulamin</a></li>
        <li><a routerLink="/polityka-prywatnosci" routerLinkActive="active">Polityka prywatności</a></li>
        <li><a routerLink="/informacje-prawne" routerLinkActive="active">Informacja prawna</a></li>
        <li><a href="https://wciagnij.to/blog/" target="_blank" rel="noopener">Blog</a></li>
        <li><a routerLink="/cookies" routerLinkActive="active">Polityka cookies</a></li>
        <li><a routerLink="/kontakt" routerLinkActive="active">Kontakt</a></li>
      </ul>
    </div>
  </div>
  <div class="container-fluid">
    <div class="site-footer">
      <div class="row mobile-width">
        <div class="col-md-4 col-sm-12 imoje">
          Płatności obsługuje imoje
          <img class="imoje-icon" src="assets/imoje.png" />
          <img src="assets/ing_logo.png" />
        </div>
        <div class="col-md-4 col-sm-12 text-center coppyright">
          © 2022 wciagnij.to
        </div>
        <div class="col-md-4 col-sm-12">
          <ul class="social-icons">
            <li>
              <a href="https://www.youtube.com/channel/UC5MmBtPfjuRioHw3SZXh5wQ/featured"
                ><i class="fab fa-youtube"></i
              ></a>
            </li>
            <li>
              <a href="https://www.facebook.com/Wciagnij.to/"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
              <a href="https://www.instagram.com/wciagnijto/"><i class="fab fa-instagram"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>

<!--<script src="http://unpkg.com/slick-carousel@1.8.1/slick/slick.js"></script>-->
