<app-loader></app-loader>
<div [hidden]="loading" class="place position-relative">
  <div class="container-fluid">
    <div class="container page_content_place">
      <div class="row">
        <div class="col-md-12 order-1 order-md-2">
          <div class="row selectconsumption">
            <div class="col-md-4 col-sm-12"></div>
            <div class="col-md-4 col-sm-12 text-center">
              <div class="btn-group switcher">
                <a
                  [routerLink]=""
                  class="btn btn-primary"
                  (click)="ChangeDelivery(1)"
                  [ngClass]="{
                    active: consume_type === 1
                  }"
                  *ngIf="place.delivery"
                  >Dostawa</a
                >
                <a
                  [routerLink]=""
                  class="btn btn-primary"
                  (click)="ChangeDelivery(2)"
                  [ngClass]="{
                    active: consume_type === 2
                  }"
                  *ngIf="place.takeaway"
                  >Na wynos</a
                >
                <a
                  [routerLink]=""
                  class="btn btn-primary"
                  (click)="ChangeDelivery(3)"
                  [ngClass]="{
                    active: consume_type === 3
                  }"
                  *ngIf="place.on_local"
                  >Na miejscu</a
                >
              </div>
            </div>
            <div class="col-md-2 col-sm-12">
              <div class="text-right" *ngIf="!isOpen">Lokal zamknięty</div>
            </div>
            <div class="col-md-2 nav-item basket-container" ngbDropdown display="dynamic" placement="bottom right">
              <app-widget-basket></app-widget-basket>
            </div>
          </div>
          <div class="remote_order" *ngIf="!place.remote_order">
            <mat-icon style="margin-right: 15px">info icon </mat-icon> Zamówienia tylko telefoniczne:
            <a href="tel:{{ place.phone_number }}"
              ><span style="text-decoration: underline;margin-left: 5px; color: white">{{
                place.phone_number
              }}</span></a
            >
          </div>
          <mat-expansion-panel
            *ngFor="let cat of place.category"
            class="category_panel"
            [expanded]="cat.id === toCatId || place.category.length == 1"
          >
            <mat-expansion-panel-header [collapsedHeight]="'64px'">
              <mat-panel-title>
                <img
                  [lazyLoad]="cat.image_object.b | getImageUrl"
                  [defaultImage]="'assets/placeholder.svg'"
                  *ngIf="cat.image_object"
                />
                <h4 id="{{ cat.id }}">{{ cat.name | translateString: translate.currentLang }}</h4>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let prod of cat.products">
              <div class="single" *ngIf="prod.consume_type.indexOf(consume_type) > -1 && prod.destinations.includes(3)">
                <app-product
                  #appProduct
                  [product]="prod"
                  [place]="placeCart"
                  [logo]="place.logo"
                  (messageEvent)="receiveMessage($event)"
                  [cat]="cat"
                  [id]="prod.id"
                  [isOpen]="isOpen"
                  [consume_type]="consume_type"
                  [all_products]="all_products"
                ></app-product>
              </div>
              <!--<div class="aditional_text">Dostępny od poniedziałku do piątku w godz.: 12:00-16:00</div>-->
            </div>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
  </div>
</div>
