import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { extract } from '@app/core';
import { FoodtypeComponent } from './foodtype.component';
import { FoodComponent } from './food/food.component';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'zamow-jedzenie/:lang',
      component: FoodtypeComponent
    },
    {
      path: 'zamow-jedzenie/:lang/:rodzaj',
      component: FoodComponent
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class FoodtypeRoutingModule {}
