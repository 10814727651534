<div class="dialog">
  <div class="text">
    <h6>Korzystanie z Wciągnij.to za pomocą aplikacji jest przyjemniejsze</h6>
    <span
      >Nie przegap żadnych promocji oraz nowości. Zainstaluj aplikacje Wciągnij.to i korzystaj ze wszystkich
      funkcji.</span
    >
  </div>
  <div class="buttons">
    <button mat-stroked-button (click)="close()">Nie teraz</button>
    <button mat-flat-button color="light"><a [href]="mobileAppLink">Przejdź do aplikacji</a></button>
  </div>
</div>
