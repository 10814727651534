import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RevLocationIQContext } from '@app/shared/reversegeocode/reversegeocode.service';
import { ImageModel } from '@app/shared/model/image.model';
import PlaceResult = google.maps.places.PlaceResult;
import { UserLocation } from '@app/shared/model/userLocation';
import { PlaceModel } from '@app/shared/model/place.model';
const routes = {
  quote: () => `/restaurantsGeo`
};

export interface APIRestaurantsContext {
  data: PlaceModel[];
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class NearestService {
  constructor(private httpClient: HttpClient) {}

  getNearestQuote(context: UserLocation, filter: any): Observable<any> {
    var data: any = {};
    if ('lat' in context && 'lng' in context) {
      data = {
        lat: context.lat,
        lng: context.lng,
        filter: filter
      };
    }
    return this.httpClient
      .skipLoader()
      .cache()
      .post<APIRestaurantsContext[]>(routes.quote(), data)
      .pipe(
        map((body: APIRestaurantsContext[]) => body),
        catchError(() => of('Error, could not load nearest restaurants'))
      );
  }
}
