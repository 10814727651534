import { Component, Inject, Input, OnInit } from '@angular/core';
import { PlaceModel } from '@app/shared/model/place.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateStringPipe } from '@app/shared/translate-string.pipe';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  providers: [TranslateStringPipe]
})
export class InfoDialogComponent implements OnInit {
  place: PlaceModel;

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { place: PlaceModel }
  ) {
    this.place = data.place;
  }

  ngOnInit(): void {}
}
