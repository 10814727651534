import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PlaceModel } from '../shared/model/place.model';
import { ComponentsForProduct } from '../shared/model/menuProduct.model';

const routes = {
  quote: () => `/restaurant`,
  components: () => `/getComponents`
};

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  constructor(private httpClient: HttpClient) {}

  getPlaceQuote(slug: string) {
    const data: any = {
      slug: slug,
      debug: true
    };
    return this.httpClient
      .cache()

      .post<PlaceModel>(routes.quote(), data)
      .pipe(
        map((body: PlaceModel) => body),
        catchError(() => of('Error, could not load nearest restaurants'))
      );
  }

  getPlaceQuoteById(id: string) {
    const data: any = {
      id: id
    };
    return this.httpClient
      .cache()

      .post<PlaceModel>(routes.quote(), data)
      .pipe(
        map((body: PlaceModel) => body),
        catchError(() => of('Error, could not load nearest restaurants'))
      );
  }

  getComponentsForProduct(product_id: string, attribute_id: string, consumption_id: number, source: number) {
    const data: any = {
      product_id: product_id,
      attribute_id: attribute_id,
      source: source,
      consumption_type: consumption_id
    };
    return this.httpClient
      .cache()
      .skipLoader()
      .post<ComponentsForProduct>(routes.components(), data)
      .pipe(
        map((body: ComponentsForProduct) => body),
        catchError(() => of('Error, could not load nearest restaurants'))
      );
  }
}
