import { NameLocalizationModel } from './nameLocalization.model';
import { ImageModel } from './image.model';

export class MenuAttributeModel {
  id: string;
  price: number;
  name: NameLocalizationModel[];
  size: number;
  components: MenuComponentModel[];
  print_only_name: boolean;
  free_components: number;
  linked: string[];
  componentCategory: MenuComponentCategory[];
  has_component: boolean;
}

export class MenuComponentCategory {
  category_type: number;
  flag: number;
  id: string;
  local_id: string;
  name: NameLocalizationModel[];
  timestamp: number;
  type: string;
  uid: string;
}

export class ComponentsForProduct {
  components: MenuComponentModel[];
  componentCategory: MenuComponentCategory[];
}

export class PrinterTagModel {
  tag: string;
  id: string;
}

export class CutleryModel {
  type: number;
  name: string;
  count: number;
  id: string;
}

export class MenuProductModel {
  attributes: MenuAttributeModel[];
  category: string;
  category_group: string[];
  flag: number;
  sort: number;
  image: string;
  local_id: string;
  max: number;
  name: NameLocalizationModel[];
  image_object: ImageModel;
  product_type: number;
  tags: PrinterTagModel[];
  timestamp: number;
  type: string;
  uid: string;
  vat: number;
  destinations: number[];
  enabled: boolean;
  kitchen_display: boolean;
  out_of_stock: boolean;
  time: number;
  half_possible: boolean;
  cutlery: CutleryModel[];
  description: NameLocalizationModel[];
  short_name: string;
  calculation_type: number;
  free_ex_select_type: number;
  id: string;
  consume_type: number[];
  alcohol_group: number;
  collect_statistics: boolean;
  in_store: boolean;
  unit_group: number;
}

export class MenuComponentModel {
  id: string;
  price: number;
  name: NameLocalizationModel[];
  category_id: string;
  category: MenuComponentCategory;
  max: number;
  min: number;
  icon: number;
  default_count: number;
  default_checked: boolean;
  weight: number;
  editable: boolean;
  takeout: boolean;
  delivery: boolean;
  on_local: boolean;
  free_components: boolean;
  timestamp: number;
  type: string;
  flag: number;
  local_id: string;
  uid: string;
}
