<div class="cart" [ngClass]="{ hasItems: count > 0 }">
  <a id="cart-toogle" class="nav-link" (click)="openCart()">
    <i class="fas fa-shopping-basket"></i> Koszyk <span class="cart_count" *ngIf="count !== 0">{{ count }}</span>
  </a>

  <div id="cart-dropdown" ngbDropdownMenu aria-labelledby="menu-icon">
    <div class="dropdown-header text-center">
      <h3 class="m-0">Koszyk</h3>
    </div>

    <div class="dropdown-content">
      <div *ngIf="count !== 0; else noProducts">
        <div class="row product" *ngFor="let item of cart.order.products; index as i">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <div class="btn-group btn-group-vertical select_quantity">
              <button class="btn btn-xs btn-link plus" (click)="Plus(0, i)"><i class="fas fa-chevron-up"></i></button>
              <button class="btn btn-xs disabled quantity" disabled="">{{ item.count }}</button>
              <button class="btn btn-xs btn-link minus" (click)="Minus(0, i)">
                <i class="fas fa-chevron-down"></i>
              </button>
            </div>
          </div>
          <div class="col-7 d-flex align-items-center">
            {{ item.product.name | translateString: translate.currentLang }}
          </div>
          <div class="col-3 d-flex justify-content-right align-items-center">
            {{ item.price * item.count | currency: ' ' }} PLN
          </div>
          <div class="row product linked" *ngFor="let item_l of item.linked; index as j">
            <div class="col-2 d-flex justify-content-center align-items-center">
              <div class="btn-group btn-group-vertical select_quantity">
                <button class="btn btn-xs disabled quantity" disabled="">{{ item_l.count }}</button>
              </div>
            </div>
            <div class="col-7 d-flex align-items-center">
              {{ item_l.product.name | translateString: translate.currentLang }}
            </div>
            <div class="col-3 d-flex justify-content-right align-items-center">
              {{ item_l.price * item_l.count | currency: ' ' }} PLN
            </div>
          </div>
        </div>
      </div>

      <ng-template #noProducts>
        <div class="no_items">Brak produktów w koszyku</div>
      </ng-template>

      <div class="row" *ngIf="count !== 0">
        <div class="col-2 d-flex justify-content-center align-items-center">
          <img src="assets/icon/delivery.svg" class="delivery_icon" />
        </div>
        <div class="col-10 px-0 py-2">
          CENA DOSTAWY: <span class="price">{{ cart.order.delivery.price | currency: ' ' }} PLN</span>
        </div>
      </div>
    </div>

    <div class="row no-gutters bottom_buttons" *ngIf="count !== 0 && cart.place.min_order_price <= cart.bill.sum">
      <div class="col-10">
        <button type="button" routerLink="/checkout">Do kasy ({{ cart.bill.sum | currency: ' ' }} PLN)</button>
      </div>
      <div class="col-2">
        <button type="button" style="width: 101%;" (click)="Delete()"><i class="far fa-trash-alt"></i></button>
      </div>
    </div>
    <div class="row no-gutters bottom_buttons" *ngIf="count !== 0 && cart.place.min_order_price > cart.bill.sum">
      <div class="col-12 text-center text-danger">
        Do minimalnego zamówienia brakuje:
        {{ cart.place.min_order_price - (cart.bill.sum - cart.place.delivery_price) | number: '.2' }}
      </div>
    </div>
  </div>

  <!--<a routerLink="/checkout" routerLinkActive="active"><span translate>Cart</span></a>-->
</div>
