import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { PhoneappComponent } from './phoneapp/phoneapp.component';
import { FooterComponent } from '@app/shared/footer/footer.component';
import { HeaderComponent } from '@app/shared/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { HeaderHomeComponent } from './header-home/header-home.component';
import { HeaderSearchComponent } from './header-search/header-search.component';
import { BasketComponent } from './basket/basket.component';
import { TranslateStringPipe } from '../shared/translate-string.pipe';
import { NormalizeStringPipe } from './normalize-string.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GetimageurlPipe } from '@app/shared/getimageurl.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';
import { SlugifierPipe } from './slugifier.pipe';
import { CartComponent } from './cart/cart.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    TranslateModule,
    RouterModule,
    CommonModule,
    NgbModule,
    MatExpansionModule,
    FormsModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    HttpClientModule,
    InlineSVGModule,
    LazyLoadImageModule,
    MatIconModule
  ],
  declarations: [
    LoaderComponent,
    PhoneappComponent,
    FooterComponent,
    HeaderComponent,
    HeaderHomeComponent,
    HeaderSearchComponent,
    BasketComponent,
    TranslateStringPipe,
    NormalizeStringPipe,
    GetimageurlPipe,
    SlugifierPipe,
    CartComponent
  ],
  exports: [
    LoaderComponent,
    PhoneappComponent,
    FooterComponent,
    HeaderComponent,
    HeaderHomeComponent,
    HeaderSearchComponent,
    TranslateStringPipe,
    NormalizeStringPipe,
    GetimageurlPipe,
    SlugifierPipe,
    CartComponent
  ]
})
export class SharedModule {}
