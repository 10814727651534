<header class="hr_bottom" [ngClass]="{ 'padding-mobile': target === 'mobile' }">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-12 p-0">
        <div class="py-2">
          <a routerLink="" routerLinkActive="active" title="{{ 'Home' | translate }}">
            <img class="logo" src="/assets/logo_2020.svg" alt="{{ 'Home' | translate }}" />
          </a>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 p-0">
        <nav class="navbar navbar-expand-lg bg-transparent ">
          <button
            class="navbar-toggler"
            type="button"
            aria-controls="navbar-menu"
            aria-label="Toggle navigation"
            (click)="toggleMenu()"
            [attr.aria-expanded]="!menuHidden"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
            <div class="navbar-nav ml-auto">
              <a class="nav-item nav-link nav-separator" routerLink="/jak-dzialamy" routerLinkActive="active">
                <span translate>About us</span>
              </a>
              <a class="nav-item nav-link nav-separator" routerLink="/dla-restauracji" routerLinkActive="active">
                <span translate>For Office</span>
              </a>
              <app-basket></app-basket>

              <!-- <div class="nav-item color-wt" ngbDropdown display="dynamic" placement="bottom-right">
                <a id="language-dropdown" class="nav-link nav-separator" ngbDropdownToggle>{{ currentLanguage }}</a>
                <div ngbDropdownMenu aria-labelledby="language-dropdown">
                  <button class="dropdown-item" *ngFor="let language of languages" (click)="setLanguage(language)">
                    {{ language }}
                  </button>
                </div>
              </div>
              <div class="nav-item color-wt" ngbDropdown display="dynamic" placement="bottom-right">
                <a id="user-dropdown" class="nav-link" ngbDropdownToggle>
                  <i class="fas fa-user-circle"></i>
                </a>
                <div ngbDropdownMenu aria-labelledby="user-dropdown">
                  <h6 class="dropdown-header">
                    <span translate>Logged in as</span> <b>{{ username }}</b>
                  </h6>
                  <div class="dropdown-divider"></div>
                  <button class="dropdown-item" (click)="logout()" translate>Logout</button>
                </div>
              </div> -->
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
