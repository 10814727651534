<app-loader></app-loader>
<div [hidden]="loading" class="position-relative">
  <app-header></app-header>
  <div class="container page_content">
    <div class="oferta_container" style="min-height:  320px; display: flex; flex-direction: column;">
      <h1 class="text-center page-title">Strona nie istnieje</h1>
      <div style="display: flex; flex: 1; justify-content: center; align-items: center;">
        <p class="no-offers">
          Daj się wciągnąć na stronie: <br /><br /><a
            routerLink=""
            routerLinkActive="active"
            title="{{ 'Home' | translate }}"
          >
            <img class="logo" src="assets/logo_2020.svg" />
          </a>
        </p>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
