import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { CredentialsService } from './credentials.service';
import { combineAll } from 'rxjs/operators';
import { AuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private credentialsService: CredentialsService, private authService: AuthService) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: SocialUser): Observable<SocialUser> {
    // Replace by proper authentication call
    const data = context;
    this.credentialsService.setCredentials(data);
    return of(data);
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.authService.signOut();
    this.credentialsService.setCredentials();

    return of(true);
  }
}
