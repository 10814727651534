import { NgModule } from '@angular/core';
import { Routes, RouterModule, RoutesRecognized } from '@angular/router';

import { extract } from '@app/core';
import { HomeComponent } from './home.component';
import { Shell } from '@app/shell/shell.service';
import { DomainGuard } from '@app/core/domain/domain.guard';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// //console.log(window.location.hostname);
// const isCurrentDomainMain: boolean =
//   window.location.hostname === 'wciagnij.to' ||
//   window.location.hostname === 'dev.wciagnij.to' ||
//   window.location.hostname === 'localhost'; // local // prod

const routes: Routes = [
  Shell.childRoutes([
    {
      path: '',
      redirectTo: '/home',
      pathMatch: 'full'
    },
    {
      path: 'home',
      component: HomeComponent,
      canLoad: []
    }
  ])
];

// const routesRest: Routes = [
//   Shell.childRoutes([
//     {
//       path: 'home',
//       component: HomeComponent
//     }
//   ])
// ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule {}
